import React, { useState, useRef, useEffect } from 'react';
import { Whisper, Popover, Slider } from 'rsuite';
import 'rsuite/Slider/styles/index.css';
import 'rsuite/Popover/styles/index.css';
const ThumbnailSlider = ({ frameFiles, thumbnailUrls, selectedFrameIndex, onThumbnailSelected, viewportContainer, isSingleView, }) => {
    // The top of the slider is normally the slider's max value, but we want things the other way around.
    const invert = (value) => frameFiles.length - 1 - value;
    const [sliderValue, setSliderValue] = useState(invert(selectedFrameIndex));
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [isSliderActive, setIsSliderActive] = useState(false);
    const [isMouseOverPopover, setIsMouseOverPopover] = useState(false);
    const [sliderContainerHeight, setSliderContainerHeight] = useState(0);
    const [viewportContainerWidth, setViewportContainerWidth] = useState(viewportContainer ? viewportContainer.clientWidth : 0);
    const sliderContainerRef = useRef(null);
    const thumbnailRefs = useRef([]);
    const popoverRef = useRef(null);
    const popoverCloseTimeoutRef = useRef(null);
    const activeIndex = invert(sliderValue);
    // Respond to viewport resizing.
    useEffect(() => {
        if (!viewportContainer) {
            return;
        }
        const handleResize = (entries) => {
            for (let entry of entries) {
                if (entry.target === viewportContainer) {
                    setViewportContainerWidth(entry.contentRect.width);
                }
            }
        };
        const resizeObserver = new ResizeObserver(handleResize);
        resizeObserver.observe(viewportContainer);
        return () => {
            resizeObserver.disconnect();
        };
    }, [viewportContainer]);
    // Update slider height when container height changes.
    useEffect(() => {
        if (sliderContainerRef.current) {
            setSliderContainerHeight(sliderContainerRef.current.offsetHeight);
        }
    }, [sliderContainerRef.current ? sliderContainerRef.current.offsetHeight : 0]);
    // Update slider value when selectedFrameIndex or number of frames changes.
    useEffect(() => {
        setSliderValue(invert(selectedFrameIndex));
    }, [selectedFrameIndex, frameFiles.length]);
    // Scroll to active thumbnail when it changes.
    useEffect(() => {
        if (thumbnailRefs.current[activeIndex]) {
            thumbnailRefs.current[activeIndex]?.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        }
    }, [activeIndex]);
    // Manual logic to determine when to close the popover. Normally you would rely on the trigger and delay props of
    // the Whisper component, but their implementation is not ideal for users who value their sanity.
    useEffect(() => {
        if (isSliderActive || isMouseOverPopover) {
            // If either is active, clear any existing close timeout to prevent the popover from closing.
            if (popoverCloseTimeoutRef.current) {
                clearTimeout(popoverCloseTimeoutRef.current);
                popoverCloseTimeoutRef.current = null;
            }
            setIsPopoverOpen(true);
        }
        else {
            // Start a timeout to close the popover after a delay.
            popoverCloseTimeoutRef.current = setTimeout(() => {
                setIsPopoverOpen(false);
                popoverCloseTimeoutRef.current = null;
            }, 300);
        }
        // Cleanup function to clear timeout when component unmounts.
        return () => {
            if (popoverCloseTimeoutRef.current) {
                clearTimeout(popoverCloseTimeoutRef.current);
                popoverCloseTimeoutRef.current = null;
            }
        };
    }, [isSliderActive, isMouseOverPopover]);
    // Scroll to active thumbnail when popover opens. Normally you would use the onOpen prop of the Whisper component,
    // but it only executes after it has fully faded into view, causing the images to jump as the scroll happens.
    useEffect(() => {
        if (isPopoverOpen && thumbnailRefs.current[activeIndex]) {
            // We need to delay the scrolling to ensure the popover has begun rendering and the thumbnails are visible.
            setTimeout(() => {
                thumbnailRefs.current[activeIndex].scrollIntoView({
                    behavior: 'instant',
                    block: 'center'
                });
            }, 10);
        }
    }, [isPopoverOpen]);
    const handleSliderChange = (sliderValue) => {
        setSliderValue(sliderValue);
        onThumbnailSelected(invert(sliderValue));
    };
    const speaker = (React.createElement(Popover, { ref: popoverRef, style: { padding: 0, width: (isSingleView) ? `${(viewportContainerWidth / 2) * 0.2}px` : `${viewportContainerWidth * 0.2}px`, height: `${sliderContainerHeight}px`, overflowY: 'auto', marginLeft: '-2px' }, arrow: false, className: 'rs-theme-dark', onMouseEnter: () => setIsMouseOverPopover(true), onMouseLeave: () => setIsMouseOverPopover(false) },
        React.createElement("div", { style: { height: '100%', overflowY: 'auto' }, className: 'multi-historical-timeline', onFocus: () => setIsMouseOverPopover(true), onBlur: () => setIsMouseOverPopover(false) }, frameFiles.map((frameFile, index) => (React.createElement("div", { key: index, ref: (el) => (thumbnailRefs.current[index] = el), style: {
                padding: '0',
                margin: '0',
                cursor: 'pointer',
            }, className: index == activeIndex ? 'selected-frame' : 'unselected-frame', onClick: () => handleSliderChange(invert(index)) },
            frameFile.flagged && React.createElement("img", { src: 'flag.svg', className: 'multi-frame-flag', alt: 'Flagged' }),
            React.createElement("img", { src: thumbnailUrls[index] || "no-preview.png", alt: `Thumbnail ${index}`, style: { width: '100%', height: 'auto' } })))))));
    const handleWheel = (event) => {
        const delta = Math.sign(event.deltaY);
        const newValue = Math.max(0, Math.min(frameFiles.length - 1, sliderValue - delta));
        handleSliderChange(newValue);
    };
    return (React.createElement("div", { ref: sliderContainerRef, style: { display: 'flex', alignItems: 'center', height: '100%', flex: '0 0 min-content' }, className: 'rs-theme-dark' },
        React.createElement(Whisper, { placement: "right", trigger: "none", speaker: speaker, enterable: true, open: isPopoverOpen },
            React.createElement(Slider, { style: { zIndex: 1 }, vertical: true, min: 0, max: frameFiles.length - 1, value: sliderValue, onChange: handleSliderChange, step: 1, tooltip: false, onFocus: () => setIsSliderActive(true), onBlur: () => setIsSliderActive(false), onMouseEnter: () => setIsMouseOverPopover(true), onMouseLeave: () => setIsMouseOverPopover(false), onWheel: handleWheel }))));
};
export default ThumbnailSlider;
