import { map, defaultArg } from "../fable_modules/fable-library.3.7.12/Option.js";
import { PatientIdentidfiableInformation_get_id_, PatientIdentidfiableInformation_get_lastName_, PatientIdentidfiableInformation_get_firstName_, ReportType_get_display, ReportType_get_all, PatientIdentidfiableInformation_get_empty, ReportType, PatientIdentidfiableInformation_validate_Z2CA7CC3A } from "../Optiscan.Models/Shared.js";
import { compareArrays, uncurry, equals } from "../fable_modules/fable-library.3.7.12/Util.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.12/Choice.js";
import { Option, content } from "../fable_modules/Fulma.2.16.0/Elements/Content.fs.js";
import { sortBy, map as map_1, empty, ofArray, singleton } from "../fable_modules/fable-library.3.7.12/List.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { reportTableColumns, Msg } from "./DashboardTypes.js";
import { FormField_labeledField, FormField_onChangeSet, FormField_text, FormField_ReactSelect_CallbackSingle$1, FormField_ReactSelect_single } from "../Optiscan.SharedUI/Forms.js";
import { Option as Option_1, columns } from "../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { Option as Option_2, column } from "../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { Functions_flip } from "../fable_modules/Webbler.Models.1.2.6/Common.fs.js";
import * as react from "react";
import { Option as Option_3, div } from "../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { div as div_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/Control.fs.js";
import { Option as Option_4, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { Common_GenericOption, Color_IColor } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { HTMLAttr, DOMAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { Card_foot, Card_body, Card_head, Card_card, background, Option as Option_5, modal } from "../fable_modules/Fulma.2.16.0/Components/Modal.fs.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.12/MapUtil.js";
import { content as content_1, Header_icon, Header_title, header, card } from "../fable_modules/Fulma.2.16.0/Components/Card.fs.js";
import { toString } from "../fable_modules/fable-library.3.7.12/Types.js";
import { op_UnaryNegation_Int32 } from "../fable_modules/fable-library.3.7.12/Int32.js";
import { ListDisplayModification_ListDisplayModification$2_applyListModifications, Sort_itemViewListSortable } from "../Optiscan.SharedUI/Sort.js";

export function view(model, dispatch) {
    const piiValid = defaultArg(map(PatientIdentidfiableInformation_validate_Z2CA7CC3A, model.patientIdentidfiableInformation), equals(model.reportType, new ReportType(1)) ? (new FSharpResult$2(0, void 0)) : (new FSharpResult$2(1, "Data cannot be empty")));
    const canSaveReport = equals(piiValid, new FSharpResult$2(0, void 0));
    return content(singleton(new Option(1, "full-page-content")), toList(delay(() => {
        let dispatch_1, piiValid_1, identifiedPatientInfo, reportType, modalState, actionName, onSelect, reportInfo, body, children, footer;
        return append(singleton_1((dispatch_1 = dispatch, (piiValid_1 = piiValid, (identifiedPatientInfo = defaultArg(model.patientIdentidfiableInformation, PatientIdentidfiableInformation_get_empty()), (reportType = model.reportType, (modalState = model.maybeReportModalState, (actionName = ((modalState != null) ? ((modalState.tag === 1) ? "Download" : "") : ""), (onSelect = ((modalState != null) ? ((modalState.tag === 1) ? ((reportInfo = modalState.fields[0], new Msg(23, reportInfo, new Msg(14, reportInfo)))) : (new Msg(0, new Error("Invalid modal state")))) : (new Msg(0, new Error("Invalid modal state")))), (body = ((children = toList(delay(() => append(singleton_1(FormField_ReactSelect_single(ReportType_get_all(), [], ReportType_get_display(), reportType, new FormField_ReactSelect_CallbackSingle$1(0, (arg) => {
            dispatch_1(new Msg(21, arg));
        }), "Report Type", true, "Report", [])), delay(() => (equals(reportType, new ReportType(0)) ? singleton(columns(ofArray([new Option_1(1), new Option_1(2)]), toList(delay(() => {
            let f, optic, f_1, optic_2, f_2, optic_4;
            const setter = (arg_1) => {
                dispatch_1(new Msg(22, arg_1));
            };
            return singleton_1(column(singleton(new Option_2(3, singleton(["style", {
                overflowX: "auto",
            }]))), ofArray([FormField_text(identifiedPatientInfo.firstName, empty(), [], FormField_onChangeSet(setter, uncurry(2, (f = ((optic = PatientIdentidfiableInformation_get_firstName_(), (value_1) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic)(value_1))), (b) => ((a) => Functions_flip(uncurry(2, f), b, a))))), "Report", "First Name", []), FormField_text(identifiedPatientInfo.lastName, empty(), [], FormField_onChangeSet(setter, uncurry(2, (f_1 = ((optic_2 = PatientIdentidfiableInformation_get_lastName_(), (value_3) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic_2)(value_3))), (b_1) => ((a_1) => Functions_flip(uncurry(2, f_1), b_1, a_1))))), "Report", "Last Name", []), FormField_text(identifiedPatientInfo.id, empty(), [], FormField_onChangeSet(setter, uncurry(2, (f_2 = ((optic_4 = PatientIdentidfiableInformation_get_id_(), (value_5) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic_4)(value_5))), (b_2) => ((a_2) => Functions_flip(uncurry(2, f_2), b_2, a_2))))), "Report", "Patient MRN", [])])));
        })))) : empty()))))), react.createElement("form", {}, ...children))), (footer = singleton(div(singleton(new Option_3(5)), ofArray([div_1(empty(), singleton(button(ofArray([new Option_4(0, new Color_IColor(6)), new Option_4(16, !canSaveReport), new Option_4(17, toList(delay(() => append(singleton_1(new DOMAttr(40, (_arg2) => {
            dispatch_1(onSelect);
        })), delay(() => append(singleton_1(new HTMLAttr(159, "button")), delay(() => {
            if (piiValid_1.tag === 0) {
                return empty_1();
            }
            else {
                return singleton_1(new HTMLAttr(158, piiValid_1.fields[0]));
            }
        })))))))]), singleton(`${actionName} Report`)))), div_1(empty(), singleton(button(ofArray([new Option_4(0, new Color_IColor(8)), new Option_4(17, ofArray([new DOMAttr(40, (_arg3) => {
            dispatch_1(new Msg(20, void 0));
        }), new HTMLAttr(159, "button")]))]), singleton("Cancel"))))]))), modal(singleton(new Option_5(1, modalState != null)), ofArray([background(singleton(new Common_GenericOption(1, singleton(new DOMAttr(40, (_arg4) => {
            dispatch_1(new Msg(20, void 0));
        })))), empty()), Card_card(empty(), ofArray([Card_head(empty(), singleton(`Select the Type of Report to ${actionName}`)), Card_body(empty(), singleton(body)), Card_foot(empty(), footer)]))])))))))))))), delay(() => {
            let props_6, children_6;
            return append(singleton_1((props_6 = [["style", {
                marginLeft: "0.5rem",
            }]], (children_6 = [react.createElement("h1", {}, "Dashboard"), react.createElement("p", {}, "Welcome to your InVivage Pathology Portal")], react.createElement("div", keyValueList(props_6, 1), ...children_6)))), delay(() => {
                let children_10;
                return model.loadingSessions ? singleton_1(react.createElement("div", {
                    className: "is-loading",
                })) : append(singleton_1((children_10 = toList(delay(() => map_1((scheduledSession) => card(singleton(new Common_GenericOption(0, "embed-card")), ofArray([header(empty(), ofArray([Header_title(empty(), singleton("Live Consultation")), Header_icon(empty(), singleton(button(ofArray([new Option_4(18, (_arg1) => {
                    dispatch(new Msg(3, scheduledSession));
                }), new Option_4(16, (scheduledSession.sessionState === 0) ? true : (scheduledSession.sessionState === 3))]), singleton("Join Live Session"))))])), content_1(empty(), ofArray([FormField_labeledField("ScheduledSession", "Session Date/Time", toString(scheduledSession.scheduledTime), []), FormField_labeledField("ScheduledSession", "Session Name", scheduledSession.sessionName, []), FormField_labeledField("ScheduledSession", "Site Name", scheduledSession.siteName, []), FormField_labeledField("ScheduledSession", "Patient ID", scheduledSession.patientId, [])]))])), sortBy((session) => [op_UnaryNegation_Int32(session.sessionState), session.scheduledTime], model.scheduledSessions, {
                    Compare: compareArrays,
                })))), react.createElement("div", {
                    className: "scheduled-card-container",
                }, ...children_10))), delay(() => {
                    let props_16, children_16, children_14;
                    return append(singleton_1((props_16 = [["style", {
                        margin: "2rem 0.5rem",
                    }]], (children_16 = [react.createElement("h3", {}, "Reports"), (children_14 = [Sort_itemViewListSortable("Reports", reportTableColumns(dispatch), (_arg6, _arg5) => {
                    }, ListDisplayModification_ListDisplayModification$2_applyListModifications(model.reportsListDisplayModifications, model.reports), (_arg7) => empty_1(), model.reportsListSortInfo, (sortInfo, _arg8) => {
                        dispatch(new Msg(13, sortInfo));
                    }, (_arg9) => empty())], react.createElement("div", {
                        className: "table-container reports-table",
                    }, ...children_14))], react.createElement("div", keyValueList(props_16, 1), ...children_16)))), delay(() => {
                        let props_18;
                        return singleton_1((props_18 = [["style", {
                            visibility: "hidden",
                        }]], react.createElement("div", keyValueList(props_18, 1), "-")));
                    }));
                }));
            }));
        }));
    })));
}

