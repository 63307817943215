import { columns } from "../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { ofArray, singleton, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { Option, ISize, column } from "../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { footer, content, Header_title, header, card } from "../fable_modules/Fulma.2.16.0/Components/Card.fs.js";
import { Screen, Common_GenericOption } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { FormField_labeledField } from "../Optiscan.SharedUI/Forms.js";
import { map, defaultArg } from "../fable_modules/fable-library.3.7.12/Option.js";
import { append, singleton as singleton_1, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { Option as Option_1, navbar } from "../fable_modules/Fulma.2.16.0/Components/Navbar.fs.js";
import * as react from "react";
import { Option as Option_2, label } from "../fable_modules/Fulma.2.16.0/Elements/Form/Label.fs.js";
import { HTMLAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { TelepathologyMsg } from "./TelepathologyTypes.js";
import LiveTelepathology from "../../../src/Optiscan.Client/TelepathologyCommon/js/LiveTelepathology.tsx";
import { Helpers_nothing } from "../fable_modules/Fable.React.7.4.3/Fable.React.Helpers.fs.js";

export function view(model, dispatch, extraCardButtons, extraNavbarButtons) {
    let children, children_2, children_4, children_9;
    return columns(empty(), ofArray([column(empty(), singleton(card(singleton(new Common_GenericOption(0, "patient-search-card embed-card")), ofArray([header(empty(), singleton(Header_title(empty(), singleton("Patient Details")))), content(empty(), ofArray([FormField_labeledField("Patient", "Patient ID", defaultArg(map((session) => session.patientId, model.session), "Unknown"), []), FormField_labeledField("Session", "Session ID", defaultArg(map((session_1) => session_1.sessionId, model.session), "Unknown"), []), FormField_labeledField("Session", "Session Name", defaultArg(map((session_2) => session_2.sessionName, model.session), "Unknown"), []), FormField_labeledField("AnatomicalRegion", "Anatomical Location", defaultArg(map((region) => region.region, model.anatomicalRegion), "Unknown"), []), FormField_labeledField("AnatomicalRegionSite", "Anatomical Site", defaultArg(map((region_1) => region_1.site, model.anatomicalRegion), "Unknown"), [])])), footer(empty(), toList(delay(() => extraCardButtons)))])))), column(singleton(new Option(0, new Screen(0), new ISize(13))), ofArray([navbar(singleton(new Option_1(7, "columns tele-nav")), ofArray([(children = toList(delay(() => extraNavbarButtons)), react.createElement("div", {
        className: "tele-nav-back-button column is-3 navbar-item",
    }, ...children)), (children_2 = [react.createElement("img", {
        src: "flag-light.svg",
    }), "Double click an image to flag it for review"], react.createElement("div", {
        className: "tele-nav-string column is-6 navbar-item",
    }, ...children_2)), (children_4 = toList(delay(() => {
        let patternInput;
        const matchValue = model.streamStatus;
        switch (matchValue.tag) {
            case 0: {
                patternInput = ["connecting", "Connecting..."];
                break;
            }
            case 1: {
                patternInput = ["connected", "Live Acquisition"];
                break;
            }
            case 2: {
                patternInput = ["paused", "Stream Paused"];
                break;
            }
            case 3: {
                patternInput = ["stopped", "Stream Ended"];
                break;
            }
            default: {
                patternInput = ["failed", "Error"];
            }
        }
        return singleton_1(label(ofArray([new Option_2(3, singleton(new HTMLAttr(99, "tele-nav-label"))), new Option_2(2, `tele-nav-label ${patternInput[0]}`)]), ofArray([react.createElement("img", {
            src: "info-light.svg",
        }), patternInput[1]])));
    })), react.createElement("div", {
        className: "column is-3 navbar-item",
    }, ...children_4))])), (children_9 = toList(delay(() => {
        let children_6, matchValue_1;
        return append(singleton_1((children_6 = [label(singleton(new Option_2(2, "webrtc-overlay-label")), singleton((matchValue_1 = model.streamStatus, (matchValue_1.tag === 2) ? "Stream paused" : ((matchValue_1.tag === 3) ? "This session has ended" : ((matchValue_1.tag === 0) ? "Connecting..." : ((matchValue_1.tag === 1) ? "Waiting for images..." : "An error has occured"))))))], react.createElement("div", {
            id: "webrtc-overlay",
            className: "webrtc-overlay",
        }, ...children_6))), delay(() => {
            let props_13;
            const matchValue_2 = [model.signalingClient, model.peerConnection];
            let pattern_matching_result, peerConnection, signalingClient;
            if (matchValue_2[0] != null) {
                if (matchValue_2[1] != null) {
                    pattern_matching_result = 0;
                    peerConnection = matchValue_2[1];
                    signalingClient = matchValue_2[0];
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton_1((props_13 = {
                        signalingClient: signalingClient,
                        peerConnection: peerConnection,
                        dataChannels: model.dataChannels,
                        onDroppedFrame: (msg) => {
                            dispatch(new TelepathologyMsg(16, msg));
                        },
                        onImageFlag: (vctx) => {
                            dispatch(new TelepathologyMsg(9, vctx.currentFrame));
                        },
                        onControlMessage: (arg) => {
                            dispatch(new TelepathologyMsg(10, arg));
                        },
                        debugTeleStats: false,
                    }, react.createElement(LiveTelepathology, props_13)));
                }
                case 1: {
                    return singleton_1(Helpers_nothing);
                }
            }
        }));
    })), react.createElement("div", {
        className: "webrtc-container",
    }, ...children_9))]))]));
}

