import { ReportModalState, PatientReportMsg, reportsTableColumns, PatientReportModel } from "./PatientReportTypes.js";
import { ListDisplayModification_ListDisplayModification$2, Sort_SortInfo$1_getSortComparison_576B9F7E, Sort_SortInfo$1, Sort_SortDirection } from "../../Optiscan.SharedUI/Sort.js";
import { CrudListModification } from "../../Optiscan.SharedUI/Crud.js";
import { find, choose, concat, exists, length, contains, pick, filter, collect, tryFind as tryFind_1, ofArray, item, map, empty, singleton, sortWith } from "../../fable_modules/fable-library.3.7.12/List.js";
import { stringHash, equalArrays, equals, uncurry } from "../../fable_modules/fable-library.3.7.12/Util.js";
import { newGuid } from "../../fable_modules/fable-library.3.7.12/Guid.js";
import { AssessmentViewModel } from "../../Optiscan.Models/View/Assessment.js";
import { PatientIdentidfiableInformation_get_empty, PatientIdentidfiableInformation_validate_Z2CA7CC3A, ReportType, Assessment_TypeModule_all } from "../../Optiscan.Models/Shared.js";
import { Screenshots_screenshotsTableCard, assessmentEditModal, assessmentsCard, reportsTableCard, reportSelectionModal, Screenshots_Columns_SessionName } from "./PatientReportView.js";
import { Cmd_OfPromise_either, Cmd_map, Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Cmd_OfAsyncWith_attempt, Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { StateTreeNode$6, NavigationMsg$1, StateTreeMsg$4 } from "../../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { singleton as singleton_1, append, delay, toList } from "../../fable_modules/fable-library.3.7.12/Seq.js";
import { bind, some, map as map_1, defaultArg } from "../../fable_modules/fable-library.3.7.12/Option.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library.3.7.12/Choice.js";
import { Option as Option_3, columns } from "../../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { Option, ISize, column } from "../../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { Screen } from "../../fable_modules/Fulma.2.16.0/Common.fs.js";
import { Option as Option_1, navbar } from "../../fable_modules/Fulma.2.16.0/Components/Navbar.fs.js";
import { Option as Option_2, button } from "../../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import * as react from "react";
import { Fa_IconOption, Fa_i } from "../../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { Helpers_nothing } from "../../fable_modules/Fable.React.7.4.3/Fable.React.Helpers.fs.js";
import { screenshotModal } from "../ScreenshotModal/ScreenshotModalView.js";
import { Navigation_modifyUrl } from "../../fable_modules/Fable.Elmish.Browser.3.0.4/navigation.fs.js";
import { Page, pageHash } from "../../Pages.js";
import { securedUserManagementApi, securedApi } from "../../Api.js";
import { Common_eraseExceptionToError } from "../../Common/Common.js";
import { error } from "../../Optiscan.SharedUI/Toast.js";
import { ErrorMessage, ErrorMessage_get_describe } from "../../Optiscan.Models/ErrorMessage.js";
import { Security_AuthErrorMessage_get_describe } from "../../fable_modules/Webbler.Models.1.2.6/Api.fs.js";
import { toList as toList_1, containsKey, FSharpMap__get_Item, ofList, ofSeq, tryFind, ofArray as ofArray_1 } from "../../fable_modules/fable-library.3.7.12/Map.js";
import { map as map_2 } from "../../fable_modules/fable-library.3.7.12/Array.js";
import { PatientNoteViewModel } from "../../Optiscan.Models/View/PatientNote.js";
import { ScreenshotInfoViewModel, ScreenshotDetailViewModel, ScreenshotListItemViewModel, ScreenshotListItemViewModel_ofScreenshotInfoViewModel } from "../../Optiscan.Models/View/ScreenshotInfoViewModel.js";
import { mkReportSections } from "./Sections/MakeReportSections.js";
import { mkReportArgs } from "./PatientReportGeneration.js";
import { fromDate, utcNow } from "../../fable_modules/fable-library.3.7.12/DateOffset.js";
import { toString } from "../../fable_modules/fable-library.3.7.12/Date.js";
import { ReportInfoViewModel } from "../../Optiscan.Models/View/ReportInfo.js";
import { makeAndPrependTitlePage, TitlePageInformation, TitlePagePatientInformation } from "./Sections/TitlePage.js";
import { AsyncResultComputationExpression_asyncResult, AsyncResultComputationExpression_AsyncResultBuilder__Return_1505, AsyncResult_mapError, AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93, AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B } from "../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { ofPromise } from "../../Common/Extensions.js";
import { ByteArrayExtensions_SaveFileAs_72B26259 } from "../../fable_modules/Fable.Remoting.Client.7.25.0/Extensions.fs.js";
import { Reports_mkManagedDownload, Reports_mkManagedUpload } from "./PatientReportHelpers.js";
import { TelemetryAction, FileOperationContext } from "../../Optiscan.Models/Api/Api.js";
import { toConsole } from "../../fable_modules/fable-library.3.7.12/String.js";
import { PDFDocument } from "pdf-lib";
import { update, init } from "../ScreenshotModal/ScreenshotModalState.js";
import { Msg, ScreenshotModalModel, InitArgs } from "../ScreenshotModal/ScreenshotModalTypes.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { S3_downloadBlobFromS3 } from "../Historical/HistoricalHelpers.js";
import { blobToDataUrl } from "../Historical/HistoricalCommon.js";
import { List_groupBy } from "../../fable_modules/fable-library.3.7.12/Seq2.js";

export function finishOperation(model) {
    return new PatientReportModel(model.patient, model.session, model.sessions, model.sessionAnnotations, model.sessionNotes, model.assessments, model.reportsListSortInfo, model.reportsListModifications, model.maybeReportInfo, model.reports, model.loadingAssessments, model.loadingNotes, model.loadingAnnotations, model.selectedAssessment, false, false, model.screenshots, model.screenshotListSortInfo, model.screenshotListModifications, model.reportType, void 0, void 0, model.maybeScreenshotModalModel);
}

export function patientReportView(currentUser) {
    return new StateTreeNode$6((deps) => {
        const initialSortInfo = (lastColumnClicked) => (new Sort_SortInfo$1(lastColumnClicked, new Sort_SortDirection(1)));
        const initListModifications = (lastColumnClicked_1) => {
            let comparer;
            return singleton(new ListDisplayModification_ListDisplayModification$2(new CrudListModification(0), 0, (comparer = Sort_SortInfo$1_getSortComparison_576B9F7E(initialSortInfo(lastColumnClicked_1)), (list) => sortWith(uncurry(2, comparer), list))));
        };
        return [new PatientReportModel(deps.patient, deps.maybeSelectedSession, deps.sessions, empty(), empty(), map((assessmentType) => (new AssessmentViewModel(deps.patient.id, newGuid(), assessmentType, "")), Assessment_TypeModule_all), initialSortInfo(item(0, reportsTableColumns((value) => {
        }))), initListModifications(item(0, reportsTableColumns((value_1) => {
        }))), void 0, empty(), true, true, true, void 0, false, false, empty(), initialSortInfo(Screenshots_Columns_SessionName((value_2) => {
        })), initListModifications(Screenshots_Columns_SessionName((value_3) => {
        })), new ReportType(1), void 0, void 0, void 0), Cmd_batch(ofArray([Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientReportMsg(2))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientReportMsg(6))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientReportMsg(8))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientReportMsg(34))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientReportMsg(42)))]))];
    }, (model, dispatch) => {
        const children_8 = toList(delay(() => {
            const piiValid = defaultArg(map_1(PatientIdentidfiableInformation_validate_Z2CA7CC3A, model.patientIdentidfiableInformation), equals(model.reportType, new ReportType(1)) ? (new FSharpResult$2(0, void 0)) : (new FSharpResult$2(1, "Data cannot be empty")));
            const canSaveReport = (!model.isDoingReportOperation) && equals(piiValid, new FSharpResult$2(0, void 0));
            return append(singleton_1(reportSelectionModal(model.generateReportModalState, model.reportType, defaultArg(model.patientIdentidfiableInformation, PatientIdentidfiableInformation_get_empty()), canSaveReport, piiValid, model.isGeneratingReport, dispatch)), delay(() => singleton_1(columns(empty(), toList(delay(() => {
                let children_2, children, children_6, children_4;
                return append(singleton_1(column(ofArray([new Option(0, new Screen(0), new ISize(18)), new Option(2, "full-page-content")]), ofArray([navbar(singleton(new Option_1(7, "columns tele-nav")), ofArray([(children_2 = [(children = [button(singleton(new Option_2(18, (_arg1) => {
                    dispatch(new StateTreeMsg$4(0, new PatientReportMsg(1, model.session)));
                })), singleton("Back"))], react.createElement("div", {
                    className: "tele-nav-back-button navbar-item no-margin",
                }, ...children))], react.createElement("div", {
                    className: "navbar-start",
                }, ...children_2)), (children_6 = [(children_4 = toList(delay(() => {
                    const isLoading = ((model.loadingAnnotations ? true : model.loadingNotes) ? true : model.loadingAssessments) ? true : model.isDoingReportOperation;
                    return singleton_1(button(ofArray([new Option_2(16, isLoading), new Option_2(13, isLoading), new Option_2(18, (_arg2) => {
                        dispatch(new StateTreeMsg$4(0, new PatientReportMsg(36, new ReportModalState(0))));
                    })]), ofArray([Fa_i(ofArray([new Fa_IconOption(11, "fas fa-plus"), new Fa_IconOption(14, singleton(["style", {
                        marginRight: "0.5em",
                    }]))]), []), "Generate New Report"])));
                })), react.createElement("div", {
                    className: "navbar-item",
                }, ...children_4))], react.createElement("div", {
                    className: "navbar-end",
                }, ...children_6))])), columns(singleton(new Option_3(9, singleton(["style", {
                    height: "100%",
                    overflowY: "scroll",
                }]))), ofArray([column(singleton(new Option(0, new Screen(0), new ISize(8))), singleton(reportsTableCard(model, dispatch))), column(singleton(new Option(2, "column--scrollable")), toList(delay(() => append(singleton_1(assessmentsCard(model.loadingAssessments, model.assessments, (assessment) => {
                    dispatch(new StateTreeMsg$4(0, new PatientReportMsg(15, assessment)));
                })), delay(() => {
                    let matchValue;
                    return append((matchValue = model.selectedAssessment, (matchValue != null) ? singleton_1(assessmentEditModal(matchValue, true, (arg_1) => {
                        dispatch(new StateTreeMsg$4(0, new PatientReportMsg(16, arg_1)));
                    }, (_arg3) => {
                        dispatch(new StateTreeMsg$4(0, new PatientReportMsg(17)));
                    }, (_arg4) => {
                        dispatch(new StateTreeMsg$4(0, new PatientReportMsg(15, void 0)));
                    })) : singleton_1(Helpers_nothing)), delay(() => singleton_1(Screenshots_screenshotsTableCard(model, dispatch))));
                })))))]))]))), delay(() => {
                    const matchValue_1 = model.maybeScreenshotModalModel;
                    return (matchValue_1 == null) ? singleton_1(Helpers_nothing) : singleton_1(screenshotModal(matchValue_1, (arg_3) => {
                        dispatch(new StateTreeMsg$4(0, new PatientReportMsg(45, arg_3)));
                    }, (_arg5) => {
                        dispatch(new StateTreeMsg$4(0, new PatientReportMsg(48)));
                    }, (_arg6) => {
                        dispatch(new StateTreeMsg$4(0, new PatientReportMsg(47)));
                    }));
                }));
            }))))));
        }));
        return react.createElement(react.Fragment, {}, ...children_8);
    }, uncurry(4, (msg_5) => ((tupledArg) => {
        const token = tupledArg[0];
        const maybeS3Ctx = tupledArg[3];
        return (deps_1) => ((model_1) => {
            let matchValue_4, s_4, matchValue_10, s3Ctx_2, reportInfo_3, s3Ctx, matchValue_13, s_5, screenshots$0027, patternInput, s3Keys;
            if (msg_5.tag === 1) {
                if (msg_5.fields[0] != null) {
                    const s_2 = msg_5.fields[0];
                    return [finishOperation(model_1), Cmd_batch(ofArray([Navigation_modifyUrl(pageHash(new Page(6, model_1.patient.id, s_2.sessionId))), Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(5, "Historical Comparison")))]))];
                }
                else {
                    return [model_1, Cmd_none()];
                }
            }
            else if (msg_5.tag === 2) {
                const matchValue_2 = model_1.session;
                if (matchValue_2 != null) {
                    const s_3 = matchValue_2;
                    return [model_1, Cmd_OfAsyncWith_either((x) => {
                        Cmd_OfAsync_start(x);
                    }, securedApi(token).getAllNotesForSession, s_3.sessionId, (arg_4) => (new StateTreeMsg$4(0, new PatientReportMsg(3, arg_4))), (arg_5) => (new StateTreeMsg$4(1, Common_eraseExceptionToError(arg_5))))];
                }
                else {
                    return [model_1, error("Error when getting notes: session is None")];
                }
            }
            else if (msg_5.tag === 3) {
                return (msg_5.fields[0].tag === 1) ? [model_1, error(ErrorMessage_get_describe()(msg_5.fields[0].fields[0]))] : [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, msg_5.fields[0].fields[0], model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, model_1.maybeReportInfo, model_1.reports, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment, model_1.isDoingReportOperation, model_1.isGeneratingReport, model_1.screenshots, model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, model_1.maybeScreenshotModalModel), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientReportMsg(4, msg_5.fields[0].fields[0])))];
            }
            else if (msg_5.tag === 4) {
                const ids = map((note) => note.createdById, msg_5.fields[0]);
                return [model_1, Cmd_OfAsyncWith_either((x_1) => {
                    Cmd_OfAsync_start(x_1);
                }, securedUserManagementApi(token).getUsersByIds, ids, (arg_7) => (new StateTreeMsg$4(0, new PatientReportMsg(5, arg_7))), (arg_8) => (new StateTreeMsg$4(1, Common_eraseExceptionToError(arg_8))))];
            }
            else if (msg_5.tag === 5) {
                if (msg_5.fields[0].tag === 1) {
                    return [model_1, error(Security_AuthErrorMessage_get_describe()(msg_5.fields[0].fields[0]))];
                }
                else {
                    const authorsMap = ofArray_1(map_2((author) => [author.id, author.name], msg_5.fields[0].fields[0]));
                    return [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, map((note_1) => {
                        const matchValue_3 = tryFind(note_1.createdById, authorsMap);
                        if (matchValue_3 == null) {
                            return note_1;
                        }
                        else {
                            return new PatientNoteViewModel(note_1.noteId, note_1.patientId, note_1.note, note_1.dateCreated, note_1.createdById, matchValue_3, note_1.maybeSessionId);
                        }
                    }, model_1.sessionNotes), model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, model_1.maybeReportInfo, model_1.reports, model_1.loadingAssessments, false, model_1.loadingAnnotations, model_1.selectedAssessment, model_1.isDoingReportOperation, model_1.isGeneratingReport, model_1.screenshots, model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, model_1.maybeScreenshotModalModel), Cmd_none()];
                }
            }
            else if (msg_5.tag === 6) {
                return [model_1, (matchValue_4 = model_1.session, (matchValue_4 != null) ? ((s_4 = matchValue_4, Cmd_OfAsyncWith_either((x_2) => {
                    Cmd_OfAsync_start(x_2);
                }, securedApi(token).getAllSessionAnnotationsForSessionId, s_4.sessionId, (arg_10) => (new StateTreeMsg$4(0, new PatientReportMsg(7, arg_10))), (arg_11) => (new StateTreeMsg$4(0, new PatientReportMsg(0, arg_11)))))) : error("Error when getting annotations: session is None"))];
            }
            else if (msg_5.tag === 7) {
                return (msg_5.fields[0].tag === 1) ? [model_1, error(ErrorMessage_get_describe()(msg_5.fields[0].fields[0]))] : [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, msg_5.fields[0].fields[0], model_1.sessionNotes, model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, model_1.maybeReportInfo, model_1.reports, model_1.loadingAssessments, model_1.loadingNotes, false, model_1.selectedAssessment, model_1.isDoingReportOperation, model_1.isGeneratingReport, model_1.screenshots, model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, model_1.maybeScreenshotModalModel), Cmd_none()];
            }
            else if (msg_5.tag === 8) {
                return [model_1, Cmd_OfAsyncWith_either((x_3) => {
                    Cmd_OfAsync_start(x_3);
                }, securedApi(token).getAllAssessmentsForPatientId, model_1.patient.id, (arg_13) => (new StateTreeMsg$4(0, new PatientReportMsg(9, arg_13))), (arg_14) => (new StateTreeMsg$4(0, new PatientReportMsg(0, arg_14))))];
            }
            else if (msg_5.tag === 9) {
                return (msg_5.fields[0].tag === 1) ? [model_1, error(ErrorMessage_get_describe()(msg_5.fields[0].fields[0]))] : [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, map((oldAssessment) => defaultArg(tryFind_1((newAssessment) => (newAssessment.assessmentType === oldAssessment.assessmentType), msg_5.fields[0].fields[0]), oldAssessment), model_1.assessments), model_1.reportsListSortInfo, model_1.reportsListModifications, model_1.maybeReportInfo, model_1.reports, false, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment, model_1.isDoingReportOperation, model_1.isGeneratingReport, model_1.screenshots, model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, model_1.maybeScreenshotModalModel), Cmd_none()];
            }
            else if (msg_5.tag === 34) {
                return [model_1, Cmd_OfAsyncWith_either((x_4) => {
                    Cmd_OfAsync_start(x_4);
                }, securedApi(token).getAllScreenshotsForPatient, model_1.patient.id, (arg_16) => (new StateTreeMsg$4(0, new PatientReportMsg(35, arg_16))), (arg_17) => (new StateTreeMsg$4(0, new PatientReportMsg(0, arg_17))))];
            }
            else if (msg_5.tag === 35) {
                return (msg_5.fields[0].tag === 1) ? [model_1, error(ErrorMessage_get_describe()(msg_5.fields[0].fields[0]))] : [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, model_1.maybeReportInfo, model_1.reports, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment, model_1.isDoingReportOperation, model_1.isGeneratingReport, collect((arg10) => ScreenshotListItemViewModel_ofScreenshotInfoViewModel(model_1.sessions, arg10), msg_5.fields[0].fields[0]), model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, model_1.maybeScreenshotModalModel), Cmd_none()];
            }
            else if (msg_5.tag === 10) {
                return (maybeS3Ctx == null) ? [model_1, error("Missing S3 context for report generation")] : [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, model_1.maybeReportInfo, model_1.reports, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment, true, true, model_1.screenshots, model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, model_1.maybeScreenshotModalModel), Cmd_OfAsyncWith_either((x_5) => {
                    Cmd_OfAsync_start(x_5);
                }, mkReportSections, mkReportArgs(model_1, maybeS3Ctx), (arg_19) => (new StateTreeMsg$4(0, new PatientReportMsg(11, arg_19))), (arg_20) => (new StateTreeMsg$4(0, new PatientReportMsg(0, arg_20))))];
            }
            else if (msg_5.tag === 11) {
                if (msg_5.fields[0].tag === 1) {
                    return [finishOperation(model_1), error(ErrorMessage_get_describe()(msg_5.fields[0].fields[0]))];
                }
                else {
                    let revision;
                    const matchValue_5 = model_1.maybeReportInfo;
                    revision = ((matchValue_5 == null) ? 0 : (matchValue_5.revision + 1));
                    const matchValue_6 = model_1.session;
                    if (matchValue_6 != null) {
                        const session_2 = matchValue_6;
                        let timestamp;
                        let copyOfStruct = utcNow();
                        timestamp = toString(copyOfStruct, "yyyy-MM-dd-HH-mm-ss");
                        const revisionString = (revision > 0) ? (`R${revision}`) : "O";
                        const reportName = `Report_${session_2.sessionName}_${timestamp}_${revisionString}.pdf`;
                        return [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, new ReportInfoViewModel(newGuid(), model_1.patient.id, session_2.sessionId, utcNow(), `${model_1.patient.id}/${session_2.sessionId}/reports/${reportName}`, revision, `${reportName}`), model_1.reports, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment, model_1.isDoingReportOperation, model_1.isGeneratingReport, model_1.screenshots, model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, model_1.maybeScreenshotModalModel), Cmd_batch(ofArray([Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientReportMsg(29, msg_5.fields[0].fields[0]))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientReportMsg(19, msg_5.fields[0].fields[0])))]))];
                    }
                    else {
                        return [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, model_1.maybeReportInfo, model_1.reports, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment, false, model_1.isGeneratingReport, model_1.screenshots, model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, model_1.maybeScreenshotModalModel), error("Failed to generate report: Missing Session")];
                    }
                }
            }
            else if (msg_5.tag === 12) {
                const matchValue_7 = model_1.maybeReportInfo;
                if (matchValue_7 == null) {
                    return [finishOperation(model_1), error("Failed to save report: Missing report information")];
                }
                else {
                    const reportInfo_2 = matchValue_7;
                    return [model_1, Cmd_OfAsyncWith_either((x_6) => {
                        Cmd_OfAsync_start(x_6);
                    }, securedApi(token).getReportInfoFull, reportInfo_2, (arg_22) => (new StateTreeMsg$4(0, ((b) => ((tupledArg_1) => (new PatientReportMsg(13, tupledArg_1[0], tupledArg_1[1])))([msg_5.fields[0], b]))(arg_22))), (arg_23) => (new StateTreeMsg$4(0, new PatientReportMsg(0, arg_23))))];
                }
            }
            else if (msg_5.tag === 13) {
                if (msg_5.fields[1].tag === 1) {
                    return [finishOperation(model_1), error(ErrorMessage_get_describe()(msg_5.fields[1].fields[0]))];
                }
                else {
                    let patientInformation;
                    const matchValue_8 = [model_1.reportType, model_1.patientIdentidfiableInformation];
                    let pattern_matching_result;
                    if (matchValue_8[0].tag === 0) {
                        if (matchValue_8[1] != null) {
                            pattern_matching_result = 0;
                        }
                        else {
                            pattern_matching_result = 1;
                        }
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                    switch (pattern_matching_result) {
                        case 0: {
                            patientInformation = (new TitlePagePatientInformation(0, matchValue_8[1]));
                            break;
                        }
                        case 1: {
                            patientInformation = (new TitlePagePatientInformation(1, model_1.patient.id));
                            break;
                        }
                    }
                    if (msg_5.fields[1].fields[0] == null) {
                        return [finishOperation(model_1), error("Failed to download report: Missing report information")];
                    }
                    else {
                        const reportInfoFull = msg_5.fields[1].fields[0];
                        const titlePageArgs = new TitlePageInformation(patientInformation, reportInfoFull.dateCreated, reportInfoFull.accountName, reportInfoFull.siteName, reportInfoFull.revision);
                        return [model_1, Cmd_OfAsyncWith_either((x_8) => {
                            Cmd_OfAsync_start(x_8);
                        }, () => AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, makeAndPrependTitlePage(titlePageArgs, msg_5.fields[0]), (_arg1_2) => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AsyncResult_mapError((arg0_52) => (new ErrorMessage(2, arg0_52)), ofPromise()(_arg1_2.save())), (_arg2_1) => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, ByteArrayExtensions_SaveFileAs_72B26259(_arg2_1, reportInfoFull.name))))), void 0, (arg_25) => (new StateTreeMsg$4(0, new PatientReportMsg(14, arg_25))), (arg_26) => (new StateTreeMsg$4(0, new PatientReportMsg(0, arg_26))))];
                    }
                }
            }
            else if (msg_5.tag === 14) {
                return (msg_5.fields[0].tag === 1) ? [finishOperation(model_1), error(ErrorMessage_get_describe()(msg_5.fields[0].fields[0]))] : [finishOperation(model_1), Cmd_none()];
            }
            else if (msg_5.tag === 15) {
                return [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, model_1.maybeReportInfo, model_1.reports, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, msg_5.fields[0], model_1.isDoingReportOperation, model_1.isGeneratingReport, model_1.screenshots, model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, model_1.maybeScreenshotModalModel), Cmd_none()];
            }
            else if (msg_5.tag === 16) {
                return [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, model_1.maybeReportInfo, model_1.reports, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, map_1(msg_5.fields[0], model_1.selectedAssessment), model_1.isDoingReportOperation, model_1.isGeneratingReport, model_1.screenshots, model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, model_1.maybeScreenshotModalModel), Cmd_none()];
            }
            else if (msg_5.tag === 17) {
                const matchValue_9 = model_1.selectedAssessment;
                if (matchValue_9 == null) {
                    return [model_1, Cmd_none()];
                }
                else {
                    const assessment_2 = matchValue_9;
                    return [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, map((a_2) => {
                        if (a_2.assessmentId === assessment_2.assessmentId) {
                            return assessment_2;
                        }
                        else {
                            return a_2;
                        }
                    }, model_1.assessments), model_1.reportsListSortInfo, model_1.reportsListModifications, model_1.maybeReportInfo, model_1.reports, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment, model_1.isDoingReportOperation, model_1.isGeneratingReport, model_1.screenshots, model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, model_1.maybeScreenshotModalModel), Cmd_OfAsyncWith_either((x_9) => {
                        Cmd_OfAsync_start(x_9);
                    }, securedApi(token).upsertAssessment, assessment_2, (arg_28) => (new StateTreeMsg$4(0, new PatientReportMsg(18, arg_28))), (arg_29) => (new StateTreeMsg$4(0, new PatientReportMsg(0, arg_29))))];
                }
            }
            else if (msg_5.tag === 18) {
                return (msg_5.fields[0].tag === 1) ? [model_1, error(ErrorMessage_get_describe()(msg_5.fields[0].fields[0]))] : [(matchValue_10 = tryFind_1((a_3) => (a_3.assessmentId === msg_5.fields[0].fields[0].assessmentId), model_1.assessments), (matchValue_10 == null) ? (new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, model_1.maybeReportInfo, model_1.reports, true, model_1.loadingNotes, model_1.loadingAnnotations, msg_5.fields[0].fields[0], model_1.isDoingReportOperation, model_1.isGeneratingReport, model_1.screenshots, model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, model_1.maybeScreenshotModalModel)) : (new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, model_1.maybeReportInfo, model_1.reports, true, model_1.loadingNotes, model_1.loadingAnnotations, void 0, model_1.isDoingReportOperation, model_1.isGeneratingReport, model_1.screenshots, model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, model_1.maybeScreenshotModalModel))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientReportMsg(8)))];
            }
            else if (msg_5.tag === 19) {
                const matchValue_11 = [maybeS3Ctx, model_1.maybeReportInfo];
                return (matchValue_11[0] != null) ? ((matchValue_11[1] == null) ? ((s3Ctx_2 = matchValue_11[0], [model_1, error("Failed to upload report: Missing report information")])) : ((reportInfo_3 = matchValue_11[1], (s3Ctx = matchValue_11[0], [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, model_1.maybeReportInfo, model_1.reports, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment, true, model_1.isGeneratingReport, model_1.screenshots, model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, model_1.maybeScreenshotModalModel), Cmd_OfAsyncWith_either((x_10) => {
                    Cmd_OfAsync_start(x_10);
                }, (tupledArg_2) => Reports_mkManagedUpload(tupledArg_2[0], tupledArg_2[1], tupledArg_2[2]), [s3Ctx, reportInfo_3.s3Key, msg_5.fields[0]], (arg_31) => (new StateTreeMsg$4(0, new PatientReportMsg(20, arg_31))), (arg_32) => (new StateTreeMsg$4(0, new PatientReportMsg(0, arg_32))))])))) : [model_1, Cmd_none()];
            }
            else if (msg_5.tag === 20) {
                return (msg_5.fields[0].tag === 1) ? [model_1, error(ErrorMessage_get_describe()(msg_5.fields[0].fields[0]))] : [model_1, Cmd_batch(ofArray([Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientReportMsg(21, msg_5.fields[0].fields[0]))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientReportMsg(22)))]))];
            }
            else if (msg_5.tag === 21) {
                return [model_1, Cmd_OfAsyncWith_attempt((x_11) => {
                    Cmd_OfAsync_start(x_11);
                }, securedApi(token).telemetry, new TelemetryAction(0, new FileOperationContext(2), singleton(msg_5.fields[0])), (arg_34) => (new StateTreeMsg$4(0, new PatientReportMsg(0, arg_34))))];
            }
            else if (msg_5.tag === 22) {
                const matchValue_12 = model_1.maybeReportInfo;
                if (matchValue_12 == null) {
                    return [model_1, error("Failed to save report info")];
                }
                else {
                    const reportInfo_4 = matchValue_12;
                    toConsole(`Revision#: ${reportInfo_4.revision}`);
                    return [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, model_1.maybeReportInfo, model_1.reports, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment, true, model_1.isGeneratingReport, model_1.screenshots, model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, model_1.maybeScreenshotModalModel), Cmd_OfAsyncWith_either((x_12) => {
                        Cmd_OfAsync_start(x_12);
                    }, securedApi(token).createReportInfo, reportInfo_4, (arg_36) => (new StateTreeMsg$4(0, new PatientReportMsg(23, arg_36))), (arg_37) => (new StateTreeMsg$4(0, new PatientReportMsg(0, arg_37))))];
                }
            }
            else if (msg_5.tag === 23) {
                return (msg_5.fields[0].tag === 1) ? [model_1, error(ErrorMessage_get_describe()(msg_5.fields[0].fields[0]))] : [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, model_1.maybeReportInfo, model_1.reports, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment, false, model_1.isGeneratingReport, model_1.screenshots, model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, model_1.maybeScreenshotModalModel), Cmd_none()];
            }
            else if (msg_5.tag === 24) {
                return [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, void 0, model_1.reports, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment, true, model_1.isGeneratingReport, model_1.screenshots, model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, model_1.maybeScreenshotModalModel), (matchValue_13 = model_1.session, (matchValue_13 == null) ? error("Failed to get report info: Missing Session") : ((s_5 = matchValue_13, Cmd_OfAsyncWith_either((x_13) => {
                    Cmd_OfAsync_start(x_13);
                }, securedApi(token).getMostRecentReporInfoBySessionId, s_5.sessionId, (arg_39) => (new StateTreeMsg$4(0, new PatientReportMsg(25, arg_39))), (arg_40) => (new StateTreeMsg$4(0, new PatientReportMsg(0, arg_40)))))))];
            }
            else if (msg_5.tag === 25) {
                return (msg_5.fields[0].tag === 1) ? [finishOperation(model_1), error(ErrorMessage_get_describe()(msg_5.fields[0].fields[0]))] : [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, msg_5.fields[0].fields[0], model_1.reports, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment, model_1.isDoingReportOperation, model_1.isGeneratingReport, model_1.screenshots, model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, model_1.maybeScreenshotModalModel), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientReportMsg(10)))];
            }
            else if (msg_5.tag === 26) {
                return (maybeS3Ctx == null) ? [finishOperation(model_1), Cmd_none()] : [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, msg_5.fields[0], model_1.reports, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment, model_1.isDoingReportOperation, model_1.isGeneratingReport, model_1.screenshots, model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, model_1.maybeScreenshotModalModel), Cmd_OfAsyncWith_either((x_14) => {
                    Cmd_OfAsync_start(x_14);
                }, (tupledArg_3) => Reports_mkManagedDownload(tupledArg_3[0], tupledArg_3[1]), [maybeS3Ctx, msg_5.fields[0].s3Key], (arg_42) => (new StateTreeMsg$4(0, new PatientReportMsg(27, arg_42))), (arg_43) => (new StateTreeMsg$4(0, new PatientReportMsg(0, arg_43))))];
            }
            else if (msg_5.tag === 27) {
                return (msg_5.fields[0].tag === 1) ? [finishOperation(model_1), error(ErrorMessage_get_describe()(msg_5.fields[0].fields[0]))] : [model_1, Cmd_batch(ofArray([Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientReportMsg(12, msg_5.fields[0].fields[0][0]))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientReportMsg(28, msg_5.fields[0].fields[0][1])))]))];
            }
            else if (msg_5.tag === 28) {
                return [model_1, Cmd_OfAsyncWith_attempt((x_15) => {
                    Cmd_OfAsync_start(x_15);
                }, securedApi(token).telemetry, new TelemetryAction(1, new FileOperationContext(2), singleton(msg_5.fields[0])), (arg_45) => (new StateTreeMsg$4(0, new PatientReportMsg(0, arg_45))))];
            }
            else if (msg_5.tag === 29) {
                return [model_1, Cmd_OfAsyncWith_either((x_17) => {
                    Cmd_OfAsync_start(x_17);
                }, () => AsyncResult_mapError((arg0_90) => (new ErrorMessage(2, arg0_90)), ofPromise()(PDFDocument.load(msg_5.fields[0].output('arraybuffer')))), void 0, (arg_47) => (new StateTreeMsg$4(0, new PatientReportMsg(30, arg_47))), (arg_48) => (new StateTreeMsg$4(0, new PatientReportMsg(0, arg_48))))];
            }
            else if (msg_5.tag === 30) {
                return (msg_5.fields[0].tag === 1) ? [finishOperation(model_1), error(ErrorMessage_get_describe()(msg_5.fields[0].fields[0]))] : [model_1, Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientReportMsg(12, msg_5.fields[0].fields[0])))];
            }
            else if (msg_5.tag === 31) {
                return [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, model_1.maybeReportInfo, model_1.reports, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment, model_1.isDoingReportOperation, model_1.isGeneratingReport, model_1.screenshots, msg_5.fields[0], toList(delay(() => append(filter((modification) => (!equals(modification.Source, new CrudListModification(0))), model_1.screenshotListModifications), delay(() => {
                    let comparer_1;
                    return singleton_1(new ListDisplayModification_ListDisplayModification$2(new CrudListModification(0), 0, (comparer_1 = Sort_SortInfo$1_getSortComparison_576B9F7E(msg_5.fields[0]), (list_10) => sortWith(uncurry(2, comparer_1), list_10))));
                })))), model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, model_1.maybeScreenshotModalModel), Cmd_none()];
            }
            else if (msg_5.tag === 32) {
                return [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, model_1.maybeReportInfo, model_1.reports, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment, model_1.isDoingReportOperation, model_1.isGeneratingReport, (msg_5.fields[1].tag === 2) ? map((s_7) => {
                    if (equalArrays([s_7.screenshotId, s_7.role], [msg_5.fields[0], msg_5.fields[1]])) {
                        return new ScreenshotListItemViewModel(s_7.screenshotId, s_7.sessionName, s_7.name, s_7.date, s_7.description, s_7.locationDescription, s_7.role, msg_5.fields[2], s_7.imageUrl);
                    }
                    else {
                        return s_7;
                    }
                }, model_1.screenshots) : ((msg_5.fields[1].tag === 1) ? ((screenshots$0027 = map((s_8) => {
                    if (equalArrays([s_8.screenshotId, s_8.role], [msg_5.fields[0], msg_5.fields[1]])) {
                        return new ScreenshotListItemViewModel(s_8.screenshotId, s_8.sessionName, s_8.name, s_8.date, s_8.description, s_8.locationDescription, s_8.role, msg_5.fields[2], s_8.imageUrl);
                    }
                    else {
                        return s_8;
                    }
                }, model_1.screenshots), (patternInput = pick((s_9) => {
                    const matchValue_15 = s_9.role;
                    let pattern_matching_result_1, s3Keys_2;
                    if (matchValue_15.tag === 0) {
                        if (contains(msg_5.fields[1].fields[1], matchValue_15.fields[0], {
                            Equals: (x_18, y) => (x_18 === y),
                            GetHashCode: stringHash,
                        })) {
                            pattern_matching_result_1 = 0;
                            s3Keys_2 = matchValue_15.fields[0];
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                    switch (pattern_matching_result_1) {
                        case 0: {
                            return [s_9.screenshotId, s3Keys_2];
                        }
                        case 1: {
                            return void 0;
                        }
                    }
                }, screenshots$0027), (length(filter((s_10) => {
                    const matchValue_16 = s_10.role;
                    let pattern_matching_result_2;
                    if (matchValue_16.tag === 1) {
                        if (contains(matchValue_16.fields[1], patternInput[1], {
                            Equals: (x_19, y_1) => (x_19 === y_1),
                            GetHashCode: stringHash,
                        })) {
                            pattern_matching_result_2 = 0;
                        }
                        else {
                            pattern_matching_result_2 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_2 = 1;
                    }
                    switch (pattern_matching_result_2) {
                        case 0: {
                            return s_10.selected;
                        }
                        case 1: {
                            return false;
                        }
                    }
                }, screenshots$0027)) === 0) ? map((s_11) => {
                    if (s_11.screenshotId === patternInput[0]) {
                        return new ScreenshotListItemViewModel(s_11.screenshotId, s_11.sessionName, s_11.name, s_11.date, s_11.description, s_11.locationDescription, s_11.role, false, s_11.imageUrl);
                    }
                    else {
                        return s_11;
                    }
                }, screenshots$0027) : screenshots$0027))) : ((s3Keys = msg_5.fields[1].fields[0], map((s_6) => {
                    if (exists((nodeS3Key) => {
                        const matchValue_14 = s_6.role;
                        let pattern_matching_result_3;
                        if (matchValue_14.tag === 1) {
                            if (matchValue_14.fields[1] === nodeS3Key) {
                                pattern_matching_result_3 = 0;
                            }
                            else {
                                pattern_matching_result_3 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_3 = 1;
                        }
                        switch (pattern_matching_result_3) {
                            case 0: {
                                return true;
                            }
                            case 1: {
                                let pattern_matching_result_4;
                                if (matchValue_14.tag === 0) {
                                    if (equals(matchValue_14.fields[0], s3Keys)) {
                                        pattern_matching_result_4 = 0;
                                    }
                                    else {
                                        pattern_matching_result_4 = 1;
                                    }
                                }
                                else {
                                    pattern_matching_result_4 = 1;
                                }
                                switch (pattern_matching_result_4) {
                                    case 0: {
                                        return true;
                                    }
                                    case 1: {
                                        return false;
                                    }
                                }
                            }
                        }
                    }, s3Keys)) {
                        return new ScreenshotListItemViewModel(s_6.screenshotId, s_6.sessionName, s_6.name, s_6.date, s_6.description, s_6.locationDescription, s_6.role, msg_5.fields[2], s_6.imageUrl);
                    }
                    else {
                        return s_6;
                    }
                }, model_1.screenshots)))), model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, model_1.maybeScreenshotModalModel), Cmd_none()];
            }
            else if (msg_5.tag === 33) {
                if (maybeS3Ctx != null) {
                    const s3Ctx_5 = maybeS3Ctx;
                    const s3KeysToDownload = concat(choose((s_13) => {
                        const matchValue_17 = s_13.role;
                        switch (matchValue_17.tag) {
                            case 2: {
                                return singleton(matchValue_17.fields[1]);
                            }
                            case 1: {
                                return void 0;
                            }
                            default: {
                                return matchValue_17.fields[0];
                            }
                        }
                    }, filter((s_12) => (s_12.screenshotId === msg_5.fields[0]), model_1.screenshots)));
                    const patternInput_1 = init(new InitArgs(true, void 0));
                    return [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, model_1.maybeReportInfo, model_1.reports, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment, model_1.isDoingReportOperation, model_1.isGeneratingReport, model_1.screenshots, model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, patternInput_1[0]), Cmd_batch(ofArray([Cmd_map((arg_50) => (new StateTreeMsg$4(0, new PatientReportMsg(45, arg_50))), patternInput_1[1]), Cmd_OfPromise_either(() => {
                        let pr_1;
                        const pr = map((s3Key_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (S3_downloadBlobFromS3(s3Ctx_5, s3Key_6, (value_6) => {
                        }, (s3Key_7) => {
                            console.error(some("Failed to download screenshot"), s3Key_7);
                        }).then((_arg3_1) => (blobToDataUrl(_arg3_1).then((_arg4_1) => (Promise.resolve([s3Key_6, _arg4_1])))))))), s3KeysToDownload);
                        pr_1 = (Promise.all(pr));
                        return pr_1.then(ofSeq);
                    }, void 0, (arg_54) => {
                        let tupledArg_4;
                        return new StateTreeMsg$4(0, (tupledArg_4 = [new FSharpResult$2(0, [msg_5.fields[0], arg_54]), msg_5.fields[1]], new PatientReportMsg(46, tupledArg_4[0], tupledArg_4[1])));
                    }, (arg_57) => {
                        let tupledArg_5;
                        return new StateTreeMsg$4(0, (tupledArg_5 = [new FSharpResult$2(1, arg_57), msg_5.fields[1]], new PatientReportMsg(46, tupledArg_5[0], tupledArg_5[1])));
                    })]))];
                }
                else {
                    return [model_1, Cmd_none()];
                }
            }
            else if (msg_5.tag === 46) {
                if (msg_5.fields[0].tag === 1) {
                    return [model_1, Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientReportMsg(0, msg_5.fields[0].fields[0])))];
                }
                else {
                    const sessionScreenshotDetails = ofList(map((tupledArg_6) => [tupledArg_6[0], map((tuple_1) => tuple_1[1], tupledArg_6[1])], List_groupBy((tuple) => tuple[0], choose((s_14) => {
                        const matchValue_18 = s_14.role;
                        let pattern_matching_result_5, s3Key_10, sessionId_2;
                        if (matchValue_18.tag === 1) {
                            if (containsKey(matchValue_18.fields[1], msg_5.fields[0].fields[0][1])) {
                                pattern_matching_result_5 = 1;
                                s3Key_10 = matchValue_18.fields[1];
                                sessionId_2 = matchValue_18.fields[0];
                            }
                            else {
                                pattern_matching_result_5 = 2;
                            }
                        }
                        else if (matchValue_18.tag === 2) {
                            if (containsKey(matchValue_18.fields[1], msg_5.fields[0].fields[0][1])) {
                                pattern_matching_result_5 = 1;
                                s3Key_10 = matchValue_18.fields[1];
                                sessionId_2 = matchValue_18.fields[0];
                            }
                            else {
                                pattern_matching_result_5 = 2;
                            }
                        }
                        else {
                            pattern_matching_result_5 = 0;
                        }
                        switch (pattern_matching_result_5) {
                            case 0: {
                                return void 0;
                            }
                            case 1: {
                                return [sessionId_2, new ScreenshotDetailViewModel(s_14.description, s_14.locationDescription, s3Key_10, FSharpMap__get_Item(msg_5.fields[0].fields[0][1], s3Key_10))];
                            }
                            case 2: {
                                return void 0;
                            }
                        }
                    }, model_1.screenshots), {
                        Equals: (x_20, y_2) => (x_20 === y_2),
                        GetHashCode: stringHash,
                    })));
                    let patternInput_2;
                    const s_16 = find((s_15) => (s_15.screenshotId === msg_5.fields[0].fields[0][0]), model_1.screenshots);
                    patternInput_2 = [s_16.name, s_16.date];
                    return [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, model_1.maybeReportInfo, model_1.reports, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment, model_1.isDoingReportOperation, model_1.isGeneratingReport, model_1.screenshots, model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, new ScreenshotModalModel(false, false, new ScreenshotInfoViewModel(msg_5.fields[0].fields[0][0], sessionScreenshotDetails, patternInput_2[0], fromDate(patternInput_2[1])))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientReportMsg(45, new Msg(1, msg_5.fields[1]))))];
                }
            }
            else if (msg_5.tag === 47) {
                return [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, model_1.maybeReportInfo, model_1.reports, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment, model_1.isDoingReportOperation, model_1.isGeneratingReport, model_1.screenshots, model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, void 0), Cmd_none()];
            }
            else if (msg_5.tag === 48) {
                const maybeScreenshotInfo = bind((m) => m.maybeScreenshotInfo, model_1.maybeScreenshotModalModel);
                if (maybeScreenshotInfo != null) {
                    const screenshotInfo_1 = maybeScreenshotInfo;
                    return [model_1, Cmd_OfAsyncWith_either((x_21) => {
                        Cmd_OfAsync_start(x_21);
                    }, securedApi(token).updateScreenshotInfo, screenshotInfo_1, (arg_58) => (new StateTreeMsg$4(0, new PatientReportMsg(49, arg_58))), (arg_59) => (new StateTreeMsg$4(0, new PatientReportMsg(0, arg_59))))];
                }
                else {
                    return [model_1, Cmd_none()];
                }
            }
            else if (msg_5.tag === 49) {
                if (msg_5.fields[0].tag === 1) {
                    return [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, model_1.maybeReportInfo, model_1.reports, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment, model_1.isDoingReportOperation, model_1.isGeneratingReport, model_1.screenshots, model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, map_1((m_3) => (new ScreenshotModalModel(m_3.isLoading, false, m_3.maybeScreenshotInfo)), model_1.maybeScreenshotModalModel)), error(ErrorMessage_get_describe()(msg_5.fields[0].fields[0]))];
                }
                else {
                    const maybeScreenshotInfo_1 = bind((m_1) => m_1.maybeScreenshotInfo, model_1.maybeScreenshotModalModel);
                    if (maybeScreenshotInfo_1 == null) {
                        return [model_1, Cmd_none()];
                    }
                    else {
                        const screenshotInfo_2 = maybeScreenshotInfo_1;
                        const updatedDescriptions = ofList(collect((tupledArg_7) => map((detail_1) => [detail_1.s3Key, detail_1.description], tupledArg_7[1]), toList_1(screenshotInfo_2.sessionScreenshotDetails)));
                        const maybeUpdatedScreenshotId = bind((m_2) => bind((s_17) => s_17.screenshotId, m_2.maybeScreenshotInfo), model_1.maybeScreenshotModalModel);
                        const updatedName = defaultArg(screenshotInfo_2.name, "");
                        return [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, model_1.maybeReportInfo, model_1.reports, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment, model_1.isDoingReportOperation, model_1.isGeneratingReport, map((s_18) => {
                            if (equals(s_18.screenshotId, maybeUpdatedScreenshotId)) {
                                const matchValue_19 = s_18.role;
                                switch (matchValue_19.tag) {
                                    case 1: {
                                        return new ScreenshotListItemViewModel(s_18.screenshotId, s_18.sessionName, updatedName, s_18.date, FSharpMap__get_Item(updatedDescriptions, matchValue_19.fields[1]), s_18.locationDescription, s_18.role, s_18.selected, s_18.imageUrl);
                                    }
                                    case 2: {
                                        return new ScreenshotListItemViewModel(s_18.screenshotId, s_18.sessionName, updatedName, s_18.date, FSharpMap__get_Item(updatedDescriptions, matchValue_19.fields[1]), s_18.locationDescription, s_18.role, s_18.selected, s_18.imageUrl);
                                    }
                                    default: {
                                        return new ScreenshotListItemViewModel(s_18.screenshotId, s_18.sessionName, updatedName, s_18.date, s_18.description, s_18.locationDescription, s_18.role, s_18.selected, s_18.imageUrl);
                                    }
                                }
                            }
                            else {
                                return s_18;
                            }
                        }, model_1.screenshots), model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, void 0), Cmd_none()];
                    }
                }
            }
            else if (msg_5.tag === 36) {
                return [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, model_1.maybeReportInfo, model_1.reports, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment, model_1.isDoingReportOperation, model_1.isGeneratingReport, model_1.screenshots, model_1.screenshotListSortInfo, model_1.screenshotListModifications, new ReportType(1), void 0, msg_5.fields[0], model_1.maybeScreenshotModalModel), Cmd_none()];
            }
            else if (msg_5.tag === 37) {
                return [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, model_1.maybeReportInfo, model_1.reports, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment, model_1.isDoingReportOperation, model_1.isGeneratingReport, model_1.screenshots, model_1.screenshotListSortInfo, model_1.screenshotListModifications, defaultArg(msg_5.fields[0], new ReportType(1)), model_1.patientIdentidfiableInformation, model_1.generateReportModalState, model_1.maybeScreenshotModalModel), Cmd_none()];
            }
            else if (msg_5.tag === 38) {
                return [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, model_1.maybeReportInfo, model_1.reports, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment, model_1.isDoingReportOperation, model_1.isGeneratingReport, model_1.screenshots, model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, msg_5.fields[0](defaultArg(model_1.patientIdentidfiableInformation, PatientIdentidfiableInformation_get_empty())), model_1.generateReportModalState, model_1.maybeScreenshotModalModel), Cmd_none()];
            }
            else if (msg_5.tag === 39) {
                if (model_1.reportType.tag === 0) {
                    const matchValue_21 = model_1.patientIdentidfiableInformation;
                    if (matchValue_21 == null) {
                        return [model_1, error("Failed to verify patient info, patient identifiable information is missing")];
                    }
                    else {
                        const pii_2 = matchValue_21;
                        return [model_1, Cmd_OfAsyncWith_either((x_22) => {
                            Cmd_OfAsync_start(x_22);
                        }, securedApi(token).verifyPatientIdentifiableInformation, [model_1.patient.id, pii_2], (arg_61) => (new StateTreeMsg$4(0, ((b_2) => ((tupledArg_8) => (new PatientReportMsg(40, tupledArg_8[0], tupledArg_8[1])))([msg_5.fields[0], b_2]))(arg_61))), (arg_62) => (new StateTreeMsg$4(0, new PatientReportMsg(0, arg_62))))];
                    }
                }
                else {
                    return [model_1, Cmd_OfFunc_result(new StateTreeMsg$4(0, msg_5.fields[0]))];
                }
            }
            else if (msg_5.tag === 40) {
                return (msg_5.fields[1].tag === 1) ? [model_1, error("Failed to verify patient info, information does not match patient")] : [model_1, Cmd_OfFunc_result(new StateTreeMsg$4(0, msg_5.fields[0]))];
            }
            else if (msg_5.tag === 42) {
                return [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, model_1.maybeReportInfo, model_1.reports, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment, true, model_1.isGeneratingReport, model_1.screenshots, model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, model_1.maybeScreenshotModalModel), Cmd_OfAsyncWith_either((x_23) => {
                    Cmd_OfAsync_start(x_23);
                }, securedApi(token).getAllReportInfoBySessionId, defaultArg(map_1((s_19) => s_19.sessionId, model_1.session), "00000000-0000-0000-0000-000000000000"), (arg_64) => (new StateTreeMsg$4(0, new PatientReportMsg(43, arg_64))), (arg_65) => (new StateTreeMsg$4(0, new PatientReportMsg(0, arg_65))))];
            }
            else if (msg_5.tag === 43) {
                return (msg_5.fields[0].tag === 1) ? [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, model_1.maybeReportInfo, model_1.reports, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment, false, model_1.isGeneratingReport, model_1.screenshots, model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, model_1.maybeScreenshotModalModel), error(ErrorMessage_get_describe()(msg_5.fields[0].fields[0]))] : [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, model_1.maybeReportInfo, msg_5.fields[0].fields[0], model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment, false, model_1.isGeneratingReport, model_1.screenshots, model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, model_1.maybeScreenshotModalModel), Cmd_none()];
            }
            else if (msg_5.tag === 41) {
                return [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, msg_5.fields[0], toList(delay(() => append(filter((modification_1) => (!equals(modification_1.Source, new CrudListModification(0))), model_1.reportsListModifications), delay(() => {
                    let comparer_2;
                    return singleton_1(new ListDisplayModification_ListDisplayModification$2(new CrudListModification(0), 0, (comparer_2 = Sort_SortInfo$1_getSortComparison_576B9F7E(msg_5.fields[0]), (list_30) => sortWith(uncurry(2, comparer_2), list_30))));
                })))), model_1.maybeReportInfo, model_1.reports, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment, model_1.isDoingReportOperation, model_1.isGeneratingReport, model_1.screenshots, model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, model_1.maybeScreenshotModalModel), Cmd_none()];
            }
            else if (msg_5.tag === 44) {
                return [new PatientReportModel(model_1.patient, msg_5.fields[0], model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, model_1.maybeReportInfo, model_1.reports, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment, model_1.isDoingReportOperation, model_1.isGeneratingReport, model_1.screenshots, model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, model_1.maybeScreenshotModalModel), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientReportMsg(42)))];
            }
            else if (msg_5.tag === 45) {
                const matchValue_22 = model_1.maybeScreenshotModalModel;
                if (matchValue_22 == null) {
                    return [model_1, Cmd_none()];
                }
                else {
                    const patternInput_3 = update(void 0, msg_5.fields[0], matchValue_22);
                    return [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.reportsListSortInfo, model_1.reportsListModifications, model_1.maybeReportInfo, model_1.reports, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment, model_1.isDoingReportOperation, model_1.isGeneratingReport, model_1.screenshots, model_1.screenshotListSortInfo, model_1.screenshotListModifications, model_1.reportType, model_1.patientIdentidfiableInformation, model_1.generateReportModalState, patternInput_3[0]), Cmd_map((arg_67) => (new StateTreeMsg$4(0, new PatientReportMsg(45, arg_67))), patternInput_3[1])];
                }
            }
            else {
                console.error(some(`Error: ${msg_5.fields[0]}`));
                return [finishOperation(model_1), Cmd_none()];
            }
        });
    })));
}

