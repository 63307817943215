import { Args } from "./Sections/AnnotationsSection.js";
import { Args as Args_1 } from "./Sections/NotesSection.js";
import { Args as Args_2 } from "./Sections/AssessmentsSection.js";
import { filter } from "../../fable_modules/fable-library.3.7.12/List.js";
import { ScreenshotListItemViewModel__get_isParent } from "../../Optiscan.Models/View/ScreenshotInfoViewModel.js";
import { Args as Args_3 } from "./Sections/ScreenshotsSection.js";
import { ReportArgs } from "./Sections/MakeReportSections.js";

export function mkReportArgs(model, s3ctx) {
    return new ReportArgs(new Args(model.sessionAnnotations), new Args_1(model.sessionNotes), new Args_2(model.assessments), new Args_3(filter((s) => {
        if (s.selected) {
            return !ScreenshotListItemViewModel__get_isParent(s);
        }
        else {
            return false;
        }
    }, model.screenshots)), s3ctx);
}

