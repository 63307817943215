import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.12/Choice.js";
import { some, toArray, map, defaultArg, bind } from "../fable_modules/fable-library.3.7.12/Option.js";
import { tail, head, isEmpty, singleton as singleton_2, empty, ofArray, tryFind } from "../fable_modules/fable-library.3.7.12/List.js";
import { crudListState, crudListInbound, CrudListMsg$1, crudEditUpdate, CrudButton_save, CrudEditMsg$2, CrudButton_cancel, CrudButton_edit, crudModal, crudDetailState } from "../Optiscan.SharedUI/Crud.js";
import { FormField_ReactSelect_CallbackSingle$1, FormField_ReactSelect_deselectable, FormField_labeledField } from "../Optiscan.SharedUI/Forms.js";
import { Cmd_batch, Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Internal_BranchMsgChild$2, Internal_BranchModelChild$2, Internal_StateTreeRoot$6, Internal_StateTreeModel$1, StateTreeConfig$6, defaultConfig, StateTreeMsg$4, StateTreeNode$6 } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { DeviceEditModel_get_siteId_, DeviceEditModel_get_accountId_, DeviceEditMsg, DeviceEditModel } from "./DevicesTypes.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { empty as empty_1, singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { SelectPropsMulti$1 } from "../Optiscan.SharedUI/bindings/ReactSelectBind.js";
import { AccountViewModel_get_name_ } from "../Optiscan.Models/View/Account.js";
import { Optic_Set, Optic_Set_op_HatEquals_2170E4F5, Optic_Get, Optic_Get_op_HatDot_21762A61 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { Option } from "../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { partialApply, compare, comparePrimitives, uncurry, equals } from "../fable_modules/fable-library.3.7.12/Util.js";
import { SiteViewModel_get_siteId_, SiteViewModel_get_name_ } from "../Optiscan.Models/View/Site.js";
import { securedApi } from "../Api.js";
import { Common_eraseExceptionToError } from "../Common/Common.js";
import { error as error_3 } from "../Optiscan.SharedUI/Toast.js";
import { ErrorMessage_get_describe } from "../Optiscan.Models/ErrorMessage.js";
import { Sort_SortableColumn$1, Sort_Compare$1 } from "../Optiscan.SharedUI/Sort.js";
import { Internal_combineChildIndexPathFromKeyPathMap, NavigationMsg$1_destructiveCast_Z33BEA92B, defaultConfig as defaultConfig_1, StateTree$3_$ctor_4E60E31B } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { singleton as singleton_1 } from "../fable_modules/fable-library.3.7.12/AsyncBuilder.js";
import { Permission, isAllowed } from "../Optiscan.Models/Security.js";
import { Result_mapError } from "../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { Cmd_map, Cmd_batch as Cmd_batch_1, Cmd_none as Cmd_none_1 } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { ofList } from "../fable_modules/fable-library.3.7.12/Map.js";
import * as react from "react";

export function canSaveEditedDevice(device) {
    const matchValue = [device.accountId, device.siteId];
    let pattern_matching_result;
    if (matchValue[0] != null) {
        if (matchValue[1] != null) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else if (matchValue[1] == null) {
        pattern_matching_result = 0;
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return new FSharpResult$2(0, void 0);
        }
        case 1: {
            return new FSharpResult$2(1, "If an Account is selected a site must also be selected");
        }
    }
}

export function tryGetAccountForDevice(model) {
    return bind((accountId) => tryFind((account) => (account.id === accountId), model.accounts), model.device.accountId);
}

export function tryGetSiteForDevice(model) {
    return bind((siteId) => tryFind((site) => (site.siteId === siteId), model.sites), model.device.siteId);
}

export const deviceDetailState = new StateTreeNode$6(crudDetailState().Init, (model, dispatch) => crudModal("Details", dispatch, ofArray([FormField_labeledField("Device", "Name", model.name, []), FormField_labeledField("Device", "Account", defaultArg(model.accountName, "--"), []), FormField_labeledField("Device", "Site", defaultArg(model.siteName, "--"), []), FormField_labeledField("Device", "Online", model.online ? "Online" : "Offline", [])]), ofArray([CrudButton_edit(dispatch), CrudButton_cancel(dispatch)])), (msg, tupledArg, _arg1, model_1) => [model_1, Cmd_none()]);

export function deviceEditState() {
    return new StateTreeNode$6((deps) => [new DeviceEditModel(deps, false, empty(), false, empty()), Cmd_OfFunc_result(new StateTreeMsg$4(0, new DeviceEditMsg(1)))], (model, dispatch) => crudModal("Edit", dispatch, toList(delay(() => {
        let optic;
        return append(singleton(FormField_ReactSelect_deselectable(model.accounts, [new SelectPropsMulti$1(11, model.loadingAccounts)], (optic = AccountViewModel_get_name_(), (target) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic)(target)), tryGetAccountForDevice(model), new FormField_ReactSelect_CallbackSingle$1(0, (arg_4) => {
            dispatch(new StateTreeMsg$4(0, new DeviceEditMsg(2, arg_4)));
        }), "Account", "Device", toList(delay(() => (model.loadingAccounts ? singleton(new Option(10, "is-loading")) : empty_1()))))), delay(() => {
            let optic_4, f1_6, f1_5, mapping, optic_6, f2_5, optic_8;
            return singleton(FormField_ReactSelect_deselectable(model.sites, [new SelectPropsMulti$1(11, equals(Optic_Get_op_HatDot_21762A61(new Optic_Get(0), DeviceEditModel_get_accountId_())(model), void 0) ? true : model.loadingSites)], (optic_4 = SiteViewModel_get_name_(), (target_4) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic_4)(target_4)), tryGetSiteForDevice(model), new FormField_ReactSelect_CallbackSingle$1(0, (f1_6 = ((f1_5 = ((mapping = ((optic_6 = SiteViewModel_get_siteId_(), (target_6) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic_6)(target_6))), (option) => map(mapping, option))), (f2_5 = ((optic_8 = DeviceEditModel_get_siteId_(), (value) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic_8)(value))), (arg_5) => f2_5(f1_5(arg_5))))), (arg_6) => {
                dispatch(new StateTreeMsg$4(0, new DeviceEditMsg(0, new CrudEditMsg$2(0, f1_6(arg_6)))));
            })), "Site", "Device", toList(delay(() => (model.loadingSites ? singleton(new Option(10, "is-loading")) : empty_1())))));
        }));
    })), ofArray([CrudButton_save((arg0_12) => (new DeviceEditMsg(0, arg0_12)), canSaveEditedDevice(model.device), dispatch), CrudButton_cancel(dispatch)])), uncurry(4, (msg_2) => ((tupledArg) => {
        const token = tupledArg[0];
        return (_arg1) => ((model_3) => {
            if (msg_2.tag === 1) {
                return [new DeviceEditModel(model_3.device, true, model_3.accounts, true, model_3.sites), Cmd_OfAsyncWith_either((x) => {
                    Cmd_OfAsync_start(x);
                }, securedApi(token).getAccounts, void 0, (arg_7) => (new StateTreeMsg$4(0, new DeviceEditMsg(3, arg_7))), (arg_8) => (new StateTreeMsg$4(1, Common_eraseExceptionToError(arg_8))))];
            }
            else if (msg_2.tag === 2) {
                return [new DeviceEditModel(model_3.device, model_3.loadingAccounts, model_3.accounts, true, model_3.sites), Cmd_batch(toList(delay(() => {
                    let f1_9, value_2, f2_9;
                    return append(singleton(Cmd_OfFunc_result(new StateTreeMsg$4(0, new DeviceEditMsg(0, new CrudEditMsg$2(0, (f1_9 = ((value_2 = map((acc) => acc.id, msg_2.fields[0]), Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), DeviceEditModel_get_accountId_())(value_2))), (f2_9 = Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), DeviceEditModel_get_siteId_())(void 0), (arg_10) => f2_9(f1_9(arg_10))))))))), delay(() => ofArray(toArray(map((acc_1) => Cmd_OfAsyncWith_either((x_1) => {
                        Cmd_OfAsync_start(x_1);
                    }, securedApi(token).getSitesForAccount, acc_1.id, (arg_11) => (new StateTreeMsg$4(0, new DeviceEditMsg(4, arg_11))), (arg_12) => (new StateTreeMsg$4(1, Common_eraseExceptionToError(arg_12)))), msg_2.fields[0])))));
                })))];
            }
            else if (msg_2.tag === 3) {
                if (msg_2.fields[0].tag === 1) {
                    return [model_3, Cmd_batch(ofArray([error_3(ErrorMessage_get_describe()(msg_2.fields[0].fields[0])), Cmd_OfFunc_result(new StateTreeMsg$4(2))]))];
                }
                else {
                    const matchValue = model_3.device.accountId;
                    if (matchValue != null) {
                        const accountId = matchValue;
                        return [new DeviceEditModel(model_3.device, false, msg_2.fields[0].fields[0], true, model_3.sites), Cmd_OfAsyncWith_either((x_2) => {
                            Cmd_OfAsync_start(x_2);
                        }, securedApi(token).getSitesForAccount, accountId, (arg_14) => (new StateTreeMsg$4(0, new DeviceEditMsg(4, arg_14))), (arg_15) => (new StateTreeMsg$4(1, Common_eraseExceptionToError(arg_15))))];
                    }
                    else {
                        return [new DeviceEditModel(model_3.device, false, msg_2.fields[0].fields[0], false, model_3.sites), Cmd_OfFunc_result(new StateTreeMsg$4(2))];
                    }
                }
            }
            else {
                return (msg_2.tag === 4) ? ((msg_2.fields[0].tag === 1) ? [new DeviceEditModel(model_3.device, model_3.loadingAccounts, model_3.accounts, false, model_3.sites), Cmd_batch(ofArray([error_3(ErrorMessage_get_describe()(msg_2.fields[0].fields[0])), Cmd_OfFunc_result(new StateTreeMsg$4(2))]))] : [new DeviceEditModel(model_3.device, model_3.loadingAccounts, model_3.accounts, false, msg_2.fields[0].fields[0]), Cmd_OfFunc_result(new StateTreeMsg$4(2))]) : crudEditUpdate((arg0_13) => (new DeviceEditMsg(0, arg0_13)), (model_4) => securedApi(token).editDevice(model_4.device), Common_eraseExceptionToError, "Device edited.", ErrorMessage_get_describe(), msg_2.fields[0], model_3);
            }
        });
    })));
}

export const stateTree = (() => {
    let config_3, inputRecord, current_8, keepCachedChildren_3, child_4, child, current, config, maybeAutoMessage, key_2, current_2, config_1, keepCachedChildren_1, child_2, current_7, config_2, maybeAutoMessage_1, key_6, current_6, current_11, config_4, maybeAutoMessage_2, key_9, current_10;
    const columns = ofArray([new Sort_SortableColumn$1("Name", (dvm) => dvm.name, new Sort_Compare$1(0, (dvm1, dvm2) => comparePrimitives(dvm1.name, dvm2.name))), new Sort_SortableColumn$1("Account", (dvm_1) => defaultArg(dvm_1.accountName, "--"), new Sort_Compare$1(0, (dvm1_1, dvm2_1) => compare(dvm1_1.accountName, dvm2_1.accountName))), new Sort_SortableColumn$1("Site", (dvm_2) => defaultArg(dvm_2.siteName, "--"), new Sort_Compare$1(0, (dvm1_2, dvm2_2) => compare(dvm1_2.siteName, dvm2_2.siteName))), new Sort_SortableColumn$1("Region", (dvm_3) => defaultArg(dvm_3.region, "--"), new Sort_Compare$1(0, (dvm1_3, dvm2_3) => compare(dvm1_3.region, dvm2_3.region))), new Sort_SortableColumn$1("Device Status", (dvm_4) => (dvm_4.online ? "🟢 Online" : "🔴 Offline"), new Sort_Compare$1(0, (dvm1_4, dvm2_4) => compare(dvm1_4.siteName, dvm2_4.siteName)))]);
    return StateTree$3_$ctor_4E60E31B((config_3 = ((inputRecord = defaultConfig(), new StateTreeConfig$6(true, inputRecord.ReInitOnNavigation, new StateTreeMsg$4(0, new CrudListMsg$1(0)), inputRecord.KeepCachedChildren, (msg, arg10$0040, model) => crudListInbound(msg, void 0, model)))), (current_8 = crudListState("Devices", "Device", columns, (tupledArg) => singleton_1.Delay(() => (isAllowed(new Permission(26))(tupledArg[1].roles) ? singleton_1.Bind(securedApi(tupledArg[0]).getDevices(), (_arg1) => singleton_1.Return(Result_mapError()(ErrorMessage_get_describe())(_arg1))) : singleton_1.Return(new FSharpResult$2(0, empty())))), false), (keepCachedChildren_3 = config_3.KeepCachedChildren, (child_4 = ((child = ((current = deviceEditState(), (config = defaultConfig_1(), (maybeAutoMessage = config.MaybeSendMessageOnNavigation, (key_2 = "edit", (current_2 = current, new Internal_StateTreeRoot$6((_arg2, deps, maybeCachedModel, _arg1_1) => {
        const matchValue = [maybeCachedModel, config.ReInitOnNavigation, maybeAutoMessage];
        let pattern_matching_result, model_1, model_1_1, msg_1;
        if (matchValue[0] != null) {
            if (matchValue[1]) {
                pattern_matching_result = 2;
            }
            else if (matchValue[2] != null) {
                pattern_matching_result = 1;
                model_1_1 = matchValue[0];
                msg_1 = matchValue[2];
            }
            else {
                pattern_matching_result = 0;
                model_1 = matchValue[0];
            }
        }
        else {
            pattern_matching_result = 2;
        }
        switch (pattern_matching_result) {
            case 0: {
                return [model_1, Cmd_none_1()];
            }
            case 1: {
                return [model_1_1, Cmd_OfFunc_result(msg_1)];
            }
            case 2: {
                const patternInput = current_2.Init(deps);
                return [new Internal_StateTreeModel$1(patternInput[0], false), Cmd_batch_1(toList(delay(() => append(singleton(patternInput[1]), delay(() => ofArray(toArray(map(Cmd_OfFunc_result, maybeAutoMessage))))))))];
            }
        }
    }, (_arg3, dispatch, model_2) => current_2.View(model_2.NodeModel, dispatch), (_arg4, msg_3_1, pass, deps_1, model_3) => {
        switch (msg_3_1.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_1.fields[0]}`));
                return [model_3, Cmd_none_1(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3.NodeModel, true), Cmd_none_1(), void 0, void 0];
            }
            case 3: {
                return [model_3, Cmd_none_1(), void 0, msg_3_1.fields[0]];
            }
            case 4: {
                return [model_3, Cmd_none_1(), msg_3_1.fields[0], void 0];
            }
            default: {
                const patternInput_1 = current_2.Update(msg_3_1.fields[0], pass, deps_1, model_3.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1[0], model_3.Initialized), patternInput_1[1], void 0, void 0];
            }
        }
    }, (msg_4, deps_2, model_4) => {
        const patternInput_2 = config.UpdateInbound(msg_4, deps_2, model_4.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2[0], model_4.Initialized), patternInput_2[1]];
    }, key_2, ofList(singleton_2([singleton_2(key_2), singleton_2(0)]))))))))), (config_1 = defaultConfig_1(), (keepCachedChildren_1 = config_1.KeepCachedChildren, (child_2 = child, (current_7 = ((config_2 = config_1, (maybeAutoMessage_1 = config_2.MaybeSendMessageOnNavigation, (key_6 = "detail", (current_6 = deviceDetailState, new Internal_StateTreeRoot$6((_arg2_1, deps_3, maybeCachedModel_1, _arg1_2) => {
        const matchValue_1 = [maybeCachedModel_1, config_2.ReInitOnNavigation, maybeAutoMessage_1];
        let pattern_matching_result_1, model_5, model_1_2, msg_5;
        if (matchValue_1[0] != null) {
            if (matchValue_1[1]) {
                pattern_matching_result_1 = 2;
            }
            else if (matchValue_1[2] != null) {
                pattern_matching_result_1 = 1;
                model_1_2 = matchValue_1[0];
                msg_5 = matchValue_1[2];
            }
            else {
                pattern_matching_result_1 = 0;
                model_5 = matchValue_1[0];
            }
        }
        else {
            pattern_matching_result_1 = 2;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                return [model_5, Cmd_none_1()];
            }
            case 1: {
                return [model_1_2, Cmd_OfFunc_result(msg_5)];
            }
            case 2: {
                const patternInput_3 = current_6.Init(deps_3);
                return [new Internal_StateTreeModel$1(patternInput_3[0], false), Cmd_batch_1(toList(delay(() => append(singleton(patternInput_3[1]), delay(() => ofArray(toArray(map(Cmd_OfFunc_result, maybeAutoMessage_1))))))))];
            }
        }
    }, (_arg3_1, dispatch_1, model_2_1) => current_6.View(model_2_1.NodeModel, dispatch_1), (_arg4_1, msg_3_2, pass_1, deps_1_1, model_3_1) => {
        switch (msg_3_2.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_2.fields[0]}`));
                return [model_3_1, Cmd_none_1(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3_1.NodeModel, true), Cmd_none_1(), void 0, void 0];
            }
            case 3: {
                return [model_3_1, Cmd_none_1(), void 0, msg_3_2.fields[0]];
            }
            case 4: {
                return [model_3_1, Cmd_none_1(), msg_3_2.fields[0], void 0];
            }
            default: {
                const patternInput_1_1 = current_6.Update(msg_3_2.fields[0], pass_1, deps_1_1, model_3_1.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1_1[0], model_3_1.Initialized), patternInput_1_1[1], void 0, void 0];
            }
        }
    }, (msg_4_1, deps_2_1, model_4_1) => {
        const patternInput_2_1 = config_2.UpdateInbound(msg_4_1, deps_2_1, model_4_1.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2_1[0], model_4_1.Initialized), patternInput_2_1[1]];
    }, key_6, ofList(singleton_2([singleton_2(key_6), singleton_2(0)])))))))), new Internal_StateTreeRoot$6((indexPath, deps_4, maybeCachedModel_2, _arg1_3) => {
        const maybeCachedCurrentModel = bind((cachedModel) => cachedModel.MaybeCurrentModel, maybeCachedModel_2);
        const maybeCachedChildModel = bind((cachedModel_1) => cachedModel_1.MaybeChildModel, maybeCachedModel_2);
        const matchValue_2 = [indexPath, maybeCachedCurrentModel];
        let pattern_matching_result_2, currentModel_1, xs;
        if (!isEmpty(matchValue_2[0])) {
            if (head(matchValue_2[0]) === 0) {
                if (isEmpty(tail(matchValue_2[0]))) {
                    pattern_matching_result_2 = 0;
                }
                else if (matchValue_2[1] != null) {
                    pattern_matching_result_2 = 1;
                    currentModel_1 = matchValue_2[1];
                    xs = tail(matchValue_2[0]);
                }
                else {
                    pattern_matching_result_2 = 0;
                }
            }
            else {
                pattern_matching_result_2 = 2;
            }
        }
        else {
            pattern_matching_result_2 = 2;
        }
        switch (pattern_matching_result_2) {
            case 0: {
                const patternInput_4 = current_7.Init(indexPath, deps_4, maybeCachedCurrentModel, false);
                return [new Internal_BranchModelChild$2(patternInput_4[0], keepCachedChildren_1 ? maybeCachedChildModel : (void 0)), Cmd_map((arg0_2) => (new Internal_BranchMsgChild$2(0, arg0_2)), patternInput_4[1])];
            }
            case 1: {
                const patternInput_1_2 = child_2.Init(xs, currentModel_1.NodeModel, maybeCachedChildModel, keepCachedChildren_1);
                return [new Internal_BranchModelChild$2(currentModel_1, patternInput_1_2[0]), Cmd_map((arg0_2_1) => (new Internal_BranchMsgChild$2(1, arg0_2_1)), patternInput_1_2[1])];
            }
            case 2: {
                console.error(some("Failed to initialize state, invalid index"));
                return [defaultArg(maybeCachedModel_2, new Internal_BranchModelChild$2(void 0, void 0)), Cmd_none_1()];
            }
        }
    }, (indexPath_1, dispatch_2, model_6) => {
        let pattern_matching_result_3, xs_1;
        if (!isEmpty(indexPath_1)) {
            if (head(indexPath_1) === 0) {
                if (isEmpty(tail(indexPath_1))) {
                    pattern_matching_result_3 = 0;
                }
                else {
                    pattern_matching_result_3 = 1;
                    xs_1 = tail(indexPath_1);
                }
            }
            else {
                pattern_matching_result_3 = 2;
            }
        }
        else {
            pattern_matching_result_3 = 2;
        }
        switch (pattern_matching_result_3) {
            case 0: {
                return defaultArg(map(partialApply(1, current_7.View, [indexPath_1, (arg) => {
                    dispatch_2(new Internal_BranchMsgChild$2(0, arg));
                }]), model_6.MaybeCurrentModel), "Loading...");
            }
            case 1: {
                const children = toList(delay(() => append(config_1.VisibleToChildren ? singleton(defaultArg(map(partialApply(1, current_7.View, [indexPath_1, (arg_1) => {
                    dispatch_2(new Internal_BranchMsgChild$2(0, arg_1));
                }]), model_6.MaybeCurrentModel), "Loading...")) : empty_1(), delay(() => singleton(defaultArg(map(partialApply(1, child_2.View, [xs_1, (arg_2) => {
                    dispatch_2(new Internal_BranchMsgChild$2(1, arg_2));
                }]), model_6.MaybeChildModel), "Loading..."))))));
                return react.createElement("div", {}, ...children);
            }
            case 2: {
                return "Woops, looks like you took a wrong turn...";
            }
        }
    }, (indexPath_2, branchMsg, pass_2, deps_1_2, model_1_3) => {
        let newNodeModel;
        const matchValue_1_1 = [branchMsg, indexPath_2];
        let pattern_matching_result_4, msg_8, xs_2, msg_1_3, xs_3;
        if (matchValue_1_1[0].tag === 1) {
            if (!isEmpty(matchValue_1_1[1])) {
                if (head(matchValue_1_1[1]) === 0) {
                    pattern_matching_result_4 = 1;
                    msg_1_3 = matchValue_1_1[0].fields[0];
                    xs_3 = tail(matchValue_1_1[1]);
                }
                else {
                    pattern_matching_result_4 = 2;
                }
            }
            else {
                pattern_matching_result_4 = 2;
            }
        }
        else if (!isEmpty(matchValue_1_1[1])) {
            if (head(matchValue_1_1[1]) === 0) {
                pattern_matching_result_4 = 0;
                msg_8 = matchValue_1_1[0].fields[0];
                xs_2 = tail(matchValue_1_1[1]);
            }
            else {
                pattern_matching_result_4 = 2;
            }
        }
        else {
            pattern_matching_result_4 = 2;
        }
        switch (pattern_matching_result_4) {
            case 0: {
                const matchValue_2_1 = model_1_3.MaybeCurrentModel;
                if (matchValue_2_1 == null) {
                    console.error(some("Failed to update state, not initialized"));
                    return [model_1_3, Cmd_none_1(), void 0, void 0];
                }
                else {
                    const currentModel_2 = matchValue_2_1;
                    const patternInput_2_2 = current_7.Update(indexPath_2, msg_8, pass_2, deps_1_2, currentModel_2);
                    const newCurrentModel = patternInput_2_2[0];
                    const maybeOutboundMsg = patternInput_2_2[3];
                    const maybeNavMsg = patternInput_2_2[2];
                    const currentCmd_1 = patternInput_2_2[1];
                    let pattern_matching_result_5;
                    if (msg_8.tag === 2) {
                        if (!equals(xs_2, empty())) {
                            pattern_matching_result_5 = 0;
                        }
                        else {
                            pattern_matching_result_5 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_5 = 1;
                    }
                    switch (pattern_matching_result_5) {
                        case 0: {
                            const patternInput_3_1 = child_2.Init(xs_2, currentModel_2.NodeModel, model_1_3.MaybeChildModel, false);
                            return [new Internal_BranchModelChild$2(newCurrentModel, patternInput_3_1[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_8) => (new Internal_BranchMsgChild$2(0, arg0_8)), currentCmd_1), Cmd_map((arg0_9) => (new Internal_BranchMsgChild$2(1, arg0_9)), patternInput_3_1[1])])), maybeNavMsg, maybeOutboundMsg];
                        }
                        case 1: {
                            return [new Internal_BranchModelChild$2(newCurrentModel, model_1_3.MaybeChildModel), Cmd_map((arg0_11) => (new Internal_BranchMsgChild$2(0, arg0_11)), currentCmd_1), maybeNavMsg, maybeOutboundMsg];
                        }
                    }
                }
            }
            case 1: {
                const matchValue_3 = [model_1_3.MaybeCurrentModel, model_1_3.MaybeChildModel];
                let pattern_matching_result_6, childModel_1, currentModel_3;
                if (matchValue_3[0] != null) {
                    if (matchValue_3[1] != null) {
                        pattern_matching_result_6 = 0;
                        childModel_1 = matchValue_3[1];
                        currentModel_3 = matchValue_3[0];
                    }
                    else {
                        pattern_matching_result_6 = 1;
                    }
                }
                else {
                    pattern_matching_result_6 = 1;
                }
                switch (pattern_matching_result_6) {
                    case 0: {
                        const patternInput_4_1 = child_2.Update(xs_3, msg_1_3, pass_2, currentModel_3.NodeModel, childModel_1);
                        const maybeNavMsg_1 = patternInput_4_1[2];
                        return [new Internal_BranchModelChild$2((maybeNavMsg_1 != null) ? ((maybeNavMsg_1.tag === 2) ? ((newNodeModel = maybeNavMsg_1.fields[0], map((model_2_2) => (new Internal_StateTreeModel$1(newNodeModel, model_2_2.Initialized)), model_1_3.MaybeCurrentModel))) : model_1_3.MaybeCurrentModel) : model_1_3.MaybeCurrentModel, patternInput_4_1[0]), Cmd_map((arg0_13) => (new Internal_BranchMsgChild$2(1, arg0_13)), patternInput_4_1[1]), map(NavigationMsg$1_destructiveCast_Z33BEA92B, maybeNavMsg_1), patternInput_4_1[3]];
                    }
                    case 1: {
                        console.error(some("Failed to update state, parent not initialized"));
                        return [model_1_3, Cmd_none_1(), void 0, void 0];
                    }
                }
            }
            case 2: {
                console.warn(some("Got an update for a node that shouldn\u0027t be initialized, ignoring"));
                return [model_1_3, Cmd_none_1(), void 0, void 0];
            }
        }
    }, (msg_2_3, deps_2_2, model_3_2) => {
        let patternInput_6;
        const matchValue_4 = model_3_2.MaybeCurrentModel;
        if (matchValue_4 == null) {
            patternInput_6 = [model_3_2.MaybeCurrentModel, Cmd_none_1()];
        }
        else {
            const patternInput_5 = current_7.UpdateInbound(msg_2_3, deps_2_2, matchValue_4);
            patternInput_6 = [patternInput_5[0], patternInput_5[1]];
        }
        let patternInput_8;
        const matchValue_5 = [model_3_2.MaybeChildModel, model_3_2.MaybeCurrentModel];
        let pattern_matching_result_7, childModel_2, currentModel_5;
        if (matchValue_5[0] != null) {
            if (matchValue_5[1] != null) {
                pattern_matching_result_7 = 0;
                childModel_2 = matchValue_5[0];
                currentModel_5 = matchValue_5[1];
            }
            else {
                pattern_matching_result_7 = 1;
            }
        }
        else {
            pattern_matching_result_7 = 1;
        }
        switch (pattern_matching_result_7) {
            case 0: {
                const patternInput_7 = child_2.UpdateInbound(msg_2_3, currentModel_5.NodeModel, childModel_2);
                patternInput_8 = [patternInput_7[0], patternInput_7[1]];
                break;
            }
            case 1: {
                patternInput_8 = [model_3_2.MaybeChildModel, Cmd_none_1()];
                break;
            }
        }
        return [new Internal_BranchModelChild$2(patternInput_6[0], patternInput_8[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_14) => (new Internal_BranchMsgChild$2(0, arg0_14)), patternInput_6[1]), Cmd_map((arg0_15) => (new Internal_BranchMsgChild$2(1, arg0_15)), patternInput_8[1])]))];
    }, current_7.LastKey, Internal_combineChildIndexPathFromKeyPathMap(current_7.LastKey, child_2.IndexPathFromKeyPath, current_7.IndexPathFromKeyPath)))))))), (current_11 = ((config_4 = config_3, (maybeAutoMessage_2 = config_4.MaybeSendMessageOnNavigation, (key_9 = "list", (current_10 = current_8, new Internal_StateTreeRoot$6((_arg2_2, deps_5, maybeCachedModel_3, _arg1_4) => {
        const matchValue_6 = [maybeCachedModel_3, config_4.ReInitOnNavigation, maybeAutoMessage_2];
        let pattern_matching_result_8, model_7, model_1_4, msg_9;
        if (matchValue_6[0] != null) {
            if (matchValue_6[1]) {
                pattern_matching_result_8 = 2;
            }
            else if (matchValue_6[2] != null) {
                pattern_matching_result_8 = 1;
                model_1_4 = matchValue_6[0];
                msg_9 = matchValue_6[2];
            }
            else {
                pattern_matching_result_8 = 0;
                model_7 = matchValue_6[0];
            }
        }
        else {
            pattern_matching_result_8 = 2;
        }
        switch (pattern_matching_result_8) {
            case 0: {
                return [model_7, Cmd_none_1()];
            }
            case 1: {
                return [model_1_4, Cmd_OfFunc_result(msg_9)];
            }
            case 2: {
                const patternInput_9 = current_10.Init(deps_5);
                return [new Internal_StateTreeModel$1(patternInput_9[0], false), Cmd_batch_1(toList(delay(() => append(singleton(patternInput_9[1]), delay(() => ofArray(toArray(map(Cmd_OfFunc_result, maybeAutoMessage_2))))))))];
            }
        }
    }, (_arg3_2, dispatch_3, model_2_3) => current_10.View(model_2_3.NodeModel, dispatch_3), (_arg4_2, msg_3_3, pass_3, deps_1_3, model_3_3) => {
        switch (msg_3_3.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_3.fields[0]}`));
                return [model_3_3, Cmd_none_1(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3_3.NodeModel, true), Cmd_none_1(), void 0, void 0];
            }
            case 3: {
                return [model_3_3, Cmd_none_1(), void 0, msg_3_3.fields[0]];
            }
            case 4: {
                return [model_3_3, Cmd_none_1(), msg_3_3.fields[0], void 0];
            }
            default: {
                const patternInput_1_3 = current_10.Update(msg_3_3.fields[0], pass_3, deps_1_3, model_3_3.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1_3[0], model_3_3.Initialized), patternInput_1_3[1], void 0, void 0];
            }
        }
    }, (msg_4_2, deps_2_3, model_4_2) => {
        const patternInput_2_3 = config_4.UpdateInbound(msg_4_2, deps_2_3, model_4_2.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2_3[0], model_4_2.Initialized), patternInput_2_3[1]];
    }, key_9, ofList(singleton_2([singleton_2(key_9), singleton_2(0)])))))))), new Internal_StateTreeRoot$6((indexPath_3, deps_6, maybeCachedModel_4, _arg1_5) => {
        const maybeCachedCurrentModel_1 = bind((cachedModel_2) => cachedModel_2.MaybeCurrentModel, maybeCachedModel_4);
        const maybeCachedChildModel_1 = bind((cachedModel_1_1) => cachedModel_1_1.MaybeChildModel, maybeCachedModel_4);
        const matchValue_7 = [indexPath_3, maybeCachedCurrentModel_1];
        let pattern_matching_result_9, currentModel_1_1, xs_4;
        if (!isEmpty(matchValue_7[0])) {
            if (head(matchValue_7[0]) === 0) {
                if (isEmpty(tail(matchValue_7[0]))) {
                    pattern_matching_result_9 = 0;
                }
                else if (matchValue_7[1] != null) {
                    pattern_matching_result_9 = 1;
                    currentModel_1_1 = matchValue_7[1];
                    xs_4 = tail(matchValue_7[0]);
                }
                else {
                    pattern_matching_result_9 = 0;
                }
            }
            else {
                pattern_matching_result_9 = 2;
            }
        }
        else {
            pattern_matching_result_9 = 2;
        }
        switch (pattern_matching_result_9) {
            case 0: {
                const patternInput_10 = current_11.Init(indexPath_3, deps_6, maybeCachedCurrentModel_1, false);
                return [new Internal_BranchModelChild$2(patternInput_10[0], keepCachedChildren_3 ? maybeCachedChildModel_1 : (void 0)), Cmd_map((arg0_16) => (new Internal_BranchMsgChild$2(0, arg0_16)), patternInput_10[1])];
            }
            case 1: {
                const patternInput_1_4 = child_4.Init(xs_4, currentModel_1_1.NodeModel, maybeCachedChildModel_1, keepCachedChildren_3);
                return [new Internal_BranchModelChild$2(currentModel_1_1, patternInput_1_4[0]), Cmd_map((arg0_2_2) => (new Internal_BranchMsgChild$2(1, arg0_2_2)), patternInput_1_4[1])];
            }
            case 2: {
                console.error(some("Failed to initialize state, invalid index"));
                return [defaultArg(maybeCachedModel_4, new Internal_BranchModelChild$2(void 0, void 0)), Cmd_none_1()];
            }
        }
    }, (indexPath_1_1, dispatch_4, model_8) => {
        let pattern_matching_result_10, xs_1_1;
        if (!isEmpty(indexPath_1_1)) {
            if (head(indexPath_1_1) === 0) {
                if (isEmpty(tail(indexPath_1_1))) {
                    pattern_matching_result_10 = 0;
                }
                else {
                    pattern_matching_result_10 = 1;
                    xs_1_1 = tail(indexPath_1_1);
                }
            }
            else {
                pattern_matching_result_10 = 2;
            }
        }
        else {
            pattern_matching_result_10 = 2;
        }
        switch (pattern_matching_result_10) {
            case 0: {
                return defaultArg(map(partialApply(1, current_11.View, [indexPath_1_1, (arg_3) => {
                    dispatch_4(new Internal_BranchMsgChild$2(0, arg_3));
                }]), model_8.MaybeCurrentModel), "Loading...");
            }
            case 1: {
                const children_2 = toList(delay(() => append(config_3.VisibleToChildren ? singleton(defaultArg(map(partialApply(1, current_11.View, [indexPath_1_1, (arg_4) => {
                    dispatch_4(new Internal_BranchMsgChild$2(0, arg_4));
                }]), model_8.MaybeCurrentModel), "Loading...")) : empty_1(), delay(() => singleton(defaultArg(map(partialApply(1, child_4.View, [xs_1_1, (arg_5) => {
                    dispatch_4(new Internal_BranchMsgChild$2(1, arg_5));
                }]), model_8.MaybeChildModel), "Loading..."))))));
                return react.createElement("div", {}, ...children_2);
            }
            case 2: {
                return "Woops, looks like you took a wrong turn...";
            }
        }
    }, (indexPath_2_1, branchMsg_1, pass_4, deps_1_4, model_1_5) => {
        let newNodeModel_1;
        const matchValue_1_2 = [branchMsg_1, indexPath_2_1];
        let pattern_matching_result_11, msg_12, xs_2_1, msg_1_5, xs_3_1;
        if (matchValue_1_2[0].tag === 1) {
            if (!isEmpty(matchValue_1_2[1])) {
                if (head(matchValue_1_2[1]) === 0) {
                    pattern_matching_result_11 = 1;
                    msg_1_5 = matchValue_1_2[0].fields[0];
                    xs_3_1 = tail(matchValue_1_2[1]);
                }
                else {
                    pattern_matching_result_11 = 2;
                }
            }
            else {
                pattern_matching_result_11 = 2;
            }
        }
        else if (!isEmpty(matchValue_1_2[1])) {
            if (head(matchValue_1_2[1]) === 0) {
                pattern_matching_result_11 = 0;
                msg_12 = matchValue_1_2[0].fields[0];
                xs_2_1 = tail(matchValue_1_2[1]);
            }
            else {
                pattern_matching_result_11 = 2;
            }
        }
        else {
            pattern_matching_result_11 = 2;
        }
        switch (pattern_matching_result_11) {
            case 0: {
                const matchValue_2_2 = model_1_5.MaybeCurrentModel;
                if (matchValue_2_2 == null) {
                    console.error(some("Failed to update state, not initialized"));
                    return [model_1_5, Cmd_none_1(), void 0, void 0];
                }
                else {
                    const currentModel_2_1 = matchValue_2_2;
                    const patternInput_2_4 = current_11.Update(indexPath_2_1, msg_12, pass_4, deps_1_4, currentModel_2_1);
                    const newCurrentModel_3 = patternInput_2_4[0];
                    const maybeOutboundMsg_2 = patternInput_2_4[3];
                    const maybeNavMsg_2 = patternInput_2_4[2];
                    const currentCmd_1_1 = patternInput_2_4[1];
                    let pattern_matching_result_12;
                    if (msg_12.tag === 2) {
                        if (!equals(xs_2_1, empty())) {
                            pattern_matching_result_12 = 0;
                        }
                        else {
                            pattern_matching_result_12 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_12 = 1;
                    }
                    switch (pattern_matching_result_12) {
                        case 0: {
                            const patternInput_3_2 = child_4.Init(xs_2_1, currentModel_2_1.NodeModel, model_1_5.MaybeChildModel, false);
                            return [new Internal_BranchModelChild$2(newCurrentModel_3, patternInput_3_2[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_8_1) => (new Internal_BranchMsgChild$2(0, arg0_8_1)), currentCmd_1_1), Cmd_map((arg0_9_1) => (new Internal_BranchMsgChild$2(1, arg0_9_1)), patternInput_3_2[1])])), maybeNavMsg_2, maybeOutboundMsg_2];
                        }
                        case 1: {
                            return [new Internal_BranchModelChild$2(newCurrentModel_3, model_1_5.MaybeChildModel), Cmd_map((arg0_11_1) => (new Internal_BranchMsgChild$2(0, arg0_11_1)), currentCmd_1_1), maybeNavMsg_2, maybeOutboundMsg_2];
                        }
                    }
                }
            }
            case 1: {
                const matchValue_3_1 = [model_1_5.MaybeCurrentModel, model_1_5.MaybeChildModel];
                let pattern_matching_result_13, childModel_1_1, currentModel_3_1;
                if (matchValue_3_1[0] != null) {
                    if (matchValue_3_1[1] != null) {
                        pattern_matching_result_13 = 0;
                        childModel_1_1 = matchValue_3_1[1];
                        currentModel_3_1 = matchValue_3_1[0];
                    }
                    else {
                        pattern_matching_result_13 = 1;
                    }
                }
                else {
                    pattern_matching_result_13 = 1;
                }
                switch (pattern_matching_result_13) {
                    case 0: {
                        const patternInput_4_2 = child_4.Update(xs_3_1, msg_1_5, pass_4, currentModel_3_1.NodeModel, childModel_1_1);
                        const maybeNavMsg_1_1 = patternInput_4_2[2];
                        return [new Internal_BranchModelChild$2((maybeNavMsg_1_1 != null) ? ((maybeNavMsg_1_1.tag === 2) ? ((newNodeModel_1 = maybeNavMsg_1_1.fields[0], map((model_2_4) => (new Internal_StateTreeModel$1(newNodeModel_1, model_2_4.Initialized)), model_1_5.MaybeCurrentModel))) : model_1_5.MaybeCurrentModel) : model_1_5.MaybeCurrentModel, patternInput_4_2[0]), Cmd_map((arg0_13_1) => (new Internal_BranchMsgChild$2(1, arg0_13_1)), patternInput_4_2[1]), map(NavigationMsg$1_destructiveCast_Z33BEA92B, maybeNavMsg_1_1), patternInput_4_2[3]];
                    }
                    case 1: {
                        console.error(some("Failed to update state, parent not initialized"));
                        return [model_1_5, Cmd_none_1(), void 0, void 0];
                    }
                }
            }
            case 2: {
                console.warn(some("Got an update for a node that shouldn\u0027t be initialized, ignoring"));
                return [model_1_5, Cmd_none_1(), void 0, void 0];
            }
        }
    }, (msg_2_5, deps_2_4, model_3_4) => {
        let patternInput_6_1;
        const matchValue_4_1 = model_3_4.MaybeCurrentModel;
        if (matchValue_4_1 == null) {
            patternInput_6_1 = [model_3_4.MaybeCurrentModel, Cmd_none_1()];
        }
        else {
            const patternInput_5_1 = current_11.UpdateInbound(msg_2_5, deps_2_4, matchValue_4_1);
            patternInput_6_1 = [patternInput_5_1[0], patternInput_5_1[1]];
        }
        let patternInput_8_1;
        const matchValue_5_1 = [model_3_4.MaybeChildModel, model_3_4.MaybeCurrentModel];
        let pattern_matching_result_14, childModel_2_1, currentModel_5_1;
        if (matchValue_5_1[0] != null) {
            if (matchValue_5_1[1] != null) {
                pattern_matching_result_14 = 0;
                childModel_2_1 = matchValue_5_1[0];
                currentModel_5_1 = matchValue_5_1[1];
            }
            else {
                pattern_matching_result_14 = 1;
            }
        }
        else {
            pattern_matching_result_14 = 1;
        }
        switch (pattern_matching_result_14) {
            case 0: {
                const patternInput_7_1 = child_4.UpdateInbound(msg_2_5, currentModel_5_1.NodeModel, childModel_2_1);
                patternInput_8_1 = [patternInput_7_1[0], patternInput_7_1[1]];
                break;
            }
            case 1: {
                patternInput_8_1 = [model_3_4.MaybeChildModel, Cmd_none_1()];
                break;
            }
        }
        return [new Internal_BranchModelChild$2(patternInput_6_1[0], patternInput_8_1[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_14_1) => (new Internal_BranchMsgChild$2(0, arg0_14_1)), patternInput_6_1[1]), Cmd_map((arg0_15_1) => (new Internal_BranchMsgChild$2(1, arg0_15_1)), patternInput_8_1[1])]))];
    }, current_11.LastKey, Internal_combineChildIndexPathFromKeyPathMap(current_11.LastKey, child_4.IndexPathFromKeyPath, current_11.IndexPathFromKeyPath))))))));
})();

