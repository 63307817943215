import { RenderingEngine, Enums, init as csRenderInit, eventTarget } from '@cornerstonejs/core';
import { addTool, ToolGroupManager, PanTool, ZoomTool, EllipticalROITool, WindowLevelTool, LengthTool, ArrowAnnotateTool, PlanarFreehandROITool, Enums as csToolsEnums, init as csToolsInit, annotation, synchronizers, } from '@cornerstonejs/tools';
import React, { useRef, useEffect, useState } from 'react';
import { initCornerstoneDicomImageLoader, addFilesToDicomLoader, fileToIndexMap, indexToFileMap } from './CornerstoneDicomImageLoader';
import { ControlledMenu, MenuItem, SubMenu } from '@szhsin/react-menu';
import html2canvas from 'html2canvas';
const { ViewportType } = Enums;
const { createCameraPositionSynchronizer, createVOISynchronizer } = synchronizers;
const cameraTools = [
    PanTool.toolName,
    ZoomTool.toolName,
    WindowLevelTool.toolName,
];
const annotationTools = [
    EllipticalROITool.toolName,
    LengthTool.toolName,
    ArrowAnnotateTool.toolName,
    PlanarFreehandROITool.toolName
];
const toolNamesMap = {
    [PanTool.toolName]: "Pan Tool",
    [ZoomTool.toolName]: "Zoom Tool",
    [WindowLevelTool.toolName]: "Window Level Tool",
    [EllipticalROITool.toolName]: "Circle/Oval ROI Tool",
    [LengthTool.toolName]: "Length Tool",
    [ArrowAnnotateTool.toolName]: "Notes/Arrow Tool",
    [PlanarFreehandROITool.toolName]: "Freehand ROI Tool",
};
const toolGroupId = 'toolGroup';
const toolGroup = ToolGroupManager.createToolGroup(toolGroupId);
function getMappedToolName(toolName) { return toolNamesMap[toolName] || toolName; }
function addToolsToToolGroup() {
    const tools = [...cameraTools, ...annotationTools];
    for (let i in tools) {
        toolGroup.addTool(tools[i]);
    }
    toolGroup?.setToolConfiguration(ArrowAnnotateTool.toolName, {
        getTextCallback: (callback) => callback('   '),
        changeTextCallback: (data, eventData, callback) => callback(data.data.text),
    });
}
function disableCameraTools() {
    for (let i in cameraTools) {
        toolGroup.setToolDisabled(cameraTools[i]);
    }
}
function enableAnnotationTools() {
    for (let i in annotationTools) {
        toolGroup.setToolEnabled(annotationTools[i]);
    }
}
function activateTool(toolName) {
    toolGroup.setToolEnabled(toolName);
    toolGroup.setToolActive(toolName, {
        bindings: [
            {
                mouseButton: csToolsEnums.MouseBindings.Primary, // Left Click
            },
        ],
    });
}
var initializedGlobally = false;
//const cameraSyncId = 'cameraSync';
async function globallyInitializeCornerstone() {
    if (initializedGlobally) {
        return;
    }
    else {
        initializedGlobally = true;
        // Globally initialize cornerstone
        initCornerstoneDicomImageLoader();
        csRenderInit();
        csToolsInit();
        addTool(PanTool);
        addTool(ZoomTool);
        addTool(WindowLevelTool);
        addTool(EllipticalROITool);
        addTool(LengthTool);
        addTool(ArrowAnnotateTool);
        addTool(PlanarFreehandROITool);
        //createCameraPositionSynchronizer(cameraSyncId);
    }
}
async function initializeCornerstone(constants) {
    await globallyInitializeCornerstone();
    // Create rendering engine
    const cornerstoneObjects = {
        renderingEngine: new RenderingEngine(constants.renderingEngineId)
    };
    addToolsToToolGroup();
    enableAnnotationTools();
    return cornerstoneObjects;
}
async function activatePanTool() {
    disableCameraTools();
    enableAnnotationTools();
    activateTool(PanTool.toolName);
}
async function activateZoomTool() {
    disableCameraTools();
    enableAnnotationTools();
    activateTool(ZoomTool.toolName);
}
async function activateWindowLevelTool() {
    disableCameraTools();
    enableAnnotationTools();
    activateTool(WindowLevelTool.toolName);
}
async function activateEllipticalROITool() {
    disableCameraTools();
    enableAnnotationTools();
    activateTool(EllipticalROITool.toolName);
}
async function activateLengthTool() {
    disableCameraTools();
    enableAnnotationTools();
    activateTool(LengthTool.toolName);
}
async function activateArrowAnnotateTool() {
    disableCameraTools();
    enableAnnotationTools();
    activateTool(ArrowAnnotateTool.toolName);
}
async function activatePlanarFreehandROITool() {
    disableCameraTools();
    enableAnnotationTools();
    activateTool(PlanarFreehandROITool.toolName);
}
//function addCameraSyncronizer(constants: CornerstoneConstants) {
//    const cameraSynchronizer = SynchronizerManager.getSynchronizer(cameraSyncId);
//    if (cameraSynchronizer) {
//        cameraSynchronizer.add({
//            renderingEngineId: constants.renderingEngineId,
//            viewportId: constants.viewportId
//        });
//    }
//}
//function removeCameraSyncronizer(constants: CornerstoneConstants) {
//    const cameraSynchronizer = SynchronizerManager.getSynchronizer(cameraSyncId);
//    if (cameraSynchronizer) {
//        cameraSynchronizer.remove({
//            renderingEngineId: constants.renderingEngineId,
//            viewportId: constants.viewportId
//        });
//    }
//}
async function setupCornerstoneViewport(constants, cornerstoneObjectsPromise, element, imageIds, annotations, initialFrame, loadedDicomFileNames) {
    element.oncontextmenu = (e) => e.preventDefault();
    const viewportInput = {
        viewportId: constants.viewportId,
        element,
        type: ViewportType.STACK,
    };
    const cornerstoneObjects = await cornerstoneObjectsPromise;
    // Enable the DOM Element for use with Cornerstone
    cornerstoneObjects.renderingEngine.enableElement(viewportInput);
    const viewport = cornerstoneObjects.renderingEngine.getViewport(constants.viewportId);
    await viewport.setStack(imageIds, initialFrame);
    viewport.render();
    toolGroup.addViewport(constants.viewportId, constants.renderingEngineId);
    var currentAnnotations = annotation.state.getAllAnnotations();
    var loadedDicomFileIndexes = loadedDicomFileNames.map((fileName) => fileToIndexMap.get(fileName));
    currentAnnotations.forEach((a) => {
        // If the annotation isn't in any of the loaded images then clear it
        if (!loadedDicomFileIndexes.includes(a.metadata.referencedImageId)) {
            annotation.state.removeAnnotation(a.annotationUID);
        }
    });
    annotations.forEach((a) => {
        if (annotation.state.getAnnotation(a.annotationUID) == null) {
            const fileName = a.metadata.referencedImageId;
            const imageIndex = fileToIndexMap.get(fileName);
            a.metadata.referencedImageId = imageIndex;
            annotation.state.addAnnotation(a, element);
        }
    });
    activatePanTool();
    //addCameraSyncronizer(constants);
    return viewport;
}
async function teardownCornerstoneViewport(constants, cornerstoneObjectsPromise) {
    const cornerstoneObjects = await cornerstoneObjectsPromise;
    cornerstoneObjects.renderingEngine.disableElement(constants.viewportId);
    //removeCameraSyncronizer(constants);
    // Why are the arguments of this function in a different order from addViewport?
    // also why is it pluralized?
    toolGroup.removeViewports(constants.renderingEngineId, constants.viewportId);
}
async function setFrame(constants, cornerstoneObjectsPromise, frameEvent) {
    if (frameEvent.viewportIndex === constants.viewportIndex) {
        const cornerstoneObjects = await cornerstoneObjectsPromise;
        const viewport = cornerstoneObjects.renderingEngine.getViewport(constants.viewportId);
        await viewport.setImageIdIndex(frameEvent.frameIndex);
        viewport.render();
    }
}
function forceNarrowSliceIndex(sliceIndex) {
    if (typeof sliceIndex === 'number') {
        return sliceIndex;
    }
    else {
        return sliceIndex[0];
    }
}
function saveAnnotation(annotation, handler) {
    if ('onInterpolationComplete' in annotation) {
        delete annotation.onInterpolationComplete;
    }
    let annotationData = structuredClone(annotation);
    const imageIndex = annotationData.metadata.referencedImageId;
    const fileName = indexToFileMap.get(imageIndex);
    const sliceIndex = forceNarrowSliceIndex(annotationData.metadata.sliceIndex);
    const toolName = getMappedToolName(annotation.metadata.toolName);
    // This has to be type any
    const config = toolGroup.getToolConfiguration(annotation.metadata.toolName);
    const measurements = config.getTextLines ? config.getTextLines(annotation.data, Object.keys(annotation.data.cachedStats)[0]) : [];
    //This is recalculated on load so we don't need to save it
    delete annotationData.data.cachedStats;
    annotationData.metadata.referencedImageId = fileName;
    handler({
        annotationUID: annotation.annotationUID,
        annotationData: JSON.stringify(annotationData),
        toolName: toolName,
        measurements: measurements,
        sliceIndex: sliceIndex
    });
}
async function updateAnnotation(cornerstoneObjectsPromise, constants, annotation, handler) {
    const cornerstoneObjects = await cornerstoneObjectsPromise;
    const viewport = cornerstoneObjects.renderingEngine.getViewport(constants.viewportId);
    viewport.render();
    if ('onInterpolationComplete' in annotation) {
        delete annotation.onInterpolationComplete;
    }
    let annotationData = structuredClone(annotation);
    const imageIndex = annotationData.metadata.referencedImageId;
    const fileName = indexToFileMap.get(imageIndex);
    const sliceIndex = forceNarrowSliceIndex(annotationData.metadata.sliceIndex);
    const toolName = getMappedToolName(annotation.metadata.toolName);
    // This has to be type any
    const config = toolGroup.getToolConfiguration(annotation.metadata.toolName);
    const measurements = config.getTextLines ? config.getTextLines(annotation.data, Object.keys(annotation.data.cachedStats)[0]) : [];
    //This is recalculated on load so we don't need to save it
    delete annotationData.data.cachedStats;
    annotationData.metadata.referencedImageId = fileName;
    handler({
        annotationUID: annotation.annotationUID,
        annotationData: JSON.stringify(annotationData),
        toolName: toolName,
        measurements: measurements,
        sliceIndex: sliceIndex
    });
}
;
function selectAnnotation(annotationUID) {
    try {
        annotation.selection.deselectAnnotation(); //no arguments de-selects all annotations
        annotation.selection.setAnnotationSelected(annotationUID, true);
        var targetAnnotation = annotation.state.getAnnotation(annotationUID);
        var toolToEnable = targetAnnotation.metadata.toolName;
        disableCameraTools();
        enableAnnotationTools();
        activateTool(toolToEnable);
    }
    catch (e) {
        if (e instanceof Error) {
            console.error(`Unable to select annotation: ${e}`);
        }
    }
}
async function removeAnnotation(constants, cornerstoneObjectsPromise, annotationUID) {
    const cornerstoneObjects = await cornerstoneObjectsPromise;
    const viewport = cornerstoneObjects.renderingEngine.getViewport(constants.viewportId);
    annotation.state.removeAnnotation(annotationUID);
    viewport.render();
}
async function setAnnotationText(constants, cornerstoneObjectsPromise, eventDetail) {
    const cornerstoneObjects = await cornerstoneObjectsPromise;
    const viewport = cornerstoneObjects.renderingEngine.getViewport(constants.viewportId);
    const targetAnnotation = annotation.state.getAnnotation(eventDetail.annotationUID);
    // Limits us to only being able to update the text on an annotation that is being displayed,
    // however that should always be the case with the current implementation
    if (targetAnnotation.metadata.referencedImageId === viewport.getCurrentImageId()) {
        //Other annotations don't have text but setting it shouldn't cause any issues
        targetAnnotation.data.text = eventDetail.annotationText;
        viewport.render();
        return targetAnnotation;
    }
    else {
        return null;
    }
}
function screenshot(constants, screenshotHandler) {
    const divForDownloadViewport = document.querySelector(`div[data-viewport-uid="${constants.viewportId}"]`);
    html2canvas(divForDownloadViewport).then((canvas) => {
        const canvasInfo = [{
                viewportIndex: constants.viewportIndex,
                canvasData: canvas.toDataURL()
            }];
        screenshotHandler(canvasInfo);
    });
}
async function multiScreenshot(totalViewports, screenshotHandler) {
    const canvasInfoList = [];
    for (var i = 0; i < totalViewports; i++) {
        const divForDownloadViewport = document.querySelector(`div[data-viewport-uid="viewport${i}"]`);
        var canvas = await html2canvas(divForDownloadViewport);
        const canvasInfo = {
            viewportIndex: i,
            canvasData: canvas.toDataURL()
        };
        canvasInfoList.push(canvasInfo);
    }
    screenshotHandler(canvasInfoList);
}
const HistoricalView = ({ viewportIndex, totalViewports, initialFrame, dicomFiles, loadedDicomFileNames, onDicomFilesLoaded, doStartPlayback, doPausePlayback, doSetMaximizedViewport, eventNames, toggleFlagged, saveAnnotationHandler, updateAnnotationHandler, setSelectedAnnotation, screenshotHandler, setScreenshotLoading, annotations, isSingleView, isMaximized }) => {
    const containerRef = useRef(null);
    const context = useRef(null);
    const constantsRef = useRef(null);
    const cornerstoneObjectsPromiseRef = useRef(null);
    const resizeObserverRef = useRef(new ResizeObserver((async (_) => {
        const cornerstoneObjects = await cornerstoneObjectsPromiseRef.current;
        cornerstoneObjects.renderingEngine.resize();
    })));
    const [isOpen, setOpen] = useState(false);
    const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
    const parsedAnnotations = annotations.map(annotation => JSON.parse(annotation));
    const loadDicoms = (files) => {
        const imageIds = addFilesToDicomLoader(files);
        let viewportPromise = setupCornerstoneViewport(constantsRef.current, cornerstoneObjectsPromiseRef.current, containerRef.current, imageIds, parsedAnnotations, initialFrame, loadedDicomFileNames);
        viewportPromise.then(onDicomFilesLoaded);
        return viewportPromise;
    };
    var lastAnnotation = null;
    const takeScreenshot = () => { screenshot(constantsRef.current, screenshotHandler); };
    const takeMultiScreenshot = () => { setScreenshotLoading(true); multiScreenshot(totalViewports, screenshotHandler); };
    const setFrameEventHandler = (e) => { setFrame(constantsRef.current, cornerstoneObjectsPromiseRef.current, e.detail); };
    ;
    const selectAnnotationHandler = (e) => { selectAnnotation(e.detail); };
    const removeAnnotationHandler = (e) => { removeAnnotation(constantsRef.current, cornerstoneObjectsPromiseRef.current, e.detail); lastAnnotation = null; };
    const setSelectedAnnotationHandler = (e) => setSelectedAnnotation(e.detail.added[0]);
    const setAnnotationTextHandler = async (e) => {
        const targetAnnotation = await setAnnotationText(constantsRef.current, cornerstoneObjectsPromiseRef.current, e.detail);
        if (targetAnnotation)
            await updateAnnotation(cornerstoneObjectsPromiseRef.current, constantsRef.current, targetAnnotation, updateAnnotationHandler);
    };
    const maximizeViewport = (viewportIndex, isMaximized) => {
        doSetMaximizedViewport(isMaximized ? viewportIndex : null);
    };
    useEffect(() => {
        if (!constantsRef.current)
            constantsRef.current = {
                viewportIndex: viewportIndex,
                viewportId: 'viewport' + viewportIndex,
                renderingEngineId: 'renderingEngine' + viewportIndex,
            };
        if (!cornerstoneObjectsPromiseRef.current)
            cornerstoneObjectsPromiseRef.current = initializeCornerstone(constantsRef.current);
        if (!context.current && dicomFiles && dicomFiles.length > 0) {
            let viewportPromise = loadDicoms(dicomFiles);
            window.addEventListener(eventNames.Screenshot, takeScreenshot);
            window.addEventListener(eventNames.SetFrame, setFrameEventHandler);
            window.addEventListener(eventNames.SelectAnnotation, selectAnnotationHandler);
            window.addEventListener(eventNames.RemoveAnnotation, removeAnnotationHandler);
            window.addEventListener(eventNames.SetAnnotationText, setAnnotationTextHandler);
            resizeObserverRef.current.observe(containerRef.current);
            let debounceTimeout;
            function handleSave(annotation) {
                if (annotationTools.includes(toolGroup.currentActivePrimaryToolName)) {
                    saveAnnotation(annotation, saveAnnotationHandler);
                    console.log(`Annotation added: ${annotation.annotationUID}`);
                }
            }
            function handleUpdateDebounce(annotation) {
                if (annotationTools.includes(toolGroup.currentActivePrimaryToolName)) {
                    updateAnnotation(cornerstoneObjectsPromiseRef.current, constantsRef.current, annotation, updateAnnotationHandler);
                    console.log(`Annotation updated: ${annotation.annotationUID}`);
                }
            }
            function handleAnnotationEventCompleted(e) {
                viewportPromise.then((viewport) => {
                    viewport.render();
                    if (lastAnnotation && e.detail.annotation.annotationUID === lastAnnotation.annotationUID)
                        clearTimeout(debounceTimeout);
                    lastAnnotation = e.detail.annotation;
                    // This will trigger multiple times if more than one viewport is showing the same frame
                    // Good enough for now
                    // Deeply annoying because this event has the srcElement field, but it is null
                    if (e.detail.annotation.metadata.referencedImageId === viewport.getCurrentImageId()) {
                        handleSave(e.detail.annotation);
                    }
                });
            }
            eventTarget.addEventListener(csToolsEnums.Events.ANNOTATION_COMPLETED, handleAnnotationEventCompleted);
            function handleAnnotationEventModified(e) {
                viewportPromise.then((viewport) => { viewport.render(); });
                if (lastAnnotation && e.detail.annotation.annotationUID === lastAnnotation.annotationUID)
                    clearTimeout(debounceTimeout);
                lastAnnotation = e.detail.annotation;
                if (e.detail.viewportId === constantsRef.current.viewportId) {
                    handleUpdateDebounce(e.detail.annotation);
                }
            }
            eventTarget.addEventListener(csToolsEnums.Events.ANNOTATION_MODIFIED, handleAnnotationEventModified);
            function handleAnnotationEventMouseUp(e) {
                viewportPromise.then((viewport) => {
                    viewport.render();
                    clearTimeout(debounceTimeout);
                    if (lastAnnotation && e.target == viewport.canvas) {
                        handleUpdateDebounce(lastAnnotation);
                    }
                });
            }
            // this is a last resort since some changes (moving labels) don't trigger other events
            containerRef.current.addEventListener("mouseup", handleAnnotationEventMouseUp);
            eventTarget.addEventListener(csToolsEnums.Events.ANNOTATION_SELECTION_CHANGE, setSelectedAnnotationHandler);
            //eventTarget.addEventListener(csToolsEnums.Events.ANNOTATION_REMOVED, (e) => { deleteAnnotationHandler(e.detail.annotation.annoitationUID) })
            context.current = {
                handleAnnotationEventCompleted: handleAnnotationEventCompleted,
                handleAnnotationEventModified: handleAnnotationEventModified,
                handleAnnotationEventMouseUp: handleAnnotationEventMouseUp,
                handleAnnotationSelectionChange: setSelectedAnnotationHandler
            };
        }
        return () => {
            if (context.current) {
                teardownCornerstoneViewport(constantsRef.current, cornerstoneObjectsPromiseRef.current);
                window.removeEventListener(eventNames.Screenshot, takeScreenshot);
                window.removeEventListener(eventNames.SetFrame, setFrameEventHandler);
                window.removeEventListener(eventNames.SelectAnnotation, selectAnnotationHandler);
                window.removeEventListener(eventNames.RemoveAnnotation, removeAnnotationHandler);
                window.removeEventListener(eventNames.SetAnnotationText, setAnnotationTextHandler);
                eventTarget.removeEventListener(csToolsEnums.Events.ANNOTATION_COMPLETED, context.current.handleAnnotationEventCompleted);
                eventTarget.removeEventListener(csToolsEnums.Events.ANNOTATION_MODIFIED, context.current.handleAnnotationEventModified);
                eventTarget.removeEventListener(csToolsEnums.Events.ANNOTATION_SELECTION_CHANGE, context.current.handleAnnotationSelectionChange);
                containerRef.current.removeEventListener("mouseup", context.current.handleAnnotationEventMouseUp);
                resizeObserverRef.current.unobserve(containerRef.current);
                context.current = null;
            }
        };
    }, [containerRef, dicomFiles]);
    const contextMenu = () => {
        if (isSingleView) {
            return (React.createElement(ControlledMenu, { menuClassName: "tool-menu", anchorPoint: anchorPoint, state: isOpen ? 'open' : 'closed', direction: "right", onClose: () => {
                    setOpen(false);
                } },
                React.createElement(SubMenu, { label: "Playback Controls" },
                    React.createElement(MenuItem, { onClick: doStartPlayback }, "Play"),
                    React.createElement(MenuItem, { onClick: doPausePlayback }, "Pause")),
                React.createElement(SubMenu, { label: "Camera Controls" },
                    React.createElement(MenuItem, { onClick: activatePanTool }, getMappedToolName(PanTool.toolName)),
                    React.createElement(MenuItem, { onClick: activateZoomTool }, getMappedToolName(ZoomTool.toolName)),
                    React.createElement(MenuItem, { onClick: activateWindowLevelTool }, getMappedToolName(WindowLevelTool.toolName))),
                React.createElement(SubMenu, { label: "Annotation Tools" },
                    React.createElement(MenuItem, { onClick: activateEllipticalROITool }, getMappedToolName(EllipticalROITool.toolName)),
                    React.createElement(MenuItem, { onClick: activatePlanarFreehandROITool }, getMappedToolName(PlanarFreehandROITool.toolName)),
                    React.createElement(MenuItem, { onClick: activateLengthTool }, getMappedToolName(LengthTool.toolName)),
                    React.createElement(MenuItem, { onClick: activateArrowAnnotateTool }, getMappedToolName(ArrowAnnotateTool.toolName))),
                React.createElement(MenuItem, { onClick: toggleFlagged }, "Flag/Unflag Image"),
                React.createElement(MenuItem, { onClick: takeScreenshot }, "Capture Image"),
                React.createElement(MenuItem, { onClick: (e) => maximizeViewport(viewportIndex, !isMaximized) }, isMaximized ? "Minimize Viewport" : "Maximize Viewport")));
        }
        else {
            return (React.createElement(ControlledMenu, { menuClassName: "tool-menu", anchorPoint: anchorPoint, state: isOpen ? 'open' : 'closed', direction: "right", onClose: () => {
                    setOpen(false);
                } },
                React.createElement(SubMenu, { label: "Playback Controls" },
                    React.createElement(MenuItem, { onClick: doStartPlayback }, "Play"),
                    React.createElement(MenuItem, { onClick: doPausePlayback }, "Pause")),
                React.createElement(SubMenu, { label: "Camera Controls" },
                    React.createElement(MenuItem, { onClick: activatePanTool }, getMappedToolName(PanTool.toolName)),
                    React.createElement(MenuItem, { onClick: activateZoomTool }, getMappedToolName(ZoomTool.toolName)),
                    React.createElement(MenuItem, { onClick: activateWindowLevelTool }, getMappedToolName(WindowLevelTool.toolName))),
                React.createElement(SubMenu, { label: "Annotation Tools" },
                    React.createElement(MenuItem, { onClick: activateEllipticalROITool }, getMappedToolName(EllipticalROITool.toolName)),
                    React.createElement(MenuItem, { onClick: activatePlanarFreehandROITool }, getMappedToolName(PlanarFreehandROITool.toolName)),
                    React.createElement(MenuItem, { onClick: activateLengthTool }, getMappedToolName(LengthTool.toolName)),
                    React.createElement(MenuItem, { onClick: activateArrowAnnotateTool }, getMappedToolName(ArrowAnnotateTool.toolName))),
                React.createElement(SubMenu, { label: "Capture Image" },
                    React.createElement(MenuItem, { onClick: takeScreenshot }, "Capture Single Image"),
                    React.createElement(MenuItem, { onClick: takeMultiScreenshot }, "Capture Multiview Image")),
                React.createElement(MenuItem, { onClick: toggleFlagged }, "Flag/Unflag Image"),
                React.createElement(MenuItem, { onClick: (e) => maximizeViewport(viewportIndex, !isMaximized) }, isMaximized ? "Minimize Viewport" : "Maximize Viewport")));
        }
    };
    return (React.createElement("div", { style: { flex: '1', width: '100%' }, onContextMenu: (e) => {
            e.preventDefault();
            setAnchorPoint({ x: e.clientX, y: e.clientY });
            setOpen(true);
        } },
        React.createElement("div", { style: { height: '100%', width: '100%' }, id: "viewer", ref: containerRef }),
        contextMenu()));
};
export default HistoricalView;
