export const TelestatsHelpers = (() => {
    const createTelestatsObject = () => {
        return {
            startTime: Date.now(),
            currentFrameNum: 0,
            framesReceived: 0,
            foundFirstFrame: false,
            averageLatency: 0,
            droppedFrames: 0,
            duplicateSegments: 0,
            frames: [],
            log: ""
        };
    };
    const doLog = (telestats, logger, message) => {
        logger(message);
        telestats.log = telestats.log + "\n" + message;
        return telestats;
    };
    const formatBytes = (bytes) => {
        if (bytes === 0)
            return "0 Bytes";
        // those are some big frames 🖼️
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + " " + sizes[i];
    };
    const getLatencyMap = (telestats) => {
        return telestats.frames.map((frame) => frame === undefined ? undefined : frame.latency);
    };
    //Formula taken from https://obkio.com/blog/how-to-measure-jitter/
    const calcJitter = (telestats, latencyMap) => {
        let count = latencyMap
            .map((latency) => {
            return (latency - telestats.averageLatency) ** 2;
        })
            .reduce((acc, v) => { return acc + v; }, 0);
        return Math.sqrt((1.0 / telestats.framesReceived) * count);
    };
    const calcAverageLatency = (latencyMap) => {
        let sum = latencyMap.reduce((acc, v) => { return acc + v; }, 0);
        let count = latencyMap.reduce((acc, _) => { return acc + 1; }, 0); // Sparse arrays have to be counted like this
        return sum / count;
    };
    const updateMetrics = (telestats, frameNumber, timestamp) => {
        telestats.frames[frameNumber].latency = Number(timestamp - telestats.frames[frameNumber].timestamp);
        let latencyMap = getLatencyMap(telestats);
        telestats.averageLatency = calcAverageLatency(latencyMap);
        telestats.frames[frameNumber].jitter = calcJitter(telestats, latencyMap);
        return telestats;
    };
    const updateTelestatsElems = (telestats, frameNumber) => {
        let latencyCountElem = document.getElementById("latency");
        let avgLatencyCountElem = document.getElementById("avg-latency");
        let jitterCountElem = document.getElementById("jitter");
        latencyCountElem.innerHTML = `Latency: ${telestats.frames[frameNumber].latency}`;
        avgLatencyCountElem.innerHTML = `Average Latency: ${telestats.averageLatency.toFixed(2)}`;
        jitterCountElem.innerHTML = `Jitter: ${telestats.frames[frameNumber].jitter.toFixed(2)}`;
    };
    const updateDroppedFramesElem = (telestats) => {
        let droppedFrameCountElem = document.getElementById("dropped-frames");
        droppedFrameCountElem.innerHTML = `Dropped Frames ${telestats.droppedFrames}`;
    };
    const downloadString = (text, fileType, fileName) => {
        var blob = new Blob([text], { type: fileType });
        var a = document.createElement('a');
        a.download = fileName;
        a.href = URL.createObjectURL(blob);
        a.dataset.downloadurl = [fileType, a.download, a.href].join(':');
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setTimeout(function () { URL.revokeObjectURL(a.href); }, 1500);
    };
    const checkdroppedFrames = (telestats, frameNumber) => {
        if (frameNumber > telestats.currentFrameNum) {
            var droppedFramesOld = telestats.droppedFrames;
            for (let i = telestats.currentFrameNum; i < frameNumber; i++) {
                if (i == 0) {
                    continue;
                }
                if (telestats.frames[i] === undefined) {
                    telestats.droppedFrames++;
                }
                else if (telestats.frames[i].dropped) {
                    telestats.droppedFrames++;
                }
                else if (telestats.frames[i].segments.size < telestats.frames[i].segmentCount) {
                    telestats.frames[i].dropped = true;
                    telestats.droppedFrames++;
                }
            }
            let framesDropped = telestats.droppedFrames - droppedFramesOld;
            if (framesDropped) {
                updateDroppedFramesElem(telestats);
                telestats = doLog(telestats, console.info, `Frame #: ${frameNumber} | Dropped ${framesDropped} frame(s) | Total Dropped Frames ${telestats.droppedFrames}`);
            }
        }
        return telestats;
    };
    const setStartTime = (telestats, startTime) => {
        telestats.startTime = startTime;
        return telestats;
    };
    const trySetFirstFrame = (telestats, firstFrame) => {
        if (!telestats.foundFirstFrame) {
            telestats.currentFrameNum = firstFrame;
            telestats.foundFirstFrame = true;
        }
        return telestats;
    };
    const gatherMetrics = (telestats, frameNumber, segmentNumber, segmentCount, fileSize, timestamp) => {
        let foundFrame = false;
        let now = BigInt(Date.now());
        telestats.frames = telestats.frames.map((frame, index) => {
            foundFrame = index === frameNumber;
            return frame;
        });
        if (foundFrame) {
            telestats = updateMetrics(telestats, frameNumber, now);
            telestats.frames[frameNumber].segments.set(segmentNumber, (telestats.frames[frameNumber].segments.get(segmentNumber) || 0) + 1);
            let segments_received = Array
                .from(telestats.frames[frameNumber].segments.values())
                .reduce((acc, value) => acc + value, 0);
            telestats.frames[frameNumber].segmentsReceived = segments_received;
            telestats.duplicateSegments += (segments_received > segmentCount) ? 1 : 0;
        }
        else {
            let segments = new Map();
            segments.set(segmentNumber, 1);
            let latency = Number(now - timestamp);
            var newFrame = {
                latency: latency,
                jitter: 0,
                segments: segments,
                segmentCount: segmentCount,
                segmentsReceived: 1,
                dropped: true,
                fileSize: formatBytes(fileSize),
                timestamp: timestamp,
            };
            telestats.frames[frameNumber] = newFrame;
            let latencyMap = getLatencyMap(telestats);
            calcAverageLatency(latencyMap);
            telestats.frames[frameNumber].jitter = calcJitter(telestats, latencyMap);
            telestats.framesReceived++;
        }
        return telestats;
    };
    const adjustForRenderTime = (telestats, frameNumber, timestamp) => {
        telestats.frames[frameNumber].dropped = false;
        telestats = updateMetrics(telestats, frameNumber, timestamp);
        checkdroppedFrames(telestats, frameNumber);
        telestats.currentFrameNum = frameNumber;
        updateTelestatsElems(telestats, frameNumber);
        return telestats;
    };
    const toCsv = (telestats) => {
        let headers = "Frame Count,Frames Rendered,Frame Number,Latency,Jitter,Segment Count,Segments Received,Frame Dropped,File Size,Timestamp,Dropped Frames,Duplicate Frames,Avg. Latency";
        let latencyMap = getLatencyMap(telestats);
        let i = 0;
        let framesRendered = 0;
        let body = latencyMap
            .map((latency, index) => {
            let frame = telestats.frames[index];
            if (!frame.dropped)
                framesRendered++;
            console.log(`Frame Dropped?: ${frame.dropped} | Frames Rendered: ${framesRendered}`);
            let date = new Date(Number(frame.timestamp));
            let iso_timestamp = date.toISOString().replace(/[TZ]/g, " ");
            if (i++ == 0) {
                return `${i},${framesRendered},${index},${latency},${frame.jitter.toFixed(2)},${frame.segmentCount},${frame.segmentsReceived},${frame.dropped},${frame.fileSize},${iso_timestamp},${telestats.droppedFrames},${telestats.duplicateSegments},${telestats.averageLatency}`;
            }
            else {
                return `${i},${framesRendered},${index},${latency},${frame.jitter.toFixed(2)},${frame.segmentCount},${frame.segmentsReceived},${frame.dropped},${frame.fileSize},${iso_timestamp},,,`;
            }
        })
            .reduce((acc, v) => { return `${acc}\n${v}`; }, "");
        let csv = `${headers}\n${body}\n---\n${telestats.log}`;
        downloadString(csv, "text/plain", "test.csv");
    };
    return { createTelestatsObject, dolog: doLog, setStartTime, trySetFirstFrame, gatherMetrics, adjustForRenderTime, toCsv };
})();
