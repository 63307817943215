import { ScreenshotModalModel } from "./ScreenshotModalTypes.js";
import { Cmd_ofSub, Cmd_none } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { equals } from "../../fable_modules/fable-library.3.7.12/Util.js";
import { some } from "../../fable_modules/fable-library.3.7.12/Option.js";

export function init(args) {
    return [new ScreenshotModalModel(args.isLoading, false, args.maybeScreenshotInfo), Cmd_none()];
}

export function update(_args, msg, model) {
    switch (msg.tag) {
        case 1: {
            const maybeSelectedS3Key = msg.fields[0];
            return [model, Cmd_ofSub((_arg1) => {
                window.setTimeout(() => {
                    if (maybeSelectedS3Key == null) {
                    }
                    else {
                        const selectedS3Key = maybeSelectedS3Key;
                        const element = document.getElementById(selectedS3Key);
                        if (!equals(element, null)) {
                            element.scrollIntoView();
                        }
                    }
                }, 100);
            })];
        }
        case 2: {
            return [msg.fields[0](model), Cmd_none()];
        }
        default: {
            console.error(some(msg.fields[0]));
            return [model, Cmd_none()];
        }
    }
}

