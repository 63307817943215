import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { S3_bodyToString, S3_bodyToBlob, S3_GetObjectRequestParams_get_create, S3_GetObjectRequestParams } from "../../bindings/Aws/Fable.Helpers.Aws.js";
import { promise } from "../../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { map2, flatten, bind, map as map_2, defaultArgWith, some, value as value_1 } from "../../fable_modules/fable-library.3.7.12/Option.js";
import { uncurry, stringHash, comparePrimitives, createAtom } from "../../fable_modules/fable-library.3.7.12/Util.js";
import { FSharpMap__Add, tryFind, ofSeq } from "../../fable_modules/fable-library.3.7.12/Map.js";
import { tryFind as tryFind_1, sortBy, reverse, indexed, length, concat, cons, fold, isEmpty, item, updateAt, empty, ofArray, map as map_3 } from "../../fable_modules/fable-library.3.7.12/List.js";
import { join, replace, split } from "../../fable_modules/fable-library.3.7.12/String.js";
import { List_groupBy, List_distinct } from "../../fable_modules/fable-library.3.7.12/Seq2.js";
import { FlaggedImageListItem, AnatomicalRegionIntrimType, FileMetadata, FrameFile } from "./HistoricalCommon.js";
import { singleton } from "../../fable_modules/fable-library.3.7.12/AsyncBuilder.js";
import { securedApi } from "../../Api.js";
import { AnatomicalRegionViewModel } from "../../Optiscan.Models/View/AnatomicalRegion.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library.3.7.12/Choice.js";

export function customEvent(etype, detail) {
    const eventInit = new (class {
        set bubbles(_arg1) {
        }
        get bubbles() {
            return false;
        }
        set cancelable(_arg2) {
        }
        get cancelable() {
            return false;
        }
        set composed(_arg3) {
        }
        get composed() {
            return false;
        }
        set detail(_arg4) {
        }
        get detail() {
            return detail;
        }
    }
    )();
    const evt = new CustomEvent(etype, eventInit);
    window.dispatchEvent(evt);
}

export function S3_downloadBlobFromS3(s3Ctx, s3Key, onBlobRetrieved, onError) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestParams = new S3_GetObjectRequestParams(s3Ctx.S3Config.Bucket, s3Key);
        const request = S3_GetObjectRequestParams_get_create()(requestParams);
        return s3Ctx.S3Client.getObject(request).promise().then((_arg1) => {
            const matchValue = _arg1.Body;
            if (matchValue == null) {
                onError(s3Key);
                return (Promise.reject(new Error(`Failed to load file ${s3Key}`)));
            }
            else {
                const body = value_1(matchValue);
                return S3_bodyToBlob(body).then((_arg2) => {
                    const blob = _arg2;
                    onBlobRetrieved(blob);
                    return Promise.resolve(blob);
                });
            }
        });
    }));
}

export let Dicoms_dicomCache = createAtom(ofSeq([], {
    Compare: comparePrimitives,
}));

export function Dicoms_clearDicomCache() {
    Dicoms_dicomCache(ofSeq([], {
        Compare: comparePrimitives,
    }), true);
}

export function Dicoms_loadDicomFromS3(s3Ctx, s3Key) {
    const matchValue = tryFind(s3Key, Dicoms_dicomCache());
    if (matchValue == null) {
        return S3_downloadBlobFromS3(s3Ctx, s3Key, (blob_1) => {
            Dicoms_dicomCache(FSharpMap__Add(Dicoms_dicomCache(), s3Key, blob_1), true);
        }, (s3Key_1) => {
            console.error(some(`Failed to load DICOM file ${s3Key_1}`));
        });
    }
    else {
        const a = matchValue;
        return Promise.resolve(a);
    }
}

export function Dicoms_loadAndParseFileList(patientId, sessionId, s3Ctx) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestParams = new S3_GetObjectRequestParams(s3Ctx.S3Config.Bucket, `${patientId}/${sessionId}/.listing`);
        const request = S3_GetObjectRequestParams_get_create()(requestParams);
        return s3Ctx.S3Client.getObject(request).promise().then((_arg1) => (defaultArgWith(map_2(S3_bodyToString, _arg1.Body), () => (Promise.reject(new Error("Failed to get historical file listing")))).then((_arg2) => {
            const allFilesSplit = map_3((fpath) => split(fpath, ["/"], null, 0), ofArray(split(replace(_arg2, "\\", "/"), ["\n"], null, 0)));
            const allLocationNames = List_distinct(map_3((fpath_1) => fpath_1[0], allFilesSplit), {
                Equals: (x, y) => (x === y),
                GetHashCode: stringHash,
            });
            const allSiteNames = map_3((arg_1) => List_distinct(map_3((fpath_3) => fpath_3[1], arg_1[1]), {
                Equals: (x_2, y_2) => (x_2 === y_2),
                GetHashCode: stringHash,
            }), List_groupBy((fpath_2) => fpath_2[0], allFilesSplit, {
                Equals: (x_1, y_1) => (x_1 === y_1),
                GetHashCode: stringHash,
            }));
            const allFilesSplitSiteGrouped = map_3((arg_2) => List_groupBy((fpath_5) => fpath_5[1], arg_2[1], {
                Equals: (x_4, y_4) => (x_4 === y_4),
                GetHashCode: stringHash,
            }), List_groupBy((fpath_4) => fpath_4[0], allFilesSplit, {
                Equals: (x_3, y_3) => (x_3 === y_3),
                GetHashCode: stringHash,
            }));
            const allSequenceNames = map_3((list_12) => map_3((arg_4) => List_distinct(map_3((fpath_6) => fpath_6[2], arg_4[1]), {
                Equals: (x_5, y_5) => (x_5 === y_5),
                GetHashCode: stringHash,
            }), list_12), allFilesSplitSiteGrouped);
            const allFrameFilePaths = map_3((list_19) => map_3((list_18) => map_3((arg_6) => map_3((pathComponents) => (new FrameFile(join("/", pathComponents), false)), arg_6[1]), list_18), list_19), map_3((list_15) => map_3((arg_5) => List_groupBy((fpath_7) => fpath_7[2], arg_5[1], {
                Equals: (x_6, y_6) => (x_6 === y_6),
                GetHashCode: stringHash,
            }), list_15), allFilesSplitSiteGrouped));
            return Promise.resolve(new FileMetadata(allLocationNames, allSiteNames, allSequenceNames, allFrameFilePaths, empty(), empty(), sessionId));
        })));
    }));
}

export function Thumbnails_loadThumbnailFromS3(s3Ctx, fpath) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestParams = new S3_GetObjectRequestParams(s3Ctx.S3Config.Bucket, fpath);
        const request = S3_GetObjectRequestParams_get_create()(requestParams);
        return s3Ctx.S3Client.getObject(request).promise().then((_arg1) => {
            const matchValue = _arg1.Body;
            if (matchValue == null) {
                console.error(some(`Failed to load thumbnail ${fpath}`));
                return Promise.resolve(void 0);
            }
            else {
                const body = value_1(matchValue);
                return S3_bodyToBlob(body).then((_arg2) => (Promise.resolve(window.URL.createObjectURL(_arg2))));
            }
        });
    }));
}

export function FlaggedImages_flagFrame(flagged, frames, locationIndex, siteIndex, sequenceIndex, frameIndex) {
    return updateAt(locationIndex, updateAt(siteIndex, updateAt(sequenceIndex, updateAt(frameIndex, new FrameFile(item(frameIndex, item(sequenceIndex, item(siteIndex, item(locationIndex, frames)))).filePath, flagged), item(sequenceIndex, item(siteIndex, item(locationIndex, frames)))), item(siteIndex, item(locationIndex, frames))), item(locationIndex, frames)), frames);
}

export function FlaggedImages_loadFlaggedImagesPromise(sessionId, metadata, token) {
    return singleton.Delay(() => singleton.Bind(securedApi(token).getAllAnatomicalRegions(sessionId), (_arg1) => singleton.Bind(securedApi(token).getAllFlaggedSessionImages(sessionId), (_arg2) => {
        const matchValue = [_arg1, _arg2];
        let pattern_matching_result, flaggedImages, sequences, e;
        const copyOfStruct = matchValue[0];
        if (copyOfStruct.tag === 1) {
            pattern_matching_result = 1;
            e = copyOfStruct.fields[0];
        }
        else {
            const copyOfStruct_1 = matchValue[1];
            if (copyOfStruct_1.tag === 1) {
                pattern_matching_result = 1;
                e = copyOfStruct_1.fields[0];
            }
            else {
                pattern_matching_result = 0;
                flaggedImages = copyOfStruct_1.fields[0];
                sequences = copyOfStruct.fields[0];
            }
        }
        switch (pattern_matching_result) {
            case 0: {
                const frameToSequenceMap = isEmpty(sequences) ? fold(uncurry(2, (tupledArg_3) => {
                    const count = tupledArg_3[1] | 0;
                    return (frameCount) => [cons(new AnatomicalRegionViewModel(sessionId, count, frameCount.locationName, frameCount.siteName, frameCount.sequenceName), tupledArg_3[0]), count + frameCount.frameCount];
                }), [empty(), 0], concat(map_3((tupledArg) => {
                    const i = tupledArg[0] | 0;
                    return concat(map_3((tupledArg_1) => {
                        const j = tupledArg_1[0] | 0;
                        return map_3((tupledArg_2) => (new AnatomicalRegionIntrimType(length(tupledArg_2[1]), item(i, metadata.locationNames), item(j, item(i, metadata.siteNames)), item(tupledArg_2[0], item(j, item(i, metadata.sequenceNames))))), indexed(tupledArg_1[1]));
                    }, indexed(tupledArg[1])));
                }, indexed(metadata.frames))))[0] : reverse(sortBy((s) => s.frameNumber, sequences, {
                    Compare: comparePrimitives,
                }));
                const newMeta = fold((metadata_1, image) => {
                    const maybeImageSequence = tryFind_1((seq) => (seq.frameNumber <= image.frameNumber), frameToSequenceMap);
                    if (maybeImageSequence == null) {
                        return metadata_1;
                    }
                    else {
                        const imageSequence = maybeImageSequence;
                        const frameIndex = (image.frameNumber - imageSequence.frameNumber) | 0;
                        const maybeLocationIndex = map_2((tuple_2) => tuple_2[0], tryFind_1((arg) => (imageSequence.region === arg[1]), indexed(metadata_1.locationNames)));
                        const maybeSiteIndex = bind((i_1) => map_2((tuple_4) => tuple_4[0], tryFind_1((arg_1) => (imageSequence.site === arg_1[1]), indexed(item(i_1, metadata_1.siteNames)))), maybeLocationIndex);
                        const matchValue_1 = [maybeLocationIndex, maybeSiteIndex, flatten(map2((i_2, j_1) => map_2((tuple_6) => tuple_6[0], tryFind_1((arg_2) => (imageSequence.sequence === arg_2[1]), indexed(item(j_1, item(i_2, metadata_1.sequenceNames))))), maybeLocationIndex, maybeSiteIndex))];
                        let pattern_matching_result_1, locationIndex, sequenceIndex, siteIndex;
                        if (matchValue_1[0] != null) {
                            if (matchValue_1[1] != null) {
                                if (matchValue_1[2] != null) {
                                    pattern_matching_result_1 = 0;
                                    locationIndex = matchValue_1[0];
                                    sequenceIndex = matchValue_1[2];
                                    siteIndex = matchValue_1[1];
                                }
                                else {
                                    pattern_matching_result_1 = 1;
                                }
                            }
                            else {
                                pattern_matching_result_1 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                        switch (pattern_matching_result_1) {
                            case 0: {
                                if ((length(item(sequenceIndex, item(siteIndex, item(locationIndex, metadata_1.frames)))) > frameIndex) && (frameIndex >= 0)) {
                                    return new FileMetadata(metadata_1.locationNames, metadata_1.siteNames, metadata_1.sequenceNames, FlaggedImages_flagFrame(true, metadata_1.frames, locationIndex, siteIndex, sequenceIndex, frameIndex), cons(new FlaggedImageListItem(metadata_1.sessionId, locationIndex, siteIndex, sequenceIndex, frameIndex, imageSequence.region, imageSequence.site, imageSequence.sequence), metadata_1.flaggedImages), metadata_1.frameToSequenceMap, metadata_1.sessionId);
                                }
                                else {
                                    return metadata_1;
                                }
                            }
                            case 1: {
                                return metadata_1;
                            }
                        }
                    }
                }, metadata, flaggedImages);
                return singleton.Return(new FSharpResult$2(0, new FileMetadata(newMeta.locationNames, newMeta.siteNames, newMeta.sequenceNames, newMeta.frames, newMeta.flaggedImages, frameToSequenceMap, newMeta.sessionId)));
            }
            case 1: {
                return singleton.Return(new FSharpResult$2(1, e));
            }
        }
    })));
}

export function FlaggedImages_mkFlaggedImageListItemFromSelected(metadata, selectedSequence, frameIndex) {
    return new FlaggedImageListItem(selectedSequence.sessionId, selectedSequence.locationIndex, selectedSequence.siteIndex, selectedSequence.sequenceIndex, frameIndex, item(selectedSequence.locationIndex, metadata.locationNames), item(selectedSequence.siteIndex, item(selectedSequence.locationIndex, metadata.siteNames)), item(selectedSequence.sequenceIndex, item(selectedSequence.siteIndex, item(selectedSequence.locationIndex, metadata.sequenceNames))));
}

export function FlaggedImages_tryGetFrameNumber(metadata, flaggedImage, frameIndex) {
    return map_2((map_1) => (map_1.frameNumber + frameIndex), tryFind_1((map) => {
        if ((map.region === flaggedImage.locationName) && (map.site === flaggedImage.siteName)) {
            return map.sequence === flaggedImage.sequenceName;
        }
        else {
            return false;
        }
    }, metadata.frameToSequenceMap));
}

