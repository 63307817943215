import React from 'react';
import webRTC from "./WebRTC";
function doPulse() {
    let elem = document.getElementById("viewer");
    elem.onanimationend = (_) => {
        if (elem.classList.contains("pulse"))
            elem.classList.remove("pulse");
        if (elem.classList.contains("pulse2"))
            elem.classList.remove("pulse2");
    };
    if (elem.classList.contains("pulse")) {
        elem.classList.remove("pulse");
        elem.classList.add("pulse2");
    }
    else if (elem.classList.contains("pulse2")) {
        elem.classList.remove("pulse2");
        elem.classList.add("pulse");
    }
    else {
        elem.classList.add("pulse");
    }
}
function setupInteraction(containerRef, viewerContext, onImageFlag) {
    var clickCount = 0;
    var clickTimer;
    const eventListenerCallback = (e) => {
        e.preventDefault();
        clickCount++;
        if (clickCount <= 1) {
            clickTimer = setTimeout(() => {
                clickCount = 0;
            }, 400);
        }
        else if (clickCount == 2) {
            doPulse();
            onImageFlag(viewerContext.current);
            clearTimeout(clickTimer);
            clickCount = 0;
        }
    };
    containerRef.current.addEventListener("mousedown", eventListenerCallback);
    return eventListenerCallback;
}
function teardownInteraction(containerRef, callback) {
    containerRef.current.removeEventListener("mousedown", callback);
}
const LiveTelepathology = ({ signalingClient, peerConnection, dataChannels, onDroppedFrame, onImageFlag, onControlMessage, debugTeleStats }) => {
    const containerRef = React.useRef(null);
    const context = React.useRef(null);
    const viewerContext = React.useRef({
        currentFrame: 0,
        foundFirstFrame: false,
        dataStarted: false,
        stopped: false
    });
    React.useEffect(() => {
        if (!context.current) {
            const canvas = document.createElement("canvas");
            containerRef.current.appendChild(canvas);
            webRTC.setupDataChannels(canvas, dataChannels, peerConnection, viewerContext, onDroppedFrame, onControlMessage, debugTeleStats);
            const interactionCallback = setupInteraction(containerRef, viewerContext, onImageFlag);
            context.current = {
                canvas,
                interactionCallback
            };
        }
        return () => {
            if (context.current) {
                const { canvas, interactionCallback } = context.current;
                teardownInteraction(containerRef, interactionCallback);
                canvas.remove();
                context.current = null;
            }
            signalingClient.close();
            peerConnection.close();
        };
    }, [containerRef]);
    return React.createElement("div", { id: "viewer", ref: containerRef });
};
export default LiveTelepathology;
