import { Union, Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { union_type, lambda_type, string_type, class_type, record_type, option_type, bool_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";
import { ScreenshotInfoViewModel$reflection } from "../../Optiscan.Models/View/ScreenshotInfoViewModel.js";

export class ScreenshotModalModel extends Record {
    constructor(isLoading, isSaving, maybeScreenshotInfo) {
        super();
        this.isLoading = isLoading;
        this.isSaving = isSaving;
        this.maybeScreenshotInfo = maybeScreenshotInfo;
    }
}

export function ScreenshotModalModel$reflection() {
    return record_type("Client.Patients.Historical.ScreenshotModal.Types.ScreenshotModalModel", [], ScreenshotModalModel, () => [["isLoading", bool_type], ["isSaving", bool_type], ["maybeScreenshotInfo", option_type(ScreenshotInfoViewModel$reflection())]]);
}

export function ScreenshotModalModel_get_maybeScreenshotInfo_() {
    return [(m) => m.maybeScreenshotInfo, (v) => ((m_1) => (new ScreenshotModalModel(m_1.isLoading, m_1.isSaving, v)))];
}

export class InitArgs extends Record {
    constructor(isLoading, maybeScreenshotInfo) {
        super();
        this.isLoading = isLoading;
        this.maybeScreenshotInfo = maybeScreenshotInfo;
    }
}

export function InitArgs$reflection() {
    return record_type("Client.Patients.Historical.ScreenshotModal.Types.InitArgs", [], InitArgs, () => [["isLoading", bool_type], ["maybeScreenshotInfo", option_type(ScreenshotInfoViewModel$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExnErrorMsg", "ScrollIntoView", "SetScreenshotInfo"];
    }
}

export function Msg$reflection() {
    return union_type("Client.Patients.Historical.ScreenshotModal.Types.Msg", [], Msg, () => [[["Item", class_type("System.Exception")]], [["maybeSelectedS3Key", option_type(string_type)]], [["Item", lambda_type(ScreenshotModalModel$reflection(), ScreenshotModalModel$reflection())]]]);
}

