import { TelestatsHelpers } from "./TelestatsHelpers";
function getIntMetadataAtOffset(frameData, offset) {
    // Fun bit magic to convert little endian byte array to an unsinged integer
    let shift = -8; // start at -8 because += works like ++i not i++
    return frameData
        .slice(offset * 4, (offset * 4) + 4)
        .reduce((acc, v) => acc + (v << (shift += 8)), 0);
}
function getInt64MetadataAtOffset(frameData, offset) {
    // Fun bit magic to convert little endian byte array to an unsinged integer
    let shift = -8n; // start at -8 because += works like ++i not i++
    return frameData
        .slice(offset * 4, (offset * 4) + 8)
        .reduce((acc, v) => acc + (BigInt(v) << (shift += 8n)), 0n);
}
function checkImageReceivedTimeout(taskId) {
    let webRTCOverlay = document.getElementById("webrtc-overlay");
    webRTCOverlay.classList.add("receiving-images");
    if (taskId !== null) {
        clearTimeout(taskId);
    }
    taskId = setTimeout(() => {
        taskId = null;
        webRTCOverlay.classList.remove("receiving-images");
    }, 2000);
    return taskId;
}
function streamDataChannelToTextureCallback(canvas, viewerContext, onDroppedFrame, debugTeleStats) {
    //const canvas = viewerContext.current.canvas;
    const canvasContext = canvas.getContext("2d");
    const SEGMENT_SIZE = 16000;
    const SEGMENT_METADATA_SIZE = 24;
    viewerContext.current.currentFrame = 0;
    viewerContext.current.foundFirstFrame = false;
    viewerContext.current.dataStarted = false;
    viewerContext.current.stopped = false;
    var bufferedFrames = [];
    // For checking if we're receiving images
    let taskTimeoutId = null;
    // Telemetry Statistics
    let telestats = TelestatsHelpers.createTelestatsObject();
    if (debugTeleStats) {
        let downloadCsvButton = document.getElementById("csv-download");
        downloadCsvButton.onclick = (_) => TelestatsHelpers.toCsv(telestats);
    }
    const startTime = Date.now();
    if (debugTeleStats)
        telestats = TelestatsHelpers.setStartTime(telestats, startTime);
    return (frameSegment) => {
        if (!viewerContext.current.stopped) {
            const frameData = new Uint8Array(frameSegment.data);
            var frameNumber = getIntMetadataAtOffset(frameData, 0);
            var fileSize = getIntMetadataAtOffset(frameData, 1);
            var segmentCount = getIntMetadataAtOffset(frameData, 2);
            var segmentNumber = getIntMetadataAtOffset(frameData, 3);
            var timestamp = getInt64MetadataAtOffset(frameData, 4);
            // This is needed so we don't count the first frame as dropped
            if (!viewerContext.current.foundFirstFrame) {
                viewerContext.current.currentFrame = frameNumber - 1;
                viewerContext.current.foundFirstFrame = true;
            }
            if (debugTeleStats) {
                let segmentLogMessage = `Frame #: ${frameNumber} | Segment #: ${segmentNumber + 1}/${segmentCount} | Timestamp: ${Date.now() - startTime} ms | Latency: ${BigInt(Date.now()) - timestamp} ms`;
                telestats = TelestatsHelpers.trySetFirstFrame(telestats, frameNumber);
                telestats = TelestatsHelpers.gatherMetrics(telestats, frameNumber, segmentNumber, segmentCount, fileSize, timestamp);
                telestats = TelestatsHelpers.dolog(telestats, console.info, segmentLogMessage);
            }
            var foundFrame = false;
            viewerContext.current.dataStarted = true;
            bufferedFrames =
                bufferedFrames
                    .map(bufferedFrame => {
                    if (bufferedFrame.frameNumber == frameNumber) {
                        foundFrame = true;
                        (new Uint8Array(bufferedFrame.buffer)).set(frameData.slice(SEGMENT_METADATA_SIZE), segmentNumber * (SEGMENT_SIZE - SEGMENT_METADATA_SIZE));
                        bufferedFrame.segmentsReceived = bufferedFrame.segmentsReceived + 1;
                    }
                    return bufferedFrame;
                });
            if (foundFrame == false) {
                var newFrame = {
                    frameNumber: frameNumber,
                    segmentCount: segmentCount,
                    segmentsReceived: 1,
                    timestamp: timestamp,
                    buffer: new ArrayBuffer(fileSize)
                };
                (new Uint8Array(newFrame.buffer)).set(frameData.slice(SEGMENT_METADATA_SIZE), segmentNumber * (SEGMENT_SIZE - SEGMENT_METADATA_SIZE));
                bufferedFrames.push(newFrame);
            }
            bufferedFrames =
                bufferedFrames
                    .filter(bufferedFrame => {
                    if (bufferedFrame.frameNumber < viewerContext.current.currentFrame) {
                        // If we are already ahead of this frame then dispose of it
                        return false;
                    }
                    else if (bufferedFrame.segmentsReceived == bufferedFrame.segmentCount) {
                        let droppedFrameCount = bufferedFrame.frameNumber - viewerContext.current.currentFrame;
                        let renderTime = BigInt(0);
                        let frameRendered = false;
                        let frameDropReason = "missing frame data";
                        createImageBitmap(new Blob([bufferedFrame.buffer], { type: "image/jpeg" }))
                            .then((bitmap) => {
                            // TODO: We need to be able to dynamically handle different aspect ratios, but we don't actually know the aspect ratio untill this point
                            // (https://prolucid.atlassian.net/browse/OTP-432)
                            canvas.width = bitmap.width;
                            canvas.height = bitmap.height;
                            canvasContext.drawImage(bitmap, 0, 0);
                            renderTime = BigInt(Date.now());
                            frameRendered = true;
                        }).catch((error) => {
                            frameDropReason = "render error";
                            if (debugTeleStats) {
                                telestats = TelestatsHelpers.dolog(telestats, console.error, `Render Error: ${error}`);
                            }
                            else {
                                console.error(`Render Error: ${error}`);
                            }
                            droppedFrameCount++;
                        }).finally(() => {
                            if (frameRendered) {
                                window.dispatchEvent(new Event("teleKeepAlive"));
                                taskTimeoutId = checkImageReceivedTimeout(taskTimeoutId);
                                let frameLogMessage = `Frame #: ${bufferedFrame.frameNumber} | Timestamp: ${Date.now() - startTime} ms | Latency: ${renderTime - timestamp} ms`;
                                if (debugTeleStats) {
                                    telestats = TelestatsHelpers.adjustForRenderTime(telestats, bufferedFrame.frameNumber, renderTime);
                                    telestats = TelestatsHelpers.dolog(telestats, console.info, frameLogMessage);
                                }
                                else {
                                    console.info(frameLogMessage);
                                }
                            }
                            if (droppedFrameCount > 1) {
                                let frameDropMsg = `Dropped ${droppedFrameCount - 1} frame(s) due to ${frameDropReason}`;
                                // Telestats has a more detailed log message
                                if (!debugTeleStats) {
                                    console.error(frameDropMsg);
                                }
                                onDroppedFrame(frameDropMsg);
                            }
                            viewerContext.current.currentFrame = bufferedFrame.frameNumber;
                        });
                        return false;
                    }
                    else {
                        return true;
                    }
                });
        }
    };
}
// Take JPEG images streamed over a WebRTC data channel and copy them to a Vtk.js texture
function setupDataChannels(canvas, dataChannels, peerConnection, viewerContext, droppedFrameCallback, controlChannelCallback, debugTeleStats) {
    const handleDataChannel = (channel) => {
        if (channel.label === "controlChannel") {
            channel.onmessage = (message) => {
                controlChannelCallback(JSON.parse(message.data));
            };
        }
        else if (channel.label === "videoChannel") {
            channel.binaryType = "arraybuffer";
            channel.onmessage = streamDataChannelToTextureCallback(canvas, viewerContext, droppedFrameCallback, debugTeleStats);
        }
    };
    // There is still a very small chance that a data channel comes in inbetween setting up the react element and
    // setting up the ondatachannel callback. This will only ever happen if the user had the page unfocused and
    // tabs back into the page at the exact right time which is extremely unlikely and not worth worrying about
    // Handle any future data channels
    peerConnection.ondatachannel = event => {
        console.log(event);
        handleDataChannel(event.channel);
    };
    // Handle any existing data channels
    for (const channel of dataChannels) {
        handleDataChannel(channel);
    }
}
export default { setupDataChannels };
