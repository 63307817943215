import * as jspdf from "jspdf";
import * as jspdf_autotable from "jspdf-autotable";
import { ofArray, tryFind, cons, map, toArray } from "../../fable_modules/fable-library.3.7.12/List.js";
import { isArrayLike, equals } from "../../fable_modules/fable-library.3.7.12/Util.js";
import { Record, Union } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { record_type, string_type, class_type, union_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";
import { substring } from "../../fable_modules/fable-library.3.7.12/String.js";
import { value as value_2, map as map_1 } from "../../fable_modules/fable-library.3.7.12/Option.js";
import { S3_PutObjectRequestParams, S3_PutObjectRequestParams_get_create, S3_GetObjectRequestParams_get_create, S3_GetObjectRequestParams } from "../../bindings/Aws/Fable.Helpers.Aws.js";
import { AsyncResultComputationExpression_AsyncResultBuilder__Return_1505, AsyncResultComputationExpression_asyncResult, AsyncResult_ofError, AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505, AsyncResult_mapError, AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93, AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B } from "../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { ErrorMessage } from "../../Optiscan.Models/ErrorMessage.js";
import { ofPromise } from "../../Common/Extensions.js";
import { PDFDocument } from "pdf-lib";

export const Pdf_JsPdf = jspdf;

export const Pdf_Autotable = jspdf_autotable;

export const Pdf_Spacing_leftMargin = 14;

export const Pdf_Spacing_topMargin = 20;

export const Pdf_Spacing_baseSpacingY = 10;

export const Pdf_Spacing_narrowSpacingY = 5;

export function Pdf_Doc_create() {
    return new Pdf_JsPdf.jsPDF({
        format: "letter",
    });
}

export function Pdf_Image_addImage(width, height, yOffset, img, doc) {
    return doc.addImage(img, Pdf_Spacing_leftMargin, Pdf_Spacing_topMargin + yOffset, width, height);
}

export function Pdf_Image_addImageCustom(width, height, xOffset, yOffset, img, doc) {
    return doc.addImage(img, xOffset, yOffset, width, height);
}

export const Pdf_Table_Style = {
    font: "times",
    minCellWidth: 35,
};

export function Pdf_Table_ofCsvRows(headers, rows) {
    return [[toArray(headers)], toArray(map((row) => toArray(cons(row.displayName, row.values)), rows))];
}

export function Pdf_Table_Adv_make(tableParameters, doc) {
    const arg00 = Pdf_JsPdf.jsPDF;
    Pdf_Autotable.applyPlugin(arg00);
    return doc.autoTable(tableParameters);
}

export function Pdf_Table_Adv_makeTitle(content, startY, doc) {
    const previousFont = doc.getFontSize();
    doc.setFontSize(20);
    doc.text(content, Pdf_Spacing_leftMargin, startY);
    return doc.setFontSize(previousFont);
}

export function Pdf_Table_Adv_makeTitleCustom(content, startX, startY, doc) {
    const previousFont = doc.getFontSize();
    doc.setFontSize(20);
    doc.text(content, startX, startY);
    return doc.setFontSize(previousFont);
}

export function Pdf_Table_Adv_makeSubTitle(content, startY, doc) {
    const previousFont = doc.getFontSize();
    doc.setFontSize(14);
    doc.text(content, Pdf_Spacing_leftMargin, startY);
    return doc.setFontSize(previousFont);
}

export function Pdf_Table_Adv_makeSubTitleCustom(content, startX, startY, doc) {
    const previousFont = doc.getFontSize();
    doc.setFontSize(14);
    doc.text(content, startX, startY);
    return doc.setFontSize(previousFont);
}

export function Pdf_Table_Basic_make(title, head, body, startY, doc) {
    const arg00 = Pdf_JsPdf.jsPDF;
    Pdf_Autotable.applyPlugin(arg00);
    return Pdf_Table_Adv_make({
        body: body,
        head: head,
        startY: startY + Pdf_Spacing_narrowSpacingY,
        styles: Pdf_Table_Style,
    }, Pdf_Table_Adv_makeTitle(title, startY, doc));
}

export function Pdf_Images_Adv_addImage(image, startX, startY, width, height, doc) {
    return doc.addImage({
        compression: "NONE",
        height: height,
        imageData: new Uint8Array(image),
        width: width,
        x: startX,
        y: startY,
    });
}

export const Pdf_Images_Basic_pixelsToMm = (() => {
    const pxPerMm = 96 / 25.4;
    return (px) => (px / pxPerMm);
})();

export function Pdf_Images_Basic_addImageAutoScale(image, name, startX, startY, centerImageInY, centerImageInX, width, height, doc) {
    const processed = doc.processPNG(image, 0, name, "NONE");
    const maxWidth = width;
    const maxHeight = height;
    const givenRatio = maxWidth / maxHeight;
    const imageRatio = processed.width / processed.height;
    const patternInput = (givenRatio < imageRatio) ? [maxWidth, maxWidth * (1 / imageRatio)] : [maxHeight * imageRatio, maxHeight];
    const width_1 = patternInput[0];
    const height_1 = patternInput[1];
    const startY_1 = (centerImageInY && (height_1 < maxHeight)) ? (startY + ((maxHeight - height_1) / 2)) : startY;
    return [Pdf_Images_Adv_addImage(image, (centerImageInX && (width_1 < maxWidth)) ? (startX + ((maxWidth - width_1) / 2)) : startX, startY_1, width_1, height_1, doc), height_1, startY_1];
}

export function Pdf_Images_Basic_tryParse(toString, all, v) {
    return tryFind((x) => equals(toString(x), v), all);
}

export class Pdf_Images_Basic_ImageType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["JPG", "PNG"];
    }
}

export function Pdf_Images_Basic_ImageType$reflection() {
    return union_type("Client.Patients.PatientReport.Helpers.Pdf.Images.Basic.ImageType", [], Pdf_Images_Basic_ImageType, () => [[], []]);
}

export function Pdf_Images_Basic_ImageType_get_all() {
    return ofArray([new Pdf_Images_Basic_ImageType(0), new Pdf_Images_Basic_ImageType(1)]);
}

export function Pdf_Images_Basic_ImageType_ofString_Z721C83C5(value) {
    const value_1 = value.toLocaleLowerCase();
    switch (value_1) {
        case "png":
        case "pjp": {
            return new Pdf_Images_Basic_ImageType(1);
        }
        case "jpg":
        case "pjpeg":
        case "jpeg":
        case "jpe":
        case "jif":
        case "jfif": {
            return new Pdf_Images_Basic_ImageType(0);
        }
        default: {
            return void 0;
        }
    }
}

export class Pdf_Images_Basic_JsPdfImage extends Record {
    constructor(imageData, imageName, imageKey, imageType) {
        super();
        this.imageData = imageData;
        this.imageName = imageName;
        this.imageKey = imageKey;
        this.imageType = imageType;
    }
}

export function Pdf_Images_Basic_JsPdfImage$reflection() {
    return record_type("Client.Patients.PatientReport.Helpers.Pdf.Images.Basic.JsPdfImage", [], Pdf_Images_Basic_JsPdfImage, () => [["imageData", class_type("Fable.Core.JS.ArrayBuffer")], ["imageName", string_type], ["imageKey", string_type], ["imageType", Pdf_Images_Basic_ImageType$reflection()]]);
}

export function Pdf_Images_Basic_JsPdfImage_processImage(image, doc) {
    if (image.imageType.tag === 0) {
        return doc.processJPEG(image.imageData, 0, image.imageName, "NONE");
    }
    else {
        return doc.processPNG(image.imageData, 0, image.imageName, "NONE");
    }
}

export function Pdf_Images_Basic_JsPdfImage_create(imageData, imageKey) {
    const fileName = substring(imageKey, imageKey.lastIndexOf("/") + 1);
    return map_1((imageType) => (new Pdf_Images_Basic_JsPdfImage(imageData, fileName, imageKey, imageType)), Pdf_Images_Basic_ImageType_ofString_Z721C83C5(substring(imageKey, imageKey.lastIndexOf(".") + 1)));
}

export function Pdf_Images_Basic_addImageAutoScaleMaxOfImageSize(image, startX, startY, width, height, doc) {
    const processed = Pdf_Images_Basic_JsPdfImage_processImage(image, doc);
    const maxWidth = Math.min(width, Pdf_Images_Basic_pixelsToMm(processed.width));
    const maxHeight = Math.min(height, Pdf_Images_Basic_pixelsToMm(processed.height));
    const givenRatio = maxWidth / maxHeight;
    const imageRatio = processed.width / processed.height;
    const patternInput = (givenRatio < imageRatio) ? [maxWidth, maxWidth * (1 / imageRatio)] : [maxHeight * imageRatio, maxHeight];
    const height_1 = patternInput[1];
    return Pdf_Images_Adv_addImage(image.imageData, startX, (height_1 < maxHeight) ? (startY + ((maxHeight - height_1) / 2)) : startY, patternInput[0], height_1, doc);
}

export function Reports_mkManagedDownload(s3Ctx, fpath) {
    let downloadPromise;
    const requestParams = new S3_GetObjectRequestParams(s3Ctx.S3Config.Bucket, fpath);
    const request = S3_GetObjectRequestParams_get_create()(requestParams);
    downloadPromise = s3Ctx.S3Client.getObject(request).promise();
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AsyncResult_mapError((arg0) => (new ErrorMessage(2, arg0)), ofPromise()(downloadPromise)), (_arg1) => {
        let buffer, arg00;
        const matchValue = _arg1.Body;
        return (matchValue == null) ? AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505(AsyncResultComputationExpression_asyncResult, AsyncResult_ofError(new ErrorMessage(2, new Error("Error when downloading report: get request body empty")))) : (isArrayLike(value_2(matchValue)) ? ((buffer = value_2(matchValue), AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AsyncResult_mapError((arg0_1) => (new ErrorMessage(2, arg0_1)), ofPromise()((arg00 = (buffer.buffer), PDFDocument.load(arg00)))), (_arg2) => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, [_arg2, fpath])))) : AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505(AsyncResultComputationExpression_asyncResult, AsyncResult_ofError(new ErrorMessage(2, new Error("Error when downloading report: unexpected return type")))));
    }));
}

export function Reports_mkManagedUpload(s3Ctx, fpath, doc) {
    let uploadPromise;
    const blob = doc.output('blob');
    const request = S3_PutObjectRequestParams_get_create()(ofArray([new S3_PutObjectRequestParams(2, s3Ctx.S3Config.Bucket), new S3_PutObjectRequestParams(3, fpath), new S3_PutObjectRequestParams(1, blob)]));
    uploadPromise = s3Ctx.S3Client.upload(request).promise();
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AsyncResult_mapError((arg0) => (new ErrorMessage(2, arg0)), ofPromise()(uploadPromise)), (_arg1) => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, _arg1.Key)));
}

