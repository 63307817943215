import { update as update_12, init as init_1 } from "./Notifications/State.js";
import { update as update_1, init as init_2 } from "./Auth/AuthState.js";
import { update as update_2, init as init_3 } from "./TelepathologyGuest/TelepathologyGuestState.js";
import { update as update_3, init as init_4 } from "./Users/UsersState.js";
import { update as update_4, init as init_5 } from "./Accounts/AccountsState.js";
import { update as update_5, init as init_6 } from "./Sites/SitesState.js";
import { update as update_6, init as init_7 } from "./AuthorizedSites/AuthorizedSitesState.js";
import { update as update_7, init as init_8 } from "./Devices/DevicesState.js";
import { update as update_8, init as init_9 } from "./Patients/PatientSearchState.js";
import { update as update_9, init as init_10 } from "./ExVivo/ExVivoState.js";
import { update as update_10, init as init_11 } from "./UserProfile/UserProfileState.js";
import { update as update_11, init as init_12 } from "./Dashboard/DashboardState.js";
import { handleUpdatedUrl } from "./Routing.js";
import { pageHash, Page, AuthPage } from "./Pages.js";
import { Msg, Model } from "./Types.js";
import { Cmd_OfPromise_either, Cmd_ofSub, Cmd_none, Cmd_map, Cmd_batch } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { cons, empty, singleton, ofArray } from "./fable_modules/fable-library.3.7.12/List.js";
import { ProxyRequestException__get_StatusCode, ProxyRequestException } from "./fable_modules/Fable.Remoting.Client.7.25.0/Types.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Msg as Msg_1 } from "./Auth/AuthTypes.js";
import { error } from "./Optiscan.SharedUI/Toast.js";
import { printf, toText } from "./fable_modules/fable-library.3.7.12/String.js";
import { toArray, map as map_1, defaultArg, value as value_1, some } from "./fable_modules/fable-library.3.7.12/Option.js";
import { Navigation_newUrl } from "./fable_modules/Fable.Elmish.Browser.3.0.4/navigation.fs.js";
import { subscribe } from "./Auth/AuthApi.js";
import { subscribe as subscribe_1 } from "./Users/UsersApi.js";
import { subscribe as subscribe_2 } from "./Dashboard/DashboardApi.js";
import { subscribe as subscribe_3 } from "./Accounts/AccountsApi.js";
import { subscribe as subscribe_4 } from "./Sites/SitesApi.js";
import { subscribe as subscribe_5 } from "./Patients/PatientSearchApi.js";
import { subscribe as subscribe_6 } from "./AuthorizedSites/AuthorizedSitesApi.js";
import { subscribe as subscribe_7 } from "./UserProfile/UserProfileApi.js";
import { subscribe as subscribe_8 } from "./Devices/DevicesApi.js";
import { subscribe as subscribe_9 } from "./ExVivo/ExVivoApi.js";
import { disconnect, subscribe as subscribe_10 } from "./Notifications/Api.js";
import { securedApi } from "./Api.js";
import { S3Context as S3Context_2, mkS3Client, updateGlobalConfig, mkCredentials } from "./Common/AwsCommon.js";
import { ofList } from "./fable_modules/fable-library.3.7.12/Map.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { SessionManagement_SessionManagementModel__get_token } from "./Session.js";
import { equals } from "./fable_modules/fable-library.3.7.12/Util.js";
import { iterate, singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library.3.7.12/Seq.js";
import { Msg as Msg_2 } from "./Notifications/Types.js";
import { Msg as Msg_3 } from "./Users/UsersTypes.js";
import { Msg as Msg_4 } from "./Accounts/AccountsTypes.js";
import { Msg as Msg_5 } from "./Devices/DevicesTypes.js";
import { Msg as Msg_6 } from "./Patients/PatientSearchTypes.js";
import { Msg as Msg_7 } from "./ExVivo/ExVivoTypes.js";
import { Msg as Msg_8 } from "./UserProfile/UserProfileTypes.js";
import { Msg as Msg_9 } from "./AuthorizedSites/AuthorizedSitesTypes.js";
import { Msg as Msg_10 } from "./Dashboard/DashboardTypes.js";
import { Permission, isAllowed } from "./Optiscan.Models/Security.js";
import { Msg as Msg_11 } from "./Sites/SitesTypes.js";
import { toUniversalTime, compare, utcNow, op_Addition } from "./fable_modules/fable-library.3.7.12/Date.js";
import { fromMinutes } from "./fable_modules/fable-library.3.7.12/TimeSpan.js";

export function init(result) {
    const patternInput = init_1();
    const patternInput_1 = init_2();
    const patternInput_2 = init_3();
    const patternInput_3 = init_4();
    const patternInput_4 = init_5();
    const patternInput_5 = init_6(void 0, void 0);
    const patternInput_6 = init_7(void 0);
    const patternInput_7 = init_8();
    const patternInput_8 = init_9(void 0, void 0);
    const patternInput_9 = init_10();
    const patternInput_10 = init_11();
    const patternInput_11 = init_12();
    const patternInput_12 = handleUpdatedUrl(result, new Model(patternInput[0], new Page(0, new AuthPage(0)), void 0, patternInput_1[0], patternInput_2[0], patternInput_3[0], patternInput_4[0], patternInput_5[0], patternInput_6[0], patternInput_7[0], patternInput_8[0], patternInput_9[0], patternInput_10[0], patternInput_11[0], false, true, void 0, void 0, void 0, void 0));
    return [patternInput_12[0], Cmd_batch(ofArray([patternInput_12[1], Cmd_map((arg0) => (new Msg(11, arg0)), patternInput[1]), Cmd_map((arg0_1) => (new Msg(2, arg0_1)), patternInput_1[1]), Cmd_map((arg0_2) => (new Msg(3, arg0_2)), patternInput_2[1]), Cmd_map((arg0_3) => (new Msg(7, arg0_3)), patternInput_3[1]), Cmd_map((arg0_4) => (new Msg(4, arg0_4)), patternInput_4[1]), Cmd_map((arg0_5) => (new Msg(5, arg0_5)), patternInput_5[1]), Cmd_map((arg0_6) => (new Msg(6, arg0_6)), patternInput_6[1]), Cmd_map((arg0_7) => (new Msg(8, arg0_7)), patternInput_7[1]), Cmd_map((arg0_8) => (new Msg(9, arg0_8)), patternInput_8[1]), Cmd_map((arg0_9) => (new Msg(10, arg0_9)), patternInput_9[1]), Cmd_map((arg0_10) => (new Msg(12, arg0_10)), patternInput_10[1]), Cmd_map((arg0_11) => (new Msg(13, arg0_11)), patternInput_11[1])]))];
}

export function authenticatedUpdate(token, msg, model) {
    let rawTopics;
    let pattern_matching_result, ex, msg_4, msg_5, msg_6;
    if (msg.tag === 2) {
        if (msg.fields[0].tag === 0) {
            pattern_matching_result = 0;
            ex = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
            msg_4 = msg.fields[0];
        }
    }
    else if (msg.tag === 3) {
        if (msg.fields[0].tag === 0) {
            pattern_matching_result = 0;
            ex = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 2;
            msg_5 = msg.fields[0];
        }
    }
    else if (msg.tag === 4) {
        if (msg.fields[0].tag === 0) {
            pattern_matching_result = 0;
            ex = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 4;
        }
    }
    else if (msg.tag === 5) {
        if (msg.fields[0].tag === 0) {
            pattern_matching_result = 0;
            ex = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 4;
        }
    }
    else if (msg.tag === 6) {
        if (msg.fields[0].tag === 0) {
            pattern_matching_result = 0;
            ex = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 4;
        }
    }
    else if (msg.tag === 7) {
        if (msg.fields[0].tag === 0) {
            pattern_matching_result = 0;
            ex = msg.fields[0].fields[0];
        }
        else if (model.AuthModel.UserInfo != null) {
            pattern_matching_result = 3;
            msg_6 = msg.fields[0];
        }
        else {
            pattern_matching_result = 4;
        }
    }
    else if (msg.tag === 12) {
        if (msg.fields[0].tag === 0) {
            pattern_matching_result = 0;
            ex = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 4;
        }
    }
    else if (msg.tag === 9) {
        if (msg.fields[0].tag === 0) {
            pattern_matching_result = 0;
            ex = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 4;
        }
    }
    else if (msg.tag === 13) {
        if (msg.fields[0].tag === 0) {
            pattern_matching_result = 0;
            ex = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 4;
        }
    }
    else if (msg.tag === 0) {
        pattern_matching_result = 0;
        ex = msg.fields[0];
    }
    else {
        pattern_matching_result = 4;
    }
    switch (pattern_matching_result) {
        case 0: {
            return [model, (ex instanceof ProxyRequestException) ? ((ProxyRequestException__get_StatusCode(ex) === 401) ? Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(2, new Msg_1(6))), error("You have been logged out")])) : Cmd_none()) : ((console.error(some(toText(printf("Error: %O"))(ex))), Cmd_none()))];
        }
        case 1: {
            const patternInput = update_1(msg_4, model.AuthModel);
            return [new Model(model.NotificationsModel, model.CurrentPage, model.RedirectPage, patternInput[0], model.TelepathologyGuestModel, model.UsersModel, model.AccountsModel, model.SitesModel, model.AuthorizedSitesModel, model.DevicesModel, model.PatientSearchModel, model.ExVivoModel, model.UserProfileModel, model.DashboardModel, model.MenuIsDefaultCollapsed, model.MenuIsExpanded, model.S3Context, model.AwsToken, model.S3Configuration, model.Credentials), Cmd_map((arg0) => (new Msg(2, arg0)), patternInput[1])];
        }
        case 2: {
            const patternInput_1 = update_2(msg_5, model.TelepathologyGuestModel);
            return [new Model(model.NotificationsModel, model.CurrentPage, model.RedirectPage, model.AuthModel, patternInput_1[0], model.UsersModel, model.AccountsModel, model.SitesModel, model.AuthorizedSitesModel, model.DevicesModel, model.PatientSearchModel, model.ExVivoModel, model.UserProfileModel, model.DashboardModel, model.MenuIsDefaultCollapsed, model.MenuIsExpanded, model.S3Context, model.AwsToken, model.S3Configuration, model.Credentials), Cmd_map((arg0_1) => (new Msg(3, arg0_1)), patternInput_1[1])];
        }
        case 3: {
            const patternInput_2 = update_3(token, value_1(model.AuthModel.UserInfo), msg_6, model.UsersModel);
            return [new Model(model.NotificationsModel, model.CurrentPage, model.RedirectPage, model.AuthModel, model.TelepathologyGuestModel, patternInput_2[0], model.AccountsModel, model.SitesModel, model.AuthorizedSitesModel, model.DevicesModel, model.PatientSearchModel, model.ExVivoModel, model.UserProfileModel, model.DashboardModel, model.MenuIsDefaultCollapsed, model.MenuIsExpanded, model.S3Context, model.AwsToken, model.S3Configuration, model.Credentials), Cmd_map((arg0_2) => (new Msg(7, arg0_2)), patternInput_2[1])];
        }
        case 4: {
            let pattern_matching_result_1, msg_8;
            if (msg.tag === 4) {
                if (model.AuthModel.UserInfo != null) {
                    pattern_matching_result_1 = 0;
                    msg_8 = msg.fields[0];
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 1;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    const patternInput_3 = update_4(token, value_1(model.AuthModel.UserInfo), msg_8, model.AccountsModel);
                    return [new Model(model.NotificationsModel, model.CurrentPage, model.RedirectPage, model.AuthModel, model.TelepathologyGuestModel, model.UsersModel, patternInput_3[0], model.SitesModel, model.AuthorizedSitesModel, model.DevicesModel, model.PatientSearchModel, model.ExVivoModel, model.UserProfileModel, model.DashboardModel, model.MenuIsDefaultCollapsed, model.MenuIsExpanded, model.S3Context, model.AwsToken, model.S3Configuration, model.Credentials), Cmd_map((arg0_3) => (new Msg(4, arg0_3)), patternInput_3[1])];
                }
                case 1: {
                    let pattern_matching_result_2, msg_10;
                    if (msg.tag === 5) {
                        if (model.AuthModel.UserInfo != null) {
                            pattern_matching_result_2 = 0;
                            msg_10 = msg.fields[0];
                        }
                        else {
                            pattern_matching_result_2 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_2 = 1;
                    }
                    switch (pattern_matching_result_2) {
                        case 0: {
                            const matchValue_1 = model.CurrentPage;
                            if (matchValue_1.tag === 3) {
                                const patternInput_4 = update_5(token, value_1(model.AuthModel.UserInfo), matchValue_1.fields[0], msg_10, model.SitesModel);
                                return [new Model(model.NotificationsModel, model.CurrentPage, model.RedirectPage, model.AuthModel, model.TelepathologyGuestModel, model.UsersModel, model.AccountsModel, patternInput_4[0], model.AuthorizedSitesModel, model.DevicesModel, model.PatientSearchModel, model.ExVivoModel, model.UserProfileModel, model.DashboardModel, model.MenuIsDefaultCollapsed, model.MenuIsExpanded, model.S3Context, model.AwsToken, model.S3Configuration, model.Credentials), Cmd_map((arg0_4) => (new Msg(5, arg0_4)), patternInput_4[1])];
                            }
                            else {
                                console.error(some(toText(printf("Unexpected SiteMsg on page %O"))(model.CurrentPage)));
                                return [model, Cmd_none()];
                            }
                        }
                        case 1: {
                            let pattern_matching_result_3, msg_12;
                            if (msg.tag === 6) {
                                if (model.AuthModel.UserInfo != null) {
                                    pattern_matching_result_3 = 0;
                                    msg_12 = msg.fields[0];
                                }
                                else {
                                    pattern_matching_result_3 = 1;
                                }
                            }
                            else {
                                pattern_matching_result_3 = 1;
                            }
                            switch (pattern_matching_result_3) {
                                case 0: {
                                    const patternInput_5 = update_6(token, value_1(model.AuthModel.UserInfo), msg_12, model.AuthorizedSitesModel);
                                    return [new Model(model.NotificationsModel, model.CurrentPage, model.RedirectPage, model.AuthModel, model.TelepathologyGuestModel, model.UsersModel, model.AccountsModel, model.SitesModel, patternInput_5[0], model.DevicesModel, model.PatientSearchModel, model.ExVivoModel, model.UserProfileModel, model.DashboardModel, model.MenuIsDefaultCollapsed, model.MenuIsExpanded, model.S3Context, model.AwsToken, model.S3Configuration, model.Credentials), Cmd_map((arg0_5) => (new Msg(6, arg0_5)), patternInput_5[1])];
                                }
                                case 1: {
                                    let pattern_matching_result_4, msg_14;
                                    if (msg.tag === 8) {
                                        if (model.AuthModel.UserInfo != null) {
                                            pattern_matching_result_4 = 0;
                                            msg_14 = msg.fields[0];
                                        }
                                        else {
                                            pattern_matching_result_4 = 1;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_4 = 1;
                                    }
                                    switch (pattern_matching_result_4) {
                                        case 0: {
                                            const patternInput_6 = update_7(token, value_1(model.AuthModel.UserInfo), msg_14, model.DevicesModel);
                                            return [new Model(model.NotificationsModel, model.CurrentPage, model.RedirectPage, model.AuthModel, model.TelepathologyGuestModel, model.UsersModel, model.AccountsModel, model.SitesModel, model.AuthorizedSitesModel, patternInput_6[0], model.PatientSearchModel, model.ExVivoModel, model.UserProfileModel, model.DashboardModel, model.MenuIsDefaultCollapsed, model.MenuIsExpanded, model.S3Context, model.AwsToken, model.S3Configuration, model.Credentials), Cmd_map((arg0_6) => (new Msg(8, arg0_6)), patternInput_6[1])];
                                        }
                                        case 1: {
                                            let pattern_matching_result_5, state, msg_17;
                                            if (msg.tag === 9) {
                                                if (msg.fields[0].tag === 3) {
                                                    if (msg.fields[0].fields[0].tag === 2) {
                                                        pattern_matching_result_5 = 0;
                                                        state = msg.fields[0].fields[0].fields[0].fields[0];
                                                    }
                                                    else if (model.AuthModel.UserInfo != null) {
                                                        pattern_matching_result_5 = 1;
                                                        msg_17 = msg.fields[0];
                                                    }
                                                    else {
                                                        pattern_matching_result_5 = 2;
                                                    }
                                                }
                                                else if (model.AuthModel.UserInfo != null) {
                                                    pattern_matching_result_5 = 1;
                                                    msg_17 = msg.fields[0];
                                                }
                                                else {
                                                    pattern_matching_result_5 = 2;
                                                }
                                            }
                                            else {
                                                pattern_matching_result_5 = 2;
                                            }
                                            switch (pattern_matching_result_5) {
                                                case 0: {
                                                    if (model.MenuIsDefaultCollapsed !== state) {
                                                        return [new Model(model.NotificationsModel, model.CurrentPage, model.RedirectPage, model.AuthModel, model.TelepathologyGuestModel, model.UsersModel, model.AccountsModel, model.SitesModel, model.AuthorizedSitesModel, model.DevicesModel, model.PatientSearchModel, model.ExVivoModel, model.UserProfileModel, model.DashboardModel, state, !state, model.S3Context, model.AwsToken, model.S3Configuration, model.Credentials), Cmd_none()];
                                                    }
                                                    else {
                                                        return [model, Cmd_none()];
                                                    }
                                                }
                                                case 1: {
                                                    const matchValue_2 = model.CurrentPage;
                                                    if (matchValue_2.tag === 6) {
                                                        const patternInput_7 = update_8(token, value_1(model.AuthModel.UserInfo), model.Credentials, model.S3Context, model.S3Configuration, matchValue_2.fields[0], matchValue_2.fields[1], msg_17, model.PatientSearchModel);
                                                        return [new Model(model.NotificationsModel, model.CurrentPage, model.RedirectPage, model.AuthModel, model.TelepathologyGuestModel, model.UsersModel, model.AccountsModel, model.SitesModel, model.AuthorizedSitesModel, model.DevicesModel, patternInput_7[0], model.ExVivoModel, model.UserProfileModel, model.DashboardModel, model.MenuIsDefaultCollapsed, model.MenuIsExpanded, model.S3Context, model.AwsToken, model.S3Configuration, model.Credentials), Cmd_map((arg0_7) => (new Msg(9, arg0_7)), patternInput_7[1])];
                                                    }
                                                    else {
                                                        console.error(some(`Unexpected PatientSearchMsg ${msg_17} on page ${model.CurrentPage}`));
                                                        return [model, Cmd_none()];
                                                    }
                                                }
                                                case 2: {
                                                    let pattern_matching_result_6, msg_19;
                                                    if (msg.tag === 10) {
                                                        if (model.AuthModel.UserInfo != null) {
                                                            pattern_matching_result_6 = 0;
                                                            msg_19 = msg.fields[0];
                                                        }
                                                        else {
                                                            pattern_matching_result_6 = 1;
                                                        }
                                                    }
                                                    else {
                                                        pattern_matching_result_6 = 1;
                                                    }
                                                    switch (pattern_matching_result_6) {
                                                        case 0: {
                                                            const matchValue_3 = model.CurrentPage;
                                                            if (matchValue_3.tag === 7) {
                                                                const patternInput_8 = update_9(token, value_1(model.AuthModel.UserInfo), matchValue_3.fields[0], model.Credentials, model.S3Configuration, msg_19, model.ExVivoModel);
                                                                return [new Model(model.NotificationsModel, model.CurrentPage, model.RedirectPage, model.AuthModel, model.TelepathologyGuestModel, model.UsersModel, model.AccountsModel, model.SitesModel, model.AuthorizedSitesModel, model.DevicesModel, model.PatientSearchModel, patternInput_8[0], model.UserProfileModel, model.DashboardModel, model.MenuIsDefaultCollapsed, model.MenuIsExpanded, model.S3Context, model.AwsToken, model.S3Configuration, model.Credentials), Cmd_map((arg0_8) => (new Msg(10, arg0_8)), patternInput_8[1])];
                                                            }
                                                            else {
                                                                return [model, Cmd_none()];
                                                            }
                                                        }
                                                        case 1: {
                                                            let pattern_matching_result_7, msg_21;
                                                            if (msg.tag === 12) {
                                                                if (model.AuthModel.UserInfo != null) {
                                                                    pattern_matching_result_7 = 0;
                                                                    msg_21 = msg.fields[0];
                                                                }
                                                                else {
                                                                    pattern_matching_result_7 = 1;
                                                                }
                                                            }
                                                            else {
                                                                pattern_matching_result_7 = 1;
                                                            }
                                                            switch (pattern_matching_result_7) {
                                                                case 0: {
                                                                    const patternInput_9 = update_10(token, value_1(model.AuthModel.UserInfo), msg_21, model.UserProfileModel);
                                                                    return [new Model(model.NotificationsModel, model.CurrentPage, model.RedirectPage, model.AuthModel, model.TelepathologyGuestModel, model.UsersModel, model.AccountsModel, model.SitesModel, model.AuthorizedSitesModel, model.DevicesModel, model.PatientSearchModel, model.ExVivoModel, patternInput_9[0], model.DashboardModel, model.MenuIsDefaultCollapsed, model.MenuIsExpanded, model.S3Context, model.AwsToken, model.S3Configuration, model.Credentials), Cmd_map((arg0_9) => (new Msg(12, arg0_9)), patternInput_9[1])];
                                                                }
                                                                case 1: {
                                                                    let pattern_matching_result_8, msg_23;
                                                                    if (msg.tag === 13) {
                                                                        if (model.AuthModel.UserInfo != null) {
                                                                            pattern_matching_result_8 = 0;
                                                                            msg_23 = msg.fields[0];
                                                                        }
                                                                        else {
                                                                            pattern_matching_result_8 = 1;
                                                                        }
                                                                    }
                                                                    else {
                                                                        pattern_matching_result_8 = 1;
                                                                    }
                                                                    switch (pattern_matching_result_8) {
                                                                        case 0: {
                                                                            const patternInput_10 = update_11(token, value_1(model.AuthModel.UserInfo), model.S3Context, msg_23, model.DashboardModel);
                                                                            return [new Model(model.NotificationsModel, model.CurrentPage, model.RedirectPage, model.AuthModel, model.TelepathologyGuestModel, model.UsersModel, model.AccountsModel, model.SitesModel, model.AuthorizedSitesModel, model.DevicesModel, model.PatientSearchModel, model.ExVivoModel, model.UserProfileModel, patternInput_10[0], model.MenuIsDefaultCollapsed, model.MenuIsExpanded, model.S3Context, model.AwsToken, model.S3Configuration, model.Credentials), Cmd_map((arg0_10) => (new Msg(13, arg0_10)), patternInput_10[1])];
                                                                        }
                                                                        case 1: {
                                                                            let pattern_matching_result_9, page, client, msg_24, msg_26, active, config, r, credentials_2, s3Context_1;
                                                                            if (msg.tag === 7) {
                                                                                pattern_matching_result_9 = 0;
                                                                            }
                                                                            else if (msg.tag === 4) {
                                                                                pattern_matching_result_9 = 0;
                                                                            }
                                                                            else if (msg.tag === 5) {
                                                                                pattern_matching_result_9 = 0;
                                                                            }
                                                                            else if (msg.tag === 6) {
                                                                                pattern_matching_result_9 = 0;
                                                                            }
                                                                            else if (msg.tag === 8) {
                                                                                pattern_matching_result_9 = 0;
                                                                            }
                                                                            else if (msg.tag === 9) {
                                                                                pattern_matching_result_9 = 0;
                                                                            }
                                                                            else if (msg.tag === 10) {
                                                                                pattern_matching_result_9 = 0;
                                                                            }
                                                                            else if (msg.tag === 13) {
                                                                                pattern_matching_result_9 = 0;
                                                                            }
                                                                            else if (msg.tag === 12) {
                                                                                pattern_matching_result_9 = 0;
                                                                            }
                                                                            else if (msg.tag === 1) {
                                                                                pattern_matching_result_9 = 1;
                                                                                page = msg.fields[0];
                                                                            }
                                                                            else if (msg.tag === 11) {
                                                                                if (msg.fields[0].tag === 3) {
                                                                                    pattern_matching_result_9 = 2;
                                                                                    client = msg.fields[0].fields[0];
                                                                                    msg_24 = msg.fields[0];
                                                                                }
                                                                                else {
                                                                                    pattern_matching_result_9 = 3;
                                                                                    msg_26 = msg.fields[0];
                                                                                }
                                                                            }
                                                                            else if (msg.tag === 14) {
                                                                                pattern_matching_result_9 = 4;
                                                                                active = msg.fields[0];
                                                                            }
                                                                            else if (msg.tag === 15) {
                                                                                pattern_matching_result_9 = 5;
                                                                            }
                                                                            else if (msg.tag === 16) {
                                                                                pattern_matching_result_9 = 6;
                                                                                config = msg.fields[0];
                                                                            }
                                                                            else if (msg.tag === 17) {
                                                                                pattern_matching_result_9 = 7;
                                                                                r = msg.fields[0];
                                                                            }
                                                                            else if (msg.tag === 18) {
                                                                                pattern_matching_result_9 = 8;
                                                                                credentials_2 = msg.fields[0];
                                                                                s3Context_1 = msg.fields[1];
                                                                            }
                                                                            else {
                                                                                pattern_matching_result_9 = 9;
                                                                            }
                                                                            switch (pattern_matching_result_9) {
                                                                                case 0: {
                                                                                    return [model, error("Unable to find active user.")];
                                                                                }
                                                                                case 1: {
                                                                                    return [model, Cmd_batch(singleton(Navigation_newUrl(pageHash(page))))];
                                                                                }
                                                                                case 2: {
                                                                                    const patternInput_11 = update_12(token, msg_24, model.NotificationsModel);
                                                                                    const toSubs = (tupledArg, map) => [tupledArg[0], (arg) => map(tupledArg[1](arg))];
                                                                                    const subs_1 = Cmd_ofSub((rawTopics = defaultArg(map_1((ui) => ofArray([toSubs(subscribe(ui.id), (arg0_11) => (new Msg(2, arg0_11))), toSubs(subscribe_1(ui.id), (arg0_12) => (new Msg(7, arg0_12))), toSubs(subscribe_2(ui.id), (arg0_13) => (new Msg(13, arg0_13))), toSubs(subscribe_3(ui.id), (arg0_14) => (new Msg(4, arg0_14))), toSubs(subscribe_4(ui.id), (arg0_15) => (new Msg(5, arg0_15))), toSubs(subscribe_5(ui.id), (arg0_16) => (new Msg(9, arg0_16))), toSubs(subscribe_6(ui.id), (arg0_17) => (new Msg(6, arg0_17))), toSubs(subscribe_7(ui.id), (arg0_18) => (new Msg(12, arg0_18))), toSubs(subscribe_8(ui.id), (arg0_19) => (new Msg(8, arg0_19))), toSubs(subscribe_9(ui.id), (arg0_20) => (new Msg(10, arg0_20)))]), model.AuthModel.UserInfo), empty()), (dispatch) => {
                                                                                        subscribe_10(client, rawTopics, dispatch);
                                                                                    }));
                                                                                    return [new Model(patternInput_11[0], model.CurrentPage, model.RedirectPage, model.AuthModel, model.TelepathologyGuestModel, model.UsersModel, model.AccountsModel, model.SitesModel, model.AuthorizedSitesModel, model.DevicesModel, model.PatientSearchModel, model.ExVivoModel, model.UserProfileModel, model.DashboardModel, model.MenuIsDefaultCollapsed, model.MenuIsExpanded, model.S3Context, model.AwsToken, model.S3Configuration, model.Credentials), Cmd_batch(ofArray([Cmd_map((arg0_21) => (new Msg(11, arg0_21)), patternInput_11[1]), subs_1]))];
                                                                                }
                                                                                case 3: {
                                                                                    const patternInput_12 = update_12(token, msg_26, model.NotificationsModel);
                                                                                    return [new Model(patternInput_12[0], model.CurrentPage, model.RedirectPage, model.AuthModel, model.TelepathologyGuestModel, model.UsersModel, model.AccountsModel, model.SitesModel, model.AuthorizedSitesModel, model.DevicesModel, model.PatientSearchModel, model.ExVivoModel, model.UserProfileModel, model.DashboardModel, model.MenuIsDefaultCollapsed, model.MenuIsExpanded, model.S3Context, model.AwsToken, model.S3Configuration, model.Credentials), Cmd_map((arg0_22) => (new Msg(11, arg0_22)), patternInput_12[1])];
                                                                                }
                                                                                case 4: {
                                                                                    return [new Model(model.NotificationsModel, model.CurrentPage, model.RedirectPage, model.AuthModel, model.TelepathologyGuestModel, model.UsersModel, model.AccountsModel, model.SitesModel, model.AuthorizedSitesModel, model.DevicesModel, model.PatientSearchModel, model.ExVivoModel, model.UserProfileModel, model.DashboardModel, model.MenuIsDefaultCollapsed, active, model.S3Context, model.AwsToken, model.S3Configuration, model.Credentials), Cmd_none()];
                                                                                }
                                                                                case 5: {
                                                                                    return [model, Cmd_OfAsyncWith_either((x) => {
                                                                                        Cmd_OfAsync_start(x);
                                                                                    }, securedApi(token).getS3Configuration, void 0, (arg0_23) => (new Msg(16, arg0_23)), (arg0_24) => (new Msg(0, arg0_24)))];
                                                                                }
                                                                                case 6: {
                                                                                    return [new Model(model.NotificationsModel, model.CurrentPage, model.RedirectPage, model.AuthModel, model.TelepathologyGuestModel, model.UsersModel, model.AccountsModel, model.SitesModel, model.AuthorizedSitesModel, model.DevicesModel, model.PatientSearchModel, model.ExVivoModel, model.UserProfileModel, model.DashboardModel, model.MenuIsDefaultCollapsed, model.MenuIsExpanded, model.S3Context, model.AwsToken, config, model.Credentials), Cmd_OfAsyncWith_either((x_1) => {
                                                                                        Cmd_OfAsync_start(x_1);
                                                                                    }, securedApi(token).getIdentity, void 0, (arg0_25) => (new Msg(17, arg0_25)), (arg0_26) => (new Msg(0, arg0_26)))];
                                                                                }
                                                                                case 7: {
                                                                                    const matchValue_4 = model.S3Configuration;
                                                                                    if (matchValue_4 != null) {
                                                                                        const config_1 = matchValue_4;
                                                                                        if (r.tag === 1) {
                                                                                            return [model, Cmd_none()];
                                                                                        }
                                                                                        else {
                                                                                            const result = r.fields[0];
                                                                                            const credentials = mkCredentials(config_1.IdentityPoolId, result.IdentityId, config_1.Region, ofList(singleton([config_1.Authority, result.Token])));
                                                                                            updateGlobalConfig(config_1.Region, credentials);
                                                                                            const s3Context = new S3Context_2(config_1, mkS3Client(config_1.Bucket), void 0, config_1.Bucket);
                                                                                            return [new Model(model.NotificationsModel, model.CurrentPage, model.RedirectPage, model.AuthModel, model.TelepathologyGuestModel, model.UsersModel, model.AccountsModel, model.SitesModel, model.AuthorizedSitesModel, model.DevicesModel, model.PatientSearchModel, model.ExVivoModel, model.UserProfileModel, model.DashboardModel, model.MenuIsDefaultCollapsed, model.MenuIsExpanded, s3Context, result, model.S3Configuration, credentials), Cmd_OfPromise_either((tupledArg_1) => {
                                                                                                const credentials_1 = tupledArg_1[0];
                                                                                                return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (credentials_1.getPromise().then(() => (Promise.resolve([credentials_1, tupledArg_1[1]]))))));
                                                                                            }, [credentials, s3Context], (tupledArg_2) => (new Msg(18, tupledArg_2[0], tupledArg_2[1])), (arg0_28) => (new Msg(0, arg0_28)))];
                                                                                        }
                                                                                    }
                                                                                    else {
                                                                                        return [model, Cmd_none()];
                                                                                    }
                                                                                }
                                                                                case 8: {
                                                                                    return [new Model(model.NotificationsModel, model.CurrentPage, model.RedirectPage, model.AuthModel, model.TelepathologyGuestModel, model.UsersModel, model.AccountsModel, model.SitesModel, model.AuthorizedSitesModel, model.DevicesModel, model.PatientSearchModel, model.ExVivoModel, model.UserProfileModel, model.DashboardModel, model.MenuIsDefaultCollapsed, model.MenuIsExpanded, new S3Context_2(s3Context_1.S3Config, s3Context_1.S3Client, credentials_2.expireTime, s3Context_1.ActiveBucket), model.AwsToken, model.S3Configuration, model.Credentials), Cmd_none()];
                                                                                }
                                                                                case 9: {
                                                                                    throw (new Error("Match failure: Client.Types.Msg"));
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

export function update(msg, model) {
    let tupledArg;
    const matchValue = [SessionManagement_SessionManagementModel__get_token(model.AuthModel.SessionManagement), msg];
    let pattern_matching_result, msg_1, token, msg_2, msg_14, msg_15, msg_16, msg_17, token_1, msg_18;
    if (matchValue[0] != null) {
        if (matchValue[1].tag === 2) {
            if (matchValue[1].fields[0].tag === 0) {
                pattern_matching_result = 0;
                msg_1 = matchValue[1];
                token = matchValue[0];
            }
            else if (matchValue[1].fields[0].tag === 18) {
                pattern_matching_result = 1;
                msg_2 = matchValue[1].fields[0];
            }
            else if (matchValue[1].fields[0].tag === 6) {
                pattern_matching_result = 2;
                msg_14 = matchValue[1].fields[0];
            }
            else {
                pattern_matching_result = 3;
                msg_15 = matchValue[1].fields[0];
            }
        }
        else if (matchValue[1].tag === 3) {
            pattern_matching_result = 4;
            msg_16 = matchValue[1].fields[0];
        }
        else {
            pattern_matching_result = 5;
            msg_17 = matchValue[1];
            token_1 = matchValue[0];
        }
    }
    else if (matchValue[1].tag === 2) {
        if (matchValue[1].fields[0].tag === 18) {
            pattern_matching_result = 1;
            msg_2 = matchValue[1].fields[0];
        }
        else if (matchValue[1].fields[0].tag === 6) {
            pattern_matching_result = 2;
            msg_14 = matchValue[1].fields[0];
        }
        else {
            pattern_matching_result = 3;
            msg_15 = matchValue[1].fields[0];
        }
    }
    else if (matchValue[1].tag === 3) {
        pattern_matching_result = 4;
        msg_16 = matchValue[1].fields[0];
    }
    else {
        pattern_matching_result = 6;
        msg_18 = matchValue;
    }
    switch (pattern_matching_result) {
        case 0: {
            return authenticatedUpdate(token.rawToken, msg_1, model);
        }
        case 1: {
            const patternInput = update_1(msg_2, model.AuthModel);
            const m = patternInput[0];
            return [new Model(model.NotificationsModel, model.CurrentPage, equals(model.CurrentPage, new Page(0, new AuthPage(0))) ? (void 0) : model.RedirectPage, m, model.TelepathologyGuestModel, model.UsersModel, model.AccountsModel, model.SitesModel, model.AuthorizedSitesModel, model.DevicesModel, model.PatientSearchModel, model.ExVivoModel, model.UserProfileModel, model.DashboardModel, model.MenuIsDefaultCollapsed, model.MenuIsExpanded, model.S3Context, model.AwsToken, model.S3Configuration, model.Credentials), Cmd_batch(toList(delay(() => append(singleton_1(Cmd_map((arg0) => (new Msg(2, arg0)), patternInput[1])), delay(() => append(singleton_1(Cmd_OfFunc_result(new Msg(11, new Msg_2(1)))), delay(() => append(singleton_1(Cmd_OfFunc_result(new Msg(7, new Msg_3(1)))), delay(() => append(singleton_1(Cmd_OfFunc_result(new Msg(4, new Msg_4(1)))), delay(() => append(singleton_1(Cmd_OfFunc_result(new Msg(8, new Msg_5(1)))), delay(() => append(singleton_1(Cmd_OfFunc_result(new Msg(9, new Msg_6(1)))), delay(() => append(singleton_1(Cmd_OfFunc_result(new Msg(10, new Msg_7(1)))), delay(() => append(singleton_1(Cmd_OfFunc_result(new Msg(12, new Msg_8(1)))), delay(() => append(singleton_1(Cmd_OfFunc_result(new Msg(6, new Msg_9(1)))), delay(() => append(singleton_1(Cmd_OfFunc_result(new Msg(13, new Msg_10(1)))), delay(() => append(singleton_1(Cmd_OfFunc_result(new Msg(15))), delay(() => {
                const matchValue_1 = model.CurrentPage;
                let pattern_matching_result_1;
                if (matchValue_1.tag === 0) {
                    if (matchValue_1.fields[0].tag === 0) {
                        pattern_matching_result_1 = 0;
                    }
                    else {
                        pattern_matching_result_1 = 2;
                    }
                }
                else if (matchValue_1.tag === 3) {
                    pattern_matching_result_1 = 1;
                }
                else {
                    pattern_matching_result_1 = 2;
                }
                switch (pattern_matching_result_1) {
                    case 0: {
                        const matchValue_2 = model.RedirectPage;
                        if (matchValue_2 == null) {
                            const matchValue_3 = m.UserInfo;
                            if (matchValue_3 != null) {
                                const user = matchValue_3;
                                return isAllowed(new Permission(40))(user.roles) ? singleton_1(Navigation_newUrl(pageHash(new Page(10)))) : singleton_1(Navigation_newUrl(pageHash(new Page(2))));
                            }
                            else {
                                console.error(some("Unable to redirect due to missing user info"));
                                return singleton_1(error("Unable to redirect due to missing user info"));
                            }
                        }
                        else {
                            return singleton_1(Navigation_newUrl(pageHash(matchValue_2)));
                        }
                    }
                    case 1: {
                        return singleton_1(Cmd_OfFunc_result(new Msg(5, new Msg_11(1))));
                    }
                    case 2: {
                        return singleton_1(Cmd_none());
                    }
                }
            })))))))))))))))))))))))))];
        }
        case 2: {
            const patternInput_1 = update_1(msg_14, model.AuthModel);
            iterate((arg) => {
                disconnect(arg);
            }, toArray(model.NotificationsModel.Client));
            return [new Model(model.NotificationsModel, model.CurrentPage, model.RedirectPage, patternInput_1[0], model.TelepathologyGuestModel, model.UsersModel, model.AccountsModel, model.SitesModel, model.AuthorizedSitesModel, model.DevicesModel, model.PatientSearchModel, model.ExVivoModel, model.UserProfileModel, model.DashboardModel, model.MenuIsDefaultCollapsed, model.MenuIsExpanded, model.S3Context, model.AwsToken, model.S3Configuration, model.Credentials), Cmd_map((arg0_1) => (new Msg(2, arg0_1)), patternInput_1[1])];
        }
        case 3: {
            const patternInput_2 = update_1(msg_15, model.AuthModel);
            return [new Model(model.NotificationsModel, model.CurrentPage, model.RedirectPage, patternInput_2[0], model.TelepathologyGuestModel, model.UsersModel, model.AccountsModel, model.SitesModel, model.AuthorizedSitesModel, model.DevicesModel, model.PatientSearchModel, model.ExVivoModel, model.UserProfileModel, model.DashboardModel, model.MenuIsDefaultCollapsed, model.MenuIsExpanded, model.S3Context, model.AwsToken, model.S3Configuration, model.Credentials), Cmd_map((arg0_2) => (new Msg(2, arg0_2)), patternInput_2[1])];
        }
        case 4: {
            const patternInput_3 = update_2(msg_16, model.TelepathologyGuestModel);
            return [new Model(model.NotificationsModel, model.CurrentPage, model.RedirectPage, model.AuthModel, patternInput_3[0], model.UsersModel, model.AccountsModel, model.SitesModel, model.AuthorizedSitesModel, model.DevicesModel, model.PatientSearchModel, model.ExVivoModel, model.UserProfileModel, model.DashboardModel, model.MenuIsDefaultCollapsed, model.MenuIsExpanded, model.S3Context, model.AwsToken, model.S3Configuration, model.Credentials), Cmd_map((arg0_3) => (new Msg(3, arg0_3)), patternInput_3[1])];
        }
        case 5: {
            let patternInput_4;
            const expiryThreshold = op_Addition(utcNow(), fromMinutes(10));
            const matchValue_4 = model.S3Context;
            let pattern_matching_result_2;
            if (matchValue_4 != null) {
                if (matchValue_4.S3Expiry != null) {
                    if (compare(toUniversalTime(matchValue_4.S3Expiry), expiryThreshold) <= 0) {
                        pattern_matching_result_2 = 0;
                    }
                    else {
                        pattern_matching_result_2 = 1;
                    }
                }
                else {
                    pattern_matching_result_2 = 1;
                }
            }
            else {
                pattern_matching_result_2 = 1;
            }
            switch (pattern_matching_result_2) {
                case 0: {
                    patternInput_4 = [new Model(model.NotificationsModel, model.CurrentPage, model.RedirectPage, model.AuthModel, model.TelepathologyGuestModel, model.UsersModel, model.AccountsModel, model.SitesModel, model.AuthorizedSitesModel, model.DevicesModel, model.PatientSearchModel, model.ExVivoModel, model.UserProfileModel, model.DashboardModel, model.MenuIsDefaultCollapsed, model.MenuIsExpanded, void 0, void 0, model.S3Configuration, model.Credentials), singleton(Cmd_OfAsyncWith_either((x) => {
                        Cmd_OfAsync_start(x);
                    }, securedApi(token_1.rawToken).getIdentity, void 0, (arg0_4) => (new Msg(17, arg0_4)), (arg0_5) => (new Msg(0, arg0_5))))];
                    break;
                }
                case 1: {
                    patternInput_4 = [model, empty()];
                    break;
                }
            }
            const patternInput_5 = authenticatedUpdate(token_1.rawToken, msg_17, patternInput_4[0]);
            return [patternInput_5[0], Cmd_batch(cons(patternInput_5[1], patternInput_4[1]))];
        }
        case 6: {
            console.log(some((tupledArg = msg_18, toText(printf("Ignoring unauthenticated msg: %O"))([tupledArg[0], tupledArg[1]]))));
            return [model, Cmd_none()];
        }
    }
}

