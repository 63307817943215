import { toUniversalTime, now, toOffset, date as date_3, minute, hour, day, month, year, create } from "../../../fable_modules/fable-library.3.7.12/DateOffset.js";
import { defaultArgWith, toArray, map, defaultArg } from "../../../fable_modules/fable-library.3.7.12/Option.js";
import { AsyncResult_ofSuccess, AsyncResult_map, ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Return_1505, ResultComputationExpression_ResultBuilder__Bind_764BA1D3, ResultComputationExpression_ResultBuilder__Delay_1505, ResultComputationExpression_ResultBuilder__Run_FCFD9EF } from "../../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { validateNotNullOrEmpty } from "../../../fable_modules/Webbler.Models.1.2.6/Validation.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library.3.7.12/Choice.js";
import { CrudButton_edit, crudEditUpdate, CrudButton_cancel, CrudButton_save, CrudEditMsg$2, crudModal } from "../../../Optiscan.SharedUI/Crud.js";
import { empty as empty_1, iterate, singleton, append, delay, toList } from "../../../fable_modules/fable-library.3.7.12/Seq.js";
import { ScheduledSessionViewModel, ScheduledSessionViewModel_get_timeZone_, ScheduledSessionViewModel_get_time_, ScheduledSessionViewModel_get_date_, ScheduledSessionViewModel_get_sessionName_, Msg } from "./PatientSessionsTypes.js";
import { NavigationMsg$1, StateTreeNode$6, StateTreeMsg$4 } from "../../../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { FormField_displayList, FormField_labeledField, FormField_ReactSelect_CallbackSingle$1, FormField_ReactSelect_single, FormField_onChangeDateOpticLocal, FormField_date, FormField_ReactSelect_CallbackMulti$1, FormField_ReactSelect_multi, FormField_onChangeSet, FormField_text } from "../../../Optiscan.SharedUI/Forms.js";
import { contains, filter, map as map_1, tryFind, ofArray, empty } from "../../../fable_modules/fable-library.3.7.12/List.js";
import { equals, stringHash, uncurry } from "../../../fable_modules/fable-library.3.7.12/Util.js";
import { Optic_Get, Optic_Get_op_HatDot_21762A61, Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../../../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { Functions_flip } from "../../../fable_modules/Webbler.Models.1.2.6/Common.fs.js";
import { rangeDouble } from "../../../fable_modules/fable-library.3.7.12/Range.js";
import { printf, interpolate, toText } from "../../../fable_modules/fable-library.3.7.12/String.js";
import { SelectPropsMulti$1 } from "../../../Optiscan.SharedUI/bindings/ReactSelectBind.js";
import { Option } from "../../../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { error } from "../../../Optiscan.SharedUI/Toast.js";
import { ErrorMessage_get_describe } from "../../../Optiscan.Models/ErrorMessage.js";
import { getCurrentTimezoneName } from "../../../../../src/Optiscan.Client/Patients/PatientDetails/PatientSessions/js/PatientSessionsHelper.tsx";
import { Cmd_batch, Cmd_none } from "../../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { securedApi } from "../../../Api.js";
import { SessionViewModel, SessionCreateViewModel } from "../../../Optiscan.Models/View/Session.js";
import { Common_eraseExceptionToError } from "../../../Common/Common.js";
import { toString, fromDateTimeOffset } from "../../../fable_modules/fable-library.3.7.12/Date.js";
import { Permission, isAllowed } from "../../../Optiscan.Models/Security.js";

export function trySetTime(time, date) {
    return create(year(date), month(date), day(date), defaultArg(map((s) => (~(~(s / 2))), time), 0), defaultArg(map((s_1) => {
        if ((s_1 % 2) === 0) {
            return 0;
        }
        else {
            return 30;
        }
    }, time), 0), 0, date.offset);
}

export function trySetTimeZone(timezone, date) {
    return create(year(date), month(date), day(date), hour(date), minute(date), 0, defaultArg(map((s) => s.offset, timezone), 0));
}

export function getDate(scheduledTime) {
    return create(year(scheduledTime), month(scheduledTime), day(scheduledTime), 0, 0, 0, scheduledTime.offset);
}

export function getTime(scheduledTime) {
    if (minute(scheduledTime) >= 30) {
        return ((hour(scheduledTime) * 2) + 1) | 0;
    }
    else {
        return (hour(scheduledTime) * 2) | 0;
    }
}

export function canSaveEditedSession(session) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, validateNotNullOrEmpty("Session name cannot be empty", session.sessionName), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, (session.date == null) ? (new FSharpResult$2(1, "Scheduled date cannot be empty")) : (new FSharpResult$2(0, void 0)), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, (session.time == null) ? (new FSharpResult$2(1, "Scheduled time cannot be empty")) : (new FSharpResult$2(0, void 0)), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, (session.timeZone == null) ? (new FSharpResult$2(1, "Time zone cannot be empty")) : (new FSharpResult$2(0, void 0)), () => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result)))))));
}

export function sessionInputView(model, dispatch) {
    return crudModal((model.maybeSelectedSession == null) ? "Schedule Session" : "Edit Scheduled Session", dispatch, toList(delay(() => {
        let f, optic;
        const setter = (arg_2) => {
            dispatch(new StateTreeMsg$4(0, new Msg(3, new CrudEditMsg$2(0, arg_2))));
        };
        return append(singleton(FormField_text(model.sessionName, empty(), [], FormField_onChangeSet(setter, uncurry(2, (f = ((optic = ScheduledSessionViewModel_get_sessionName_(), (value) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic)(value))), (b) => ((a) => Functions_flip(uncurry(2, f), b, a))))), "PatientSession", "Session Name", [])), delay(() => append(singleton(FormField_ReactSelect_multi(model.availableUsers, [], (user) => user.FullName, model.selectedUsers, new FormField_ReactSelect_CallbackMulti$1(0, (arg_5) => {
            dispatch(new StateTreeMsg$4(0, new Msg(2, toList(arg_5))));
        }), "Users to Invite", "PatientSession", [], false)), delay(() => {
            let optic_2;
            return append(singleton(FormField_date(model.date, [], [], FormField_onChangeDateOpticLocal(setter, (optic_2 = ScheduledSessionViewModel_get_date_(), (target) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic_2)(target)), uncurry(2, (maybeDateTimeOffset) => {
                iterate((offset) => {
                    dispatch(new StateTreeMsg$4(0, new Msg(0, date_3(offset))));
                }, toArray(maybeDateTimeOffset));
                return Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), ScheduledSessionViewModel_get_date_())(maybeDateTimeOffset);
            })), "PatientSession", "Scheduled Date")([])), delay(() => {
                let f1_6, optic_6;
                return (model.date != null) ? append(singleton(FormField_ReactSelect_single(toList(rangeDouble(0, 1, 48)), [], (index) => (((index % 2) === 0) ? toText(interpolate("%02i%P():00", [~(~(index / 2))])) : toText(interpolate("%02i%P():30", [~(~(index / 2))]))), model.time, new FormField_ReactSelect_CallbackSingle$1(0, (f1_6 = ((optic_6 = ScheduledSessionViewModel_get_time_(), (value_4) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic_6)(value_4))), (arg_6) => {
                    setter(f1_6(arg_6));
                })), "Scheduled Time", true, "PatientSession", [])), delay(() => {
                    let f1_7, optic_8;
                    return singleton(FormField_ReactSelect_single(defaultArg(model.timeZones, empty()), [new SelectPropsMulti$1(11, model.timeZones == null)], (zone) => zone.label, model.timeZone, new FormField_ReactSelect_CallbackSingle$1(0, (f1_7 = ((optic_8 = ScheduledSessionViewModel_get_timeZone_(), (value_7) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic_8)(value_7))), (arg_7) => {
                        setter(f1_7(arg_7));
                    })), "Time Zone", true, "PatientSession", toList(delay(() => ((model.timeZones == null) ? singleton(new Option(10, "is-loading")) : empty_1())))));
                })) : empty_1();
            }));
        }))));
    })), ofArray([CrudButton_save((x) => x, canSaveEditedSession(model), (_arg1) => {
        if (_arg1.tag === 0) {
            dispatch(new StateTreeMsg$4(0, new Msg(3, _arg1.fields[0])));
        }
    }), CrudButton_cancel(dispatch)]));
}

export function sessionCreateState() {
    return new StateTreeNode$6((deps) => [new ScheduledSessionViewModel(void 0, deps.patient.id, void 0, "", empty(), deps.authroizedUsers, void 0, void 0, void 0), Cmd_OfFunc_result(new StateTreeMsg$4(2))], sessionInputView, uncurry(4, (msg_2) => ((tupledArg) => {
        const token = tupledArg[0];
        return (deps_1) => ((newModel) => {
            if (msg_2.tag === 1) {
                if (msg_2.fields[0].tag === 1) {
                    return [newModel, error(ErrorMessage_get_describe()(msg_2.fields[0].fields[0]))];
                }
                else {
                    const currentTimezoneName = getCurrentTimezoneName();
                    return [new ScheduledSessionViewModel(newModel.maybeSelectedSession, newModel.patientId, msg_2.fields[0].fields[0], newModel.sessionName, newModel.selectedUsers, newModel.availableUsers, newModel.date, newModel.time, tryFind((zone) => {
                        if (zone.standardName === currentTimezoneName) {
                            return true;
                        }
                        else {
                            return zone.daylightName === currentTimezoneName;
                        }
                    }, msg_2.fields[0].fields[0])), Cmd_none()];
                }
            }
            else {
                return (msg_2.tag === 2) ? [new ScheduledSessionViewModel(newModel.maybeSelectedSession, newModel.patientId, newModel.timeZones, newModel.sessionName, msg_2.fields[0], newModel.availableUsers, newModel.date, newModel.time, newModel.timeZone), Cmd_none()] : ((msg_2.tag === 3) ? crudEditUpdate((arg0_3) => (new Msg(3, arg0_3)), (model_1) => AsyncResult_map(() => {
                }, securedApi(token).createSession(new SessionCreateViewModel(model_1.patientId, model_1.sessionName, map((date_2) => trySetTimeZone(model_1.timeZone, date_2), map((date_1) => trySetTime(model_1.time, date_1), model_1.date)), map_1((user) => user.Id, model_1.selectedUsers)))), Common_eraseExceptionToError, "Session created.", ErrorMessage_get_describe(), msg_2.fields[0], newModel) : [new ScheduledSessionViewModel(newModel.maybeSelectedSession, newModel.patientId, void 0, newModel.sessionName, newModel.selectedUsers, newModel.availableUsers, newModel.date, newModel.time, void 0), Cmd_OfAsyncWith_either((x) => {
                    Cmd_OfAsync_start(x);
                }, securedApi(token).getAdjustedTimezones, msg_2.fields[0], (arg) => (new StateTreeMsg$4(0, new Msg(1, arg))), (arg_1) => (new StateTreeMsg$4(1, Common_eraseExceptionToError(arg_1))))]);
            }
        });
    })));
}

export function sessionDetailState(currentUser) {
    return new StateTreeNode$6((deps) => {
        let copyOfStruct, date, time;
        const matchValue = deps.maybeSelectedSession;
        if (matchValue == null) {
            return [new ScheduledSessionViewModel(void 0, deps.patient.id, void 0, "", empty(), empty(), void 0, void 0, void 0), Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(1)))];
        }
        else {
            const session = matchValue;
            const localScheduledTime = toOffset(session.scheduledTime, (copyOfStruct = now(), copyOfStruct.offset));
            return [(date = getDate(localScheduledTime), (time = getTime(localScheduledTime), new ScheduledSessionViewModel(session, deps.patient.id, void 0, session.sessionName, filter((user) => contains(user.Id, session.usersToNotify, {
                Equals: (x, y) => (x === y),
                GetHashCode: stringHash,
            }), deps.authroizedUsers), deps.authroizedUsers, date, time, void 0))), Cmd_batch(ofArray([Cmd_OfFunc_result(new StateTreeMsg$4(2)), Cmd_OfFunc_result(new StateTreeMsg$4(0, new Msg(0, fromDateTimeOffset(localScheduledTime, 0))))]))];
        }
    }, (model, dispatch) => {
        let matchValue_1, t, arg10, arg10_1;
        return crudModal("Details", dispatch, ofArray([FormField_labeledField("PatientSession", "Session Name", model.sessionName, []), FormField_displayList("Users to Invite", map_1((user_1) => user_1.FullName, model.selectedUsers)), FormField_labeledField("PatientSession", "Scheduled Date", defaultArg(map((d) => toString(d, "yyyy-MM-dd"), model.date), "Not Scheduled"), []), FormField_labeledField("PatientSession", "Scheduled Time", (matchValue_1 = model.time, (matchValue_1 == null) ? "Not Scheduled" : ((t = (matchValue_1 | 0), ((t % 2) === 0) ? ((arg10 = ((~(~(t / 2))) | 0), toText(printf("%02i:00"))(arg10))) : ((arg10_1 = ((~(~(t / 2))) | 0), toText(printf("%02i:30"))(arg10_1)))))), []), FormField_labeledField("PatientSession", "Time Zone", defaultArg(map((tz) => tz.label, model.timeZone), "Not Specified"), [])]), toList(delay(() => append(isAllowed(new Permission(38))(currentUser.roles) ? singleton(CrudButton_edit(dispatch)) : empty_1(), delay(() => singleton(CrudButton_cancel(dispatch)))))));
    }, (msg_6, tupledArg, deps_1, newModel) => {
        if (msg_6.tag === 1) {
            if (msg_6.fields[0].tag === 0) {
                const currentTimeZoneName = getCurrentTimezoneName();
                return [new ScheduledSessionViewModel(newModel.maybeSelectedSession, newModel.patientId, msg_6.fields[0].fields[0], newModel.sessionName, newModel.selectedUsers, newModel.availableUsers, newModel.date, newModel.time, tryFind((zone) => {
                    if (zone.standardName === currentTimeZoneName) {
                        return true;
                    }
                    else {
                        return zone.daylightName === currentTimeZoneName;
                    }
                }, msg_6.fields[0].fields[0])), Cmd_none()];
            }
            else {
                return [newModel, error(ErrorMessage_get_describe()(msg_6.fields[0].fields[0]))];
            }
        }
        else {
            return (msg_6.tag === 0) ? [new ScheduledSessionViewModel(newModel.maybeSelectedSession, newModel.patientId, void 0, newModel.sessionName, newModel.selectedUsers, newModel.availableUsers, newModel.date, newModel.time, void 0), Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, securedApi(tupledArg[0]).getAdjustedTimezones, msg_6.fields[0], (arg) => (new StateTreeMsg$4(0, new Msg(1, arg))), (arg_1) => (new StateTreeMsg$4(1, Common_eraseExceptionToError(arg_1))))] : [newModel, Cmd_none()];
        }
    });
}

export function sessionEditState(currentUser) {
    return new StateTreeNode$6((deps) => [new ScheduledSessionViewModel(deps.maybeSelectedSession, deps.patientId, deps.timeZones, deps.sessionName, deps.selectedUsers, deps.availableUsers, deps.date, deps.time, deps.timeZone), Cmd_OfFunc_result(new StateTreeMsg$4(2))], sessionInputView, (msg_2, tupledArg, deps_1, newModel) => {
        switch (msg_2.tag) {
            case 2: {
                return [new ScheduledSessionViewModel(newModel.maybeSelectedSession, newModel.patientId, newModel.timeZones, newModel.sessionName, msg_2.fields[0], newModel.availableUsers, newModel.date, newModel.time, newModel.timeZone), Cmd_none()];
            }
            case 3: {
                const matchValue = newModel.maybeSelectedSession;
                if (matchValue == null) {
                    return [newModel, Cmd_none()];
                }
                else {
                    const existingSession = matchValue;
                    return crudEditUpdate((arg0) => (new Msg(3, arg0)), (model_1) => {
                        const newSession = new SessionViewModel(existingSession.sessionId, existingSession.patientId, existingSession.deviceId, model_1.sessionName, existingSession.sessionState, existingSession.dateCreated, existingSession.region, defaultArgWith(map(toUniversalTime, map((date_2) => trySetTimeZone(model_1.timeZone, date_2), map((date_1) => trySetTime(model_1.time, date_1), model_1.date))), () => existingSession.scheduledTime), map_1((user) => user.Id, model_1.selectedUsers));
                        return equals(newSession, existingSession) ? AsyncResult_ofSuccess() : AsyncResult_map(() => {
                        }, securedApi(tupledArg[0]).updateSession(newSession));
                    }, Common_eraseExceptionToError, "Session updated.", ErrorMessage_get_describe(), msg_2.fields[0], newModel);
                }
            }
            default: {
                return [newModel, Cmd_none()];
            }
        }
    });
}

