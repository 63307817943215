import { update, init } from "../../TelepathologyCommon/TelepathologyState.js";
import { some, map } from "../../fable_modules/fable-library.3.7.12/Option.js";
import { TelepathologyApi, TelepathologyDependency } from "../../TelepathologyCommon/TelepathologyTypes.js";
import { Cmd_none, Cmd_batch, Cmd_map } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { PatientTelepathologyMsg } from "./PatientTelepathologyTypes.js";
import { StateTreeNode$6, NavigationMsg$1, StateTreeMsg$4 } from "../../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { view } from "../../TelepathologyCommon/TelepathologyView.js";
import { Option, button } from "../../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { ofArray, singleton } from "../../fable_modules/fable-library.3.7.12/List.js";
import { uncurry } from "../../fable_modules/fable-library.3.7.12/Util.js";
import { securedApi } from "../../Api.js";
import { TelemetryAction } from "../../Optiscan.Models/Api/Api.js";
import { Navigation_modifyUrl } from "../../fable_modules/Fable.Elmish.Browser.3.0.4/navigation.fs.js";
import { Page, pageHash } from "../../Pages.js";
import { Cmd_OfFunc_result } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";

export function patientTelepathologyView() {
    return new StateTreeNode$6((deps) => {
        const patternInput = init(new TelepathologyDependency(deps.patient.deviceId, map((s) => s.region, deps.activeSession), deps.activeSession));
        return [patternInput[0], Cmd_map((arg) => (new StateTreeMsg$4(0, new PatientTelepathologyMsg(1, arg))), patternInput[1])];
    }, (model_1, dispatch) => view(model_1, (arg_2) => {
        dispatch(new StateTreeMsg$4(0, new PatientTelepathologyMsg(1, arg_2)));
    }, [button(singleton(new Option(18, (_arg1) => {
        dispatch(new StateTreeMsg$4(0, new PatientTelepathologyMsg(2, "SearchForm")));
    })), singleton("Back to Search"))], [button(singleton(new Option(18, (_arg2) => {
        dispatch(new StateTreeMsg$4(0, new PatientTelepathologyMsg(2, "list")));
    })), singleton("Back"))]), uncurry(4, (msg) => ((tupledArg) => {
        const token = tupledArg[0];
        return (deps_1) => ((model_2) => {
            let f2_3;
            switch (msg.tag) {
                case 1: {
                    const patternInput_1 = update(msg.fields[0], new TelepathologyApi(securedApi(token).flagSessionImage, securedApi(token).getCurrentAnatomicalRegion, securedApi(token).getCurrentActiveSessionForDevice, securedApi(token).generateWebRTCPermissionToken, (f2_3 = securedApi(token).telemetry, (arg_3) => f2_3(new TelemetryAction(6, arg_3)))), tupledArg[2], tupledArg[4], model_2);
                    return [patternInput_1[0], Cmd_map((arg_4) => (new StateTreeMsg$4(0, new PatientTelepathologyMsg(1, arg_4))), patternInput_1[1])];
                }
                case 2: {
                    const targetState = msg.fields[0];
                    console.info(some("VIEWER: Stopping client"));
                    let viewer = document.getElementById("viewer");
                    viewer.srcObject = (void 0);
                    return [model_2, Cmd_batch(ofArray([Navigation_modifyUrl((targetState === "list") ? pageHash(new Page(6, deps_1.patient.id, void 0)) : pageHash(new Page(6, void 0, void 0))), Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(3, targetState)))]))];
                }
                case 3: {
                    console.info(some("VIEWER: Stopping client"));
                    let viewer_1 = document.getElementById("viewer");
                    viewer_1.srcObject = (void 0);
                    document.getElementById("csv-download").click();
                    return [model_2, Cmd_batch(ofArray([Navigation_modifyUrl(pageHash(new Page(6, deps_1.patient.id, void 0))), Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(3, "list")))]))];
                }
                default: {
                    console.error(some(`Error: ${msg.fields[0]}`));
                    return [model_2, Cmd_none()];
                }
            }
        });
    })));
}

