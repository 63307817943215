import { content, Header_title, header, card } from "../../fable_modules/Fulma.2.16.0/Components/Card.fs.js";
import { Size_ISize, Color_IColor, Common_GenericOption } from "../../fable_modules/Fulma.2.16.0/Common.fs.js";
import { map, toArray, tryFind, filter, empty, ofArray, singleton } from "../../fable_modules/fable-library.3.7.12/List.js";
import { label } from "../../fable_modules/Fulma.2.16.0/Elements/Form/Label.fs.js";
import { FormField_text, FormField_onChangeSet, FormField_textArea, FormField_ReactSelect_CallbackSingle$1, FormField_ReactSelect_single } from "../../Optiscan.SharedUI/Forms.js";
import { bind } from "../../fable_modules/fable-library.3.7.12/Option.js";
import { reportsTableColumns, PatientReportMsg } from "./PatientReportTypes.js";
import { StateTreeMsg$4 } from "../../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { Sort_SortableColumnView$1, Sort_Compare$1, ListDisplayModification_ListDisplayModification$2_applyListModifications, Sort_itemViewListSortable } from "../../Optiscan.SharedUI/Sort.js";
import { append, singleton as singleton_1, delay, toList, empty as empty_1 } from "../../fable_modules/fable-library.3.7.12/Seq.js";
import * as react from "react";
import { PatientIdentidfiableInformation_get_id_, PatientIdentidfiableInformation_get_lastName_, PatientIdentidfiableInformation_get_firstName_, ReportType, ReportType_get_display, ReportType_get_all, Assessment_TypeModule_describe } from "../../Optiscan.Models/Shared.js";
import { HTMLAttr, DOMAttr } from "../../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { Fa_IconOption, Fa_i } from "../../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { keyValueList } from "../../fable_modules/fable-library.3.7.12/MapUtil.js";
import { substring } from "../../fable_modules/fable-library.3.7.12/String.js";
import { Card_foot, Card_body, Card_head, Card_card, background, Option, modal } from "../../fable_modules/Fulma.2.16.0/Components/Modal.fs.js";
import { Option as Option_1 } from "../../fable_modules/Fulma.2.16.0/Elements/Form/Textarea.fs.js";
import { equals, uncurry } from "../../fable_modules/fable-library.3.7.12/Util.js";
import { AssessmentViewModel_get_assessment_ } from "../../Optiscan.Models/View/Assessment.js";
import { Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { Functions_flip } from "../../fable_modules/Webbler.Models.1.2.6/Common.fs.js";
import { Option as Option_2, button } from "../../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { ErrorMessage } from "../../Optiscan.Models/ErrorMessage.js";
import { SelectPropsMulti$1 } from "../../Optiscan.SharedUI/bindings/ReactSelectBind.js";
import { Option as Option_3, columns } from "../../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { Option as Option_4, column } from "../../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { Option as Option_5 } from "../../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { Option as Option_6, div } from "../../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { div as div_1 } from "../../fable_modules/Fulma.2.16.0/Elements/Form/Control.fs.js";
import { input } from "../../fable_modules/Fulma.2.16.0/Elements/Form/Checkbox.fs.js";
import { Option as Option_7, icon } from "../../fable_modules/Fulma.2.16.0/Elements/Icon.fs.js";
import { toShortDateString } from "../../fable_modules/fable-library.3.7.12/Date.js";

export function reportsTableCard(model, dispatch) {
    let children;
    const loadingClass = model.isDoingReportOperation ? "is-loading" : "";
    return card(ofArray([new Common_GenericOption(0, `embed-card ${loadingClass}`), new Common_GenericOption(1, singleton(["style", {
        marginBottom: "2em",
        overflowX: "visible",
        overflowY: "visible",
    }]))]), ofArray([header(empty(), singleton(Header_title(empty(), singleton("Reports")))), content(singleton(new Common_GenericOption(1, singleton(["style", {
        overflowX: "visible",
        overflowY: "visible",
    }]))), ofArray([label(empty(), singleton("Session")), FormField_ReactSelect_single(filter((s_2) => (s_2.sessionState === 2), model.sessions), [], (s_3) => s_3.sessionName, bind((s1) => tryFind((s2) => (s1.sessionId === s2.sessionId), model.sessions), model.session), new FormField_ReactSelect_CallbackSingle$1(0, (arg_1) => {
        dispatch(new StateTreeMsg$4(0, new PatientReportMsg(44, arg_1)));
    }), "Session", false, "Reports", []), (children = [Sort_itemViewListSortable("Reports", reportsTableColumns(dispatch), (_arg2, _arg1) => {
    }, ListDisplayModification_ListDisplayModification$2_applyListModifications(model.reportsListModifications, model.reports), (_arg3) => empty_1(), model.reportsListSortInfo, (sortInfo, _arg4) => {
        dispatch(new StateTreeMsg$4(0, new PatientReportMsg(41, sortInfo)));
    }, (_arg5) => empty())], react.createElement("div", {
        className: "table-container",
    }, ...children))]))]));
}

export function assessmentsCardSection(assessment, onClick) {
    let children_2, props, children, children_4;
    const characterLimit = 280;
    const children_6 = [(children_2 = [Assessment_TypeModule_describe(assessment.assessmentType), (props = [["style", {
        marginLeft: "0.5em",
    }], new DOMAttr(40, (_arg1) => {
        onClick(assessment);
    })], (children = [Fa_i(singleton(new Fa_IconOption(11, "fas fa-edit")), [])], react.createElement("a", keyValueList(props, 1), ...children)))], react.createElement("header", {
        className: "assessments-card__section-header",
    }, ...children_2)), (children_4 = toList(delay(() => ((assessment.assessment.length > characterLimit) ? singleton_1(`${substring(assessment.assessment, 0, characterLimit)}...`) : ((assessment.assessment.length === 0) ? singleton_1("N/A") : singleton_1(assessment.assessment))))), react.createElement("p", {
        className: "assessments-card__section-content",
        onClick: (_arg2) => {
            onClick(assessment);
        },
    }, ...children_4))];
    return react.createElement("section", {
        className: "assessments-card__section",
    }, ...children_6);
}

export function assessmentsCard(isLoading, assessments, sectionEditHandler) {
    let children;
    const loadingClass = isLoading ? "is-loading" : "";
    return card(ofArray([new Common_GenericOption(0, `embed-card ${loadingClass}`), new Common_GenericOption(1, singleton(["style", {
        marginBottom: "2em",
    }]))]), ofArray([header(empty(), singleton((children = [Header_title(empty(), singleton("Assessments"))], react.createElement("div", {
        className: "card-header-container",
    }, ...children)))), content(singleton(new Common_GenericOption(0, "assessments-card__content")), singleton(toArray(map((assessment) => assessmentsCardSection(assessment, sectionEditHandler), assessments))))]));
}

export function assessmentEditModal(assessment, isEditing, setter, saveCallback, cancelCallback) {
    let f, optic;
    return modal(singleton(new Option(1, isEditing)), ofArray([background(empty(), empty()), Card_card(empty(), ofArray([Card_head(empty(), singleton("Edit Assessment")), Card_body(empty(), singleton(FormField_textArea("Assessment", assessment.assessment, empty(), [new Option_1(14, singleton(new HTMLAttr(55, true)))], FormField_onChangeSet(setter, uncurry(2, (f = ((optic = AssessmentViewModel_get_assessment_(), (value) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic)(value))), (b) => ((a) => Functions_flip(uncurry(2, f), b, a))))), "PatientAssessment", Assessment_TypeModule_describe(assessment.assessmentType))([]))), Card_foot(empty(), ofArray([button(singleton(new Option_2(18, saveCallback)), singleton("Save")), button(singleton(new Option_2(18, cancelCallback)), singleton("Cancel"))]))]))]));
}

export function reportSelectionModal(modalState, reportType, identifiedPatientInfo, canSave, piiValid, isGeneratingReport, dispatch) {
    let reportInfo;
    const actionName = (modalState != null) ? ((modalState.tag === 1) ? "Download" : "Generate") : "";
    const onSelect = (modalState != null) ? ((modalState.tag === 1) ? ((reportInfo = modalState.fields[0], new StateTreeMsg$4(0, new PatientReportMsg(39, new PatientReportMsg(26, reportInfo))))) : (new StateTreeMsg$4(0, new PatientReportMsg(39, new PatientReportMsg(24))))) : (new StateTreeMsg$4(1, new ErrorMessage(0)));
    let body;
    const children = toList(delay(() => append(singleton_1(FormField_ReactSelect_single(ReportType_get_all(), [new SelectPropsMulti$1(11, isGeneratingReport)], ReportType_get_display(), reportType, new FormField_ReactSelect_CallbackSingle$1(0, (arg_1) => {
        dispatch(new StateTreeMsg$4(0, new PatientReportMsg(37, arg_1)));
    }), "Report Type", true, "Report", [])), delay(() => (equals(reportType, new ReportType(0)) ? singleton(columns(ofArray([new Option_3(1), new Option_3(2)]), toList(delay(() => {
        let f, optic, f_1, optic_2, f_2, optic_4;
        const setter = (arg_3) => {
            dispatch(new StateTreeMsg$4(0, new PatientReportMsg(38, arg_3)));
        };
        return singleton_1(column(singleton(new Option_4(3, singleton(["style", {
            overflowX: "auto",
        }]))), ofArray([FormField_text(identifiedPatientInfo.firstName, empty(), [new Option_5(4, isGeneratingReport)], FormField_onChangeSet(setter, uncurry(2, (f = ((optic = PatientIdentidfiableInformation_get_firstName_(), (value) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic)(value))), (b) => ((a) => Functions_flip(uncurry(2, f), b, a))))), "Report", "First Name", []), FormField_text(identifiedPatientInfo.lastName, empty(), [new Option_5(4, isGeneratingReport)], FormField_onChangeSet(setter, uncurry(2, (f_1 = ((optic_2 = PatientIdentidfiableInformation_get_lastName_(), (value_2) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic_2)(value_2))), (b_1) => ((a_1) => Functions_flip(uncurry(2, f_1), b_1, a_1))))), "Report", "Last Name", []), FormField_text(identifiedPatientInfo.id, empty(), [new Option_5(4, isGeneratingReport)], FormField_onChangeSet(setter, uncurry(2, (f_2 = ((optic_4 = PatientIdentidfiableInformation_get_id_(), (value_4) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic_4)(value_4))), (b_2) => ((a_2) => Functions_flip(uncurry(2, f_2), b_2, a_2))))), "Report", "Patient MRN", [])])));
    })))) : empty())))));
    body = react.createElement("form", {
        disabled: true,
    }, ...children);
    const footer = singleton(div(singleton(new Option_6(5)), ofArray([div_1(empty(), singleton(button(ofArray([new Option_2(0, new Color_IColor(6)), new Option_2(16, !canSave), new Option_2(13, isGeneratingReport), new Option_2(17, toList(delay(() => append(singleton_1(new DOMAttr(40, (_arg1) => {
        dispatch(onSelect);
    })), delay(() => append(singleton_1(new HTMLAttr(159, "button")), delay(() => {
        if (piiValid.tag === 0) {
            return empty_1();
        }
        else {
            return singleton_1(new HTMLAttr(158, piiValid.fields[0]));
        }
    })))))))]), singleton(`${actionName} Report`)))), div_1(empty(), singleton(button(ofArray([new Option_2(0, new Color_IColor(8)), new Option_2(16, isGeneratingReport), new Option_2(17, ofArray([new DOMAttr(40, (_arg2) => {
        dispatch(new StateTreeMsg$4(0, new PatientReportMsg(36, void 0)));
    }), new HTMLAttr(159, "button")]))]), singleton("Cancel"))))])));
    const modalBackground = isGeneratingReport ? empty() : singleton(new Common_GenericOption(1, singleton(new DOMAttr(40, (_arg3) => {
        dispatch(new StateTreeMsg$4(0, new PatientReportMsg(36, void 0)));
    }))));
    return modal(singleton(new Option(1, modalState != null)), ofArray([background(toList(delay(() => modalBackground)), empty()), Card_card(empty(), ofArray([Card_head(empty(), singleton(`Select the Type of Report to ${actionName}`)), Card_body(empty(), singleton(body)), Card_foot(empty(), footer)]))]));
}

export function Screenshots_Columns_noComparer(_arg2, _arg1) {
    return 0;
}

export function Screenshots_Columns_truncateText(maxLength, text) {
    if (text.length > maxLength) {
        return `${substring(text, 0, maxLength)}...`;
    }
    else {
        return text;
    }
}

export function Screenshots_Columns_selectVm(dispatch) {
    return (vm) => ((evt) => dispatch(new StateTreeMsg$4(0, new PatientReportMsg(32, vm.screenshotId, vm.role, !vm.selected))));
}

export function Screenshots_Columns_mkSelection(dispatch) {
    return new Sort_SortableColumnView$1("", (vm) => {
        const checkbox = input(singleton(new Common_GenericOption(1, ofArray([new HTMLAttr(62, vm.selected), new HTMLAttr(132, true)]))));
        if (vm.role.tag === 1) {
            const props = [["style", {
                paddingLeft: "1em",
            }]];
            return react.createElement("span", keyValueList(props, 1), checkbox);
        }
        else {
            return checkbox;
        }
    }, uncurry(2, Screenshots_Columns_selectVm(dispatch)), new Sort_Compare$1(0, Screenshots_Columns_noComparer));
}

export function Screenshots_Columns_Name(dispatch) {
    return new Sort_SortableColumnView$1("Name", (vm) => {
        const characterLimit = 40;
        if (vm.role.tag === 1) {
            const props = [["style", {
                paddingLeft: "1em",
            }]];
            const children = [Screenshots_Columns_truncateText(characterLimit, vm.name)];
            return react.createElement("span", keyValueList(props, 1), ...children);
        }
        else {
            return Screenshots_Columns_truncateText(characterLimit, vm.name);
        }
    }, uncurry(2, Screenshots_Columns_selectVm(dispatch)), new Sort_Compare$1(0, Screenshots_Columns_noComparer));
}

export function Screenshots_Columns_mkPeek(dispatch) {
    return new Sort_SortableColumnView$1("", (vm) => button(ofArray([new Option_2(0, new Color_IColor(5)), new Option_2(1, new Size_ISize(0))]), singleton(icon(singleton(new Option_7(0, new Size_ISize(0))), singleton(react.createElement("i", {
        className: "fas fa-image",
    }))))), (vm_1, evt) => {
        let matchValue;
        dispatch(new StateTreeMsg$4(0, new PatientReportMsg(33, vm_1.screenshotId, (matchValue = vm_1.role, (matchValue.tag === 1) ? matchValue.fields[1] : ((matchValue.tag === 0) ? (void 0) : matchValue.fields[1])))));
    }, new Sort_Compare$1(0, Screenshots_Columns_noComparer));
}

export function Screenshots_Columns_SessionName(dispatch) {
    return new Sort_SortableColumnView$1("Session", (vm) => Screenshots_Columns_truncateText(40, vm.sessionName), uncurry(2, Screenshots_Columns_selectVm(dispatch)), new Sort_Compare$1(0, Screenshots_Columns_noComparer));
}

export function Screenshots_Columns_Date(dispatch) {
    return new Sort_SortableColumnView$1("Date", (vm) => toShortDateString(vm.date), uncurry(2, Screenshots_Columns_selectVm(dispatch)), new Sort_Compare$1(0, Screenshots_Columns_noComparer));
}

export function Screenshots_Columns_Location(dispatch) {
    return new Sort_SortableColumnView$1("Location", (vm) => Screenshots_Columns_truncateText(40, vm.locationDescription), uncurry(2, Screenshots_Columns_selectVm(dispatch)), new Sort_Compare$1(0, Screenshots_Columns_noComparer));
}

export function Screenshots_screenshotsTableCard(model, dispatch) {
    let children;
    return card(ofArray([new Common_GenericOption(0, " embed-card"), new Common_GenericOption(1, singleton(["style", {
        marginBottom: "2em",
    }]))]), ofArray([header(empty(), singleton(Header_title(empty(), singleton("Captured Images")))), content(empty(), singleton((children = [Sort_itemViewListSortable("CapturedImage", ofArray([Screenshots_Columns_mkSelection(dispatch), Screenshots_Columns_Name(dispatch), Screenshots_Columns_Date(dispatch), Screenshots_Columns_mkPeek(dispatch), Screenshots_Columns_SessionName(dispatch), Screenshots_Columns_Location(dispatch)]), (_arg2, _arg1) => {
    }, ListDisplayModification_ListDisplayModification$2_applyListModifications(model.screenshotListModifications, model.screenshots), (_arg3) => empty_1(), model.screenshotListSortInfo, (sortInfo, _arg4) => {
        dispatch(new StateTreeMsg$4(0, new PatientReportMsg(31, sortInfo)));
    }, (_arg5) => empty())], react.createElement("div", {
        className: "table-container",
    }, ...children))))]));
}

