import { Record } from "../../../fable_modules/fable-library.3.7.12/Types.js";
import { ScreenshotListItemViewModel, ScreenshotListItemViewModel$reflection } from "../../../Optiscan.Models/View/ScreenshotInfoViewModel.js";
import { float64_type, record_type, list_type } from "../../../fable_modules/fable-library.3.7.12/Reflection.js";
import { AsyncResult_ofSuccess, AsyncResultComputationExpression_AsyncResultBuilder__Zero, AsyncResult_ofError, AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505, AsyncResultComputationExpression_AsyncResultBuilder__Combine_Z6CC99CA7, AsyncResult_ofAsync, AsyncResultComputationExpression_asyncResult, AsyncResultComputationExpression_AsyncResultBuilder__Return_1505, AsyncResult_mapError, AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93, AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B } from "../../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { ErrorMessage } from "../../../Optiscan.Models/ErrorMessage.js";
import { ofPromise } from "../../../Common/Extensions.js";
import { empty, ofArray, map } from "../../../fable_modules/fable-library.3.7.12/List.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "../../../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { S3_PutObjectRequestParams, S3_PutObjectRequestParams_get_create } from "../../../bindings/Aws/Fable.Helpers.Aws.js";
import { value as value_6, some } from "../../../fable_modules/fable-library.3.7.12/Option.js";
import { awaitPromise } from "../../../fable_modules/fable-library.3.7.12/Async.js";
import { fetch$ } from "../../../fable_modules/Fable.Fetch.2.4.0/Fetch.fs.js";
import { Pdf_Spacing_topMargin, Pdf_Table_Adv_makeTitle, Pdf_Spacing_baseSpacingY, Pdf_Spacing_narrowSpacingY, Pdf_Images_Basic_addImageAutoScale, Pdf_Images_Basic_pixelsToMm, Pdf_Spacing_leftMargin } from "../PatientReportHelpers.js";
import { sortBy } from "../../../fable_modules/fable-library.3.7.12/Array.js";
import { compare } from "../../../fable_modules/fable-library.3.7.12/Date.js";

export class Args extends Record {
    constructor(selectedScreenshots) {
        super();
        this.selectedScreenshots = selectedScreenshots;
    }
}

export function Args$reflection() {
    return record_type("Client.Patients.PatientReport.Sections.ScreenshotsSection.Args", [], Args, () => [["selectedScreenshots", list_type(ScreenshotListItemViewModel$reflection())]]);
}

export function getS3KeyForScreenshot(s) {
    const matchValue = s.role;
    let pattern_matching_result, _sessionId, s3Key;
    switch (matchValue.tag) {
        case 2: {
            pattern_matching_result = 1;
            _sessionId = matchValue.fields[0];
            s3Key = matchValue.fields[1];
            break;
        }
        case 1: {
            pattern_matching_result = 1;
            _sessionId = matchValue.fields[0];
            s3Key = matchValue.fields[1];
            break;
        }
        default: pattern_matching_result = 0}
    switch (pattern_matching_result) {
        case 0: {
            throw (new Error("Got a parent screenshot in the screenshot PDF section"));
        }
        case 1: {
            return s3Key;
        }
    }
}

export function getScreenshots(s3Context, args) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => {
        let pr;
        return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AsyncResult_mapError((arg0_1) => (new ErrorMessage(2, arg0_1)), ofPromise()((pr = map((s) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let tupledArg;
            return ((tupledArg = ["getObject", some(S3_PutObjectRequestParams_get_create()(ofArray([new S3_PutObjectRequestParams(2, s3Context.ActiveBucket), new S3_PutObjectRequestParams(3, getS3KeyForScreenshot(s))])))], s3Context.S3Client.getSignedUrlPromise(tupledArg[0], tupledArg[1]))).then((_arg1) => (Promise.resolve(new ScreenshotListItemViewModel(s.screenshotId, s.sessionName, s.name, s.date, s.description, s.locationDescription, s.role, s.selected, _arg1))));
        })), args.selectedScreenshots), Promise.all(pr)))), (_arg2) => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, _arg2));
    });
}

function getScaledImageDimensions(image, width, height, doc) {
    const processed = doc.processPNG(image, 0, "", "NONE");
    const maxWidth = width;
    const maxHeight = height;
    const givenRatio = maxWidth / maxHeight;
    const imageRatio = processed.width / processed.height;
    if (givenRatio < imageRatio) {
        return [maxWidth, maxWidth * (1 / imageRatio)];
    }
    else {
        return [maxHeight * imageRatio, maxHeight];
    }
}

export function addImage(startY, image, doc) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AsyncResult_ofAsync(awaitPromise(PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetch$(value_6(image.imageUrl), empty()).then((_arg1) => (_arg1.arrayBuffer()))))))), (_arg2) => {
        const fileArray = _arg2;
        const pageSize = doc.internal.pageSize;
        const pageWidth = pageSize.getWidth();
        const pageHeight = pageSize.getHeight();
        const imageName = `${image.name}
${image.locationDescription}
${image.description}`;
        const maxWidth = pageWidth - (2 * Pdf_Spacing_leftMargin);
        const processed = doc.processPNG(fileArray, 0, imageName, "NONE");
        const pixelWidth = processed.width;
        const pixelHeight = processed.height;
        return AsyncResultComputationExpression_AsyncResultBuilder__Combine_Z6CC99CA7(AsyncResultComputationExpression_asyncResult, ((maxWidth > pixelWidth) ? true : (pageHeight > pixelHeight)) ? AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505(AsyncResultComputationExpression_asyncResult, AsyncResult_ofError(new ErrorMessage(25, "actual screenshot dimensions", "fit on the page"))) : AsyncResultComputationExpression_AsyncResultBuilder__Zero(AsyncResultComputationExpression_asyncResult), AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => {
            const mmHeight = Pdf_Images_Basic_pixelsToMm(pixelHeight);
            const patternInput = Pdf_Images_Basic_addImageAutoScale(fileArray, imageName, Pdf_Spacing_leftMargin, startY, false, !(Pdf_Images_Basic_pixelsToMm(pixelWidth) > maxWidth), maxWidth, mmHeight, doc);
            return AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, patternInput[2] + patternInput[1]);
        }));
    }));
}

export class AddToDocArgs extends Record {
    constructor(image, maxPageHeight, maxPageWidth, screenshotHeight, restartY, lineHeight) {
        super();
        this.image = image;
        this.maxPageHeight = maxPageHeight;
        this.maxPageWidth = maxPageWidth;
        this.screenshotHeight = screenshotHeight;
        this.restartY = restartY;
        this.lineHeight = lineHeight;
    }
}

export function AddToDocArgs$reflection() {
    return record_type("Client.Patients.PatientReport.Sections.ScreenshotsSection.AddToDocArgs", [], AddToDocArgs, () => [["image", ScreenshotListItemViewModel$reflection()], ["maxPageHeight", float64_type], ["maxPageWidth", float64_type], ["screenshotHeight", float64_type], ["restartY", float64_type], ["lineHeight", float64_type]]);
}

export function addSingleScreenshotText(docArgs, i, splitText, currentY, fontStyle, maybeFontSize, doc) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => {
        let fontSize;
        let currentY_1;
        if ((currentY + docArgs.lineHeight) > docArgs.maxPageHeight) {
            doc.addPage();
            currentY_1 = docArgs.restartY;
        }
        else {
            currentY_1 = currentY;
        }
        const currFontSize = doc.getFontSize();
        return AsyncResultComputationExpression_AsyncResultBuilder__Combine_Z6CC99CA7(AsyncResultComputationExpression_asyncResult, (maybeFontSize == null) ? ((AsyncResultComputationExpression_AsyncResultBuilder__Zero(AsyncResultComputationExpression_asyncResult))) : ((fontSize = maybeFontSize, (void doc.setFontSize(fontSize), AsyncResultComputationExpression_AsyncResultBuilder__Zero(AsyncResultComputationExpression_asyncResult)))), AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => {
            doc.setFont("times", fontStyle);
            doc.text(splitText[i], Pdf_Spacing_leftMargin, currentY_1);
            doc.setFont("times", "normal");
            doc.setFontSize(currFontSize);
            const i_1 = (i + 1) | 0;
            const currentY_2 = currentY_1 + docArgs.lineHeight;
            return (i_1 < splitText.length) ? AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505(AsyncResultComputationExpression_asyncResult, addSingleScreenshotText(docArgs, i_1, splitText, currentY_2, fontStyle, maybeFontSize, doc)) : AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, currentY_2);
        }));
    });
}

export function addSingleScreenshotCaption(docArgs, i, splitText, currentY, fontStyle, maybeFontSize, doc) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => {
        let fontSize;
        let currentY_1;
        if ((currentY + docArgs.lineHeight) > docArgs.maxPageHeight) {
            doc.addPage();
            currentY_1 = docArgs.restartY;
        }
        else {
            currentY_1 = currentY;
        }
        const currFontSize = doc.getFontSize();
        return AsyncResultComputationExpression_AsyncResultBuilder__Combine_Z6CC99CA7(AsyncResultComputationExpression_asyncResult, (maybeFontSize == null) ? ((AsyncResultComputationExpression_AsyncResultBuilder__Zero(AsyncResultComputationExpression_asyncResult))) : ((fontSize = maybeFontSize, (void doc.setFontSize(fontSize), AsyncResultComputationExpression_AsyncResultBuilder__Zero(AsyncResultComputationExpression_asyncResult)))), AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => {
            const textX = (doc.internal.pageSize.getWidth() - doc.getTextWidth(splitText[i])) / 2;
            doc.setFont("times", fontStyle);
            doc.text(Array.from(splitText), textX, currentY_1);
            doc.setFont("times", "normal");
            doc.setFontSize(currFontSize);
            return AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, currentY_1 + (docArgs.lineHeight * (splitText.length + 1)));
        }));
    });
}

export function addAllScreenshots(docArgs, i, currentY, images, doc) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => {
        const docArgs_1 = new AddToDocArgs(images[i], docArgs.maxPageHeight, docArgs.maxPageWidth, docArgs.screenshotHeight, docArgs.restartY, docArgs.lineHeight);
        let currentY_1;
        if (((currentY + Pdf_Spacing_narrowSpacingY) + docArgs_1.screenshotHeight) > docArgs_1.maxPageHeight) {
            doc.addPage();
            currentY_1 = docArgs_1.restartY;
        }
        else {
            currentY_1 = (currentY + Pdf_Spacing_narrowSpacingY);
        }
        return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, (docArgs_1.image.name.length > 0) ? addSingleScreenshotText(docArgs_1, 0, doc.splitTextToSize(docArgs_1.image.name, docArgs_1.maxPageWidth), currentY_1, "bold", void 0, doc) : AsyncResult_ofSuccess(currentY_1), (_arg1) => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, addImage(_arg1, docArgs_1.image, doc), (_arg2) => {
            let previousFontSize, splitText_1;
            const currentY_4 = _arg2 + ((Pdf_Spacing_narrowSpacingY + Pdf_Spacing_baseSpacingY) / 2);
            return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, (docArgs_1.image.description.length > 0) ? ((previousFontSize = doc.getFontSize(), (void doc.setFontSize(10), (splitText_1 = doc.splitTextToSize(`${docArgs_1.image.sessionName} | ${docArgs_1.image.locationDescription}`, docArgs_1.maxPageWidth - Pdf_Spacing_leftMargin), (void doc.setFontSize(previousFontSize), addSingleScreenshotCaption(docArgs_1, 0, splitText_1, currentY_4, "italic", 10, doc)))))) : AsyncResult_ofSuccess(currentY_4), (_arg3) => {
                const currentY_5 = _arg3;
                return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, (docArgs_1.image.description.length > 0) ? addSingleScreenshotText(docArgs_1, 0, doc.splitTextToSize(docArgs_1.image.description, docArgs_1.maxPageWidth), currentY_5, "normal", void 0, doc) : AsyncResult_ofSuccess(currentY_5), (_arg4) => {
                    const i_1 = (i + 1) | 0;
                    return (i_1 < images.length) ? AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, addAllScreenshots(docArgs_1, i_1, _arg4, images, doc), () => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult)) : AsyncResultComputationExpression_AsyncResultBuilder__Zero(AsyncResultComputationExpression_asyncResult);
                });
            });
        }));
    });
}

export function addImages(images, startY, doc) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => {
        if (images.length > 0) {
            const images_1 = sortBy((image) => image.date, images, {
                Compare: compare,
            });
            doc.addPage();
            Pdf_Table_Adv_makeTitle("Captured Images", Pdf_Spacing_topMargin, doc);
            const pageSize = doc.internal.pageSize;
            const pageHeight = pageSize.getHeight();
            const maxWidth = pageSize.getWidth() - (2 * Pdf_Spacing_leftMargin);
            const maxHeight = pageHeight - Pdf_Spacing_topMargin;
            const image_1 = images_1[0];
            return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AsyncResult_ofAsync(awaitPromise(PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetch$(value_6(image_1.imageUrl), empty()).then((_arg1) => (_arg1.arrayBuffer()))))))), (_arg2) => {
                const fileArray = _arg2;
                const processed = doc.processPNG(fileArray, 0, `${image_1.name}
${image_1.locationDescription}
${image_1.description}`, "NONE");
                const patternInput = getScaledImageDimensions(fileArray, maxWidth, Pdf_Images_Basic_pixelsToMm(processed.height), doc);
                return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, addAllScreenshots(new AddToDocArgs(images_1[0], maxHeight, maxWidth, patternInput[1], Pdf_Spacing_topMargin, Pdf_Spacing_narrowSpacingY), 0, startY, images_1, doc), () => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult));
            });
        }
        else {
            return AsyncResultComputationExpression_AsyncResultBuilder__Zero(AsyncResultComputationExpression_asyncResult);
        }
    });
}

export function create(doc, s3context, args) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, getScreenshots(s3context, args), (_arg1) => {
        const previousFont = doc.getFontSize();
        doc.setFontSize(12);
        return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, addImages(_arg1, Pdf_Spacing_topMargin + Pdf_Spacing_baseSpacingY, doc), () => {
            doc.setFontSize(previousFont);
            return AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, doc);
        });
    }));
}

