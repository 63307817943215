import { Union, Record } from "../fable_modules/fable-library.3.7.12/Types.js";
import { enum_type, array_type, list_type, lambda_type, union_type, unit_type, tuple_type, int32_type, class_type, record_type, option_type, string_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { SessionViewModel$reflection } from "../Optiscan.Models/View/Session.js";
import { ErrorMessage$reflection } from "../Optiscan.Models/ErrorMessage.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.12/Choice.js";
import { AnatomicalRegionViewModel$reflection } from "../Optiscan.Models/View/AnatomicalRegion.js";
import { WebRTCConnectionPermissionRequest$reflection } from "../Optiscan.Models/Security.js";
import { ControlMessage$reflection } from "./TelepathologyBindings.js";

export class TelepathologyDependency extends Record {
    constructor(deviceId, region, maybeActiveSession) {
        super();
        this.deviceId = deviceId;
        this.region = region;
        this.maybeActiveSession = maybeActiveSession;
    }
}

export function TelepathologyDependency$reflection() {
    return record_type("Client.Telepathology.Types.TelepathologyDependency", [], TelepathologyDependency, () => [["deviceId", string_type], ["region", option_type(string_type)], ["maybeActiveSession", option_type(SessionViewModel$reflection())]]);
}

export class TelepathologyApi extends Record {
    constructor(flagSessionImage, getCurrentAnatomicalRegion, getCurrentActiveSessionForDevice, generateWebRTCPermissionToken, connectionFailedTelemetry) {
        super();
        this.flagSessionImage = flagSessionImage;
        this.getCurrentAnatomicalRegion = getCurrentAnatomicalRegion;
        this.getCurrentActiveSessionForDevice = getCurrentActiveSessionForDevice;
        this.generateWebRTCPermissionToken = generateWebRTCPermissionToken;
        this.connectionFailedTelemetry = connectionFailedTelemetry;
    }
}

export function TelepathologyApi$reflection() {
    return record_type("Client.Telepathology.Types.TelepathologyApi", [], TelepathologyApi, () => [["flagSessionImage", lambda_type(tuple_type(class_type("System.Guid"), int32_type), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ErrorMessage$reflection()]]])]))], ["getCurrentAnatomicalRegion", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(AnatomicalRegionViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", option_type(AnatomicalRegionViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]))], ["getCurrentActiveSessionForDevice", lambda_type(string_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(SessionViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", option_type(SessionViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]))], ["generateWebRTCPermissionToken", lambda_type(WebRTCConnectionPermissionRequest$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", ErrorMessage$reflection()]]])]))], ["connectionFailedTelemetry", lambda_type(option_type(SessionViewModel$reflection()), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, unit_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", unit_type]]])]))]]);
}

export class StreamStatus extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Connecting", "Connected", "Paused", "Stopped", "Failed"];
    }
}

export function StreamStatus$reflection() {
    return union_type("Client.Telepathology.Types.StreamStatus", [], StreamStatus, () => [[], [], [], [], []]);
}

export class WebRTCState extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["IceCandidate", "Signaling", "PeerConnection"];
    }
}

export function WebRTCState$reflection() {
    return union_type("Client.Telepathology.Types.WebRTCState", [], WebRTCState, () => [[], [], []]);
}

export class TelepathologyModel extends Record {
    constructor(deviceId, initialSessionId, session, sessionRegion, videoClient, signalingChannelsClient, signalingClient, channelARN, endpoints, iceServers, peerConnection, anatomicalRegion, streamStatus, streamStatusCancellationTokenSource, streamTimeoutCancellationTokenSource, lastKnownSessionState, authenticationDataChannel, dataChannels) {
        super();
        this.deviceId = deviceId;
        this.initialSessionId = initialSessionId;
        this.session = session;
        this.sessionRegion = sessionRegion;
        this.videoClient = videoClient;
        this.signalingChannelsClient = signalingChannelsClient;
        this.signalingClient = signalingClient;
        this.channelARN = channelARN;
        this.endpoints = endpoints;
        this.iceServers = iceServers;
        this.peerConnection = peerConnection;
        this.anatomicalRegion = anatomicalRegion;
        this.streamStatus = streamStatus;
        this.streamStatusCancellationTokenSource = streamStatusCancellationTokenSource;
        this.streamTimeoutCancellationTokenSource = streamTimeoutCancellationTokenSource;
        this.lastKnownSessionState = (lastKnownSessionState | 0);
        this.authenticationDataChannel = authenticationDataChannel;
        this.dataChannels = dataChannels;
    }
}

export function TelepathologyModel$reflection() {
    return record_type("Client.Telepathology.Types.TelepathologyModel", [], TelepathologyModel, () => [["deviceId", string_type], ["initialSessionId", option_type(class_type("System.Guid"))], ["session", option_type(SessionViewModel$reflection())], ["sessionRegion", string_type], ["videoClient", option_type(class_type("Fable.Import.Aws.KinesisVideo.KinesisVideo"))], ["signalingChannelsClient", option_type(class_type("Fable.Import.Aws.KinesisVideoSignalingChannels.KinesisVideoSignalingChannels"))], ["signalingClient", option_type(class_type("Fable.Import.Aws.SignalingClient.SignalingClient"))], ["channelARN", string_type], ["endpoints", list_type(class_type("Fable.Import.Aws.KinesisVideo.ResourceEndpointListItem"))], ["iceServers", array_type(class_type("Browser.Types.RTCIceServer"))], ["peerConnection", option_type(class_type("Browser.Types.RTCPeerConnection", void 0, RTCPeerConnection))], ["anatomicalRegion", option_type(AnatomicalRegionViewModel$reflection())], ["streamStatus", StreamStatus$reflection()], ["streamStatusCancellationTokenSource", option_type(class_type("System.Threading.CancellationTokenSource"))], ["streamTimeoutCancellationTokenSource", class_type("System.Threading.CancellationTokenSource")], ["lastKnownSessionState", enum_type("Optiscan.Models.Shared.SessionState", int32_type, [["Created", 0], ["Active", 1], ["Completed", 2], ["Interrupted", 3], ["Paused", 4]])], ["authenticationDataChannel", option_type(class_type("Browser.Types.RTCDataChannel", void 0, RTCDataChannel))], ["dataChannels", list_type(class_type("Browser.Types.RTCDataChannel", void 0, RTCDataChannel))]]);
}

export class TelepathologyMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExnErrorMsg", "WebRTCExnErrorMsg", "Initialize", "StartViewer", "GotDescribeSignalingChannelOutput", "GotSignalingChannelEndpointOutput", "GotIceServerConfig", "GetAuthenticationToken", "GotAuthenticationToken", "FlagImage", "GotControlMessage", "GotActiveSession", "GotAnatomicalRegion", "StartUpdateStreamStatus", "UpdateStreamStatus", "CancelUpdateStreamStatus", "StreamErrorMsg", "StartConnectionTimeout", "ConnectionTimeoutFinished", "HandlePeerConnectionDataChannel", "HandlePeerConnectionStateChange", "HandleIceCandidateConnectionStateChange", "ConnectionFailedTelemetry", "Nothing"];
    }
}

export function TelepathologyMsg$reflection() {
    return union_type("Client.Telepathology.Types.TelepathologyMsg", [], TelepathologyMsg, () => [[["Item", class_type("System.Exception")]], [["Item", class_type("System.Exception")]], [], [], [["Item", class_type("Fable.Import.Aws.KinesisVideo.DescribeSignalingChannelOutput")]], [["Item", class_type("Fable.Import.Aws.KinesisVideo.GetSignalingChannelEndpointOutput")]], [["Item", class_type("Fable.Import.Aws.KinesisVideoSignalingChannels.GetIceServerConfigResponse")]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["frameNumber", int32_type]], [["Item", ControlMessage$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(SessionViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", option_type(SessionViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(AnatomicalRegionViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", option_type(AnatomicalRegionViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["Item", StreamStatus$reflection()]], [["Item", StreamStatus$reflection()]], [], [["Item", string_type]], [], [["Item", class_type("System.Threading.CancellationToken")]], [["Item", class_type("Browser.Types.RTCDataChannel", void 0, RTCDataChannel)]], [["Item", string_type]], [["Item", string_type]], [], []]);
}

