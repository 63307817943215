import { Record, Union } from "./fable_modules/fable-library.3.7.12/Types.js";
import { record_type, option_type, union_type, bool_type, class_type } from "./fable_modules/fable-library.3.7.12/Reflection.js";
import { Page$reflection } from "./Pages.js";
import { Model$reflection as Model$reflection_2, Msg$reflection as Msg$reflection_1 } from "./Auth/AuthTypes.js";
import { Model$reflection as Model$reflection_3, Msg$reflection as Msg$reflection_2 } from "./TelepathologyGuest/TelepathologyGuestTypes.js";
import { Model$reflection as Model$reflection_5, Msg$reflection as Msg$reflection_3 } from "./Accounts/AccountsTypes.js";
import { Model$reflection as Model$reflection_6, Msg$reflection as Msg$reflection_4 } from "./Sites/SitesTypes.js";
import { Model$reflection as Model$reflection_7, Msg$reflection as Msg$reflection_5 } from "./AuthorizedSites/AuthorizedSitesTypes.js";
import { Model$reflection as Model$reflection_4, Msg$reflection as Msg$reflection_6 } from "./Users/UsersTypes.js";
import { Model$reflection as Model$reflection_8, Msg$reflection as Msg$reflection_7 } from "./Devices/DevicesTypes.js";
import { Model$reflection as Model$reflection_9, Msg$reflection as Msg$reflection_8 } from "./Patients/PatientSearchTypes.js";
import { Model$reflection as Model$reflection_10, Msg$reflection as Msg$reflection_9 } from "./ExVivo/ExVivoTypes.js";
import { Model$reflection as Model$reflection_1, Msg$reflection as Msg$reflection_10 } from "./Notifications/Types.js";
import { Model$reflection as Model$reflection_11, Msg$reflection as Msg$reflection_11 } from "./UserProfile/UserProfileTypes.js";
import { Model$reflection as Model$reflection_12, Msg$reflection as Msg$reflection_12 } from "./Dashboard/DashboardTypes.js";
import { DeveloperIdentity$reflection, S3Configuration$reflection } from "./Optiscan.Models/Api/Api.js";
import { ErrorMessage$reflection } from "./Optiscan.Models/ErrorMessage.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.12/Choice.js";
import { S3Context$reflection } from "./Common/AwsCommon.js";

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExceptionError", "Navigate", "AuthMsg", "TelepathologyGuestMsg", "AccountMsg", "SiteMsg", "AuthorizedSiteMsg", "UsersMsg", "DeviceMsg", "PatientSearchMsg", "ExVivoMsg", "NotificationsMsg", "UserProfileMsg", "DashboardMsg", "MenuToggled", "GetS3Configuration", "GotS3Configuration", "CreateIdentityResult", "SetCredentialExpiration"];
    }
}

export function Msg$reflection() {
    return union_type("Client.Types.Msg", [], Msg, () => [[["Item", class_type("System.Exception")]], [["Item", Page$reflection()]], [["Item", Msg$reflection_1()]], [["Item", Msg$reflection_2()]], [["Item", Msg$reflection_3()]], [["Item", Msg$reflection_4()]], [["Item", Msg$reflection_5()]], [["Item", Msg$reflection_6()]], [["Item", Msg$reflection_7()]], [["Item", Msg$reflection_8()]], [["Item", Msg$reflection_9()]], [["Item", Msg$reflection_10()]], [["Item", Msg$reflection_11()]], [["Item", Msg$reflection_12()]], [["menuIsExpanded", bool_type]], [], [["Item", S3Configuration$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [DeveloperIdentity$reflection(), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", DeveloperIdentity$reflection()]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["Item1", class_type("Fable.Import.Aws.CognitoCredentials.CognitoIdentityCredentials")], ["Item2", S3Context$reflection()]]]);
}

export class Model extends Record {
    constructor(NotificationsModel, CurrentPage, RedirectPage, AuthModel, TelepathologyGuestModel, UsersModel, AccountsModel, SitesModel, AuthorizedSitesModel, DevicesModel, PatientSearchModel, ExVivoModel, UserProfileModel, DashboardModel, MenuIsDefaultCollapsed, MenuIsExpanded, S3Context, AwsToken, S3Configuration, Credentials) {
        super();
        this.NotificationsModel = NotificationsModel;
        this.CurrentPage = CurrentPage;
        this.RedirectPage = RedirectPage;
        this.AuthModel = AuthModel;
        this.TelepathologyGuestModel = TelepathologyGuestModel;
        this.UsersModel = UsersModel;
        this.AccountsModel = AccountsModel;
        this.SitesModel = SitesModel;
        this.AuthorizedSitesModel = AuthorizedSitesModel;
        this.DevicesModel = DevicesModel;
        this.PatientSearchModel = PatientSearchModel;
        this.ExVivoModel = ExVivoModel;
        this.UserProfileModel = UserProfileModel;
        this.DashboardModel = DashboardModel;
        this.MenuIsDefaultCollapsed = MenuIsDefaultCollapsed;
        this.MenuIsExpanded = MenuIsExpanded;
        this.S3Context = S3Context;
        this.AwsToken = AwsToken;
        this.S3Configuration = S3Configuration;
        this.Credentials = Credentials;
    }
}

export function Model$reflection() {
    return record_type("Client.Types.Model", [], Model, () => [["NotificationsModel", Model$reflection_1()], ["CurrentPage", Page$reflection()], ["RedirectPage", option_type(Page$reflection())], ["AuthModel", Model$reflection_2()], ["TelepathologyGuestModel", Model$reflection_3()], ["UsersModel", Model$reflection_4()], ["AccountsModel", Model$reflection_5()], ["SitesModel", Model$reflection_6()], ["AuthorizedSitesModel", Model$reflection_7()], ["DevicesModel", Model$reflection_8()], ["PatientSearchModel", Model$reflection_9()], ["ExVivoModel", Model$reflection_10()], ["UserProfileModel", Model$reflection_11()], ["DashboardModel", Model$reflection_12()], ["MenuIsDefaultCollapsed", bool_type], ["MenuIsExpanded", bool_type], ["S3Context", option_type(S3Context$reflection())], ["AwsToken", option_type(DeveloperIdentity$reflection())], ["S3Configuration", option_type(S3Configuration$reflection())], ["Credentials", option_type(class_type("Fable.Import.Aws.Credentials.Credentials"))]]);
}

