import { Record, Union } from "../fable_modules/fable-library.3.7.12/Types.js";
import { bool_type, list_type, record_type, tuple_type, option_type, unit_type, union_type, string_type, class_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { TelepathologyMsg$reflection } from "../TelepathologyCommon/TelepathologyTypes.js";
import { KvsConfiguration$reflection, Notification$reflection } from "../Optiscan.Models/Api/Api.js";
import { CrudEditMsg$2$reflection, CrudListModification$reflection, CrudListInboundMsg$reflection } from "../Optiscan.SharedUI/Crud.js";
import { StateTree$3$reflection, ErasedModel$reflection, ErasedMsg$2$reflection } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { OptiscanUserData$reflection, UserRole$reflection } from "../Optiscan.Models/Security.js";
import { Security_UserInfo$2$reflection } from "../fable_modules/Webbler.Models.1.2.6/Api.fs.js";
import { DeviceViewModel_get_accountId_, DeviceViewModel_get_siteId_, DeviceViewModel$reflection } from "../Optiscan.Models/View/Device.js";
import { ListDisplayModification_ListDisplayModification$2$reflection, Sort_SortInfo$1$reflection } from "../Optiscan.SharedUI/Sort.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.12/Choice.js";
import { AccountViewModel$reflection } from "../Optiscan.Models/View/Account.js";
import { SiteViewModel$reflection } from "../Optiscan.Models/View/Site.js";
import { Compose_Lens, Compose_Lens_op_GreaterMinusGreater_31E453EA } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { ErrorMessage$reflection } from "../Optiscan.Models/ErrorMessage.js";

export class ExVivoTelepathologyMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExnErrorMsg", "TelepathologyMsg", "StopViewer"];
    }
}

export function ExVivoTelepathologyMsg$reflection() {
    return union_type("Client.ExVivo.Types.ExVivoTelepathologyMsg", [], ExVivoTelepathologyMsg, () => [[["Item", class_type("System.Exception")]], [["Item", TelepathologyMsg$reflection()]], [["targetState", string_type]]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExceptionError", "Authenticated", "Notification", "CrudTreeMessage"];
    }
}

export function Msg$reflection() {
    return union_type("Client.ExVivo.Types.Msg", [], Msg, () => [[["Item", class_type("System.Exception")]], [], [["Item", Notification$reflection()]], [["Item", ErasedMsg$2$reflection(CrudListInboundMsg$reflection(), unit_type)]]]);
}

export class Model extends Record {
    constructor(crudListModel, stateTree) {
        super();
        this.crudListModel = crudListModel;
        this.stateTree = stateTree;
    }
}

export function Model$reflection() {
    return record_type("Client.ExVivo.Types.Model", [], Model, () => [["crudListModel", ErasedModel$reflection()], ["stateTree", option_type(StateTree$3$reflection(CrudListInboundMsg$reflection(), unit_type, tuple_type(string_type, Security_UserInfo$2$reflection(UserRole$reflection(), OptiscanUserData$reflection()), option_type(class_type("Fable.Import.Aws.Credentials.Credentials")), option_type(KvsConfiguration$reflection()))))]]);
}

export class DeviceListModel extends Record {
    constructor(items, maybeSelected, sortInfo, maybeSearchInfo, listModifications, maybeRedirectDeviceId) {
        super();
        this.items = items;
        this.maybeSelected = maybeSelected;
        this.sortInfo = sortInfo;
        this.maybeSearchInfo = maybeSearchInfo;
        this.listModifications = listModifications;
        this.maybeRedirectDeviceId = maybeRedirectDeviceId;
    }
}

export function DeviceListModel$reflection() {
    return record_type("Client.ExVivo.Types.DeviceListModel", [], DeviceListModel, () => [["items", list_type(DeviceViewModel$reflection())], ["maybeSelected", option_type(DeviceViewModel$reflection())], ["sortInfo", Sort_SortInfo$1$reflection(DeviceViewModel$reflection())], ["maybeSearchInfo", option_type(string_type)], ["listModifications", list_type(ListDisplayModification_ListDisplayModification$2$reflection(DeviceViewModel$reflection(), CrudListModification$reflection()))], ["maybeRedirectDeviceId", option_type(string_type)]]);
}

export class DeviceListMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GetItems", "GetItemsResult", "TrySelectDeviceById", "SelectItem", "UpdateSortInfo", "UpdateSearchInfo", "TriggerSearch"];
    }
}

export function DeviceListMsg$reflection() {
    return union_type("Client.ExVivo.Types.DeviceListMsg", [], DeviceListMsg, () => [[], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(DeviceViewModel$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(DeviceViewModel$reflection())]], [["ErrorValue", string_type]]])]], [["deviceId", string_type]], [["item", DeviceViewModel$reflection()]], [["sortInfo", Sort_SortInfo$1$reflection(DeviceViewModel$reflection())]], [["maybeSearchInfo", option_type(string_type)]], [["maybeSearchInfo", option_type(string_type)]]]);
}

export class DeviceEditModel extends Record {
    constructor(device, loadingAccounts, accounts, loadingSites, sites) {
        super();
        this.device = device;
        this.loadingAccounts = loadingAccounts;
        this.accounts = accounts;
        this.loadingSites = loadingSites;
        this.sites = sites;
    }
}

export function DeviceEditModel$reflection() {
    return record_type("Client.ExVivo.Types.DeviceEditModel", [], DeviceEditModel, () => [["device", DeviceViewModel$reflection()], ["loadingAccounts", bool_type], ["accounts", list_type(AccountViewModel$reflection())], ["loadingSites", bool_type], ["sites", list_type(SiteViewModel$reflection())]]);
}

export function DeviceEditModel_get_device_() {
    return [(model) => model.device, (device) => ((model_1) => (new DeviceEditModel(device, model_1.loadingAccounts, model_1.accounts, model_1.loadingSites, model_1.sites)))];
}

export function DeviceEditModel_get_siteId_() {
    const l = DeviceEditModel_get_device_();
    return Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), DeviceViewModel_get_siteId_())(l);
}

export function DeviceEditModel_get_accountId_() {
    const l = DeviceEditModel_get_device_();
    return Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), DeviceViewModel_get_accountId_())(l);
}

export class DeviceEditMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CrudEditMsg", "Initialize", "SetAccount", "GetAccountsResult", "GetSitesResult"];
    }
}

export function DeviceEditMsg$reflection() {
    return union_type("Client.ExVivo.Types.DeviceEditMsg", [], DeviceEditMsg, () => [[["Item", CrudEditMsg$2$reflection(DeviceEditModel$reflection(), ErrorMessage$reflection())]], [], [["maybeAccount", option_type(AccountViewModel$reflection())]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(AccountViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(AccountViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(SiteViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(SiteViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]]]);
}

