import { Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { record_type, int32_type, string_type, class_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";

export class ReportInfoViewModel extends Record {
    constructor(reportId, patientId, sessionId, dateCreated, s3Key, revision, name) {
        super();
        this.reportId = reportId;
        this.patientId = patientId;
        this.sessionId = sessionId;
        this.dateCreated = dateCreated;
        this.s3Key = s3Key;
        this.revision = (revision | 0);
        this.name = name;
    }
}

export function ReportInfoViewModel$reflection() {
    return record_type("Optiscan.Models.View.ReportInfo.ReportInfoViewModel", [], ReportInfoViewModel, () => [["reportId", class_type("System.Guid")], ["patientId", class_type("System.Guid")], ["sessionId", class_type("System.Guid")], ["dateCreated", class_type("System.DateTimeOffset")], ["s3Key", string_type], ["revision", int32_type], ["name", string_type]]);
}

export class ReportInfoFullViewModel extends Record {
    constructor(reportId, patientId, sessionId, dateCreated, s3Key, name, accountName, revision, siteName) {
        super();
        this.reportId = reportId;
        this.patientId = patientId;
        this.sessionId = sessionId;
        this.dateCreated = dateCreated;
        this.s3Key = s3Key;
        this.name = name;
        this.accountName = accountName;
        this.revision = (revision | 0);
        this.siteName = siteName;
    }
}

export function ReportInfoFullViewModel$reflection() {
    return record_type("Optiscan.Models.View.ReportInfo.ReportInfoFullViewModel", [], ReportInfoFullViewModel, () => [["reportId", class_type("System.Guid")], ["patientId", class_type("System.Guid")], ["sessionId", class_type("System.Guid")], ["dateCreated", class_type("System.DateTimeOffset")], ["s3Key", string_type], ["name", string_type], ["accountName", string_type], ["revision", int32_type], ["siteName", string_type]]);
}

export function ReportInfoFullViewModel_toReportInfoViewModel_32AF7BD8(rifvm) {
    return new ReportInfoViewModel(rifvm.reportId, rifvm.patientId, rifvm.sessionId, rifvm.dateCreated, rifvm.s3Key, rifvm.revision, rifvm.name);
}

