import { Record } from "../../../fable_modules/fable-library.3.7.12/Types.js";
import { create as create_2, Args$reflection } from "./AnnotationsSection.js";
import { create as create_3, Args$reflection as Args$reflection_1 } from "./NotesSection.js";
import { create, Args$reflection as Args$reflection_2 } from "./AssessmentsSection.js";
import { create as create_1, Args$reflection as Args$reflection_3 } from "./ScreenshotsSection.js";
import { S3Context$reflection } from "../../../Common/AwsCommon.js";
import { record_type } from "../../../fable_modules/fable-library.3.7.12/Reflection.js";
import { AsyncResultComputationExpression_asyncResult, AsyncResultComputationExpression_AsyncResultBuilder__Return_1505, AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93, AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B } from "../../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { Pdf_Doc_create } from "../PatientReportHelpers.js";

export class ReportArgs extends Record {
    constructor(annotationsArgs, notesArgs, assessmentsArgs, screenshotsArgs, s3context) {
        super();
        this.annotationsArgs = annotationsArgs;
        this.notesArgs = notesArgs;
        this.assessmentsArgs = assessmentsArgs;
        this.screenshotsArgs = screenshotsArgs;
        this.s3context = s3context;
    }
}

export function ReportArgs$reflection() {
    return record_type("Client.Patients.PatientReport.Sections.ReportSections.ReportArgs", [], ReportArgs, () => [["annotationsArgs", Args$reflection()], ["notesArgs", Args$reflection_1()], ["assessmentsArgs", Args$reflection_2()], ["screenshotsArgs", Args$reflection_3()], ["s3context", S3Context$reflection()]]);
}

export function mkReportSections(args) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => {
        const doc = Pdf_Doc_create();
        doc.setFont("times");
        doc.deletePage(1);
        return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, create(doc, args.assessmentsArgs), (_arg1) => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, create_1(_arg1, args.s3context, args.screenshotsArgs), (_arg2) => {
            const screenshotsSection = _arg2;
            screenshotsSection.addPage();
            return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, create_2(screenshotsSection, args.annotationsArgs), (_arg3) => {
                const annotationsSection = _arg3;
                annotationsSection.addPage();
                return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, create_3(annotationsSection, args.notesArgs), (_arg4) => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, _arg4));
            });
        }));
    });
}

