import { Union, Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { bool_type, union_type, list_type, class_type, record_type, option_type, string_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";
import { FSharpMap__get_Item, FSharpMap__get_Keys, toList, map, empty } from "../../fable_modules/fable-library.3.7.12/Map.js";
import { minValue } from "../../fable_modules/fable-library.3.7.12/DateOffset.js";
import { value as value_4, defaultArg } from "../../fable_modules/fable-library.3.7.12/Option.js";
import { exactlyOne, head, length, map as map_1, collect, tryPick } from "../../fable_modules/fable-library.3.7.12/List.js";
import { List_distinct } from "../../fable_modules/fable-library.3.7.12/Seq2.js";
import { count, stringHash } from "../../fable_modules/fable-library.3.7.12/Util.js";
import { singleton, append, delay, toList as toList_1 } from "../../fable_modules/fable-library.3.7.12/Seq.js";
import { fromDateTimeOffset } from "../../fable_modules/fable-library.3.7.12/Date.js";

export class ScreenshotDetailViewModel extends Record {
    constructor(description, locationDescription, s3Key, dataUrl) {
        super();
        this.description = description;
        this.locationDescription = locationDescription;
        this.s3Key = s3Key;
        this.dataUrl = dataUrl;
    }
}

export function ScreenshotDetailViewModel$reflection() {
    return record_type("Optiscan.Models.View.ScreenshotInfoViewModel.ScreenshotDetailViewModel", [], ScreenshotDetailViewModel, () => [["description", string_type], ["locationDescription", string_type], ["s3Key", string_type], ["dataUrl", option_type(string_type)]]);
}

export class ScreenshotInfoViewModel extends Record {
    constructor(screenshotId, sessionScreenshotDetails, name, dateCreated) {
        super();
        this.screenshotId = screenshotId;
        this.sessionScreenshotDetails = sessionScreenshotDetails;
        this.name = name;
        this.dateCreated = dateCreated;
    }
}

export function ScreenshotInfoViewModel$reflection() {
    return record_type("Optiscan.Models.View.ScreenshotInfoViewModel.ScreenshotInfoViewModel", [], ScreenshotInfoViewModel, () => [["screenshotId", option_type(class_type("System.Guid"))], ["sessionScreenshotDetails", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [class_type("System.Guid"), list_type(ScreenshotDetailViewModel$reflection())])], ["name", option_type(string_type)], ["dateCreated", class_type("System.DateTimeOffset")]]);
}

export function ScreenshotInfoViewModel_get_empty() {
    return new ScreenshotInfoViewModel(void 0, empty(), void 0, minValue());
}

export function ScreenshotInfoViewModel_get_name_() {
    return [(m) => m.name, (v) => ((m_1) => (new ScreenshotInfoViewModel(m_1.screenshotId, m_1.sessionScreenshotDetails, v, m_1.dateCreated)))];
}

export class ScreenshotListItemRole extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Parent", "Child", "Single"];
    }
}

export function ScreenshotListItemRole$reflection() {
    return union_type("Optiscan.Models.View.ScreenshotInfoViewModel.ScreenshotListItemRole", [], ScreenshotListItemRole, () => [[["childrenS3Keys", list_type(string_type)]], [["sessionId", class_type("System.Guid")], ["s3Key", string_type]], [["sessionId", class_type("System.Guid")], ["s3Key", string_type]]]);
}

export class ScreenshotListItemViewModel extends Record {
    constructor(screenshotId, sessionName, name, date, description, locationDescription, role, selected, imageUrl) {
        super();
        this.screenshotId = screenshotId;
        this.sessionName = sessionName;
        this.name = name;
        this.date = date;
        this.description = description;
        this.locationDescription = locationDescription;
        this.role = role;
        this.selected = selected;
        this.imageUrl = imageUrl;
    }
}

export function ScreenshotListItemViewModel$reflection() {
    return record_type("Optiscan.Models.View.ScreenshotInfoViewModel.ScreenshotListItemViewModel", [], ScreenshotListItemViewModel, () => [["screenshotId", class_type("System.Guid")], ["sessionName", string_type], ["name", string_type], ["date", class_type("System.DateTime")], ["description", string_type], ["locationDescription", string_type], ["role", ScreenshotListItemRole$reflection()], ["selected", bool_type], ["imageUrl", option_type(string_type)]]);
}

export function ScreenshotListItemViewModel__get_isParent(this$) {
    if (this$.role.tag === 0) {
        return true;
    }
    else {
        return false;
    }
}

export function ScreenshotListItemViewModel_ofScreenshotInfoViewModel(sessions, vm) {
    const sessionNameMap = map((sessionId_1, _arg1) => defaultArg(tryPick((s) => {
        if (s.sessionId === sessionId_1) {
            return s.sessionName;
        }
        else {
            return void 0;
        }
    }, sessions), ""), vm.sessionScreenshotDetails);
    const sessionScreenshotDetails = collect((tupledArg) => map_1((detail) => [tupledArg[0], detail], tupledArg[1]), toList(vm.sessionScreenshotDetails));
    const distinctLocationDescriptions = List_distinct(map_1((tupledArg_1) => tupledArg_1[1].locationDescription, sessionScreenshotDetails), {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    });
    const hasMultipleLocations = length(distinctLocationDescriptions) > 1;
    const hasMultipleSessions = count(FSharpMap__get_Keys(vm.sessionScreenshotDetails)) > 1;
    const s3Keys = map_1((tupledArg_2) => tupledArg_2[1].s3Key, sessionScreenshotDetails);
    const isParent = length(s3Keys) > 1;
    return toList_1(delay(() => {
        let tupledArg_4;
        return isParent ? append(singleton(new ScreenshotListItemViewModel(value_4(vm.screenshotId), hasMultipleSessions ? "Multiple" : FSharpMap__get_Item(sessionNameMap, head(sessionScreenshotDetails)[0]), defaultArg(vm.name, ""), fromDateTimeOffset(vm.dateCreated, 0), "", hasMultipleLocations ? "Multiple" : head(distinctLocationDescriptions), new ScreenshotListItemRole(0, s3Keys), false, void 0)), delay(() => map_1((tupledArg_3) => {
            const sessionId_3 = tupledArg_3[0];
            const detail_3 = tupledArg_3[1];
            return new ScreenshotListItemViewModel(value_4(vm.screenshotId), FSharpMap__get_Item(sessionNameMap, sessionId_3), defaultArg(vm.name, ""), fromDateTimeOffset(vm.dateCreated, 0), detail_3.description, detail_3.locationDescription, new ScreenshotListItemRole(1, sessionId_3, detail_3.s3Key), false, void 0);
        }, sessionScreenshotDetails))) : singleton(new ScreenshotListItemViewModel(value_4(vm.screenshotId), FSharpMap__get_Item(sessionNameMap, head(sessionScreenshotDetails)[0]), defaultArg(vm.name, ""), fromDateTimeOffset(vm.dateCreated, 0), head(sessionScreenshotDetails)[1].description, head(distinctLocationDescriptions), (tupledArg_4 = exactlyOne(map_1((tupledArg_5) => [tupledArg_5[0], tupledArg_5[1].s3Key], sessionScreenshotDetails)), new ScreenshotListItemRole(2, tupledArg_4[0], tupledArg_4[1])), false, void 0));
    }));
}

