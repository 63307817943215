import { Msg, reportTableColumns, Model } from "./DashboardTypes.js";
import { tryParse } from "../fable_modules/fable-library.3.7.12/Guid.js";
import { FSharpRef } from "../fable_modules/fable-library.3.7.12/Types.js";
import { ListDisplayModification_ListDisplayModification$2, Sort_SortInfo$1_getSortComparison_576B9F7E, Sort_SortInfo$1, Sort_SortDirection } from "../Optiscan.SharedUI/Sort.js";
import { CrudListModification } from "../Optiscan.SharedUI/Crud.js";
import { ofArray, append as append_1, map, exists, head, isEmpty, item, empty, singleton, sortWith } from "../fable_modules/fable-library.3.7.12/List.js";
import { uncurry } from "../fable_modules/fable-library.3.7.12/Util.js";
import { PatientIdentidfiableInformation_get_empty, ReportType } from "../Optiscan.Models/Shared.js";
import { Cmd_batch, Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.12/String.js";
import { defaultArg, some } from "../fable_modules/fable-library.3.7.12/Option.js";
import { singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { Cmd_OfAsyncWith_attempt, Cmd_OfFunc_result, Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { securedApi } from "../Api.js";
import { error } from "../Optiscan.SharedUI/Toast.js";
import { ErrorMessage, ErrorMessage_get_describe } from "../Optiscan.Models/ErrorMessage.js";
import { Navigation_newUrl } from "../fable_modules/Fable.Elmish.Browser.3.0.4/navigation.fs.js";
import { Page, pageHash } from "../Pages.js";
import { Reports_mkManagedDownload } from "../Patients/PatientReport/PatientReportHelpers.js";
import { TelemetryAction, FileOperationContext } from "../Optiscan.Models/Api/Api.js";
import { makeAndPrependTitlePage, TitlePageInformation, TitlePagePatientInformation } from "../Patients/PatientReport/Sections/TitlePage.js";
import { AsyncResultComputationExpression_asyncResult, AsyncResultComputationExpression_AsyncResultBuilder__Return_1505, AsyncResult_mapError, AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93, AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B } from "../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { ofPromise } from "../Common/Extensions.js";
import { ByteArrayExtensions_SaveFileAs_72B26259 } from "../fable_modules/Fable.Remoting.Client.7.25.0/Extensions.fs.js";

export function finishOperation(model) {
    return new Model(model.maybePatientId, model.maybeSessionId, model.sites, model.authorizedSites, model.loadingSessions, model.scheduledSessions, model.reports, model.reportsListSortInfo, model.reportsListDisplayModifications, model.reportType, void 0, void 0);
}

export function tryParseGuid(uuid) {
    let matchValue;
    let outArg = "00000000-0000-0000-0000-000000000000";
    matchValue = [tryParse(uuid, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function initialSortInfo(lastColumnClicked) {
    return new Sort_SortInfo$1(lastColumnClicked, new Sort_SortDirection(1));
}

export function initListModifications(lastColumnClicked) {
    let comparer;
    return singleton(new ListDisplayModification_ListDisplayModification$2(new CrudListModification(0), 0, (comparer = Sort_SortInfo$1_getSortComparison_576B9F7E(initialSortInfo(lastColumnClicked)), (list) => sortWith(uncurry(2, comparer), list))));
}

export function init() {
    return [new Model(void 0, void 0, empty(), empty(), true, empty(), empty(), initialSortInfo(item(0, reportTableColumns((value) => {
    }))), initListModifications(item(0, reportTableColumns((value_1) => {
    }))), new ReportType(1), void 0, void 0), Cmd_none()];
}

export function update(token, userInfo, maybeS3Ctx, msg, model) {
    let tupledArg_2, matchValue_1;
    let pattern_matching_result, ex, siteId, sites, siteId_1, authSites, sessions, reports, err, setter, scheduledSession, report, sortInfo, reportInfo, doc, fpath_1, reportInfo_1, err_1, s3key, doc_1, reportInfo_2, doc_2, maybeReportInfoFull, err_2, err_3, state, maybeReportType, setter_1, nextAction, reportInfo_3, nextAction_1;
    if (msg.tag === 1) {
        pattern_matching_result = 1;
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 9) {
            pattern_matching_result = 2;
        }
        else if (msg.fields[0].tag === 10) {
            pattern_matching_result = 3;
            siteId = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 4;
        }
    }
    else if (msg.tag === 5) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 12;
            err = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 5;
            sites = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 6) {
        pattern_matching_result = 6;
        siteId_1 = msg.fields[0];
    }
    else if (msg.tag === 7) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 12;
            err = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 7;
            authSites = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 8) {
        pattern_matching_result = 8;
    }
    else if (msg.tag === 9) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 12;
            err = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 9;
            sessions = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 10) {
        pattern_matching_result = 10;
    }
    else if (msg.tag === 11) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 12;
            err = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 11;
            reports = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 12) {
        pattern_matching_result = 13;
        setter = msg.fields[0];
    }
    else if (msg.tag === 3) {
        pattern_matching_result = 14;
        scheduledSession = msg.fields[0];
    }
    else if (msg.tag === 4) {
        pattern_matching_result = 15;
        report = msg.fields[0];
    }
    else if (msg.tag === 13) {
        pattern_matching_result = 16;
        sortInfo = msg.fields[0];
    }
    else if (msg.tag === 14) {
        pattern_matching_result = 17;
        reportInfo = msg.fields[0];
    }
    else if (msg.tag === 15) {
        if (msg.fields[1].tag === 1) {
            pattern_matching_result = 19;
            err_1 = msg.fields[1].fields[0];
        }
        else {
            pattern_matching_result = 18;
            doc = msg.fields[1].fields[0][0];
            fpath_1 = msg.fields[1].fields[0][1];
            reportInfo_1 = msg.fields[0];
        }
    }
    else if (msg.tag === 16) {
        pattern_matching_result = 20;
        s3key = msg.fields[0];
    }
    else if (msg.tag === 17) {
        pattern_matching_result = 21;
        doc_1 = msg.fields[1];
        reportInfo_2 = msg.fields[0];
    }
    else if (msg.tag === 18) {
        if (msg.fields[1].tag === 1) {
            pattern_matching_result = 23;
            err_2 = msg.fields[1].fields[0];
        }
        else {
            pattern_matching_result = 22;
            doc_2 = msg.fields[0];
            maybeReportInfoFull = msg.fields[1].fields[0];
        }
    }
    else if (msg.tag === 19) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 25;
            err_3 = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 24;
        }
    }
    else if (msg.tag === 20) {
        pattern_matching_result = 26;
        state = msg.fields[0];
    }
    else if (msg.tag === 21) {
        pattern_matching_result = 27;
        maybeReportType = msg.fields[0];
    }
    else if (msg.tag === 22) {
        pattern_matching_result = 28;
        setter_1 = msg.fields[0];
    }
    else if (msg.tag === 23) {
        pattern_matching_result = 29;
        nextAction = msg.fields[1];
        reportInfo_3 = msg.fields[0];
    }
    else if (msg.tag === 24) {
        if (msg.fields[1].tag === 1) {
            pattern_matching_result = 31;
        }
        else {
            pattern_matching_result = 30;
            nextAction_1 = msg.fields[0];
        }
    }
    else {
        pattern_matching_result = 0;
        ex = msg.fields[0];
    }
    switch (pattern_matching_result) {
        case 0: {
            console.error(some(toText(printf("Error: %O"))(ex)));
            return [finishOperation(model), Cmd_none()];
        }
        case 1: {
            return [model, Cmd_batch(toList(delay(() => {
                let matchValue;
                return append((matchValue = userInfo.userData.siteIds, (!isEmpty(matchValue)) ? singleton_1(Cmd_OfAsyncWith_either((x) => {
                    Cmd_OfAsync_start(x);
                }, securedApi(token).getSitesForAccount, head(matchValue).accountId, (arg0) => (new Msg(5, arg0)), (arg0_1) => (new Msg(0, arg0_1)))) : singleton_1(Cmd_none())), delay(() => singleton_1(Cmd_OfFunc_result(new Msg(10)))));
            })))];
        }
        case 2: {
            return [model, Cmd_OfFunc_result(new Msg(10))];
        }
        case 3: {
            if (exists((s) => (s.siteId === siteId), model.sites)) {
                return [model, Cmd_OfFunc_result(new Msg(8))];
            }
            else {
                return [model, Cmd_none()];
            }
        }
        case 4: {
            return [model, Cmd_none()];
        }
        case 5: {
            if (!isEmpty(sites)) {
                return [new Model(model.maybePatientId, model.maybeSessionId, sites, model.authorizedSites, model.loadingSessions, model.scheduledSessions, model.reports, model.reportsListSortInfo, model.reportsListDisplayModifications, model.reportType, model.patientIdentidfiableInformation, model.maybeReportModalState), Cmd_OfFunc_result(new Msg(6, head(sites).siteId))];
            }
            else {
                return [model, error("User Account has no sites")];
            }
        }
        case 6: {
            return [model, Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, securedApi(token).getAllTargetSitesForSite, siteId_1, (arg0_2) => (new Msg(7, arg0_2)), (arg0_3) => (new Msg(0, arg0_3)))];
        }
        case 7: {
            return [new Model(model.maybePatientId, model.maybeSessionId, model.sites, authSites, model.loadingSessions, model.scheduledSessions, model.reports, model.reportsListSortInfo, model.reportsListDisplayModifications, model.reportType, model.patientIdentidfiableInformation, model.maybeReportModalState), Cmd_OfFunc_result(new Msg(8))];
        }
        case 8: {
            const siteIds = map((s_1) => s_1.siteId, append_1(model.sites, model.authorizedSites));
            return [model, Cmd_OfAsyncWith_either((x_2) => {
                Cmd_OfAsync_start(x_2);
            }, securedApi(token).getAllScheduledSessionsForSites, siteIds, (arg0_4) => (new Msg(9, arg0_4)), (arg0_5) => (new Msg(0, arg0_5)))];
        }
        case 9: {
            return [new Model(model.maybePatientId, model.maybeSessionId, model.sites, model.authorizedSites, false, sessions, model.reports, model.reportsListSortInfo, model.reportsListDisplayModifications, model.reportType, model.patientIdentidfiableInformation, model.maybeReportModalState), Cmd_none()];
        }
        case 10: {
            return [model, Cmd_OfAsyncWith_either((x_3) => {
                Cmd_OfAsync_start(x_3);
            }, securedApi(token).getAllMostRecentReportInfoFullByUserId, void 0, (arg0_6) => (new Msg(11, arg0_6)), (arg0_7) => (new Msg(0, arg0_7)))];
        }
        case 11: {
            return [new Model(model.maybePatientId, model.maybeSessionId, model.sites, model.authorizedSites, model.loadingSessions, model.scheduledSessions, reports, model.reportsListSortInfo, model.reportsListDisplayModifications, model.reportType, model.patientIdentidfiableInformation, model.maybeReportModalState), Cmd_none()];
        }
        case 12: {
            return [new Model(model.maybePatientId, model.maybeSessionId, model.sites, model.authorizedSites, false, model.scheduledSessions, model.reports, model.reportsListSortInfo, model.reportsListDisplayModifications, model.reportType, model.patientIdentidfiableInformation, model.maybeReportModalState), error(ErrorMessage_get_describe()(err))];
        }
        case 13: {
            return [setter(model), Cmd_none()];
        }
        case 14: {
            return [finishOperation(model), Navigation_newUrl(pageHash(new Page(6, scheduledSession.patientId, scheduledSession.sessionId)))];
        }
        case 15: {
            return [finishOperation(model), Navigation_newUrl(pageHash(new Page(6, report.patientId, void 0)))];
        }
        case 16: {
            return [new Model(model.maybePatientId, model.maybeSessionId, model.sites, model.authorizedSites, model.loadingSessions, model.scheduledSessions, model.reports, sortInfo, model.reportsListDisplayModifications, model.reportType, model.patientIdentidfiableInformation, model.maybeReportModalState), Cmd_none()];
        }
        case 17: {
            if (maybeS3Ctx == null) {
                return [finishOperation(model), Cmd_none()];
            }
            else {
                return [model, Cmd_OfAsyncWith_either((x_4) => {
                    Cmd_OfAsync_start(x_4);
                }, (tupledArg) => Reports_mkManagedDownload(tupledArg[0], tupledArg[1]), [maybeS3Ctx, reportInfo.s3Key], (b) => ((tupledArg_1) => (new Msg(15, tupledArg_1[0], tupledArg_1[1])))([reportInfo, b]), (arg0_9) => (new Msg(0, arg0_9)))];
            }
        }
        case 18: {
            return [model, Cmd_batch(ofArray([Cmd_OfFunc_result((tupledArg_2 = [reportInfo_1, doc], new Msg(17, tupledArg_2[0], tupledArg_2[1]))), Cmd_OfFunc_result(new Msg(16, fpath_1))]))];
        }
        case 19: {
            return [finishOperation(model), error(ErrorMessage_get_describe()(err_1))];
        }
        case 20: {
            return [model, Cmd_OfAsyncWith_attempt((x_5) => {
                Cmd_OfAsync_start(x_5);
            }, securedApi(token).telemetry, new TelemetryAction(1, new FileOperationContext(2), singleton(s3key)), (arg0_12) => (new Msg(0, arg0_12)))];
        }
        case 21: {
            return [model, Cmd_OfAsyncWith_either((x_6) => {
                Cmd_OfAsync_start(x_6);
            }, securedApi(token).getReportInfoFull, reportInfo_2, (b_2) => ((tupledArg_3) => (new Msg(18, tupledArg_3[0], tupledArg_3[1])))([doc_1, b_2]), (arg0_14) => (new Msg(0, arg0_14)))];
        }
        case 22: {
            if (maybeReportInfoFull == null) {
                return [finishOperation(model), error("Failed to download report: Missing report information")];
            }
            else {
                const reportInfoFull = maybeReportInfoFull;
                const titlePageArgs = new TitlePageInformation((matchValue_1 = [model.reportType, model.patientIdentidfiableInformation], (matchValue_1[0].tag === 0) ? ((matchValue_1[1] != null) ? (new TitlePagePatientInformation(0, matchValue_1[1])) : (new TitlePagePatientInformation(1, reportInfoFull.patientId))) : (new TitlePagePatientInformation(1, reportInfoFull.patientId))), reportInfoFull.dateCreated, reportInfoFull.accountName, reportInfoFull.siteName, reportInfoFull.revision);
                return [model, Cmd_OfAsyncWith_either((x_8) => {
                    Cmd_OfAsync_start(x_8);
                }, () => AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, makeAndPrependTitlePage(titlePageArgs, doc_2), (_arg1_2) => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AsyncResult_mapError((arg0_15) => (new ErrorMessage(2, arg0_15)), ofPromise()(_arg1_2.save())), (_arg2) => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, ByteArrayExtensions_SaveFileAs_72B26259(_arg2, reportInfoFull.name))))), void 0, (arg0_16) => (new Msg(19, arg0_16)), (arg0_17) => (new Msg(0, arg0_17)))];
            }
        }
        case 23: {
            return [finishOperation(model), error(ErrorMessage_get_describe()(err_2))];
        }
        case 24: {
            return [finishOperation(model), Cmd_none()];
        }
        case 25: {
            return [finishOperation(model), error(ErrorMessage_get_describe()(err_3))];
        }
        case 26: {
            return [new Model(model.maybePatientId, model.maybeSessionId, model.sites, model.authorizedSites, model.loadingSessions, model.scheduledSessions, model.reports, model.reportsListSortInfo, model.reportsListDisplayModifications, new ReportType(1), void 0, state), Cmd_none()];
        }
        case 27: {
            return [new Model(model.maybePatientId, model.maybeSessionId, model.sites, model.authorizedSites, model.loadingSessions, model.scheduledSessions, model.reports, model.reportsListSortInfo, model.reportsListDisplayModifications, defaultArg(maybeReportType, new ReportType(1)), model.patientIdentidfiableInformation, model.maybeReportModalState), Cmd_none()];
        }
        case 28: {
            return [new Model(model.maybePatientId, model.maybeSessionId, model.sites, model.authorizedSites, model.loadingSessions, model.scheduledSessions, model.reports, model.reportsListSortInfo, model.reportsListDisplayModifications, model.reportType, setter_1(defaultArg(model.patientIdentidfiableInformation, PatientIdentidfiableInformation_get_empty())), model.maybeReportModalState), Cmd_none()];
        }
        case 29: {
            if (model.reportType.tag === 0) {
                const matchValue_3 = model.patientIdentidfiableInformation;
                if (matchValue_3 == null) {
                    return [model, error("Failed to verify patient info, patient identifiable information is missing")];
                }
                else {
                    const pii_2 = matchValue_3;
                    return [model, Cmd_OfAsyncWith_either((x_9) => {
                        Cmd_OfAsync_start(x_9);
                    }, securedApi(token).verifyPatientIdentifiableInformation, [reportInfo_3.patientId, pii_2], (b_4) => ((tupledArg_4) => (new Msg(24, tupledArg_4[0], tupledArg_4[1])))([nextAction, b_4]), (arg0_20) => (new Msg(0, arg0_20)))];
                }
            }
            else {
                return [model, Cmd_OfFunc_result(nextAction)];
            }
        }
        case 30: {
            return [model, Cmd_OfFunc_result(nextAction_1)];
        }
        case 31: {
            return [model, error("Failed to verify patient info, information does not match patient")];
        }
    }
}

