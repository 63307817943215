import { FormField_onChangeSet, FormField_text, FormField_labeledField } from "../Optiscan.SharedUI/Forms.js";
import * as react from "react";
import { Result_mapError, AsyncResult_map, ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Return_1505, ResultComputationExpression_ResultBuilder__Bind_764BA1D3, ResultComputationExpression_ResultBuilder__Delay_1505, ResultComputationExpression_ResultBuilder__Run_FCFD9EF } from "../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { validateNotNullOrEmpty } from "../fable_modules/Webbler.Models.1.2.6/Validation.fs.js";
import { crudListState, crudListInbound, CrudListMsg$1, CrudDeleteMsg$2, CrudButton_nodeMsg, crudEditUpdate, CrudButton_save, CrudEditMsg$2, CrudButton_cancel, CrudButton_delete, CrudButton_edit, crudModal, crudDetailState } from "../Optiscan.SharedUI/Crud.js";
import { tail, head, isEmpty, cons, ofArray, empty as empty_1, singleton } from "../fable_modules/fable-library.3.7.12/List.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { Permission, isAllowed } from "../Optiscan.Models/Security.js";
import { Internal_BranchMsgChild$2, Internal_BranchModelChild$2, Internal_BranchMsgChildAndSibling$3, Internal_BranchModelChildAndSibling$3, Internal_BranchMsgSibling$2, Internal_BranchModelSibling$2, Internal_StateTreeRoot$6, Internal_StateTreeModel$1, StateTreeConfig$6, defaultConfig, NavigationMsg$1, StateTreeMsg$4, StateTreeNode$6 } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { equals, comparePrimitives, partialApply, uncurry } from "../fable_modules/fable-library.3.7.12/Util.js";
import { SiteViewModel, SiteViewModel_get_empty, SiteViewModel_get_address_, SiteViewModel_get_number_, SiteViewModel_get_name_ } from "../Optiscan.Models/View/Site.js";
import { Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { Functions_flip } from "../fable_modules/Webbler.Models.1.2.6/Common.fs.js";
import { Common_eraseExceptionToError } from "../Common/Common.js";
import { ErrorMessage_get_describe } from "../Optiscan.Models/ErrorMessage.js";
import { securedApi } from "../Api.js";
import { Color_IColor } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { Option } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { Cmd_batch } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { success, error as error_5 } from "../Optiscan.SharedUI/Toast.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.12/Choice.js";
import { Sort_SortableColumn$1, Sort_Compare$1 } from "../Optiscan.SharedUI/Sort.js";
import { Internal_combineChildIndexPathFromKeyPathMap, NavigationMsg$1_destructiveCast_Z33BEA92B, Internal_combineSiblingIndexPathFromKeyPathMap, defaultConfig as defaultConfig_1, StateTree$3_$ctor_4E60E31B } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { singleton as singleton_2 } from "../fable_modules/fable-library.3.7.12/AsyncBuilder.js";
import { Cmd_map, Cmd_batch as Cmd_batch_1, Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { defaultArg, bind, some, map, toArray } from "../fable_modules/fable-library.3.7.12/Option.js";
import { ofList } from "../fable_modules/fable-library.3.7.12/Map.js";

export function detailView(model) {
    const children = [FormField_labeledField("Site", "Id", model.siteId, []), FormField_labeledField("Site", "Account Id", model.accountId, []), FormField_labeledField("Site", "Name", model.name, []), FormField_labeledField("Site", "Number", model.number, []), FormField_labeledField("Site", "Address", model.address, [])];
    return react.createElement(react.Fragment, {}, ...children);
}

export function canSaveEditedSite(site) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, validateNotNullOrEmpty("Site name cannot be empty", site.name), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, validateNotNullOrEmpty("Site number cannot be empty", site.number), () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, validateNotNullOrEmpty("Site address cannot be empty", site.address), () => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result))))));
}

export function siteDetailState(currentUser) {
    const inputRecord = crudDetailState();
    return new StateTreeNode$6(inputRecord.Init, (model, dispatch) => crudModal("Details", dispatch, singleton(detailView(model)), toList(delay(() => append(isAllowed(new Permission(20))(currentUser.roles) ? singleton_1(CrudButton_edit(dispatch)) : empty(), delay(() => append(isAllowed(new Permission(21))(currentUser.roles) ? singleton_1(CrudButton_delete(dispatch)) : empty(), delay(() => singleton_1(CrudButton_cancel(dispatch))))))))), inputRecord.Update);
}

export function siteEditableState(title, initializeSite, saveItem, saveSuccessToastMessage) {
    return new StateTreeNode$6((deps) => [initializeSite(deps), Cmd_OfFunc_result(new StateTreeMsg$4(2))], (model, dispatch) => crudModal(title, dispatch, toList(delay(() => {
        let f, optic;
        const setter = (arg_1) => {
            dispatch(new StateTreeMsg$4(0, new CrudEditMsg$2(0, arg_1)));
        };
        return append(singleton_1(FormField_text(model.name, empty_1(), [], FormField_onChangeSet(setter, uncurry(2, (f = ((optic = SiteViewModel_get_name_(), (value) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic)(value))), (b) => ((a) => Functions_flip(uncurry(2, f), b, a))))), "Site", "Name", [])), delay(() => {
            let f_1, optic_2;
            return append(singleton_1(FormField_text(model.number, empty_1(), [], FormField_onChangeSet(setter, uncurry(2, (f_1 = ((optic_2 = SiteViewModel_get_number_(), (value_2) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic_2)(value_2))), (b_1) => ((a_1) => Functions_flip(uncurry(2, f_1), b_1, a_1))))), "Site", "Number", [])), delay(() => {
                let f_2, optic_4;
                return singleton_1(FormField_text(model.address, empty_1(), [], FormField_onChangeSet(setter, uncurry(2, (f_2 = ((optic_4 = SiteViewModel_get_address_(), (value_4) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic_4)(value_4))), (b_2) => ((a_2) => Functions_flip(uncurry(2, f_2), b_2, a_2))))), "Site", "Address", []));
            }));
        }));
    })), ofArray([CrudButton_save((x) => x, canSaveEditedSite(model), dispatch), CrudButton_cancel(dispatch)])), (msg_2, tupledArg, _arg1, newModel) => crudEditUpdate((x_1) => x_1, partialApply(1, saveItem, [tupledArg[0]]), Common_eraseExceptionToError, saveSuccessToastMessage, ErrorMessage_get_describe(), msg_2, newModel));
}

export const siteEditState = siteEditableState("Edit", (x) => x, uncurry(2, (token) => securedApi(token).editSite), "Account edited.");

export function siteCreateState(accountId) {
    return siteEditableState("Create", (_arg1) => {
        const inputRecord = SiteViewModel_get_empty();
        return new SiteViewModel(inputRecord.siteId, accountId, inputRecord.name, inputRecord.number, inputRecord.address);
    }, (token, model) => AsyncResult_map((value) => {
    }, securedApi(token).createSite(model)), "Account created.");
}

export function siteDeleteState() {
    return new StateTreeNode$6((deps) => [deps, Cmd_OfFunc_result(new StateTreeMsg$4(2))], (model, dispatch) => crudModal("Delete", dispatch, ofArray(["Are you sure you want to delete this?", react.createElement("hr", {}), detailView(model)]), ofArray([CrudButton_nodeMsg(new CrudDeleteMsg$2(0), "Delete", [new Option(0, new Color_IColor(8))], dispatch), CrudButton_cancel(dispatch)])), (msg_2, tupledArg, _arg1, model_1) => ((msg_2.tag === 1) ? ((msg_2.fields[0].tag === 1) ? [model_1, Cmd_batch(ofArray([error_5(ErrorMessage_get_describe()(msg_2.fields[0].fields[0])), Cmd_OfFunc_result(new StateTreeMsg$4(1, msg_2.fields[0].fields[0]))]))] : [model_1, Cmd_batch(ofArray([success("Site Deleted."), Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(3, "list")))]))]) : [model_1, Cmd_OfAsyncWith_either((x) => {
        Cmd_OfAsync_start(x);
    }, securedApi(tupledArg[0]).deleteSite, model_1.siteId, (arg) => (new StateTreeMsg$4(0, new CrudDeleteMsg$2(1, arg))), (arg_3) => (new StateTreeMsg$4(0, new CrudDeleteMsg$2(1, new FSharpResult$2(1, Common_eraseExceptionToError(arg_3))))))]));
}

export function stateTree(currentUser, accountId) {
    let config_8, inputRecord, current_20, keepCachedChildren_3, child_4, siblingBuilder_3, current_10, child, siblingBuilder, current_1_1, config_1, maybeAutoMessage, key_3, current_3, current_5, config_3, maybeAutoMessage_1, key_8, current_9, config_4, current_1_2, config_5, maybeAutoMessage_2, key_12, current_13, current_15, config_7, maybeAutoMessage_3, key_17, current_19, current_23, config_9, maybeAutoMessage_4, key_20, current_22;
    const columns = ofArray([new Sort_SortableColumn$1("Name", (svm) => svm.name, new Sort_Compare$1(0, (svm1, svm2) => comparePrimitives(svm1.name, svm2.name))), new Sort_SortableColumn$1("Address", (svm_1) => svm_1.address, new Sort_Compare$1(0, (svm1_1, svm2_1) => comparePrimitives(svm1_1.address, svm2_1.address)))]);
    return StateTree$3_$ctor_4E60E31B((config_8 = ((inputRecord = defaultConfig(), new StateTreeConfig$6(true, inputRecord.ReInitOnNavigation, new StateTreeMsg$4(0, new CrudListMsg$1(0)), inputRecord.KeepCachedChildren, (msg, arg10$0040, model) => crudListInbound(msg, void 0, model)))), (current_20 = crudListState("Sites", "Site", columns, (tupledArg) => singleton_2.Delay(() => (isAllowed(new Permission(22))(tupledArg[1].roles) ? singleton_2.Bind(securedApi(tupledArg[0]).getSitesForAccount(accountId), (_arg1) => singleton_2.Return(Result_mapError()(ErrorMessage_get_describe())(_arg1))) : singleton_2.Return(new FSharpResult$2(0, empty_1())))), isAllowed(new Permission(19))(currentUser.roles)), (keepCachedChildren_3 = config_8.KeepCachedChildren, (child_4 = ((siblingBuilder_3 = ((current_10 = siteDetailState(currentUser), (child = ((siblingBuilder = ((current_1_1 = ((config_1 = defaultConfig_1(), (maybeAutoMessage = config_1.MaybeSendMessageOnNavigation, (key_3 = "edit", (current_3 = siteEditState, new Internal_StateTreeRoot$6((_arg2, deps, maybeCachedModel, _arg1_1) => {
        const matchValue = [maybeCachedModel, config_1.ReInitOnNavigation, maybeAutoMessage];
        let pattern_matching_result, model_1, model_1_1, msg_1;
        if (matchValue[0] != null) {
            if (matchValue[1]) {
                pattern_matching_result = 2;
            }
            else if (matchValue[2] != null) {
                pattern_matching_result = 1;
                model_1_1 = matchValue[0];
                msg_1 = matchValue[2];
            }
            else {
                pattern_matching_result = 0;
                model_1 = matchValue[0];
            }
        }
        else {
            pattern_matching_result = 2;
        }
        switch (pattern_matching_result) {
            case 0: {
                return [model_1, Cmd_none()];
            }
            case 1: {
                return [model_1_1, Cmd_OfFunc_result(msg_1)];
            }
            case 2: {
                const patternInput = current_3.Init(deps);
                return [new Internal_StateTreeModel$1(patternInput[0], false), Cmd_batch_1(toList(delay(() => append(singleton_1(patternInput[1]), delay(() => ofArray(toArray(map(Cmd_OfFunc_result, maybeAutoMessage))))))))];
            }
        }
    }, (_arg3, dispatch, model_2) => current_3.View(model_2.NodeModel, dispatch), (_arg4, msg_3_1, pass, deps_1, model_3) => {
        switch (msg_3_1.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_1.fields[0]}`));
                return [model_3, Cmd_none(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3.NodeModel, true), Cmd_none(), void 0, void 0];
            }
            case 3: {
                return [model_3, Cmd_none(), void 0, msg_3_1.fields[0]];
            }
            case 4: {
                return [model_3, Cmd_none(), msg_3_1.fields[0], void 0];
            }
            default: {
                const patternInput_1 = current_3.Update(msg_3_1.fields[0], pass, deps_1, model_3.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1[0], model_3.Initialized), patternInput_1[1], void 0, void 0];
            }
        }
    }, (msg_4, deps_2, model_4) => {
        const patternInput_2 = config_1.UpdateInbound(msg_4, deps_2, model_4.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2[0], model_4.Initialized), patternInput_2[1]];
    }, key_3, ofList(singleton([singleton(key_3), singleton(0)])))))))), (sibling) => {
        const current_4 = current_1_1;
        const sibling_1 = sibling;
        return new Internal_StateTreeRoot$6((indexPath, deps_3, maybeCachedModel_1, keepCachedSiblings) => {
            const maybeCachedCurrentModel = bind((cachedModel) => cachedModel.MaybeCurrentModel, maybeCachedModel_1);
            const maybeCachedSiblingModel = bind((cachedModel_1) => cachedModel_1.MaybeSiblingModel, maybeCachedModel_1);
            let pattern_matching_result_1, x, xs;
            if (isEmpty(indexPath)) {
                pattern_matching_result_1 = 1;
            }
            else if (head(indexPath) === 0) {
                if (isEmpty(tail(indexPath))) {
                    pattern_matching_result_1 = 0;
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 2;
                x = head(indexPath);
                xs = tail(indexPath);
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    const patternInput_3 = current_4.Init(indexPath, deps_3, maybeCachedCurrentModel, false);
                    return [new Internal_BranchModelSibling$2(patternInput_3[0], keepCachedSiblings ? maybeCachedSiblingModel : (void 0)), Cmd_map((arg0_2) => (new Internal_BranchMsgSibling$2(0, arg0_2)), patternInput_3[1])];
                }
                case 1: {
                    console.error(some("Failed to initialize state, invalid index"));
                    return [defaultArg(maybeCachedModel_1, new Internal_BranchModelSibling$2(void 0, void 0)), Cmd_none()];
                }
                case 2: {
                    const patternInput_1_1 = sibling_1.Init(cons(x - 1, xs), deps_3, maybeCachedSiblingModel, keepCachedSiblings);
                    return [new Internal_BranchModelSibling$2(keepCachedSiblings ? maybeCachedCurrentModel : (void 0), patternInput_1_1[0]), Cmd_map((arg0_1_1) => (new Internal_BranchMsgSibling$2(1, arg0_1_1)), patternInput_1_1[1])];
                }
            }
        }, (indexPath_1, dispatch_1, model_5) => (isEmpty(indexPath_1) ? "Woops, looks like you took a wrong turn..." : ((head(indexPath_1) === 0) ? (isEmpty(tail(indexPath_1)) ? defaultArg(map(partialApply(1, current_4.View, [indexPath_1, (arg) => {
            dispatch_1(new Internal_BranchMsgSibling$2(0, arg));
        }]), model_5.MaybeCurrentModel), "Loading...") : "Woops, looks like you took a wrong turn...") : defaultArg(map(partialApply(1, sibling_1.View, [cons(head(indexPath_1) - 1, tail(indexPath_1)), (arg_1) => {
            dispatch_1(new Internal_BranchMsgSibling$2(1, arg_1));
        }]), model_5.MaybeSiblingModel), "Loading..."))), (indexPath_2, branchMsg, pass_1, deps_1_1, model_1_2) => {
            const matchValue_1 = [branchMsg, indexPath_2];
            let pattern_matching_result_2, msg_1_2, msg_2_2, x_3, xs_3;
            if (matchValue_1[0].tag === 1) {
                if (!isEmpty(matchValue_1[1])) {
                    if (head(matchValue_1[1]) > 0) {
                        pattern_matching_result_2 = 1;
                        msg_2_2 = matchValue_1[0].fields[0];
                        x_3 = head(matchValue_1[1]);
                        xs_3 = tail(matchValue_1[1]);
                    }
                    else {
                        pattern_matching_result_2 = 2;
                    }
                }
                else {
                    pattern_matching_result_2 = 2;
                }
            }
            else if (!isEmpty(matchValue_1[1])) {
                if (head(matchValue_1[1]) === 0) {
                    pattern_matching_result_2 = 0;
                    msg_1_2 = matchValue_1[0].fields[0];
                }
                else {
                    pattern_matching_result_2 = 2;
                }
            }
            else {
                pattern_matching_result_2 = 2;
            }
            switch (pattern_matching_result_2) {
                case 0: {
                    const matchValue_1_1 = model_1_2.MaybeCurrentModel;
                    if (matchValue_1_1 == null) {
                        console.error(some("Failed to update state, not initialized"));
                        return [model_1_2, Cmd_none(), void 0, void 0];
                    }
                    else {
                        const patternInput_2_1 = current_4.Update(indexPath_2, msg_1_2, pass_1, deps_1_1, matchValue_1_1);
                        return [new Internal_BranchModelSibling$2(patternInput_2_1[0], model_1_2.MaybeSiblingModel), Cmd_map((arg0_5) => (new Internal_BranchMsgSibling$2(0, arg0_5)), patternInput_2_1[1]), patternInput_2_1[2], patternInput_2_1[3]];
                    }
                }
                case 1: {
                    const matchValue_2 = model_1_2.MaybeSiblingModel;
                    if (matchValue_2 == null) {
                        console.error(some("Failed to update state, not initialized"));
                        return [model_1_2, Cmd_none(), void 0, void 0];
                    }
                    else {
                        const patternInput_3_1 = sibling_1.Update(cons(x_3 - 1, xs_3), msg_2_2, pass_1, deps_1_1, matchValue_2);
                        return [new Internal_BranchModelSibling$2(model_1_2.MaybeCurrentModel, patternInput_3_1[0]), Cmd_map((arg0_7) => (new Internal_BranchMsgSibling$2(1, arg0_7)), patternInput_3_1[1]), patternInput_3_1[2], patternInput_3_1[3]];
                    }
                }
                case 2: {
                    console.warn(some("Got an update for a node that shouldn\u0027t be initialized, ignoring"));
                    return [model_1_2, Cmd_none(), void 0, void 0];
                }
            }
        }, (msg_3_2, deps_2_1, model_2_1) => {
            let patternInput_5;
            const matchValue_3 = model_2_1.MaybeCurrentModel;
            if (matchValue_3 == null) {
                patternInput_5 = [model_2_1.MaybeCurrentModel, Cmd_none()];
            }
            else {
                const patternInput_4 = current_4.UpdateInbound(msg_3_2, deps_2_1, matchValue_3);
                patternInput_5 = [patternInput_4[0], patternInput_4[1]];
            }
            let patternInput_7;
            const matchValue_4 = model_2_1.MaybeSiblingModel;
            if (matchValue_4 == null) {
                patternInput_7 = [model_2_1.MaybeSiblingModel, Cmd_none()];
            }
            else {
                const patternInput_6 = sibling_1.UpdateInbound(msg_3_2, deps_2_1, matchValue_4);
                patternInput_7 = [patternInput_6[0], patternInput_6[1]];
            }
            return [new Internal_BranchModelSibling$2(patternInput_5[0], patternInput_7[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_8) => (new Internal_BranchMsgSibling$2(0, arg0_8)), patternInput_5[1]), Cmd_map((arg0_9) => (new Internal_BranchMsgSibling$2(1, arg0_9)), patternInput_7[1])]))];
        }, current_4.LastKey, Internal_combineSiblingIndexPathFromKeyPathMap(sibling_1.IndexPathFromKeyPath, current_4.IndexPathFromKeyPath));
    })), (current_5 = siteDeleteState(), siblingBuilder((config_3 = defaultConfig_1(), (maybeAutoMessage_1 = config_3.MaybeSendMessageOnNavigation, (key_8 = "delete", (current_9 = current_5, new Internal_StateTreeRoot$6((_arg2_1, deps_4, maybeCachedModel_2, _arg1_2) => {
        const matchValue_5 = [maybeCachedModel_2, config_3.ReInitOnNavigation, maybeAutoMessage_1];
        let pattern_matching_result_3, model_6, model_1_3, msg_6;
        if (matchValue_5[0] != null) {
            if (matchValue_5[1]) {
                pattern_matching_result_3 = 2;
            }
            else if (matchValue_5[2] != null) {
                pattern_matching_result_3 = 1;
                model_1_3 = matchValue_5[0];
                msg_6 = matchValue_5[2];
            }
            else {
                pattern_matching_result_3 = 0;
                model_6 = matchValue_5[0];
            }
        }
        else {
            pattern_matching_result_3 = 2;
        }
        switch (pattern_matching_result_3) {
            case 0: {
                return [model_6, Cmd_none()];
            }
            case 1: {
                return [model_1_3, Cmd_OfFunc_result(msg_6)];
            }
            case 2: {
                const patternInput_8 = current_9.Init(deps_4);
                return [new Internal_StateTreeModel$1(patternInput_8[0], false), Cmd_batch_1(toList(delay(() => append(singleton_1(patternInput_8[1]), delay(() => ofArray(toArray(map(Cmd_OfFunc_result, maybeAutoMessage_1))))))))];
            }
        }
    }, (_arg3_1, dispatch_2, model_2_2) => current_9.View(model_2_2.NodeModel, dispatch_2), (_arg4_1, msg_3_3, pass_2, deps_1_2, model_3_1) => {
        switch (msg_3_3.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_3.fields[0]}`));
                return [model_3_1, Cmd_none(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3_1.NodeModel, true), Cmd_none(), void 0, void 0];
            }
            case 3: {
                return [model_3_1, Cmd_none(), void 0, msg_3_3.fields[0]];
            }
            case 4: {
                return [model_3_1, Cmd_none(), msg_3_3.fields[0], void 0];
            }
            default: {
                const patternInput_1_2 = current_9.Update(msg_3_3.fields[0], pass_2, deps_1_2, model_3_1.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1_2[0], model_3_1.Initialized), patternInput_1_2[1], void 0, void 0];
            }
        }
    }, (msg_4_1, deps_2_2, model_4_1) => {
        const patternInput_2_2 = config_3.UpdateInbound(msg_4_1, deps_2_2, model_4_1.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2_2[0], model_4_1.Initialized), patternInput_2_2[1]];
    }, key_8, ofList(singleton([singleton(key_8), singleton(0)]))))))))))), (config_4 = defaultConfig_1(), (current_1_2 = ((config_5 = config_4, (maybeAutoMessage_2 = config_5.MaybeSendMessageOnNavigation, (key_12 = "detail", (current_13 = current_10, new Internal_StateTreeRoot$6((_arg2_2, deps_5, maybeCachedModel_3, _arg1_3) => {
        const matchValue_6 = [maybeCachedModel_3, config_5.ReInitOnNavigation, maybeAutoMessage_2];
        let pattern_matching_result_4, model_7, model_1_4, msg_9;
        if (matchValue_6[0] != null) {
            if (matchValue_6[1]) {
                pattern_matching_result_4 = 2;
            }
            else if (matchValue_6[2] != null) {
                pattern_matching_result_4 = 1;
                model_1_4 = matchValue_6[0];
                msg_9 = matchValue_6[2];
            }
            else {
                pattern_matching_result_4 = 0;
                model_7 = matchValue_6[0];
            }
        }
        else {
            pattern_matching_result_4 = 2;
        }
        switch (pattern_matching_result_4) {
            case 0: {
                return [model_7, Cmd_none()];
            }
            case 1: {
                return [model_1_4, Cmd_OfFunc_result(msg_9)];
            }
            case 2: {
                const patternInput_9 = current_13.Init(deps_5);
                return [new Internal_StateTreeModel$1(patternInput_9[0], false), Cmd_batch_1(toList(delay(() => append(singleton_1(patternInput_9[1]), delay(() => ofArray(toArray(map(Cmd_OfFunc_result, maybeAutoMessage_2))))))))];
            }
        }
    }, (_arg3_2, dispatch_3, model_2_3) => current_13.View(model_2_3.NodeModel, dispatch_3), (_arg4_2, msg_3_4, pass_3, deps_1_3, model_3_2) => {
        switch (msg_3_4.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_4.fields[0]}`));
                return [model_3_2, Cmd_none(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3_2.NodeModel, true), Cmd_none(), void 0, void 0];
            }
            case 3: {
                return [model_3_2, Cmd_none(), void 0, msg_3_4.fields[0]];
            }
            case 4: {
                return [model_3_2, Cmd_none(), msg_3_4.fields[0], void 0];
            }
            default: {
                const patternInput_1_3 = current_13.Update(msg_3_4.fields[0], pass_3, deps_1_3, model_3_2.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1_3[0], model_3_2.Initialized), patternInput_1_3[1], void 0, void 0];
            }
        }
    }, (msg_4_2, deps_2_3, model_4_2) => {
        const patternInput_2_3 = config_5.UpdateInbound(msg_4_2, deps_2_3, model_4_2.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2_3[0], model_4_2.Initialized), patternInput_2_3[1]];
    }, key_12, ofList(singleton([singleton(key_12), singleton(0)])))))))), (sibling_2) => {
        const keepCachedChildren_1 = config_4.KeepCachedChildren;
        const child_2 = child;
        const current_14 = current_1_2;
        const sibling_3 = sibling_2;
        return new Internal_StateTreeRoot$6((indexPath_3, deps_6, maybeCachedModel_4, keepCachedSiblings_1) => {
            const maybeCachedCurrentModel_1 = bind((cachedModel_2) => cachedModel_2.MaybeCurrentModel, maybeCachedModel_4);
            const maybeCachedSiblingModel_1 = bind((cachedModel_1_1) => cachedModel_1_1.MaybeSiblingModel, maybeCachedModel_4);
            const maybeCachedChildModel = bind((cachedModel_2_1) => cachedModel_2_1.MaybeChildModel, maybeCachedModel_4);
            const matchValue_7 = [indexPath_3, maybeCachedCurrentModel_1];
            let pattern_matching_result_5, currentModel_1_1, xs_4, x_4, xs_1_1;
            if (isEmpty(matchValue_7[0])) {
                pattern_matching_result_5 = 3;
            }
            else if (head(matchValue_7[0]) === 0) {
                if (isEmpty(tail(matchValue_7[0]))) {
                    pattern_matching_result_5 = 0;
                }
                else if (matchValue_7[1] != null) {
                    pattern_matching_result_5 = 1;
                    currentModel_1_1 = matchValue_7[1];
                    xs_4 = tail(matchValue_7[0]);
                }
                else {
                    pattern_matching_result_5 = 0;
                }
            }
            else {
                pattern_matching_result_5 = 2;
                x_4 = head(matchValue_7[0]);
                xs_1_1 = tail(matchValue_7[0]);
            }
            switch (pattern_matching_result_5) {
                case 0: {
                    const patternInput_10 = current_14.Init(indexPath_3, deps_6, maybeCachedCurrentModel_1, false);
                    return [new Internal_BranchModelChildAndSibling$3(patternInput_10[0], keepCachedSiblings_1 ? maybeCachedSiblingModel_1 : (void 0), keepCachedChildren_1 ? maybeCachedChildModel : (void 0)), Cmd_map((arg0_10) => (new Internal_BranchMsgChildAndSibling$3(0, arg0_10)), patternInput_10[1])];
                }
                case 1: {
                    const patternInput_1_4 = child_2.Init(xs_4, currentModel_1_1.NodeModel, maybeCachedChildModel, keepCachedChildren_1);
                    return [new Internal_BranchModelChildAndSibling$3(currentModel_1_1, keepCachedSiblings_1 ? maybeCachedSiblingModel_1 : (void 0), patternInput_1_4[0]), Cmd_map((arg0_2_2) => (new Internal_BranchMsgChildAndSibling$3(2, arg0_2_2)), patternInput_1_4[1])];
                }
                case 2: {
                    const patternInput_2_4 = sibling_3.Init(cons(x_4 - 1, xs_1_1), deps_6, maybeCachedSiblingModel_1, keepCachedSiblings_1);
                    return [new Internal_BranchModelChildAndSibling$3(keepCachedSiblings_1 ? maybeCachedCurrentModel_1 : (void 0), patternInput_2_4[0], keepCachedChildren_1 ? maybeCachedChildModel : (void 0)), Cmd_map((arg0_3_1) => (new Internal_BranchMsgChildAndSibling$3(1, arg0_3_1)), patternInput_2_4[1])];
                }
                case 3: {
                    console.error(some("Failed to initialize state, invalid index"));
                    return [defaultArg(maybeCachedModel_4, new Internal_BranchModelChildAndSibling$3(void 0, void 0, void 0)), Cmd_none()];
                }
            }
        }, (indexPath_1_1, dispatch_4, model_8) => {
            if (isEmpty(indexPath_1_1)) {
                return "Woops, looks like you took a wrong turn...";
            }
            else if (head(indexPath_1_1) === 0) {
                if (isEmpty(tail(indexPath_1_1))) {
                    return defaultArg(map(partialApply(1, current_14.View, [indexPath_1_1, (arg_2) => {
                        dispatch_4(new Internal_BranchMsgChildAndSibling$3(0, arg_2));
                    }]), model_8.MaybeCurrentModel), "Loading...");
                }
                else {
                    const children = toList(delay(() => append(config_4.VisibleToChildren ? singleton_1(defaultArg(map(partialApply(1, current_14.View, [indexPath_1_1, (arg_3) => {
                        dispatch_4(new Internal_BranchMsgChildAndSibling$3(0, arg_3));
                    }]), model_8.MaybeCurrentModel), "Loading...")) : empty(), delay(() => singleton_1(defaultArg(map(partialApply(1, child_2.View, [tail(indexPath_1_1), (arg_4) => {
                        dispatch_4(new Internal_BranchMsgChildAndSibling$3(2, arg_4));
                    }]), model_8.MaybeChildModel), "Loading..."))))));
                    return react.createElement("div", {}, ...children);
                }
            }
            else {
                return defaultArg(map(partialApply(1, sibling_3.View, [cons(head(indexPath_1_1) - 1, tail(indexPath_1_1)), (arg_5) => {
                    dispatch_4(new Internal_BranchMsgChildAndSibling$3(1, arg_5));
                }]), model_8.MaybeSiblingModel), "Loading...");
            }
        }, (indexPath_2_1, branchMsg_1, pass_4, deps_1_4, model_1_5) => {
            let newNodeModel;
            const matchValue_1_2 = [branchMsg_1, indexPath_2_1];
            let pattern_matching_result_6, msg_1_5, xs_5, msg_2_5, xs_6, msg_3_5, x_3_1, xs_7;
            if (matchValue_1_2[0].tag === 2) {
                if (!isEmpty(matchValue_1_2[1])) {
                    if (head(matchValue_1_2[1]) === 0) {
                        pattern_matching_result_6 = 1;
                        msg_2_5 = matchValue_1_2[0].fields[0];
                        xs_6 = tail(matchValue_1_2[1]);
                    }
                    else {
                        pattern_matching_result_6 = 3;
                    }
                }
                else {
                    pattern_matching_result_6 = 3;
                }
            }
            else if (matchValue_1_2[0].tag === 1) {
                if (!isEmpty(matchValue_1_2[1])) {
                    if (head(matchValue_1_2[1]) > 0) {
                        pattern_matching_result_6 = 2;
                        msg_3_5 = matchValue_1_2[0].fields[0];
                        x_3_1 = head(matchValue_1_2[1]);
                        xs_7 = tail(matchValue_1_2[1]);
                    }
                    else {
                        pattern_matching_result_6 = 3;
                    }
                }
                else {
                    pattern_matching_result_6 = 3;
                }
            }
            else if (!isEmpty(matchValue_1_2[1])) {
                if (head(matchValue_1_2[1]) === 0) {
                    pattern_matching_result_6 = 0;
                    msg_1_5 = matchValue_1_2[0].fields[0];
                    xs_5 = tail(matchValue_1_2[1]);
                }
                else {
                    pattern_matching_result_6 = 3;
                }
            }
            else {
                pattern_matching_result_6 = 3;
            }
            switch (pattern_matching_result_6) {
                case 0: {
                    const matchValue_2_1 = model_1_5.MaybeCurrentModel;
                    if (matchValue_2_1 == null) {
                        console.error(some("Failed to update state, not initialized"));
                        return [model_1_5, Cmd_none(), void 0, void 0];
                    }
                    else {
                        const currentModel_2_1 = matchValue_2_1;
                        const patternInput_3_2 = current_14.Update(indexPath_2_1, msg_1_5, pass_4, deps_1_4, currentModel_2_1);
                        const newCurrentModel_2 = patternInput_3_2[0];
                        const maybeOutboundMsg_2 = patternInput_3_2[3];
                        const maybeNavMsg_2 = patternInput_3_2[2];
                        const currentCmd_1_1 = patternInput_3_2[1];
                        let pattern_matching_result_7;
                        if (msg_1_5.tag === 2) {
                            if (!equals(xs_5, empty_1())) {
                                pattern_matching_result_7 = 0;
                            }
                            else {
                                pattern_matching_result_7 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_7 = 1;
                        }
                        switch (pattern_matching_result_7) {
                            case 0: {
                                const patternInput_4_1 = child_2.Init(xs_5, currentModel_2_1.NodeModel, model_1_5.MaybeChildModel, false);
                                return [new Internal_BranchModelChildAndSibling$3(newCurrentModel_2, model_1_5.MaybeSiblingModel, patternInput_4_1[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_10_1) => (new Internal_BranchMsgChildAndSibling$3(0, arg0_10_1)), currentCmd_1_1), Cmd_map((arg0_11) => (new Internal_BranchMsgChildAndSibling$3(2, arg0_11)), patternInput_4_1[1])])), maybeNavMsg_2, maybeOutboundMsg_2];
                            }
                            case 1: {
                                return [new Internal_BranchModelChildAndSibling$3(newCurrentModel_2, model_1_5.MaybeSiblingModel, model_1_5.MaybeChildModel), Cmd_map((arg0_13) => (new Internal_BranchMsgChildAndSibling$3(0, arg0_13)), currentCmd_1_1), maybeNavMsg_2, maybeOutboundMsg_2];
                            }
                        }
                    }
                }
                case 1: {
                    const matchValue_3_1 = [model_1_5.MaybeCurrentModel, model_1_5.MaybeChildModel];
                    let pattern_matching_result_8, childModel_1, currentModel_3_1;
                    if (matchValue_3_1[0] != null) {
                        if (matchValue_3_1[1] != null) {
                            pattern_matching_result_8 = 0;
                            childModel_1 = matchValue_3_1[1];
                            currentModel_3_1 = matchValue_3_1[0];
                        }
                        else {
                            pattern_matching_result_8 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_8 = 1;
                    }
                    switch (pattern_matching_result_8) {
                        case 0: {
                            const patternInput_5_1 = child_2.Update(xs_6, msg_2_5, pass_4, currentModel_3_1.NodeModel, childModel_1);
                            const maybeNavMsg_1_1 = patternInput_5_1[2];
                            return [new Internal_BranchModelChildAndSibling$3((maybeNavMsg_1_1 != null) ? ((maybeNavMsg_1_1.tag === 2) ? ((newNodeModel = maybeNavMsg_1_1.fields[0], map((model_2_4) => (new Internal_StateTreeModel$1(newNodeModel, model_2_4.Initialized)), model_1_5.MaybeCurrentModel))) : model_1_5.MaybeCurrentModel) : model_1_5.MaybeCurrentModel, model_1_5.MaybeSiblingModel, patternInput_5_1[0]), Cmd_map((arg0_15) => (new Internal_BranchMsgChildAndSibling$3(2, arg0_15)), patternInput_5_1[1]), map(NavigationMsg$1_destructiveCast_Z33BEA92B, maybeNavMsg_1_1), patternInput_5_1[3]];
                        }
                        case 1: {
                            console.error(some("Failed to update state, parent not initialized"));
                            return [model_1_5, Cmd_none(), void 0, void 0];
                        }
                    }
                }
                case 2: {
                    const matchValue_4_1 = model_1_5.MaybeSiblingModel;
                    if (matchValue_4_1 == null) {
                        console.error(some("Failed to update state, not initialized"));
                        return [model_1_5, Cmd_none(), void 0, void 0];
                    }
                    else {
                        const patternInput_6_1 = sibling_3.Update(cons(x_3_1 - 1, xs_7), msg_3_5, pass_4, deps_1_4, matchValue_4_1);
                        return [new Internal_BranchModelChildAndSibling$3(model_1_5.MaybeCurrentModel, patternInput_6_1[0], model_1_5.MaybeChildModel), Cmd_map((arg0_17) => (new Internal_BranchMsgChildAndSibling$3(1, arg0_17)), patternInput_6_1[1]), patternInput_6_1[2], patternInput_6_1[3]];
                    }
                }
                case 3: {
                    console.warn(some("Got an update for a node that shouldn\u0027t be initialized, ignoring"));
                    return [model_1_5, Cmd_none(), void 0, void 0];
                }
            }
        }, (msg_4_3, deps_2_4, model_3_3) => {
            let patternInput_8_1;
            const matchValue_5_1 = model_3_3.MaybeCurrentModel;
            if (matchValue_5_1 == null) {
                patternInput_8_1 = [model_3_3.MaybeCurrentModel, Cmd_none()];
            }
            else {
                const patternInput_7_1 = current_14.UpdateInbound(msg_4_3, deps_2_4, matchValue_5_1);
                patternInput_8_1 = [patternInput_7_1[0], patternInput_7_1[1]];
            }
            let patternInput_10_1;
            const matchValue_6_1 = [model_3_3.MaybeChildModel, model_3_3.MaybeCurrentModel];
            let pattern_matching_result_9, childModel_2, currentModel_5;
            if (matchValue_6_1[0] != null) {
                if (matchValue_6_1[1] != null) {
                    pattern_matching_result_9 = 0;
                    childModel_2 = matchValue_6_1[0];
                    currentModel_5 = matchValue_6_1[1];
                }
                else {
                    pattern_matching_result_9 = 1;
                }
            }
            else {
                pattern_matching_result_9 = 1;
            }
            switch (pattern_matching_result_9) {
                case 0: {
                    const patternInput_9_1 = child_2.UpdateInbound(msg_4_3, currentModel_5.NodeModel, childModel_2);
                    patternInput_10_1 = [patternInput_9_1[0], patternInput_9_1[1]];
                    break;
                }
                case 1: {
                    patternInput_10_1 = [model_3_3.MaybeChildModel, Cmd_none()];
                    break;
                }
            }
            let patternInput_12;
            const matchValue_7_1 = model_3_3.MaybeSiblingModel;
            if (matchValue_7_1 == null) {
                patternInput_12 = [model_3_3.MaybeSiblingModel, Cmd_none()];
            }
            else {
                const patternInput_11 = sibling_3.UpdateInbound(msg_4_3, deps_2_4, matchValue_7_1);
                patternInput_12 = [patternInput_11[0], patternInput_11[1]];
            }
            return [new Internal_BranchModelChildAndSibling$3(patternInput_8_1[0], patternInput_12[0], patternInput_10_1[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_18) => (new Internal_BranchMsgChildAndSibling$3(0, arg0_18)), patternInput_8_1[1]), Cmd_map((arg0_19) => (new Internal_BranchMsgChildAndSibling$3(2, arg0_19)), patternInput_10_1[1]), Cmd_map((arg0_20) => (new Internal_BranchMsgChildAndSibling$3(1, arg0_20)), patternInput_12[1])]))];
        }, current_14.LastKey, Internal_combineSiblingIndexPathFromKeyPathMap(sibling_3.IndexPathFromKeyPath, Internal_combineChildIndexPathFromKeyPathMap(current_14.LastKey, child_2.IndexPathFromKeyPath, current_14.IndexPathFromKeyPath)));
    }))))), (current_15 = siteCreateState(accountId), siblingBuilder_3((config_7 = defaultConfig_1(), (maybeAutoMessage_3 = config_7.MaybeSendMessageOnNavigation, (key_17 = "create", (current_19 = current_15, new Internal_StateTreeRoot$6((_arg2_3, deps_7, maybeCachedModel_5, _arg1_4) => {
        const matchValue_8 = [maybeCachedModel_5, config_7.ReInitOnNavigation, maybeAutoMessage_3];
        let pattern_matching_result_10, model_9, model_1_6, msg_13;
        if (matchValue_8[0] != null) {
            if (matchValue_8[1]) {
                pattern_matching_result_10 = 2;
            }
            else if (matchValue_8[2] != null) {
                pattern_matching_result_10 = 1;
                model_1_6 = matchValue_8[0];
                msg_13 = matchValue_8[2];
            }
            else {
                pattern_matching_result_10 = 0;
                model_9 = matchValue_8[0];
            }
        }
        else {
            pattern_matching_result_10 = 2;
        }
        switch (pattern_matching_result_10) {
            case 0: {
                return [model_9, Cmd_none()];
            }
            case 1: {
                return [model_1_6, Cmd_OfFunc_result(msg_13)];
            }
            case 2: {
                const patternInput_13 = current_19.Init(deps_7);
                return [new Internal_StateTreeModel$1(patternInput_13[0], false), Cmd_batch_1(toList(delay(() => append(singleton_1(patternInput_13[1]), delay(() => ofArray(toArray(map(Cmd_OfFunc_result, maybeAutoMessage_3))))))))];
            }
        }
    }, (_arg3_3, dispatch_5, model_2_5) => current_19.View(model_2_5.NodeModel, dispatch_5), (_arg4_3, msg_3_6, pass_5, deps_1_5, model_3_4) => {
        switch (msg_3_6.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_6.fields[0]}`));
                return [model_3_4, Cmd_none(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3_4.NodeModel, true), Cmd_none(), void 0, void 0];
            }
            case 3: {
                return [model_3_4, Cmd_none(), void 0, msg_3_6.fields[0]];
            }
            case 4: {
                return [model_3_4, Cmd_none(), msg_3_6.fields[0], void 0];
            }
            default: {
                const patternInput_1_5 = current_19.Update(msg_3_6.fields[0], pass_5, deps_1_5, model_3_4.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1_5[0], model_3_4.Initialized), patternInput_1_5[1], void 0, void 0];
            }
        }
    }, (msg_4_4, deps_2_5, model_4_3) => {
        const patternInput_2_5 = config_7.UpdateInbound(msg_4_4, deps_2_5, model_4_3.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2_5[0], model_4_3.Initialized), patternInput_2_5[1]];
    }, key_17, ofList(singleton([singleton(key_17), singleton(0)]))))))))))), (current_23 = ((config_9 = config_8, (maybeAutoMessage_4 = config_9.MaybeSendMessageOnNavigation, (key_20 = "list", (current_22 = current_20, new Internal_StateTreeRoot$6((_arg2_4, deps_8, maybeCachedModel_6, _arg1_5) => {
        const matchValue_9 = [maybeCachedModel_6, config_9.ReInitOnNavigation, maybeAutoMessage_4];
        let pattern_matching_result_11, model_10, model_1_7, msg_16;
        if (matchValue_9[0] != null) {
            if (matchValue_9[1]) {
                pattern_matching_result_11 = 2;
            }
            else if (matchValue_9[2] != null) {
                pattern_matching_result_11 = 1;
                model_1_7 = matchValue_9[0];
                msg_16 = matchValue_9[2];
            }
            else {
                pattern_matching_result_11 = 0;
                model_10 = matchValue_9[0];
            }
        }
        else {
            pattern_matching_result_11 = 2;
        }
        switch (pattern_matching_result_11) {
            case 0: {
                return [model_10, Cmd_none()];
            }
            case 1: {
                return [model_1_7, Cmd_OfFunc_result(msg_16)];
            }
            case 2: {
                const patternInput_14 = current_22.Init(deps_8);
                return [new Internal_StateTreeModel$1(patternInput_14[0], false), Cmd_batch_1(toList(delay(() => append(singleton_1(patternInput_14[1]), delay(() => ofArray(toArray(map(Cmd_OfFunc_result, maybeAutoMessage_4))))))))];
            }
        }
    }, (_arg3_4, dispatch_6, model_2_6) => current_22.View(model_2_6.NodeModel, dispatch_6), (_arg4_4, msg_3_7, pass_6, deps_1_6, model_3_5) => {
        switch (msg_3_7.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_7.fields[0]}`));
                return [model_3_5, Cmd_none(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3_5.NodeModel, true), Cmd_none(), void 0, void 0];
            }
            case 3: {
                return [model_3_5, Cmd_none(), void 0, msg_3_7.fields[0]];
            }
            case 4: {
                return [model_3_5, Cmd_none(), msg_3_7.fields[0], void 0];
            }
            default: {
                const patternInput_1_6 = current_22.Update(msg_3_7.fields[0], pass_6, deps_1_6, model_3_5.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1_6[0], model_3_5.Initialized), patternInput_1_6[1], void 0, void 0];
            }
        }
    }, (msg_4_5, deps_2_6, model_4_4) => {
        const patternInput_2_6 = config_9.UpdateInbound(msg_4_5, deps_2_6, model_4_4.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2_6[0], model_4_4.Initialized), patternInput_2_6[1]];
    }, key_20, ofList(singleton([singleton(key_20), singleton(0)])))))))), new Internal_StateTreeRoot$6((indexPath_4, deps_9, maybeCachedModel_7, _arg1_6) => {
        const maybeCachedCurrentModel_2 = bind((cachedModel_3) => cachedModel_3.MaybeCurrentModel, maybeCachedModel_7);
        const maybeCachedChildModel_1 = bind((cachedModel_1_2) => cachedModel_1_2.MaybeChildModel, maybeCachedModel_7);
        const matchValue_10 = [indexPath_4, maybeCachedCurrentModel_2];
        let pattern_matching_result_12, currentModel_1_2, xs_8;
        if (!isEmpty(matchValue_10[0])) {
            if (head(matchValue_10[0]) === 0) {
                if (isEmpty(tail(matchValue_10[0]))) {
                    pattern_matching_result_12 = 0;
                }
                else if (matchValue_10[1] != null) {
                    pattern_matching_result_12 = 1;
                    currentModel_1_2 = matchValue_10[1];
                    xs_8 = tail(matchValue_10[0]);
                }
                else {
                    pattern_matching_result_12 = 0;
                }
            }
            else {
                pattern_matching_result_12 = 2;
            }
        }
        else {
            pattern_matching_result_12 = 2;
        }
        switch (pattern_matching_result_12) {
            case 0: {
                const patternInput_15 = current_23.Init(indexPath_4, deps_9, maybeCachedCurrentModel_2, false);
                return [new Internal_BranchModelChild$2(patternInput_15[0], keepCachedChildren_3 ? maybeCachedChildModel_1 : (void 0)), Cmd_map((arg0_21) => (new Internal_BranchMsgChild$2(0, arg0_21)), patternInput_15[1])];
            }
            case 1: {
                const patternInput_1_7 = child_4.Init(xs_8, currentModel_1_2.NodeModel, maybeCachedChildModel_1, keepCachedChildren_3);
                return [new Internal_BranchModelChild$2(currentModel_1_2, patternInput_1_7[0]), Cmd_map((arg0_2_3) => (new Internal_BranchMsgChild$2(1, arg0_2_3)), patternInput_1_7[1])];
            }
            case 2: {
                console.error(some("Failed to initialize state, invalid index"));
                return [defaultArg(maybeCachedModel_7, new Internal_BranchModelChild$2(void 0, void 0)), Cmd_none()];
            }
        }
    }, (indexPath_1_2, dispatch_7, model_11) => {
        let pattern_matching_result_13, xs_1_2;
        if (!isEmpty(indexPath_1_2)) {
            if (head(indexPath_1_2) === 0) {
                if (isEmpty(tail(indexPath_1_2))) {
                    pattern_matching_result_13 = 0;
                }
                else {
                    pattern_matching_result_13 = 1;
                    xs_1_2 = tail(indexPath_1_2);
                }
            }
            else {
                pattern_matching_result_13 = 2;
            }
        }
        else {
            pattern_matching_result_13 = 2;
        }
        switch (pattern_matching_result_13) {
            case 0: {
                return defaultArg(map(partialApply(1, current_23.View, [indexPath_1_2, (arg_6) => {
                    dispatch_7(new Internal_BranchMsgChild$2(0, arg_6));
                }]), model_11.MaybeCurrentModel), "Loading...");
            }
            case 1: {
                const children_2 = toList(delay(() => append(config_8.VisibleToChildren ? singleton_1(defaultArg(map(partialApply(1, current_23.View, [indexPath_1_2, (arg_7) => {
                    dispatch_7(new Internal_BranchMsgChild$2(0, arg_7));
                }]), model_11.MaybeCurrentModel), "Loading...")) : empty(), delay(() => singleton_1(defaultArg(map(partialApply(1, child_4.View, [xs_1_2, (arg_8) => {
                    dispatch_7(new Internal_BranchMsgChild$2(1, arg_8));
                }]), model_11.MaybeChildModel), "Loading..."))))));
                return react.createElement("div", {}, ...children_2);
            }
            case 2: {
                return "Woops, looks like you took a wrong turn...";
            }
        }
    }, (indexPath_2_2, branchMsg_2, pass_7, deps_1_7, model_1_8) => {
        let newNodeModel_1;
        const matchValue_1_3 = [branchMsg_2, indexPath_2_2];
        let pattern_matching_result_14, msg_19, xs_2_2, msg_1_8, xs_3_2;
        if (matchValue_1_3[0].tag === 1) {
            if (!isEmpty(matchValue_1_3[1])) {
                if (head(matchValue_1_3[1]) === 0) {
                    pattern_matching_result_14 = 1;
                    msg_1_8 = matchValue_1_3[0].fields[0];
                    xs_3_2 = tail(matchValue_1_3[1]);
                }
                else {
                    pattern_matching_result_14 = 2;
                }
            }
            else {
                pattern_matching_result_14 = 2;
            }
        }
        else if (!isEmpty(matchValue_1_3[1])) {
            if (head(matchValue_1_3[1]) === 0) {
                pattern_matching_result_14 = 0;
                msg_19 = matchValue_1_3[0].fields[0];
                xs_2_2 = tail(matchValue_1_3[1]);
            }
            else {
                pattern_matching_result_14 = 2;
            }
        }
        else {
            pattern_matching_result_14 = 2;
        }
        switch (pattern_matching_result_14) {
            case 0: {
                const matchValue_2_2 = model_1_8.MaybeCurrentModel;
                if (matchValue_2_2 == null) {
                    console.error(some("Failed to update state, not initialized"));
                    return [model_1_8, Cmd_none(), void 0, void 0];
                }
                else {
                    const currentModel_2_2 = matchValue_2_2;
                    const patternInput_2_7 = current_23.Update(indexPath_2_2, msg_19, pass_7, deps_1_7, currentModel_2_2);
                    const newCurrentModel_3 = patternInput_2_7[0];
                    const maybeOutboundMsg_3 = patternInput_2_7[3];
                    const maybeNavMsg_3 = patternInput_2_7[2];
                    const currentCmd_1_2 = patternInput_2_7[1];
                    let pattern_matching_result_15;
                    if (msg_19.tag === 2) {
                        if (!equals(xs_2_2, empty_1())) {
                            pattern_matching_result_15 = 0;
                        }
                        else {
                            pattern_matching_result_15 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_15 = 1;
                    }
                    switch (pattern_matching_result_15) {
                        case 0: {
                            const patternInput_3_3 = child_4.Init(xs_2_2, currentModel_2_2.NodeModel, model_1_8.MaybeChildModel, false);
                            return [new Internal_BranchModelChild$2(newCurrentModel_3, patternInput_3_3[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_8_2) => (new Internal_BranchMsgChild$2(0, arg0_8_2)), currentCmd_1_2), Cmd_map((arg0_9_2) => (new Internal_BranchMsgChild$2(1, arg0_9_2)), patternInput_3_3[1])])), maybeNavMsg_3, maybeOutboundMsg_3];
                        }
                        case 1: {
                            return [new Internal_BranchModelChild$2(newCurrentModel_3, model_1_8.MaybeChildModel), Cmd_map((arg0_11_1) => (new Internal_BranchMsgChild$2(0, arg0_11_1)), currentCmd_1_2), maybeNavMsg_3, maybeOutboundMsg_3];
                        }
                    }
                }
            }
            case 1: {
                const matchValue_3_2 = [model_1_8.MaybeCurrentModel, model_1_8.MaybeChildModel];
                let pattern_matching_result_16, childModel_1_1, currentModel_3_2;
                if (matchValue_3_2[0] != null) {
                    if (matchValue_3_2[1] != null) {
                        pattern_matching_result_16 = 0;
                        childModel_1_1 = matchValue_3_2[1];
                        currentModel_3_2 = matchValue_3_2[0];
                    }
                    else {
                        pattern_matching_result_16 = 1;
                    }
                }
                else {
                    pattern_matching_result_16 = 1;
                }
                switch (pattern_matching_result_16) {
                    case 0: {
                        const patternInput_4_2 = child_4.Update(xs_3_2, msg_1_8, pass_7, currentModel_3_2.NodeModel, childModel_1_1);
                        const maybeNavMsg_1_2 = patternInput_4_2[2];
                        return [new Internal_BranchModelChild$2((maybeNavMsg_1_2 != null) ? ((maybeNavMsg_1_2.tag === 2) ? ((newNodeModel_1 = maybeNavMsg_1_2.fields[0], map((model_2_7) => (new Internal_StateTreeModel$1(newNodeModel_1, model_2_7.Initialized)), model_1_8.MaybeCurrentModel))) : model_1_8.MaybeCurrentModel) : model_1_8.MaybeCurrentModel, patternInput_4_2[0]), Cmd_map((arg0_13_1) => (new Internal_BranchMsgChild$2(1, arg0_13_1)), patternInput_4_2[1]), map(NavigationMsg$1_destructiveCast_Z33BEA92B, maybeNavMsg_1_2), patternInput_4_2[3]];
                    }
                    case 1: {
                        console.error(some("Failed to update state, parent not initialized"));
                        return [model_1_8, Cmd_none(), void 0, void 0];
                    }
                }
            }
            case 2: {
                console.warn(some("Got an update for a node that shouldn\u0027t be initialized, ignoring"));
                return [model_1_8, Cmd_none(), void 0, void 0];
            }
        }
    }, (msg_2_8, deps_2_7, model_3_6) => {
        let patternInput_6_2;
        const matchValue_4_2 = model_3_6.MaybeCurrentModel;
        if (matchValue_4_2 == null) {
            patternInput_6_2 = [model_3_6.MaybeCurrentModel, Cmd_none()];
        }
        else {
            const patternInput_5_2 = current_23.UpdateInbound(msg_2_8, deps_2_7, matchValue_4_2);
            patternInput_6_2 = [patternInput_5_2[0], patternInput_5_2[1]];
        }
        let patternInput_8_2;
        const matchValue_5_2 = [model_3_6.MaybeChildModel, model_3_6.MaybeCurrentModel];
        let pattern_matching_result_17, childModel_2_1, currentModel_5_1;
        if (matchValue_5_2[0] != null) {
            if (matchValue_5_2[1] != null) {
                pattern_matching_result_17 = 0;
                childModel_2_1 = matchValue_5_2[0];
                currentModel_5_1 = matchValue_5_2[1];
            }
            else {
                pattern_matching_result_17 = 1;
            }
        }
        else {
            pattern_matching_result_17 = 1;
        }
        switch (pattern_matching_result_17) {
            case 0: {
                const patternInput_7_2 = child_4.UpdateInbound(msg_2_8, currentModel_5_1.NodeModel, childModel_2_1);
                patternInput_8_2 = [patternInput_7_2[0], patternInput_7_2[1]];
                break;
            }
            case 1: {
                patternInput_8_2 = [model_3_6.MaybeChildModel, Cmd_none()];
                break;
            }
        }
        return [new Internal_BranchModelChild$2(patternInput_6_2[0], patternInput_8_2[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_14_1) => (new Internal_BranchMsgChild$2(0, arg0_14_1)), patternInput_6_2[1]), Cmd_map((arg0_15_1) => (new Internal_BranchMsgChild$2(1, arg0_15_1)), patternInput_8_2[1])]))];
    }, current_23.LastKey, Internal_combineChildIndexPathFromKeyPathMap(current_23.LastKey, child_4.IndexPathFromKeyPath, current_23.IndexPathFromKeyPath))))))));
}

