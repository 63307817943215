import { Sort_SortableColumn$1, Sort_Compare$1, ListDisplayModification_ListDisplayModification$2_applyListModifications, Sort_itemListSortable, ListDisplayModification_ListDisplayModification$2, Sort_SortInfo$1_getSortComparison_576B9F7E, Sort_SortInfo$1, Sort_SortDirection, Sort_SortableColumn$1_toSortableColumnView_45D2C9EC } from "../Optiscan.SharedUI/Sort.js";
import { tail, head, isEmpty, filter, tryFind, ofArray, singleton, sortWith, empty, item as item_3 } from "../fable_modules/fable-library.3.7.12/List.js";
import { allColumnSearchModifier, CrudButton_create, CrudListModification } from "../Optiscan.SharedUI/Crud.js";
import { partialApply, compare, comparePrimitives, equals, uncurry } from "../fable_modules/fable-library.3.7.12/Util.js";
import { ExVivoTelepathologyMsg, DeviceListMsg, DeviceListModel } from "./ExVivoTypes.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Internal_BranchMsgChild$2, Internal_BranchModelChild$2, Internal_StateTreeRoot$6, Internal_StateTreeModel$1, StateTreeConfig$6, defaultConfig, StateTreeNode$6, NavigationMsg$1, StateTreeMsg$4 } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { HTMLAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import * as react from "react";
import { keyValueList } from "../fable_modules/fable-library.3.7.12/MapUtil.js";
import { content, Header_icon, Header_title, header, card } from "../fable_modules/Fulma.2.16.0/Components/Card.fs.js";
import { Common_GenericOption } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { defaultSearchComponent } from "../Optiscan.SharedUI/Search.js";
import { Cmd_map, Cmd_none, Cmd_batch } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { info, error as error_2 } from "../Optiscan.SharedUI/Toast.js";
import { Navigation_modifyUrl } from "../fable_modules/Fable.Elmish.Browser.3.0.4/navigation.fs.js";
import { Page, pageHash } from "../Pages.js";
import { update, init } from "../TelepathologyCommon/TelepathologyState.js";
import { toArray, some, bind, map, defaultArg } from "../fable_modules/fable-library.3.7.12/Option.js";
import { TelepathologyApi, TelepathologyDependency } from "../TelepathologyCommon/TelepathologyTypes.js";
import { view } from "../TelepathologyCommon/TelepathologyView.js";
import { Option, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { securedApi } from "../Api.js";
import { TelemetryAction } from "../Optiscan.Models/Api/Api.js";
import { Internal_combineChildIndexPathFromKeyPathMap, NavigationMsg$1_destructiveCast_Z33BEA92B, defaultConfig as defaultConfig_1, StateTree$3_$ctor_4E60E31B } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { singleton as singleton_2 } from "../fable_modules/fable-library.3.7.12/AsyncBuilder.js";
import { Permission, isAllowed } from "../Optiscan.Models/Security.js";
import { Result_mapError } from "../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { ErrorMessage_get_describe } from "../Optiscan.Models/ErrorMessage.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.12/Choice.js";
import { Cmd_map as Cmd_map_1, Cmd_batch as Cmd_batch_1, Cmd_none as Cmd_none_1 } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { ofList } from "../fable_modules/fable-library.3.7.12/Map.js";

export function deviceListState(listTitle, itemName, columns, getItems, canCreate, maybeRedirectDeviceId) {
    return new StateTreeNode$6((_arg1) => {
        let comparer;
        const initialSortInfo = new Sort_SortInfo$1(Sort_SortableColumn$1_toSortableColumnView_45D2C9EC(item_3(0, columns)), new Sort_SortDirection(0));
        return [new DeviceListModel(empty(), void 0, initialSortInfo, void 0, singleton(new ListDisplayModification_ListDisplayModification$2(new CrudListModification(0), 0, (comparer = Sort_SortInfo$1_getSortComparison_576B9F7E(initialSortInfo), (list) => sortWith(uncurry(2, comparer), list)))), maybeRedirectDeviceId), Cmd_OfFunc_result(new StateTreeMsg$4(0, new DeviceListMsg(0)))];
    }, (model, dispatch) => {
        let children_2;
        if (model.maybeRedirectDeviceId != null) {
            const props = [new HTMLAttr(64, "is-loading"), ["style", {
                paddingTop: "5em",
            }]];
            return react.createElement("div", keyValueList(props, 1));
        }
        else {
            return card(singleton(new Common_GenericOption(0, "table-card")), ofArray([header(empty(), toList(delay(() => append(singleton_1(Header_title(empty(), singleton(listTitle))), delay(() => append(singleton_1(Header_icon(empty(), singleton(defaultSearchComponent(listTitle, model.maybeSearchInfo, (arg_1) => {
                dispatch(new StateTreeMsg$4(0, new DeviceListMsg(5, arg_1)));
            }, (arg_3) => {
                dispatch(new StateTreeMsg$4(0, new DeviceListMsg(6, arg_3)));
            })))), delay(() => (canCreate ? singleton_1(Header_icon(empty(), singleton(CrudButton_create(itemName, dispatch)))) : empty_1())))))))), content(empty(), singleton((children_2 = [Sort_itemListSortable(itemName, columns)((item) => ((_arg2) => {
                dispatch(new StateTreeMsg$4(0, new DeviceListMsg(3, item)));
            }))(ListDisplayModification_ListDisplayModification$2_applyListModifications(model.listModifications, model.items))((_arg3) => empty_1())(model.sortInfo)((sortInfo) => ((_arg4) => {
                dispatch(new StateTreeMsg$4(0, new DeviceListMsg(4, sortInfo)));
            }))((_arg5) => empty())], react.createElement("div", {
                className: "table-container",
            }, ...children_2))))]));
        }
    }, (msg_2, tupledArg, deps, model_1) => {
        let matchValue;
        if (msg_2.tag === 1) {
            return (msg_2.fields[0].tag === 1) ? [model_1, Cmd_batch(ofArray([Cmd_OfFunc_result(new StateTreeMsg$4(2)), error_2(msg_2.fields[0].fields[0])]))] : [new DeviceListModel(msg_2.fields[0].fields[0], model_1.maybeSelected, model_1.sortInfo, model_1.maybeSearchInfo, model_1.listModifications, model_1.maybeRedirectDeviceId), (matchValue = model_1.maybeRedirectDeviceId, (matchValue == null) ? Cmd_OfFunc_result(new StateTreeMsg$4(2)) : Cmd_batch(ofArray([Cmd_OfFunc_result(new StateTreeMsg$4(0, new DeviceListMsg(2, matchValue))), Cmd_OfFunc_result(new StateTreeMsg$4(2))])))];
        }
        else if (msg_2.tag === 2) {
            const matchValue_1 = tryFind((item_1) => (item_1.name === msg_2.fields[0]), model_1.items);
            return (matchValue_1 == null) ? [new DeviceListModel(model_1.items, model_1.maybeSelected, model_1.sortInfo, model_1.maybeSearchInfo, model_1.listModifications, void 0), Cmd_none()] : [new DeviceListModel(model_1.items, model_1.maybeSelected, model_1.sortInfo, model_1.maybeSearchInfo, model_1.listModifications, void 0), Cmd_OfFunc_result(new StateTreeMsg$4(0, new DeviceListMsg(3, matchValue_1)))];
        }
        else {
            return (msg_2.tag === 3) ? ((msg_2.fields[0].exVivoStreaming && msg_2.fields[0].online) ? [new DeviceListModel(model_1.items, msg_2.fields[0], model_1.sortInfo, model_1.maybeSearchInfo, model_1.listModifications, model_1.maybeRedirectDeviceId), Cmd_batch(toList(delay(() => append((model_1.maybeRedirectDeviceId == null) ? singleton_1(Navigation_modifyUrl(pageHash(new Page(7, msg_2.fields[0].name)))) : empty_1(), delay(() => singleton_1(Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(4, "detail")))))))))] : [model_1, Cmd_batch(toList(delay(() => append((model_1.maybeRedirectDeviceId == null) ? singleton_1(Navigation_modifyUrl(pageHash(new Page(7, void 0)))) : empty_1(), delay(() => singleton_1(info("This device is not currently streaming or it is not an ex-vivo stream")))))))]) : ((msg_2.tag === 4) ? [new DeviceListModel(model_1.items, model_1.maybeSelected, msg_2.fields[0], model_1.maybeSearchInfo, toList(delay(() => append(filter((modification) => (!equals(modification.Source, new CrudListModification(0))), model_1.listModifications), delay(() => {
                let comparer_1;
                return singleton_1(new ListDisplayModification_ListDisplayModification$2(new CrudListModification(0), 0, (comparer_1 = Sort_SortInfo$1_getSortComparison_576B9F7E(msg_2.fields[0]), (list_3) => sortWith(uncurry(2, comparer_1), list_3))));
            })))), model_1.maybeRedirectDeviceId), Cmd_none()] : ((msg_2.tag === 5) ? [new DeviceListModel(model_1.items, model_1.maybeSelected, model_1.sortInfo, msg_2.fields[0], model_1.listModifications, model_1.maybeRedirectDeviceId), Cmd_none()] : ((msg_2.tag === 6) ? [new DeviceListModel(model_1.items, model_1.maybeSelected, model_1.sortInfo, msg_2.fields[0], toList(delay(() => append(filter((modification_1) => (!equals(modification_1.Source, new CrudListModification(1))), model_1.listModifications), delay(() => singleton_1(new ListDisplayModification_ListDisplayModification$2(new CrudListModification(1), 0, (crudItems) => allColumnSearchModifier(model_1.maybeSearchInfo, columns, crudItems))))))), model_1.maybeRedirectDeviceId), Cmd_none()] : [model_1, Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, getItems, [tupledArg[0], tupledArg[1]], (arg_4) => (new StateTreeMsg$4(0, new DeviceListMsg(1, arg_4))), (arg0_9) => (new StateTreeMsg$4(1, arg0_9)))])));
        }
    });
}

export function deviceDetailState() {
    return new StateTreeNode$6((deps) => {
        const patternInput = init(new TelepathologyDependency(defaultArg(map((d) => d.name, deps.maybeSelected), ""), bind((d_1) => d_1.region, deps.maybeSelected), void 0));
        return [patternInput[0], Cmd_map((arg) => (new StateTreeMsg$4(0, new ExVivoTelepathologyMsg(1, arg))), patternInput[1])];
    }, (model_1, dispatch) => view(model_1, (arg_2) => {
        dispatch(new StateTreeMsg$4(0, new ExVivoTelepathologyMsg(1, arg_2)));
    }, [], [button(singleton(new Option(18, (_arg1) => {
        dispatch(new StateTreeMsg$4(0, new ExVivoTelepathologyMsg(2, "list")));
    })), singleton("Back"))]), uncurry(4, (msg) => ((tupledArg) => {
        const token = tupledArg[0];
        return (deps_1) => ((model_2) => {
            let f2_3;
            switch (msg.tag) {
                case 1: {
                    const patternInput_1 = update(msg.fields[0], new TelepathologyApi(securedApi(token).flagSessionImage, securedApi(token).getCurrentAnatomicalRegion, securedApi(token).getCurrentActiveSessionForDevice, securedApi(token).generateWebRTCPermissionToken, (f2_3 = securedApi(token).telemetry, (arg_3) => f2_3(new TelemetryAction(6, arg_3)))), tupledArg[2], tupledArg[3], model_2);
                    return [patternInput_1[0], Cmd_map((arg_4) => (new StateTreeMsg$4(0, new ExVivoTelepathologyMsg(1, arg_4))), patternInput_1[1])];
                }
                case 2: {
                    console.info(some("VIEWER: Stopping client"));
                    let viewer = document.getElementById("viewer");
                    viewer.srcObject = (void 0);
                    return [model_2, Cmd_batch(ofArray([Navigation_modifyUrl(pageHash(new Page(7, void 0))), Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(3, msg.fields[0])))]))];
                }
                default: {
                    console.error(some(`Error: ${msg.fields[0]}`));
                    return [model_2, Cmd_none()];
                }
            }
        });
    })));
}

export function deviceListInbound(msg, _arg1, model) {
    return [model, Cmd_OfFunc_result(new StateTreeMsg$4(0, new DeviceListMsg(0)))];
}

export function stateTree(maybeRedirectDeviceId) {
    let config_1, inputRecord, current_3, keepCachedChildren_1, child_1, current, config, maybeAutoMessage, key_2, current_2, current_6, config_2, maybeAutoMessage_1, key_5, current_5;
    const columns = ofArray([new Sort_SortableColumn$1("Name", (dvm) => dvm.name, new Sort_Compare$1(0, (dvm1, dvm2) => comparePrimitives(dvm1.name, dvm2.name))), new Sort_SortableColumn$1("Account", (dvm_1) => defaultArg(dvm_1.accountName, "--"), new Sort_Compare$1(0, (dvm1_1, dvm2_1) => compare(dvm1_1.accountName, dvm2_1.accountName))), new Sort_SortableColumn$1("Site", (dvm_2) => defaultArg(dvm_2.siteName, "--"), new Sort_Compare$1(0, (dvm1_2, dvm2_2) => compare(dvm1_2.siteName, dvm2_2.siteName))), new Sort_SortableColumn$1("Region", (dvm_3) => defaultArg(dvm_3.region, "--"), new Sort_Compare$1(0, (dvm1_3, dvm2_3) => compare(dvm1_3.region, dvm2_3.region))), new Sort_SortableColumn$1("Device Status", (dvm_4) => (dvm_4.online ? "🟢 Online" : "🔴 Offline"), new Sort_Compare$1(0, (dvm1_4, dvm2_4) => comparePrimitives(dvm1_4.online, dvm2_4.online))), new Sort_SortableColumn$1("Stream Status", (dvm_5) => ((dvm_5.exVivoStreaming && dvm_5.online) ? "Streaming" : "Not Streaming"), new Sort_Compare$1(0, (dvm1_5, dvm2_5) => comparePrimitives(dvm1_5.exVivoStreaming, dvm2_5.exVivoStreaming)))]);
    return StateTree$3_$ctor_4E60E31B((config_1 = ((inputRecord = defaultConfig(), new StateTreeConfig$6(inputRecord.VisibleToChildren, inputRecord.ReInitOnNavigation, new StateTreeMsg$4(0, new DeviceListMsg(0)), inputRecord.KeepCachedChildren, (msg, arg10$0040, model) => deviceListInbound(msg, void 0, model)))), (current_3 = deviceListState("Devices", "Device", columns, (tupledArg) => singleton_2.Delay(() => (isAllowed(new Permission(29))(tupledArg[1].roles) ? singleton_2.Bind(securedApi(tupledArg[0]).getDevicesInAuthorizedSites(), (_arg1) => singleton_2.Return(Result_mapError()(ErrorMessage_get_describe())(_arg1))) : singleton_2.Return(new FSharpResult$2(0, empty())))), false, maybeRedirectDeviceId), (keepCachedChildren_1 = config_1.KeepCachedChildren, (child_1 = ((current = deviceDetailState(), (config = defaultConfig_1(), (maybeAutoMessage = config.MaybeSendMessageOnNavigation, (key_2 = "detail", (current_2 = current, new Internal_StateTreeRoot$6((_arg2, deps, maybeCachedModel, _arg1_1) => {
        const matchValue = [maybeCachedModel, config.ReInitOnNavigation, maybeAutoMessage];
        let pattern_matching_result, model_1, model_1_1, msg_1;
        if (matchValue[0] != null) {
            if (matchValue[1]) {
                pattern_matching_result = 2;
            }
            else if (matchValue[2] != null) {
                pattern_matching_result = 1;
                model_1_1 = matchValue[0];
                msg_1 = matchValue[2];
            }
            else {
                pattern_matching_result = 0;
                model_1 = matchValue[0];
            }
        }
        else {
            pattern_matching_result = 2;
        }
        switch (pattern_matching_result) {
            case 0: {
                return [model_1, Cmd_none_1()];
            }
            case 1: {
                return [model_1_1, Cmd_OfFunc_result(msg_1)];
            }
            case 2: {
                const patternInput = current_2.Init(deps);
                return [new Internal_StateTreeModel$1(patternInput[0], false), Cmd_batch_1(toList(delay(() => append(singleton_1(patternInput[1]), delay(() => ofArray(toArray(map(Cmd_OfFunc_result, maybeAutoMessage))))))))];
            }
        }
    }, (_arg3, dispatch, model_2) => current_2.View(model_2.NodeModel, dispatch), (_arg4, msg_3_1, pass, deps_1, model_3) => {
        switch (msg_3_1.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_1.fields[0]}`));
                return [model_3, Cmd_none_1(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3.NodeModel, true), Cmd_none_1(), void 0, void 0];
            }
            case 3: {
                return [model_3, Cmd_none_1(), void 0, some(msg_3_1.fields[0])];
            }
            case 4: {
                return [model_3, Cmd_none_1(), msg_3_1.fields[0], void 0];
            }
            default: {
                const patternInput_1 = current_2.Update(msg_3_1.fields[0], pass, deps_1, model_3.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1[0], model_3.Initialized), patternInput_1[1], void 0, void 0];
            }
        }
    }, (msg_4, deps_2, model_4) => {
        const patternInput_2 = config.UpdateInbound(msg_4, deps_2, model_4.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2[0], model_4.Initialized), patternInput_2[1]];
    }, key_2, ofList(singleton([singleton(key_2), singleton(0)]))))))))), (current_6 = ((config_2 = config_1, (maybeAutoMessage_1 = config_2.MaybeSendMessageOnNavigation, (key_5 = "list", (current_5 = current_3, new Internal_StateTreeRoot$6((_arg2_1, deps_3, maybeCachedModel_1, _arg1_2) => {
        const matchValue_1 = [maybeCachedModel_1, config_2.ReInitOnNavigation, maybeAutoMessage_1];
        let pattern_matching_result_1, model_5, model_1_2, msg_5;
        if (matchValue_1[0] != null) {
            if (matchValue_1[1]) {
                pattern_matching_result_1 = 2;
            }
            else if (matchValue_1[2] != null) {
                pattern_matching_result_1 = 1;
                model_1_2 = matchValue_1[0];
                msg_5 = matchValue_1[2];
            }
            else {
                pattern_matching_result_1 = 0;
                model_5 = matchValue_1[0];
            }
        }
        else {
            pattern_matching_result_1 = 2;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                return [model_5, Cmd_none_1()];
            }
            case 1: {
                return [model_1_2, Cmd_OfFunc_result(msg_5)];
            }
            case 2: {
                const patternInput_3 = current_5.Init(deps_3);
                return [new Internal_StateTreeModel$1(patternInput_3[0], false), Cmd_batch_1(toList(delay(() => append(singleton_1(patternInput_3[1]), delay(() => ofArray(toArray(map(Cmd_OfFunc_result, maybeAutoMessage_1))))))))];
            }
        }
    }, (_arg3_1, dispatch_1, model_2_1) => current_5.View(model_2_1.NodeModel, dispatch_1), (_arg4_1, msg_3_2, pass_1, deps_1_1, model_3_1) => {
        switch (msg_3_2.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_2.fields[0]}`));
                return [model_3_1, Cmd_none_1(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3_1.NodeModel, true), Cmd_none_1(), void 0, void 0];
            }
            case 3: {
                return [model_3_1, Cmd_none_1(), void 0, some(msg_3_2.fields[0])];
            }
            case 4: {
                return [model_3_1, Cmd_none_1(), msg_3_2.fields[0], void 0];
            }
            default: {
                const patternInput_1_1 = current_5.Update(msg_3_2.fields[0], pass_1, deps_1_1, model_3_1.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1_1[0], model_3_1.Initialized), patternInput_1_1[1], void 0, void 0];
            }
        }
    }, (msg_4_1, deps_2_1, model_4_1) => {
        const patternInput_2_1 = config_2.UpdateInbound(msg_4_1, deps_2_1, model_4_1.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2_1[0], model_4_1.Initialized), patternInput_2_1[1]];
    }, key_5, ofList(singleton([singleton(key_5), singleton(0)])))))))), new Internal_StateTreeRoot$6((indexPath, deps_4, maybeCachedModel_2, _arg1_3) => {
        const maybeCachedCurrentModel = bind((cachedModel) => cachedModel.MaybeCurrentModel, maybeCachedModel_2);
        const maybeCachedChildModel = bind((cachedModel_1) => cachedModel_1.MaybeChildModel, maybeCachedModel_2);
        const matchValue_2 = [indexPath, maybeCachedCurrentModel];
        let pattern_matching_result_2, currentModel_1, xs;
        if (!isEmpty(matchValue_2[0])) {
            if (head(matchValue_2[0]) === 0) {
                if (isEmpty(tail(matchValue_2[0]))) {
                    pattern_matching_result_2 = 0;
                }
                else if (matchValue_2[1] != null) {
                    pattern_matching_result_2 = 1;
                    currentModel_1 = matchValue_2[1];
                    xs = tail(matchValue_2[0]);
                }
                else {
                    pattern_matching_result_2 = 0;
                }
            }
            else {
                pattern_matching_result_2 = 2;
            }
        }
        else {
            pattern_matching_result_2 = 2;
        }
        switch (pattern_matching_result_2) {
            case 0: {
                const patternInput_4 = current_6.Init(indexPath, deps_4, maybeCachedCurrentModel, false);
                return [new Internal_BranchModelChild$2(patternInput_4[0], keepCachedChildren_1 ? maybeCachedChildModel : (void 0)), Cmd_map_1((arg0_2) => (new Internal_BranchMsgChild$2(0, arg0_2)), patternInput_4[1])];
            }
            case 1: {
                const patternInput_1_2 = child_1.Init(xs, currentModel_1.NodeModel, maybeCachedChildModel, keepCachedChildren_1);
                return [new Internal_BranchModelChild$2(currentModel_1, patternInput_1_2[0]), Cmd_map_1((arg0_2_1) => (new Internal_BranchMsgChild$2(1, arg0_2_1)), patternInput_1_2[1])];
            }
            case 2: {
                console.error(some("Failed to initialize state, invalid index"));
                return [defaultArg(maybeCachedModel_2, new Internal_BranchModelChild$2(void 0, void 0)), Cmd_none_1()];
            }
        }
    }, (indexPath_1, dispatch_2, model_6) => {
        let pattern_matching_result_3, xs_1;
        if (!isEmpty(indexPath_1)) {
            if (head(indexPath_1) === 0) {
                if (isEmpty(tail(indexPath_1))) {
                    pattern_matching_result_3 = 0;
                }
                else {
                    pattern_matching_result_3 = 1;
                    xs_1 = tail(indexPath_1);
                }
            }
            else {
                pattern_matching_result_3 = 2;
            }
        }
        else {
            pattern_matching_result_3 = 2;
        }
        switch (pattern_matching_result_3) {
            case 0: {
                return defaultArg(map(partialApply(1, current_6.View, [indexPath_1, (arg) => {
                    dispatch_2(new Internal_BranchMsgChild$2(0, arg));
                }]), model_6.MaybeCurrentModel), "Loading...");
            }
            case 1: {
                const children = toList(delay(() => append(config_1.VisibleToChildren ? singleton_1(defaultArg(map(partialApply(1, current_6.View, [indexPath_1, (arg_1) => {
                    dispatch_2(new Internal_BranchMsgChild$2(0, arg_1));
                }]), model_6.MaybeCurrentModel), "Loading...")) : empty_1(), delay(() => singleton_1(defaultArg(map(partialApply(1, child_1.View, [xs_1, (arg_2) => {
                    dispatch_2(new Internal_BranchMsgChild$2(1, arg_2));
                }]), model_6.MaybeChildModel), "Loading..."))))));
                return react.createElement("div", {}, ...children);
            }
            case 2: {
                return "Woops, looks like you took a wrong turn...";
            }
        }
    }, (indexPath_2, branchMsg, pass_2, deps_1_2, model_1_3) => {
        let newNodeModel;
        const matchValue_1_1 = [branchMsg, indexPath_2];
        let pattern_matching_result_4, msg_8, xs_2, msg_1_3, xs_3;
        if (matchValue_1_1[0].tag === 1) {
            if (!isEmpty(matchValue_1_1[1])) {
                if (head(matchValue_1_1[1]) === 0) {
                    pattern_matching_result_4 = 1;
                    msg_1_3 = matchValue_1_1[0].fields[0];
                    xs_3 = tail(matchValue_1_1[1]);
                }
                else {
                    pattern_matching_result_4 = 2;
                }
            }
            else {
                pattern_matching_result_4 = 2;
            }
        }
        else if (!isEmpty(matchValue_1_1[1])) {
            if (head(matchValue_1_1[1]) === 0) {
                pattern_matching_result_4 = 0;
                msg_8 = matchValue_1_1[0].fields[0];
                xs_2 = tail(matchValue_1_1[1]);
            }
            else {
                pattern_matching_result_4 = 2;
            }
        }
        else {
            pattern_matching_result_4 = 2;
        }
        switch (pattern_matching_result_4) {
            case 0: {
                const matchValue_2_1 = model_1_3.MaybeCurrentModel;
                if (matchValue_2_1 == null) {
                    console.error(some("Failed to update state, not initialized"));
                    return [model_1_3, Cmd_none_1(), void 0, void 0];
                }
                else {
                    const currentModel_2 = matchValue_2_1;
                    const patternInput_2_2 = current_6.Update(indexPath_2, msg_8, pass_2, deps_1_2, currentModel_2);
                    const newCurrentModel = patternInput_2_2[0];
                    const maybeOutboundMsg = patternInput_2_2[3];
                    const maybeNavMsg = patternInput_2_2[2];
                    const currentCmd_1 = patternInput_2_2[1];
                    let pattern_matching_result_5;
                    if (msg_8.tag === 2) {
                        if (!equals(xs_2, empty())) {
                            pattern_matching_result_5 = 0;
                        }
                        else {
                            pattern_matching_result_5 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_5 = 1;
                    }
                    switch (pattern_matching_result_5) {
                        case 0: {
                            const patternInput_3_1 = child_1.Init(xs_2, currentModel_2.NodeModel, model_1_3.MaybeChildModel, false);
                            return [new Internal_BranchModelChild$2(newCurrentModel, patternInput_3_1[0]), Cmd_batch_1(ofArray([Cmd_map_1((arg0_8) => (new Internal_BranchMsgChild$2(0, arg0_8)), currentCmd_1), Cmd_map_1((arg0_9) => (new Internal_BranchMsgChild$2(1, arg0_9)), patternInput_3_1[1])])), maybeNavMsg, maybeOutboundMsg];
                        }
                        case 1: {
                            return [new Internal_BranchModelChild$2(newCurrentModel, model_1_3.MaybeChildModel), Cmd_map_1((arg0_11) => (new Internal_BranchMsgChild$2(0, arg0_11)), currentCmd_1), maybeNavMsg, maybeOutboundMsg];
                        }
                    }
                }
            }
            case 1: {
                const matchValue_3 = [model_1_3.MaybeCurrentModel, model_1_3.MaybeChildModel];
                let pattern_matching_result_6, childModel_1, currentModel_3;
                if (matchValue_3[0] != null) {
                    if (matchValue_3[1] != null) {
                        pattern_matching_result_6 = 0;
                        childModel_1 = matchValue_3[1];
                        currentModel_3 = matchValue_3[0];
                    }
                    else {
                        pattern_matching_result_6 = 1;
                    }
                }
                else {
                    pattern_matching_result_6 = 1;
                }
                switch (pattern_matching_result_6) {
                    case 0: {
                        const patternInput_4_1 = child_1.Update(xs_3, msg_1_3, pass_2, currentModel_3.NodeModel, childModel_1);
                        const maybeNavMsg_1 = patternInput_4_1[2];
                        return [new Internal_BranchModelChild$2((maybeNavMsg_1 != null) ? ((maybeNavMsg_1.tag === 2) ? ((newNodeModel = maybeNavMsg_1.fields[0], map((model_2_2) => (new Internal_StateTreeModel$1(newNodeModel, model_2_2.Initialized)), model_1_3.MaybeCurrentModel))) : model_1_3.MaybeCurrentModel) : model_1_3.MaybeCurrentModel, patternInput_4_1[0]), Cmd_map_1((arg0_13) => (new Internal_BranchMsgChild$2(1, arg0_13)), patternInput_4_1[1]), map(NavigationMsg$1_destructiveCast_Z33BEA92B, maybeNavMsg_1), patternInput_4_1[3]];
                    }
                    case 1: {
                        console.error(some("Failed to update state, parent not initialized"));
                        return [model_1_3, Cmd_none_1(), void 0, void 0];
                    }
                }
            }
            case 2: {
                console.warn(some("Got an update for a node that shouldn\u0027t be initialized, ignoring"));
                return [model_1_3, Cmd_none_1(), void 0, void 0];
            }
        }
    }, (msg_2_3, deps_2_2, model_3_2) => {
        let patternInput_6;
        const matchValue_4 = model_3_2.MaybeCurrentModel;
        if (matchValue_4 == null) {
            patternInput_6 = [model_3_2.MaybeCurrentModel, Cmd_none_1()];
        }
        else {
            const patternInput_5 = current_6.UpdateInbound(msg_2_3, deps_2_2, matchValue_4);
            patternInput_6 = [patternInput_5[0], patternInput_5[1]];
        }
        let patternInput_8;
        const matchValue_5 = [model_3_2.MaybeChildModel, model_3_2.MaybeCurrentModel];
        let pattern_matching_result_7, childModel_2, currentModel_5;
        if (matchValue_5[0] != null) {
            if (matchValue_5[1] != null) {
                pattern_matching_result_7 = 0;
                childModel_2 = matchValue_5[0];
                currentModel_5 = matchValue_5[1];
            }
            else {
                pattern_matching_result_7 = 1;
            }
        }
        else {
            pattern_matching_result_7 = 1;
        }
        switch (pattern_matching_result_7) {
            case 0: {
                const patternInput_7 = child_1.UpdateInbound(msg_2_3, currentModel_5.NodeModel, childModel_2);
                patternInput_8 = [patternInput_7[0], patternInput_7[1]];
                break;
            }
            case 1: {
                patternInput_8 = [model_3_2.MaybeChildModel, Cmd_none_1()];
                break;
            }
        }
        return [new Internal_BranchModelChild$2(patternInput_6[0], patternInput_8[0]), Cmd_batch_1(ofArray([Cmd_map_1((arg0_14) => (new Internal_BranchMsgChild$2(0, arg0_14)), patternInput_6[1]), Cmd_map_1((arg0_15) => (new Internal_BranchMsgChild$2(1, arg0_15)), patternInput_8[1])]))];
    }, current_6.LastKey, Internal_combineChildIndexPathFromKeyPathMap(current_6.LastKey, child_1.IndexPathFromKeyPath, current_6.IndexPathFromKeyPath))))))));
}

