import { getConfiguration, } from '@cornerstonejs/core';
// All the cornerstoneDicomImageLoader functions are cordoned off in wrappers in this file
// because for some reason it doesn't come with typescript definitions, despite being written
// in typescript
import cornerstoneDicomImageLoader from '@cornerstonejs/dicom-image-loader';
let imageLoaderInitialized = false;
export function initCornerstoneDicomImageLoader() {
    if (!imageLoaderInitialized) {
        const { preferSizeOverAccuracy } = getConfiguration().rendering;
        cornerstoneDicomImageLoader.init({
            maxWebWorkers: 1,
            decodeConfig: {
                convertFloatPixelDataToInt: false,
                use16BitDataType: preferSizeOverAccuracy
            }
        });
        imageLoaderInitialized = true;
    }
}
let fileToIndexMap = new Map(); //Map from file name to imageId
let indexToFileMap = new Map(); //Map from imageId to file name
function customAdd(file) {
    if (!fileToIndexMap.get(file.name)) {
        const imageIndex = cornerstoneDicomImageLoader.wadouri.fileManager.add(file);
        fileToIndexMap.set(file.name, imageIndex);
        indexToFileMap.set(imageIndex, file.name);
    }
    return fileToIndexMap.get(file.name);
}
export function addFilesToDicomLoader(files) {
    return files.map((file) => customAdd(file));
}
export { fileToIndexMap, indexToFileMap };
