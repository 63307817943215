import { toString as toString_1, Record, Union } from "../fable_modules/fable-library.3.7.12/Types.js";
import { enum_type, int32_type, getEnumValues, record_type, class_type, union_type, string_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { isNullOrWhiteSpace, printf, toText } from "../fable_modules/fable-library.3.7.12/String.js";
import { toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { ofArray, tryFind } from "../fable_modules/fable-library.3.7.12/List.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.12/Choice.js";
import { isMatch } from "../fable_modules/fable-library.3.7.12/RegExp.js";

export class Terminate_TerminateError extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Generic", "Custom", "UncancellableOperation"];
    }
}

export function Terminate_TerminateError$reflection() {
    return union_type("Optiscan.Models.Shared.Terminate.TerminateError", [], Terminate_TerminateError, () => [[], [["errorMessage", string_type]], [["operation", string_type]]]);
}

export function Terminate_TerminateError_get_describe() {
    return (_arg1) => ((_arg1.tag === 1) ? _arg1.fields[0] : ((_arg1.tag === 2) ? toText(printf("Unable to cancel %s operation"))(_arg1.fields[0]) : "Generic termination error"));
}

export class AssignedSiteId extends Record {
    constructor(siteId, accountId) {
        super();
        this.siteId = siteId;
        this.accountId = accountId;
    }
}

export function AssignedSiteId$reflection() {
    return record_type("Optiscan.Models.Shared.AssignedSiteId", [], AssignedSiteId, () => [["siteId", class_type("System.Guid")], ["accountId", class_type("System.Guid")]]);
}

export function AssignedSiteId_get_siteId_() {
    return [(asid) => asid.siteId, (sid) => ((asid_1) => (new AssignedSiteId(sid, asid_1.accountId)))];
}

export function AssignedSiteId_get_accountId_() {
    return [(asid) => asid.accountId, (aid) => ((asid_1) => (new AssignedSiteId(asid_1.siteId, aid)))];
}

export const Assessment_TypeModule_all = toList(getEnumValues(enum_type("Optiscan.Models.Shared.Assessment.Type", int32_type, [["SummaryDiagnosis", 0], ["ClinicalHistoryInformation", 1], ["GeneralNotes", 2], ["Macroscopic", 3], ["Microscopic", 4]])));

export function Assessment_TypeModule_describe(value) {
    switch (value) {
        case 0: {
            return "Summary Diagnosis";
        }
        case 1: {
            return "Clinical History Information";
        }
        case 2: {
            return "General Notes";
        }
        case 3: {
            return "Macroscopic";
        }
        case 4: {
            return "Microscopic";
        }
        default: {
            return "Unknown";
        }
    }
}

export function tryParse(toString, all, v) {
    return tryFind((x) => (toString(x) === v), all);
}

export class ReportType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Identified", "Deidentified"];
    }
}

export function ReportType$reflection() {
    return union_type("Optiscan.Models.Shared.ReportType", [], ReportType, () => [[], []]);
}

export function ReportType_get_all() {
    return ofArray([new ReportType(0), new ReportType(1)]);
}

export function ReportType_get_display() {
    return toString_1;
}

export function ReportType_get_tryParse() {
    const all = ReportType_get_all();
    return (v) => tryParse(toString_1, all, v);
}

export class PatientIdentidfiableInformation extends Record {
    constructor(id, firstName, lastName) {
        super();
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
    }
}

export function PatientIdentidfiableInformation$reflection() {
    return record_type("Optiscan.Models.Shared.PatientIdentidfiableInformation", [], PatientIdentidfiableInformation, () => [["id", string_type], ["firstName", string_type], ["lastName", string_type]]);
}

export function PatientIdentidfiableInformation_get_id_() {
    return [(m) => m.id, (v) => ((m_1) => (new PatientIdentidfiableInformation(v, m_1.firstName, m_1.lastName)))];
}

export function PatientIdentidfiableInformation_get_firstName_() {
    return [(m) => m.firstName, (v) => ((m_1) => (new PatientIdentidfiableInformation(m_1.id, v, m_1.lastName)))];
}

export function PatientIdentidfiableInformation_get_lastName_() {
    return [(m) => m.lastName, (v) => ((m_1) => (new PatientIdentidfiableInformation(m_1.id, m_1.firstName, v)))];
}

export function PatientIdentidfiableInformation_get_empty() {
    return new PatientIdentidfiableInformation("", "", "");
}

export function PatientIdentidfiableInformation_validate_Z2CA7CC3A(pii) {
    if (isNullOrWhiteSpace(pii.firstName)) {
        return new FSharpResult$2(1, "First name cannot be empty");
    }
    else if (!isMatch(/^[a-zA-Z]+$/g, pii.firstName)) {
        return new FSharpResult$2(1, "First name cannot contain numbers, special characters, or spaces");
    }
    else if (isNullOrWhiteSpace(pii.lastName)) {
        return new FSharpResult$2(1, "Last name cannot be empty");
    }
    else if (!isMatch(/^[a-zA-Z]+$/g, pii.lastName)) {
        return new FSharpResult$2(1, "Last name cannot contain numbers, special characters, or spaces");
    }
    else if (isNullOrWhiteSpace(pii.id)) {
        return new FSharpResult$2(1, "ID cannot be empty");
    }
    else if (!isMatch(/^[a-zA-Z0-9]+$/g, pii.id)) {
        return new FSharpResult$2(1, "ID cannot contain special characters, or spaces");
    }
    else {
        return new FSharpResult$2(0, void 0);
    }
}

