import { Union } from "./fable_modules/fable-library.3.7.12/Types.js";
import { class_type, union_type, option_type, string_type } from "./fable_modules/fable-library.3.7.12/Reflection.js";
import { printf, toText } from "./fable_modules/fable-library.3.7.12/String.js";

export class AuthPage extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Login", "ResetPassword", "EmailChange", "RequestPasswordReset", "Register"];
    }
}

export function AuthPage$reflection() {
    return union_type("Client.Pages.Pages.AuthPage", [], AuthPage, () => [[], [["token", option_type(string_type)]], [["maybeCurrentEmail", option_type(string_type)], ["maybeNewEmail", option_type(string_type)], ["maybeToken", option_type(string_type)]], [], []]);
}

export function AuthPage_get_tryParse() {
    return (_arg1) => ((_arg1 === "login") ? (new AuthPage(0)) : ((_arg1 === "resetPassword") ? (new AuthPage(1, void 0)) : ((_arg1 === "emailChange") ? (new AuthPage(2, void 0, void 0, void 0)) : ((_arg1 === "requestPasswordReset") ? (new AuthPage(3)) : ((_arg1 === "register") ? (new AuthPage(4)) : (void 0))))));
}

export class Page extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Auth", "TelepathologyGuest", "Accounts", "Sites", "AuthorizedSites", "Devices", "PatientSearch", "ExVivo", "Users", "UserProfile", "Dashboard"];
    }
}

export function Page$reflection() {
    return union_type("Client.Pages.Pages.Page", [], Page, () => [[["Item", AuthPage$reflection()]], [["code", option_type(string_type)]], [], [["accountId", class_type("System.Guid")]], [], [], [["maybePatientId", option_type(class_type("System.Guid"))], ["maybeSessionId", option_type(class_type("System.Guid"))]], [["thingName", option_type(string_type)]], [], [], []]);
}

export const hashPrefix = (() => {
    const clo1 = toText(printf("?#%s"));
    return clo1;
})();

export const ofAuth = (() => {
    const clo2 = toText(printf("%s/%s"))("auth");
    return clo2;
})();

export function pageHash(_arg1) {
    let pattern_matching_result, token, currentEmail, newEmail, token_1, token_2, accountId, patientId, sessionId, patientId_1, thingName;
    if (_arg1.tag === 1) {
        if (_arg1.fields[0] == null) {
            pattern_matching_result = 8;
        }
        else {
            pattern_matching_result = 7;
            token_2 = _arg1.fields[0];
        }
    }
    else if (_arg1.tag === 2) {
        pattern_matching_result = 9;
    }
    else if (_arg1.tag === 3) {
        pattern_matching_result = 10;
        accountId = _arg1.fields[0];
    }
    else if (_arg1.tag === 4) {
        pattern_matching_result = 11;
    }
    else if (_arg1.tag === 5) {
        pattern_matching_result = 12;
    }
    else if (_arg1.tag === 6) {
        if (_arg1.fields[0] == null) {
            pattern_matching_result = 15;
        }
        else if (_arg1.fields[1] != null) {
            pattern_matching_result = 13;
            patientId = _arg1.fields[0];
            sessionId = _arg1.fields[1];
        }
        else {
            pattern_matching_result = 14;
            patientId_1 = _arg1.fields[0];
        }
    }
    else if (_arg1.tag === 7) {
        if (_arg1.fields[0] == null) {
            pattern_matching_result = 17;
        }
        else {
            pattern_matching_result = 16;
            thingName = _arg1.fields[0];
        }
    }
    else if (_arg1.tag === 8) {
        pattern_matching_result = 18;
    }
    else if (_arg1.tag === 9) {
        pattern_matching_result = 19;
    }
    else if (_arg1.tag === 10) {
        pattern_matching_result = 20;
    }
    else if (_arg1.fields[0].tag === 1) {
        if (_arg1.fields[0].fields[0] != null) {
            pattern_matching_result = 2;
            token = _arg1.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else if (_arg1.fields[0].tag === 3) {
        pattern_matching_result = 3;
    }
    else if (_arg1.fields[0].tag === 4) {
        pattern_matching_result = 4;
    }
    else if (_arg1.fields[0].tag === 2) {
        if (_arg1.fields[0].fields[0] != null) {
            if (_arg1.fields[0].fields[1] != null) {
                if (_arg1.fields[0].fields[2] != null) {
                    pattern_matching_result = 5;
                    currentEmail = _arg1.fields[0].fields[1];
                    newEmail = _arg1.fields[0].fields[2];
                    token_1 = _arg1.fields[0].fields[0];
                }
                else {
                    pattern_matching_result = 6;
                }
            }
            else {
                pattern_matching_result = 6;
            }
        }
        else {
            pattern_matching_result = 6;
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            return hashPrefix(ofAuth("login"));
        }
        case 1: {
            return hashPrefix(ofAuth("resetPassword"));
        }
        case 2: {
            return hashPrefix(ofAuth(toText(printf("%s?token=%s"))("resetPassword")(token)));
        }
        case 3: {
            return hashPrefix(ofAuth("requestPasswordReset"));
        }
        case 4: {
            return hashPrefix(ofAuth("register"));
        }
        case 5: {
            return hashPrefix(ofAuth(toText(printf("%s?currEmail=%s\u0026newEmail=%s\u0026token=%s"))("emailChange")(currentEmail)(newEmail)(token_1)));
        }
        case 6: {
            return hashPrefix(ofAuth("emailChange"));
        }
        case 7: {
            return hashPrefix(toText(printf("%s?token=%s"))("guest")(token_2));
        }
        case 8: {
            return hashPrefix("guest");
        }
        case 9: {
            return hashPrefix("accounts");
        }
        case 10: {
            return hashPrefix(toText(printf("%s/%O/%s"))("accounts")(accountId)("sites"));
        }
        case 11: {
            return hashPrefix("authorizedSites");
        }
        case 12: {
            return hashPrefix("devices");
        }
        case 13: {
            return hashPrefix(`${"patientSearch"}/${patientId}/details/${sessionId}`);
        }
        case 14: {
            return hashPrefix(`${"patientSearch"}/${patientId_1}`);
        }
        case 15: {
            return hashPrefix("patientSearch");
        }
        case 16: {
            return hashPrefix(`${"exVivo"}/${thingName}`);
        }
        case 17: {
            return hashPrefix("exVivo");
        }
        case 18: {
            return hashPrefix("users");
        }
        case 19: {
            return hashPrefix("userProfile");
        }
        case 20: {
            return hashPrefix("dashboard");
        }
    }
}

