import { Card_foot, Card_body, Card_title, Card_head, Card_card, Option, modal } from "../../fable_modules/Fulma.2.16.0/Components/Modal.fs.js";
import { exists as exists_1, collect as collect_1, mapIndexed, map, empty, ofArray, singleton } from "../../fable_modules/fable-library.3.7.12/List.js";
import { append, singleton as singleton_1, delay, toList, collect, length } from "../../fable_modules/fable-library.3.7.12/Seq.js";
import { exists, toList as toList_1, change, FSharpMap__get_Values } from "../../fable_modules/fable-library.3.7.12/Map.js";
import { Common_GenericOption } from "../../fable_modules/Fulma.2.16.0/Common.fs.js";
import { HTMLAttr } from "../../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { ScreenshotModalModel, Msg } from "./ScreenshotModalTypes.js";
import { Helpers_nothing } from "../../fable_modules/Fable.React.7.4.3/Fable.React.Helpers.fs.js";
import { FormField_textArea, FormField_onChangeSet, FormField_text } from "../../Optiscan.SharedUI/Forms.js";
import { map as map_1, bind, defaultArg } from "../../fable_modules/fable-library.3.7.12/Option.js";
import { Option as Option_1 } from "../../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { ScreenshotDetailViewModel, ScreenshotInfoViewModel } from "../../Optiscan.Models/View/ScreenshotInfoViewModel.js";
import { Option as Option_2, columns } from "../../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { column } from "../../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import * as react from "react";
import { Option as Option_3 } from "../../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { Option as Option_4 } from "../../fable_modules/Fulma.2.16.0/Elements/Form/Textarea.fs.js";
import { keyValueList } from "../../fable_modules/fable-library.3.7.12/MapUtil.js";
import { Option as Option_5, button } from "../../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { isNullOrWhiteSpace } from "../../fable_modules/fable-library.3.7.12/String.js";

export function screenshotModal(model, dispatch, onSaveClicked, onCancelClicked) {
    let matchValue, props_9, children_6;
    return modal(ofArray([new Option(1, true), new Option(0, singleton(["style", {
        minWidth: "100vw",
    }]))]), singleton(Card_card(empty(), ofArray([Card_head(empty(), singleton(Card_title(empty(), singleton((matchValue = model.maybeScreenshotInfo, (matchValue != null) ? ((length(collect((x) => x, FSharpMap__get_Values(matchValue.sessionScreenshotDetails))) > 1) ? "Captured Images" : "Captured Image") : ""))))), Card_body(singleton(new Common_GenericOption(1, singleton(["style", {
        overflowY: "auto",
        scrollBehavior: "smooth",
    }]))), singleton((props_9 = [new HTMLAttr(64, (model.isLoading ? true : model.isSaving) ? "is-loading" : "")], (children_6 = toList(delay(() => {
        const dispatchChange = (arg) => {
            dispatch(new Msg(2, arg));
        };
        const matchValue_1 = model.maybeScreenshotInfo;
        if (matchValue_1 == null) {
            return singleton_1(Helpers_nothing);
        }
        else {
            const screenshotInfo = matchValue_1;
            return append(singleton_1(FormField_text(defaultArg(screenshotInfo.name, ""), empty(), [new Option_1(4, model.isSaving)], FormField_onChangeSet(dispatchChange, (m, updatedName) => (new ScreenshotModalModel(m.isLoading, m.isSaving, new ScreenshotInfoViewModel(screenshotInfo.screenshotId, screenshotInfo.sessionScreenshotDetails, updatedName, screenshotInfo.dateCreated)))), "CapturedImage", "Captured Image Name", [])), delay(() => singleton_1(columns(singleton(new Option_2(2)), singleton(column(empty(), map((tupledArg_1) => {
                const detail = tupledArg_1[1];
                const children_4 = toList(delay(() => append(singleton_1(react.createElement("hr", {
                    id: detail.s3Key,
                })), delay(() => {
                    let children_2;
                    return append(singleton_1((children_2 = toList(delay(() => {
                        let matchValue_2, dataUrl;
                        return append((matchValue_2 = detail.dataUrl, (matchValue_2 == null) ? singleton_1(Helpers_nothing) : ((dataUrl = matchValue_2, singleton_1(react.createElement("img", {
                            src: dataUrl,
                        }))))), delay(() => singleton_1(react.createElement("figcaption", {}, detail.locationDescription))));
                    })), react.createElement("figure", {}, ...children_2))), delay(() => {
                        let arg70;
                        return singleton_1((arg70 = singleton(new Option_3(11, singleton(["style", {
                            minHeight: "unset",
                        }]))), FormField_textArea("Captured Image Description", detail.description, empty(), [new Option_4(9, model.isSaving)], FormField_onChangeSet(dispatchChange, (m_1, updatedDescription) => (new ScreenshotModalModel(m_1.isLoading, m_1.isSaving, new ScreenshotInfoViewModel(screenshotInfo.screenshotId, change(tupledArg_1[0], (maybeDetails) => {
                            if (maybeDetails != null) {
                                return mapIndexed((i_1, d_1) => {
                                    if (i_1 === tupledArg_1[2]) {
                                        return new ScreenshotDetailViewModel(updatedDescription, d_1.locationDescription, d_1.s3Key, d_1.dataUrl);
                                    }
                                    else {
                                        return d_1;
                                    }
                                }, maybeDetails);
                            }
                            else {
                                return void 0;
                            }
                        }, screenshotInfo.sessionScreenshotDetails), screenshotInfo.name, screenshotInfo.dateCreated)))), "CapturedImages", "Description")(arg70)));
                    }));
                }))));
                return react.createElement(react.Fragment, {}, ...children_4);
            }, collect_1((tupledArg) => mapIndexed((i, d) => [tupledArg[0], d, i], tupledArg[1]), toList_1(screenshotInfo.sessionScreenshotDetails)))))))));
        }
    })), react.createElement("div", keyValueList(props_9, 1), ...children_6))))), Card_foot(empty(), ofArray([button(ofArray([new Option_5(18, onSaveClicked), new Option_5(16, (model.isSaving ? true : isNullOrWhiteSpace(defaultArg(bind((s_3) => s_3.name, model.maybeScreenshotInfo), ""))) ? true : defaultArg(map_1((s_4) => exists((_arg1, details_2) => exists_1((detail_1) => isNullOrWhiteSpace(detail_1.description), details_2), s_4.sessionScreenshotDetails), model.maybeScreenshotInfo), true))]), singleton("Save")), button(ofArray([new Option_5(18, onCancelClicked), new Option_5(16, model.isLoading ? true : model.isSaving)]), singleton("Cancel"))]))]))));
}

