import { some, map, defaultArg } from "../fable_modules/fable-library.3.7.12/Option.js";
import { isAllowed } from "../Optiscan.Models/Security.js";
import { empty, ofArray, singleton, fold, reduce, head, tail, isEmpty, map as map_1 } from "../fable_modules/fable-library.3.7.12/List.js";
import { equals, uncurry } from "../fable_modules/fable-library.3.7.12/Util.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.12/String.js";
import { tryParse } from "../fable_modules/fable-library.3.7.12/Int32.js";
import { Union, FSharpRef } from "../fable_modules/fable-library.3.7.12/Types.js";
import { Option, container } from "../fable_modules/Fulma.2.16.0/Layouts/Container.fs.js";
import { HTMLAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { Naming_UIElement, Naming_UIElement__get_asString, Naming_mkIdFromStrings } from "../fable_modules/Webbler.Models.1.2.6/Common.fs.js";
import { div } from "../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { Option as Option_1, label as label_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/Label.fs.js";
import { div as div_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/Control.fs.js";
import { singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { Option as Option_2 } from "../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { match } from "../fable_modules/fable-library.3.7.12/RegExp.js";
import * as react from "react";
import { keyValueList } from "../fable_modules/fable-library.3.7.12/MapUtil.js";
import { Security_AuthErrorMessage } from "../fable_modules/Webbler.Models.1.2.6/Api.fs.js";
import { ErrorMessage } from "../Optiscan.Models/ErrorMessage.js";
import { union_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { Cmd_map, Cmd_batch } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";

export function Common_isAllowed(p) {
    return (arg) => defaultArg(map((ui) => isAllowed(p)(ui.roles), arg), false);
}

export function Common_isAllowedOneOf(permissions, roles) {
    const matchValue = map_1((p) => Common_isAllowed(p)(roles), permissions);
    if (!isEmpty(matchValue)) {
        if (isEmpty(tail(matchValue))) {
            return head(matchValue);
        }
        else {
            return reduce((e1, e2) => (e1 || e2), matchValue);
        }
    }
    else {
        return true;
    }
}

export function Common_combine(xs) {
    let clo1;
    return fold(uncurry(2, (clo1 = toText(printf("%s/%s")), (arg10) => {
        const clo2 = clo1(arg10);
        return clo2;
    })), "", xs);
}

export function Common_$007CInt$007C_$007C(input) {
    let matchValue;
    let outArg = 0;
    matchValue = [tryParse(input, 511, false, 32, new FSharpRef(() => outArg, (v) => {
        outArg = (v | 0);
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function Common_InputElement_ref(value, e) {
    if (!(e == null)) {
        if (!equals(e.value, value)) {
            e.value = value;
        }
    }
}

export function Common_containerWithContent(id, content) {
    return container(ofArray([new Option(0), new Option(3, singleton(new HTMLAttr(99, id)))]), singleton(content));
}

export function Common_mkId(view, label, element) {
    return new HTMLAttr(99, Naming_mkIdFromStrings(ofArray([view, label, Naming_UIElement__get_asString(element)])));
}

export function Common_mkField(view, input, inputProps, label, value, onChange) {
    const mkViewId = (element) => Common_mkId(view, label, element);
    return div(empty(), ofArray([label_1(singleton(new Option_1(3, singleton(mkViewId(new Naming_UIElement(1, new Naming_UIElement(0)))))), singleton(label)), div_1(empty(), singleton(input(ofArray([new Option_2(15, toList(delay(() => append(singleton_1(mkViewId(new Naming_UIElement(0))), delay(() => inputProps))))), new Option_2(14, (e) => {
        Common_InputElement_ref(value, e);
    }), new Option_2(13, (e_1) => {
        onChange(e_1.currentTarget.value);
    })]))))]));
}

export function Common_passwordRequirementTracker(password) {
    let children_14, props_12, children_12, children_2, arg10, children_4, arg10_1, children_6, arg10_2, children_8, arg10_3, children_10, arg10_4;
    const asSymbol = (isValid) => {
        if (isValid) {
            return "✅";
        }
        else {
            return "❌";
        }
    };
    const hasValidLength = password.length >= 8;
    const hasUppercase = match(/[A-Z]/g, password) != null;
    const hasLowercase = match(/[a-z]/g, password) != null;
    const hasNumber = match(/[0-9]/g, password) != null;
    const hasSpecial = match(/\W/g, password) != null;
    const isValidPassword = (((hasUppercase && hasLowercase) && hasNumber) && hasSpecial) && hasValidLength;
    return [(children_14 = [react.createElement("p", {}, "Password requirements:"), (props_12 = [["style", {
        listStyle: "disc",
    }]], (children_12 = [(children_2 = [(arg10 = asSymbol(hasValidLength), toText(printf("%s Minimum 8 Characters long"))(arg10))], react.createElement("li", {}, ...children_2)), (children_4 = [(arg10_1 = asSymbol(hasUppercase), toText(printf("%s At least 1 Uppercase letter (A - Z)"))(arg10_1))], react.createElement("li", {}, ...children_4)), (children_6 = [(arg10_2 = asSymbol(hasLowercase), toText(printf("%s At least 1 Lowercase letter (a - z)"))(arg10_2))], react.createElement("li", {}, ...children_6)), (children_8 = [(arg10_3 = asSymbol(hasNumber), toText(printf("%s At least 1 number (0 - 9)"))(arg10_3))], react.createElement("li", {}, ...children_8)), (children_10 = [(arg10_4 = asSymbol(hasSpecial), toText(printf("%s At least 1 non Alphanumeric character (@, #, %%, etc...)"))(arg10_4))], react.createElement("li", {}, ...children_10))], react.createElement("ul", keyValueList(props_12, 1), ...children_12)))], react.createElement("div", {
        id: "password-tracker",
    }, ...children_14)), isValidPassword];
}

export function Common_eraseExceptionToAuthError(ex) {
    console.error(some(`${ex}`));
    return new Security_AuthErrorMessage(0);
}

export function Common_eraseExceptionToError(ex) {
    console.error(some(`${ex}`));
    return new ErrorMessage(0);
}

export class InOut_InternalMsg$2 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Local", "Inbound"];
    }
}

export function InOut_InternalMsg$2$reflection(gen0, gen1) {
    return union_type("Client.Common.InOut.InternalMsg`2", [gen0, gen1], InOut_InternalMsg$2, () => [[["Item", gen0]], [["Item", gen1]]]);
}

export class InOut_Msg$3 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Internal", "External"];
    }
}

export function InOut_Msg$3$reflection(gen0, gen1, gen2) {
    return union_type("Client.Common.InOut.Msg`3", [gen0, gen1, gen2], InOut_Msg$3, () => [[["Item", InOut_InternalMsg$2$reflection(gen0, gen1)]], [["Item", gen2]]]);
}

export function InOut_wrapLocalMsg(msg) {
    return new InOut_Msg$3(0, new InOut_InternalMsg$2(0, msg));
}

export function InOut_wrapInboundMsg(msg) {
    return new InOut_Msg$3(0, new InOut_InternalMsg$2(1, msg));
}

export function InOut_wrapOutboundMsg(msg) {
    return new InOut_Msg$3(1, msg);
}

export function InOut_update(updateLocal, updateInbound, args, msg, model) {
    const patternInput = (msg.tag === 0) ? updateLocal(args, msg.fields[0], model) : updateInbound(args, msg.fields[0], model);
    return [patternInput[0], Cmd_batch(ofArray([Cmd_map(InOut_wrapLocalMsg, patternInput[1]), Cmd_map((arg0) => (new InOut_Msg$3(1, arg0)), patternInput[2])]))];
}

