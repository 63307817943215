import { label } from "../fable_modules/Fulma.2.16.0/Elements/Form/Label.fs.js";
import { ofArray, singleton, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { input } from "../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { Option, IInputType } from "../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { InOut_wrapLocalMsg } from "../Common/Common.js";
import { LocalMsg, Model_get_newEmail_ } from "./EmailChangeTypes.js";
import { Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import * as react from "react";
import { Card_foot, Card_body, Card_title, Card_head, Card_card, Option as Option_1, modal } from "../fable_modules/Fulma.2.16.0/Components/Modal.fs.js";
import { Option as Option_2, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";

export function mainView(model, dispatch) {
    const children = [label(empty(), singleton("New Email Address")), input(ofArray([new Option(1, new IInputType(0)), new Option(13, (e) => {
        let optic, value;
        dispatch(InOut_wrapLocalMsg(new LocalMsg(1, (optic = Model_get_newEmail_(), (value = e.currentTarget.value, Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic)(value))))));
    })]))];
    return react.createElement(react.Fragment, {}, ...children);
}

export function view(model, dispatch) {
    return mainView(model, dispatch);
}

export function emailChangeModal(emailChangeModel, dispatch, onClickChangeEmailAddress, onClickCancel) {
    return modal(singleton(new Option_1(1, true)), singleton(Card_card(empty(), ofArray([Card_head(empty(), singleton(Card_title(empty(), singleton("Email Change")))), Card_body(empty(), singleton(view(emailChangeModel, dispatch))), Card_foot(empty(), ofArray([button(singleton(new Option_2(18, onClickChangeEmailAddress)), singleton("Change Email Address")), button(ofArray([new Option_2(18, onClickCancel), new Option_2(19, "is-secondary")]), singleton("Cancel"))]))]))));
}

