import { compare, comparePrimitives, uncurry, int32ToString } from "../../fable_modules/fable-library.3.7.12/Util.js";
import { Sort_SortInfo$1_getSortComparison_576B9F7E, Sort_itemViewListSortable, Sort_SortableColumnView$1, Sort_Compare$1 } from "../../Optiscan.SharedUI/Sort.js";
import { substring } from "../../fable_modules/fable-library.3.7.12/String.js";
import { empty as empty_1, sortWith, map, concat, ofArray, singleton } from "../../fable_modules/fable-library.3.7.12/List.js";
import { Tooltip_tooltip } from "../../Optiscan.SharedUI/ComponentVisibility.js";
import { Option, button } from "../../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { Fa_ISize, Fa_IconOption, Fa_i } from "../../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import * as react from "react";
import { keyValueList } from "../../fable_modules/fable-library.3.7.12/MapUtil.js";
import { HTMLAttr } from "../../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { empty } from "../../fable_modules/fable-library.3.7.12/Seq.js";

export function flaggedImagesTableColumns(sendToViewportCallback) {
    return ofArray([new Sort_SortableColumnView$1("Frame", (fi) => int32ToString(fi.frameIndex), uncurry(2, void 0), new Sort_Compare$1(0, (fi1, fi2) => comparePrimitives(fi1.frameIndex, fi2.frameIndex))), new Sort_SortableColumnView$1("Location", (fi_1) => {
        let cutLocation;
        const fullLocation = `${fi_1.locationName}, ${fi_1.siteName}, ${fi_1.sequenceName}`;
        return (fullLocation.length > 55) ? ((cutLocation = substring(fullLocation, 0, 52), `${cutLocation}...`)) : fullLocation;
    }, uncurry(2, void 0), new Sort_Compare$1(0, (fi1_1, fi2_1) => compare(singleton([fi1_1.locationIndex, fi1_1.siteIndex, fi1_1.sequenceIndex]), singleton([fi2_1.locationIndex, fi2_1.siteIndex, fi2_1.sequenceIndex])))), new Sort_SortableColumnView$1("", (fi_2) => {
        const props = [["style", {
            float: "right",
        }]];
        const children = [Tooltip_tooltip("Send to Viewport", void 0, true, button(ofArray([new Option(17, singleton(["style", {
            marginRight: "1rem",
            paddingLeft: "0.5em",
            paddingRight: "0.5em",
        }])), new Option(18, (_arg1) => {
            sendToViewportCallback(fi_2);
        })]), ofArray([Fa_i(ofArray([new Fa_IconOption(11, "fas fa-arrow-right"), new Fa_IconOption(0, new Fa_ISize(0)), new Fa_IconOption(14, singleton(["style", {
            paddingRight: "0.25em",
        }]))]), []), Fa_i(ofArray([new Fa_IconOption(11, "fas fa-border-all"), new Fa_IconOption(0, new Fa_ISize(0))]), [])])))];
        return react.createElement("div", keyValueList(props, 1), ...children);
    }, uncurry(2, void 0), new Sort_Compare$1(0, (_arg3, _arg2) => 0))]);
}

export function flaggedImagesTableContents(metadata, sortInfo, sendToViewportCallback, sortCallback) {
    const flaggedImages = concat(map((m) => m.flaggedImages, metadata));
    const props = [new HTMLAttr(64, "table-container"), ["style", {
        height: "40%",
    }]];
    const children = [Sort_itemViewListSortable("FlaggedImages", flaggedImagesTableColumns(sendToViewportCallback), (_arg2, _arg1) => {
    }, sortWith(uncurry(2, Sort_SortInfo$1_getSortComparison_576B9F7E(sortInfo)), flaggedImages), (_arg3) => empty(), sortInfo, sortCallback, (_arg4) => empty_1())];
    return react.createElement("div", keyValueList(props, 1), ...children);
}

