import { content } from "../fable_modules/Fulma.2.16.0/Elements/Content.fs.js";
import { empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { singleton, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { Helpers_nothing } from "../fable_modules/Fable.React.7.4.3/Fable.React.Helpers.fs.js";
import { StateTree$3__getIndexPathFromCursor_1334CEF1, StateTree$3__get_root } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { Msg } from "./ExVivoTypes.js";
import { ErasedMsg$2 } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";

export function view(model, dispatch) {
    return content(empty(), toList(delay(() => {
        let this$, model_1;
        const matchValue = model.stateTree;
        return (matchValue == null) ? singleton(Helpers_nothing) : singleton((this$ = matchValue, (model_1 = model.crudListModel, StateTree$3__get_root(this$).View(StateTree$3__getIndexPathFromCursor_1334CEF1(this$, model_1.cursor), (arg) => {
            ((arg_1) => {
                dispatch(new Msg(3, arg_1));
            })(new ErasedMsg$2(0, arg));
        }, model_1.value))));
    })));
}

