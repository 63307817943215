import { defaultArg } from "../../fable_modules/fable-library.3.7.12/Option.js";
import { compare, uncurry } from "../../fable_modules/fable-library.3.7.12/Util.js";
import { Sort_SortInfo$1_getSortComparison_576B9F7E, Sort_itemViewListSortable, Sort_SortableColumnView$1, Sort_Compare$1 } from "../../Optiscan.SharedUI/Sort.js";
import { Tooltip_tooltip } from "../../Optiscan.SharedUI/ComponentVisibility.js";
import { Option, button } from "../../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { empty, sortWith, ofArray, singleton } from "../../fable_modules/fable-library.3.7.12/List.js";
import { Fa_ISize, Fa_IconOption, Fa_i } from "../../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import * as react from "react";
import { keyValueList } from "../../fable_modules/fable-library.3.7.12/MapUtil.js";
import { DOMAttr, HTMLAttr } from "../../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { Color_IColor } from "../../fable_modules/Fulma.2.16.0/Common.fs.js";
import { singleton as singleton_1, append, delay } from "../../fable_modules/fable-library.3.7.12/Seq.js";
import { Card_foot, Card_body, Card_head, Card_card, background, Option as Option_1, modal } from "../../fable_modules/Fulma.2.16.0/Components/Modal.fs.js";
import { FormField_textArea, FormField_onChangeSet, FormField_text } from "../../Optiscan.SharedUI/Forms.js";
import { SessionAnnotationViewModel_get_note_, SessionAnnotationViewModel_get_annotationName_ } from "../../Optiscan.Models/View/SessionAnnotationViewModel.js";
import { Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { Functions_flip } from "../../fable_modules/Webbler.Models.1.2.6/Common.fs.js";
import { Option as Option_2 } from "../../fable_modules/Fulma.2.16.0/Elements/Form/Textarea.fs.js";

export function sessionAnnotationsTableColumns(sendToViewportCallback) {
    return ofArray([new Sort_SortableColumnView$1("Annotation Name", (sa) => defaultArg(sa.annotationName, "N/A"), uncurry(2, void 0), new Sort_Compare$1(0, (sa1, sa2) => compare(sa1.annotationName, sa2.annotationName))), new Sort_SortableColumnView$1("Note", (sa_1) => defaultArg(sa_1.note, ""), uncurry(2, void 0), new Sort_Compare$1(0, (sa1_1, sa2_1) => compare(sa1_1.note, sa2_1.note))), new Sort_SortableColumnView$1("", (sa_2) => {
        const props = [["style", {
            float: "right",
        }]];
        const children = [Tooltip_tooltip("Send to Viewport", void 0, true, button(ofArray([new Option(17, singleton(["style", {
            marginRight: "1rem",
            paddingLeft: "0.5em",
            paddingRight: "0.5em",
        }])), new Option(18, (_arg1) => {
            sendToViewportCallback(sa_2);
        })]), ofArray([Fa_i(ofArray([new Fa_IconOption(11, "fas fa-arrow-right"), new Fa_IconOption(0, new Fa_ISize(0)), new Fa_IconOption(14, singleton(["style", {
            paddingRight: "0.25em",
        }]))]), []), Fa_i(ofArray([new Fa_IconOption(11, "fas fa-border-all"), new Fa_IconOption(0, new Fa_ISize(0))]), [])])))];
        return react.createElement("div", keyValueList(props, 1), ...children);
    }, uncurry(2, void 0), new Sort_Compare$1(0, (_arg3, _arg2) => 0))]);
}

export function sessionAnnotationsTableCard(annotations, selectedAnnotation, sortInfo, startEditCallback, startDeleteCallback, selectCallback, sortCallback, sendToViewportCallback) {
    let children;
    const props_2 = [new HTMLAttr(64, "table-container"), ["style", {
        height: "50%",
    }]];
    const children_2 = [(children = [button(ofArray([new Option(18, startEditCallback), new Option(17, singleton(["style", {
        width: "50%",
        margin: ".5em .5em .5em auto",
    }])), new Option(16, selectedAnnotation == null)]), ofArray([Fa_i(ofArray([new Fa_IconOption(11, "fas fa-pen"), new Fa_IconOption(14, singleton(["style", {
        marginRight: "0.5em",
    }]))]), []), "Edit"])), button(ofArray([new Option(0, new Color_IColor(8)), new Option(18, startDeleteCallback), new Option(17, singleton(["style", {
        width: "50%",
        margin: ".5em auto .5em auto",
    }])), new Option(16, selectedAnnotation == null)]), ofArray([Fa_i(ofArray([new Fa_IconOption(11, "fas fa-trash"), new Fa_IconOption(14, singleton(["style", {
        marginRight: "0.5em",
    }]))]), []), "Delete"]))], react.createElement("div", {
        className: "button-container",
    }, ...children)), Sort_itemViewListSortable("SessionAnnotations", sessionAnnotationsTableColumns(sendToViewportCallback), selectCallback, sortWith(uncurry(2, Sort_SortInfo$1_getSortComparison_576B9F7E(sortInfo)), annotations), (annotation) => {
        let pattern_matching_result;
        if (selectedAnnotation != null) {
            if (annotation.annotationId === selectedAnnotation.annotationId) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                return delay(() => append(singleton_1(new HTMLAttr(64, "is-selected")), delay(() => append(singleton_1(new DOMAttr(42, startEditCallback)), delay(() => append(singleton_1(["style", {
                    userSelect: "none",
                }]), delay(() => singleton_1(new HTMLAttr(99, `${annotation.annotationId}`)))))))));
            }
            case 1: {
                return delay(() => append(singleton_1(new DOMAttr(42, startEditCallback)), delay(() => append(singleton_1(["style", {
                    userSelect: "none",
                }]), delay(() => singleton_1(new HTMLAttr(99, `${annotation.annotationId}`)))))));
            }
        }
    }, sortInfo, sortCallback, (_arg1) => empty())];
    return react.createElement("div", keyValueList(props_2, 1), ...children_2);
}

export function sessionAnnotationsEditModal(annotation, disableSave, setter, saveCallBack, cancelCallback) {
    let f, optic, f_1, optic_2;
    return modal(singleton(new Option_1(1, true)), ofArray([background(empty(), empty()), Card_card(empty(), ofArray([Card_head(empty(), singleton("Edit Annotation Note")), Card_body(empty(), ofArray([FormField_text(defaultArg(annotation.annotationName, ""), empty(), [], FormField_onChangeSet(setter, uncurry(2, (f = ((optic = SessionAnnotationViewModel_get_annotationName_(), (value_1) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic)(value_1))), (b) => ((a) => Functions_flip(uncurry(2, f), b, a))))), "SessionAnnotation", "Annotation Name", []), FormField_textArea("Annotation Note", defaultArg(annotation.note, ""), empty(), [new Option_2(14, singleton(new HTMLAttr(55, true)))], FormField_onChangeSet(setter, uncurry(2, (f_1 = ((optic_2 = SessionAnnotationViewModel_get_note_(), (value_4) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic_2)(value_4))), (b_1) => ((a_1) => Functions_flip(uncurry(2, f_1), b_1, a_1))))), "SessionAnnotation", "Note")([])])), Card_foot(empty(), ofArray([button(ofArray([new Option(18, saveCallBack), new Option(16, disableSave)]), singleton("Save")), button(singleton(new Option(18, cancelCallback)), singleton("Cancel"))]))]))]));
}

export function sessionAnnotationDeleteModal(deleteCallback, cancelCallback) {
    return modal(singleton(new Option_1(1, true)), ofArray([background(empty(), empty()), Card_card(empty(), ofArray([Card_head(empty(), singleton("Delete Annotation")), Card_body(empty(), singleton("Are you sure you want to delete this annotation?")), Card_foot(empty(), ofArray([button(ofArray([new Option(18, deleteCallback), new Option(0, new Color_IColor(8))]), singleton("Delete")), button(singleton(new Option(18, cancelCallback)), singleton("Cancel"))]))]))]));
}

